import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import moment from "moment";
import NumberFormat from 'react-number-format';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getOfferList,setOfferFilter,setOfferCount,setOrderCount} from '../../actions';

class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            selectedOrderOption: { column: "date", label: <IntlMessages id="offer.orderbyDate" /> },
            sortColumn:"date",
            filter:"",
            filterField:"",  
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            offerFilter:this.props.offerFilter || 0,
            search: "",
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "date", label:"offer.orderbyDate"},
                { column: "firma_adi", label:"offer.orderbyName" }
              ],
            OfferStatus: [
                {value : 0 , label:"offer.OfferStatus0" },
                {value : 1 , label:"offer.OfferStatus1" },
                {value : 2 , label:"offer.OfferStatus2" },
                {value : 3 , label:"offer.OfferStatus3" },
                {value : 4 , label:"offer.OfferStatus4" }
              ] 
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        if (this.state.offerFilter==="Tümü"){
            this.props.getOfferList(1000,this.state.sortColumn,0,4);
        }else{
            this.props.getOfferList(1000,this.state.sortColumn,this.state.offerFilter,this.state.offerFilter)
        }
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.offerList.data !== nextProps.offerList.data) {
            let data = nextProps.offerList.data;
            const {sortColumn} = this.state
            sortColumn==="firma_adi" ? data  && data.sort((a, b) => a.firma_adi.localeCompare(b.firma_adi)) : 
            sortColumn==="date" ?  data  && data.sort((a,b)=> new Date(b.teklif_tarihi)-new Date(a.teklif_tarihi)) :
            data  && (data.sort((a, b) => (a[sortColumn] > b[sortColumn]) ? -1 : 1 ));
            if(this.props.offerFilter===0){
                this.props.setOfferCount(this.props.offerList.data.length)
            }
            if(this.props.offerFilter===1){
                this.props.setOrderCount(this.props.offerList.data.length)
            }
            const Total_TL =  data  && data.reduce((xtotal, data) => xtotal + parseInt(data.teklif_gtoplamtl, 10), 0)
            //const Total_USD = data.reduce((xtotal, data) => xtotal + parseInt(data.teklif_gtoplamdoviz, 10), 0)
            this.setState({
                offerList: data,
                totalItemCount:nextProps.offerList.data.length,
                totalPage: Math.ceil(nextProps.offerList.data.length/this.state.selectedPageSize),
                totalTL:Total_TL,
                
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state;
        const offerFilter =  this.props.offerFilter;
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
            sortColumn:column
            },()=>this.props.getOfferList(1000,this.state.selectedOrderOption,offerFilter,offerFilter));
    };
//******************************************************************************************************************************************************************/
    changePageSize = size => {
        this.setState({
            selectedPageSize: size,
            currentPage: 1,
            totalPage: Math.ceil(this.state.totalItemCount/size)
        });
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        if (f!=="Tümü"){
            this.props.getOfferList(1000,this.state.sortColumn,f,f);
        } else {
            this.props.getOfferList(1000,this.state.sortColumn,0,4);
        }
        this.props.setOfferFilter(f);
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.offerList.data.filter(
            item => {return item.firma_adi.toLowerCase().includes(search.target.value.toLowerCase()) 
                        || (item.related_person && item.related_person.toLowerCase().includes(search.target.value.toLowerCase()))
                        || (item.teklif_konu && item.teklif_konu.toLowerCase().includes(search.target.value.toLowerCase()))
                        || (item.firma_il && item.firma_il.toLowerCase().includes(search.target.value.toLowerCase()))
                        || (item.firma_ulke && item.firma_ulke.toLowerCase().includes(search.target.value.toLowerCase()))
                    })
        if (search && search.target.value===""){
          this.setState({
            offerList:this.props.offerList.data,
            totalItemCount:this.props.offerList.data &&  this.props.offerList.data.length ? this.props.offerList.data.length:0,
            totalPage: Math.ceil((this.props.offerList.data &&  this.props.offerList.data.length  ? this.props.offerList.data.length : 0) /this.state.selectedPageSize)
          })
      
        }else{
          this.setState({
            offerList:filteredList,
            totalItemCount:this.state.offerList &&  this.state.offerList.length ? this.state.offerList.length:0,
            totalPage: Math.ceil((this.state.offerList &&  this.state.offerList.length  ? this.state.offerList.length : 0) /this.state.selectedPageSize)
          })
        }
    };
//******************************************************************************************************************************************************************/
    editOffer=(id)=>{
        this.props.history.push({pathname : `/offer/${id}`})
    }
//******************************************************************************************************************************************************************/
    newOffer=(id)=>{
        this.props.history.push({pathname : `/offer/new`})
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {offerList,selectedPageSize,currentPage,totalItemCount,totalTL,sortColumn} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;  
        const offerFilter =  this.props.offerFilter;

        return (
            <>
            <div  className={this.props.offerList.loading ? "dimmer active" : ""}>
                {this.props.offerList.loading && <div className="loader"/>}
                <div className={this.props.offerList.loading ? "dimmer-content"  : ""}>

                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-md-flex justify-content-between  align-items-center ">
                                            <div className="form-group">
                                                <div className="selectgroup w-100">
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === "Tümü"} onChange={()=>this.onChangeFilter("teklif_durumu","Tümü")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="genel.all"/></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === 0} onChange={()=>this.onChangeFilter("teklif_durumu",0)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus0"/></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === 1} onChange={()=>this.onChangeFilter("teklif_durumu",1)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus1"/></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === 2} onChange={()=>this.onChangeFilter("teklif_durumu",2)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus2"/></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === 3} onChange={()=>this.onChangeFilter("teklif_durumu",3)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus3"/></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {offerFilter === 4} onChange={()=>this.onChangeFilter("teklif_durumu",4)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus4"/></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center sort_stat">
                                                <div className="d-flex">
                                                    <div className="ml-2">
                                                        <p className="mb-0 font-11">{messages["offer.offerTotal"].toUpperCase()}</p>
                                                        <h5 className="font-16 mb-0">
                                                            <NumberFormat
                                                                displayType={'text'}
                                                                fixedDecimalScale={true} 
                                                                decimalScale={2}
                                                                suffix={' ₺'} 
                                                                thousandSeparator={'.'} 
                                                                decimalSeparator={','}  
                                                                value={totalTL}  
                                                            /> 
                                                        
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex ml-3">
                                                    <div className="ml-2">
                                                        <p className="mb-0 font-11">{offerFilter !== "Tümü" && messages["offer.OfferStatus"+offerFilter].toUpperCase()}</p>
                                                        <h5 className="font-16 mb-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" className="form-control search" placeholder="Search..."onKeyPress={e => this.onSearchKey(e)} />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary"><IntlMessages id="button.action"/></button>
                                                <button data-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle" />
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" href="#1"  onClick={()=>this.newOffer()}><IntlMessages id="button.add"/></a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th onClick={()=>this.changeOrderBy("date")}  className="w100"><IntlMessages id="offer.date"/> {sortColumn === "date"&&<i class="fa fa-caret-up"></i>}</th>
                                    <th onClick={()=>this.changeOrderBy("firma_adi")}><IntlMessages id="offer.company"/> {sortColumn === "firma_adi"&&<i class="fa fa-caret-up"></i>}</th>
                                    <th className="w150"><IntlMessages id="offer.total"/></th>
                                    <th className="w120"><IntlMessages id="offer.status"/></th>
                                    <th className="w40"></th>
                                </tr>
                            </thead>
                                <tbody>
                                    {offerList &&  offerList.length  ? offerList.slice(startIndex, endIndex).map((row,i)=>{
                                        return (
                                            <tr key={i}>
                                                <td><span>{moment(row.teklif_tarihi).format("DD.MM.YYYY")}</span></td>
                                                <td>
                                                    <a href="#1"  onClick={()=>this.editOffer(row._id)} ><h6 className="mb-0">{row.firma_adi ? row.firma_adi : null}</h6></a>
                                                    <a href="#2"  onClick={()=>this.editOffer(row._id)} ><span>{row.teklif_konu ? row.teklif_konu :null}</span></a>
                                                </td>
                                                <td className="text-right">
                                                    <h6>
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            suffix={' '+row.teklif_dovizi} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={row.teklif_gtoplamdoviz ? row.teklif_gtoplamdoviz :""}  
                                                        />
                                                    </h6>
                                                    <span className="text-green">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            suffix={' ₺'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={row.teklif_gtoplamtl ? row.teklif_gtoplamtl :""}  
                                                        />
                                                    </span>
                                                </td>
                                                <td className="text-center"><span className={row.teklif_durumu ===  0 ? "tag tag-red":row.teklif_durumu ===  1 ? "tag tag-blue":row.teklif_durumu ===  2 ?"tag tag-yellow":row.teklif_durumu ===  3 ?"tag tag-green":"tag tag-black"}>{messages["offer.OfferStatus"+row.teklif_durumu]}</span></td>
                                                <td>
                                                    <div className="item-action dropdown">
                                                        <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)'}}>
                                                            <a href="#1" onClick={()=>this.editOffer(row._id)}className="dropdown-item"><i className="dropdown-icon fa fa-eye" /><IntlMessages id="offer.viewdetails"/></a>
                                                            <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-clone" /><IntlMessages id="offer.copy"/></a>
                                                            <a href={`/pdf/offer/t1/${row._id}`}  target="_blank" rel="noopener noreferrer" className="dropdown-item"><i className="dropdown-icon fa fa-print" /><IntlMessages id="offer.PDF"/></a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                    )}):null}
                                </tbody>
                        </table>
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            onChangePage={i => this.onChangePage(i)}
                        />  
                    </div>
                </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    offerList:state.offerList,
    locale:state.settings.locale,
    offerFilter:state.settings.offerFilter
})

const mapDispatchToProps = ({getOfferList,setOfferFilter,setOfferCount,setOrderCount})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Offers)));
