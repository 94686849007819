import {
    GET_COMPANY_DELIVERY_LIST,
    GET_COMPANY_DELIVERY_LIST_SUCCESS,
	GET_COMPANY_DELIVERY_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Product Stock List GET **************************************************************************************************************************************************

export const delivery_list_set_pending = () => ({
	type: GET_COMPANY_DELIVERY_LIST
});

export const delivery_list_set_successful = (res) => ({
	type: GET_COMPANY_DELIVERY_LIST_SUCCESS,
	response: res
});

export const delivery_list_set_failed = () => ({
	type: GET_COMPANY_DELIVERY_LIST_ERROR
});

export const getCompanyDeliveryList = (id) => dispatch =>{
	const apiUrl_list = `/deliveried/list/${id}`;
	dispatch(delivery_list_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(delivery_list_set_successful(res.data));
				resolve(true);
			} else {
				dispatch(delivery_list_set_failed());

			}
		})
		.catch((err) => {
			dispatch(delivery_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};

