import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import RowsDetails from './RowsDetails'
import QRCode from 'qrcode';

const BackGround = '#FFFFFF'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
        borderTopWidth: 1,
        borderColor:"blue",
        marginTop: '5',
    },
    rowDescriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        ///height: 50,
    },
    ProductImages: {
        width: 50,
       
        marginLeft: '0',
        backgroundColor: BackGround
     
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 350,
        marginLeft: '2',
        backgroundColor: BackGround,
    },
    QRImages: {
        flexDirection: 'column',
        width: 50,
        marginTop: '8',
        backgroundColor: BackGround,
        textAlign : "right"
    },
    rowDetailContainer: {
        marginTop: '10',
        borderTop :0.3,
        borderColor : "Grey",
        flexDirection: 'row',
     
    },
  });


  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;


  width:45px;
  `;
  const ProductName = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 14px;
  text-align: left;
  `;
  const ProductCode = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
  const InfoRow = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
 
  const Qty = styled.Text`
  margin-left: 2px;
  paddingTop:15px;
  font-size: 18px;
  text-align: center;

  width:85px;
  `;

const OfferTableRow = ({data,Dil}) => {

    const urun_miktari = parseFloat(data.urun_miktari).toFixed(2);

    const birim = Dil ==="tr" ? "Adet " : "Pcs"
    const bodylabel = Dil ==="tr" ? "Gövde Rengi=>  " : "Body Color=>"
    const driverlabel = Dil ==="tr" ? "Sürücü=>   " : "LED Driver=>"

    let value = window.location.origin+"/app/production/order/"+data._id
 
    let QRimg ="";
    QRCode.toDataURL(value, function(err, url) {
        QRimg=  url;
    });


    let img = "../assets/images/ry.png" ;
    if(data.urun_id &&  data.urun_id.productImages && data.urun_id.productImages[0].image  && data.urun_id.productImages[0].image.smallimage ){
        img = data.urun_id.productImages[0].image.smallimage;
    }else{
        img = "../assets/images/ry.png" ;
    }
    let code = {};
    let info = {};


    code = (data.urun_kodu[0] && data.urun_kodu[0]!=="" ? data.urun_kodu[0] + "-":"")
    + (data.urun_kodu[1] && data.urun_kodu[1]!=="" ?  data.urun_kodu[1]+"-":"")
    + (data.urun_kodu[2] && data.urun_kodu[2]!=="" ?  data.urun_kodu[2]+"-":"")
    + (data.urun_kodu[3] && data.urun_kodu[3]!=="" ?  data.urun_kodu[3]:"") 
    +(data.urun_kodu[4] && data.urun_kodu[4]!=="" ? "-"+data.urun_kodu[4]:"" )
    +(data.urun_kodu[5] && data.urun_kodu[5]!=="" ? "-"+data.urun_kodu[5]:"" )

    
    info = (data.urun_kodu[6] && data.urun_kodu[6]!=="" ? bodylabel + data.urun_kodu[6]+"  ":"")
    + (data.urun_kodu[7] && data.urun_kodu[7]!=="" ?   "LED=> " + data.urun_kodu[7]+" ":"")
    + (data.urun_kodu[8] && data.urun_kodu[8]!=="" ?  driverlabel + data.urun_kodu[8]:"")


    return(

        <View style={styles.rowContainer}>
            
            <View style={styles.rowDescriptionContainer}>
                {img ? 
                <Image style={styles.ProductImages} src={img}/>
                : <NoImage>  </NoImage>}
                
                <View style={styles.ProductNameContainer}>
                    <ProductName style={styles.ProductName}>{data.urun_adi}</ProductName>
                    <ProductCode style={styles.ProductName}>{code}</ProductCode>
                    <InfoRow style={styles.infoRow}>{info ? info: " "}</InfoRow>
                </View >
                <Qty>{urun_miktari+" "+birim}</Qty>
                
                <View style={styles.QRImages}>
                    <Image src={QRimg}></Image>
                </View>
            </View>

            <View style={styles.rowDetailContainer}>

                {data.Operations && data.Operations.map((operation) => {return( <RowsDetails operation={operation} id={data._id} key={operation._id}/>)})}


            </View> 
        </View>
  
)};
  
export default OfferTableRow