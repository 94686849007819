import React, { Component } from 'react'
import Pagination from "../../common/Pagination"
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
//import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import { store } from 'react-notifications-component';
import {getCompanyList} from '../../../actions'


class Companies extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            //selectedOrderOption: { column: "deviceno", label: <IntlMessages id="company.orderbyName" /> },
            sortColumn:"company_name",
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            companyModalOpen:false,
        };
      }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getCompanyList(null)
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.companyList.data !== nextProps.companyList.data) {
            this.setState({
                companyList: nextProps.companyList.data,
                totalItemCount:nextProps.companyList.totalItemCount,
                totalPage: Math.ceil(nextProps.companyList.totalItemCount/this.state.selectedPageSize) 
            }); 
        };  
        if (this.props.companyDetail.data !== nextProps.companyDetail.data) {
            this.setState({
                company: nextProps.companyDetail.data,
            }); 
        }; 
    }
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page
        });
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.companyList.data.filter(item => {return item.company_name.toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
                companyList:this.props.companyList.data,
                totalItemCount:this.props.companyList.data &&  this.props.companyList.data.length ? this.props.companyList.data.length:0,
                totalPage: Math.ceil((this.props.companyList.data &&  this.props.companyList.data.length  ? this.props.companyList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
                companyList:filteredList,
                totalItemCount:this.state.companyList &&  this.state.companyList.length ? this.state.companyList.length:0,
                totalPage: Math.ceil((this.state.companyList &&  this.state.companyList.length ? this.state.companyList.length : 0)/this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        this.setState({
            sortColumn:column
        });
    };

//******************************************************************************************************************************************************************/
    handleSubmitMyForm = () => {   
        const isOK = this.validateForm(this.state.errors);
        const { messages } = this.props.intl;
        if(isOK){
            if (this.state.company._id ){
                this.props.updateCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaguncellendi"],
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 3000,
                          onScreen: true
                        }
                    //});
                    },()=>this.toggleCompanyModal());
                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }else{
                this.props.createCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaolusturuldu"],//{messages[x.label]} 
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 3000,
                          onScreen: true
                        }
                    });                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }
            
            this.props.getCompanyList(null);
        }else{

        }

    }
//******************************************************************************************************************************************************************/
    deleteForm = () =>{
        let id = this.state.company._id;
        if (window.confirm("Teklifi Silmek istiyor musunuz ?")) {
            this.props.deleteCompany(id)
            .then(res=>{
                this.toggleCompanyModal();
                this.props.getCompanyList(null);
            }) 
          }else{
           
          }
    }
//******************************************************************************************************************************************************************/
    newForm = () => {
        this.props.history.push({pathname : `/companydetail/new`})
    }
//******************************************************************************************************************************************************************/
    companyDetails = (id) => {
        this.props.history.push({pathname : `/companydetail/`+id})
    };
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {companyList,selectedPageSize,currentPage,sortColumn,totalItemCount} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
        sortColumn==="company_name" ? companyList  && companyList.sort((a, b) => a.company_name.localeCompare(b.company_name)) : companyList  && (companyList.sort((a, b) => (a[sortColumn] > b[sortColumn]) ? 1 : (a[sortColumn] === b[sortColumn]) ? ((a.company_city > b.company_city) ? 1 : -1) : -1 ));

        return (
            <>
                <div className="container-fluid">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</div>
                                    <div className="page-options d-flex">
                                        <select className="custom-select w-auto"  onChange={(evt)=>this.changeOrderBy(evt.target.value)} >
                                            <option value={"company_name"}>{messages["company.orderbyName"]} </option>
                                            <option value={"company_category"}>{messages["company.orderbyCategory"]}</option>
                                            <option value={"company_status"}>{messages["company.orderbyStatus"]}</option>
                                        </select>
                                        <div className="input-icon ml-2">
                                            <span className="input-icon-addon">
                                                <i className="fe fe-search" />
                                            </span>
                                            <input type="text" className="form-control" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)}/>
                                        </div>
                                        <button type="button" className="btn btn-info ml-2" onClick={()=>this.newForm()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.add"/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>	
                    <div className="row clearfix">
                        <div className="col-12">
                            <div className="table-responsive">
                                {companyList && companyList.length ? 
                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w40">#</th>
                                            <th ><IntlMessages id="company.COMPANY" /></th>
                                            <th ><IntlMessages id="company.ADDRESS" /></th>
                                            <th className="w40" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {companyList.filter(x=>x[this.state.filterField] === this.state.filter).slice(startIndex, endIndex).map((row,i)=>{
                                        return(
                                            <tr key={i}>
                                                <td className="w60">
                                                    <span
                                                        className="avatar avatar-blue"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        data-original-title="Avatar Name"
                                                    >
                                                        {row.company_name.substring(0, 1)}
                                                    </span>
                                                </td>
                                                <td className="w300">
                                                    <div className="font-14"><a href="#1" onClick={()=>this.companyDetails(row._id)}>{row.company_name}</a></div>
                                                    <span className="text-muted"><a href={"mailto:"+row.company_email}>{row.company_email}</a></span>
                                                </td>
                                                <td className="w300">
                                                    <div className="font-12">{row.company_adress && row.company_adress.substring(0, 30)}</div>
                                                    <span className="text-muted">{row.company_district+" "+row.company_city+" "+row.company_country}</span>
                                                </td>
                                                <td>
                                                    <span className={row.company_category==="Suppliers"  ?"tag tag-danger": "tag tag-success"}><IntlMessages id={"company."+row.company_category} /></span>
                                                </td>
                                                <td>
                                                    <div className="item-action dropdown">
                                                        <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                            <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="company.email"/></a>
                                                            <a href="#3" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /><IntlMessages id="company.sms"/></a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>                    
                                        )
                                    })}
                                    </tbody>
                                </table>:
                                    <div className="loader"/>
                                }
                            </div>
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                onChangePage={i => this.onChangePage(i)}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    companyList : state.companyList,
    companyDetail:state.companyDetail
})

const mapDispatchToProps = ({getCompanyList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Companies)));