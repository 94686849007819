import {
    OFFER_GET_LIST,
    OFFER_GET_LIST_SUCCESS,
	OFFER_GET_LIST_ERROR,
	COMPANY_OFFER_GET_LIST,
    COMPANY_OFFER_GET_LIST_SUCCESS,
	COMPANY_OFFER_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	datalist:[],
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case OFFER_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case OFFER_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.offers,  
                page: action.response.page,
                totalPage:action.response.totalPage,
                totalItemCount:action.response.totalItemCount
            };
		case OFFER_GET_LIST_ERROR:
			return { ...state, loading: false };
		case COMPANY_OFFER_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case COMPANY_OFFER_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                datalist: action.response.offers,  
            };
		case COMPANY_OFFER_GET_LIST_ERROR:
			return { ...state, loading: false };		
		default: {
			return state
		}
	}
}
