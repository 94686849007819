import {
	OFFER_DETAILS_ACTION,
	OFFER_DETAILS_SUCCESS,
	OFFER_DETAILS_ERROR,
	CREATE_OFFER_ACTION,
	CREATE_OFFER_SUCCESS,
	CREATE_OFFER_ERROR,
	UPDATE_OFFER_ACTION,
	UPDATE_OFFER_SUCCESS,
	UPDATE_OFFER_FAILURE,
	DELETE_OFFER_ACTION,
	DELETE_OFFER_SUCCESS,
	DELETE_OFFER_FAILURE,
	SET_LOADING,
} from '../actions';
import { getOfferList } from '../actions/offerList';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from './auth';


export const setLoading = (val) => dispatch => {
	dispatch({
		type: SET_LOADING,
		payload: val,
	})
}

// Get - Offer Details ****************************************************************************************************************************************************
export const offer_details_pending = () => ({
	type: OFFER_DETAILS_ACTION
});

export const offer_details_success = (res) => ({
	type: OFFER_DETAILS_SUCCESS,
	response: res
});

export const offer_details_failure = () => ({
	type: OFFER_DETAILS_ERROR
});

export const getOfferDetails = (params,history) => dispatch =>{

	let	url = `/offers/${params.id}/${params.companyid}/${params.token}`

	dispatch(offer_details_pending());
	
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				if(res.data===null){
					history.push({
						pathname : "/orders"
					})
				}
				dispatch(offer_details_success(res.data));
				resolve(true);
            } else {
				dispatch(offer_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(offer_details_failure());
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			console.log(err)
            reject(err);
        });
    });
};

// POST - Offer Create ****************************************************************************************************************************************************
export const create_offer_pending = () => ({
	type: CREATE_OFFER_ACTION
});

export const create_offer_success = (res) => ({
	type: CREATE_OFFER_SUCCESS,
	response: res
});

export const create_offer_failure = (response) => ({
	type: CREATE_OFFER_ERROR,
	response:response
});

export const createOffer = (data,history) => dispatch => {
    const url = `/offers/create/`
	dispatch(create_offer_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	 
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_offer_success(res.data));
				history.push({
					pathname : "/offer/" + res.data._id
				})
				resolve(true);
			} else {
				dispatch(create_offer_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_offer_failure(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}
// update Offer ****************************************************************************************************************************************************
export const update_offer_pending = (companyId) => ({
	type: UPDATE_OFFER_ACTION,
	companyId: companyId
});

export const update_offer_successful = (res) => ({
	type: UPDATE_OFFER_SUCCESS,
	response: res
});

export const update_offer_failed = (response) => ({
	type: UPDATE_OFFER_FAILURE,
	response:response
});

export const updateOffer = (data,history) => dispatch => {
	const url = `/offers/update/${data._id}`
	dispatch(update_offer_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_offer_successful(res.data));
				resolve(true);
			} else {
                dispatch(update_offer_failed(res.data));
				reject(res);
			}
		})
		.catch((err) => {
			console.log(err)
            dispatch(update_offer_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete OFFER ****************************************************************************************************************************************************
export const delete_offer_pending = () => ({
	type: DELETE_OFFER_ACTION,
});

export const delete_offer_successful = (res) => ({
	type: DELETE_OFFER_SUCCESS,
	response:res
	
});

export const delete_offer_failed = (res) => ({
	type: DELETE_OFFER_FAILURE,
	response:res
});

export const deleteOffer = (id,history) => dispatch => {
	const url = `/offers/delete/${id}`
	dispatch(delete_offer_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_offer_successful(res.data));
				if(history ){
					history.push({
						pathname : "/offers"
					})  
				}else{
					dispatch(getOfferList(1000,"date",0,0))
				}

				resolve(true);
			} else {
				dispatch(delete_offer_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_offer_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
