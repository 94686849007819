import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from "../../helpers/IntlMessages";
import {getOrderItemList} from '../../actions';

class ProductionDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

//******************************************************************************************************************************************************************/
	componentDidMount() {
		this.props.getOrderItemList("detaillist",this.state.sortColumn,1,4);
	}
//******************************************************************************************************************************************************************/
	componentWillReceiveProps(nextProps){
		let orderedProductList = []
		if (this.props.orderItemList.data !== nextProps.orderItemList.data) {
            let OperationList =[]; 
            if(nextProps.orderItemList.data.length){
                for (let i of nextProps.orderItemList.data) {
                    if(i.Operations && i.Operations.length) {
                        for (let s of i.Operations) {
                            let row = {};
                            row.Images= null 
							row.urun_kodu = i.urun_kodu
							row.model = i.urun_kodu[0]
                            row.Company=i.teklifno && i.teklifno.firma_adi
                            row.Product=i.urun_adi
                            row.Operation= s.Operation
                            row.OrderedDate= i.siparis_tarihi
                            row.OperationDescription= s.OperationDescription 
                            row.OperationDetails=s.OperationDetails
                            row.OperationFinishTime= s.OperationFinishTime
                            row.OperationPercent=s.OperationPercent
                            row.OperationStaff= s.OperationStaff
                            row.OperationStartTime= s.OperationStartTime
                            row.OperationStatus= s.OperationStatus
                            row.Qty = i.urun_miktari
                            row.Unit = i.urun_birimi
                            row.operationID = s._id
                            row.orderItemID = i._id
                            OperationList.push(row); 
                        }
                    }
                }
			}
			const opSumm = [];
			OperationList.forEach(function (a) {
			   if ( !this[a.Operation]) {
				   this[a.Operation] = { OpName: a.Operation, operasyonAdet: 0, urunAdet:0,percent:0 };
				   opSumm.push(this[a.Operation]);
			   } 
				   this[a.Operation].operasyonAdet += 1;
				   this[a.Operation].urunAdet += a.Qty;
				   this[a.Operation].percent += a.OperationPercent;
		   }, Object.create(null));

			orderedProductList  && (orderedProductList.sort((a, b) => (a.qty > b.qty) ? -1 : 1 ))
			this.setState({
				opSumm:opSumm,
				OperationList:OperationList

			}); 
		};  
	}
//******************************************************************************************************************************************************************/

	render() {
		const { auth } = this.props;
		const {opSumm,OperationList} = this.state;
		return (
			<>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-lg-12">
								<div className="section-body mb-4">
									<h4><IntlMessages id="menu.welcome" /> {" "+auth.user} </h4>
									<small>
									 <IntlMessages id="menu.productionDashBoardMessage" />{' '}	{/*<a href="/#">Learn More</a> */}
									</small>
								</div>
							</div>
						</div>
						<div className="row clearfix row-deck">
							<div className="col-xl-12 col-lg-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Sipariş Durumları</h3>
									</div>
									<div className="card-body">
										<div className="row text-center">
											<div className="col-sm-4 border-right pb-4 pt-4">
												<label className="mb-0"><IntlMessages id="order.operationStatus0"/></label>
												<h4 className="font-30 text-col-blue ">{OperationList &&OperationList.length  && OperationList.filter(x=>x.OperationStatus===0).length}</h4>
											</div>
											<div className="col-sm-4 border-right pb-4 pt-4">
												<label className="mb-0"><IntlMessages id="order.operationStatus1"/></label>
												<h4 className="font-30  text-col-blue ">{OperationList &&OperationList.length && OperationList.filter(x=>x.OperationStatus===1).length}</h4>
											</div>
											<div className="col-sm-4 pb-4 pt-4">
												<label className="mb-0"><IntlMessages id="order.operationStatus2"/></label>
												<h4 className="font-30 text-col-blue ">{OperationList &&OperationList.length && OperationList.filter(x=>x.OperationStatus===2).length}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row clearfix">
							{opSumm && opSumm.length && opSumm.map((opResult,x)=>{
								return(
								<div className="col-lg-3 col-md-6" key={x}>
									<div className="card">
										<div className="card-body">
											<div className="card-value float-right text-azure">{parseFloat(opResult.percent/opResult.operasyonAdet).toFixed(0)}%</div>
											<h6 className="mb-1"><span className="counter"> {opResult.operasyonAdet} Adet  </span></h6>
											<div>{opResult.OpName.substring(0,12)}</div>
											<div className="mt-4">
												<div className="progress progress-xs">
													<div className="progress-bar bg-azure" style={{ width: (opResult.percent/opResult.operasyonAdet)+'%' }} />
												</div>
											</div>
										</div>
									</div>
								</div>
								)
							})}
						</div>
					</div>
				</div>

			</>
		);
	}
}
const mapStateToProps = state => ({
	auth:state.auth,
	orderItemList:state.orderItemList,
    locale:state.settings.locale,
})

const mapDispatchToProps = ({getOrderItemList})
export default connect(mapStateToProps, mapDispatchToProps)(ProductionDashboard);