import React, { Component } from 'react'
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {user_login_request} from '../../actions';
import { store } from 'react-notifications-component';
class AkaysisLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loginUser:{email:"harun@moduled.com.tr",password:"4599"}
		};
	}
    //*****************************************************************************************************************************************/
	onUserLogin = (values) => {
		//const { messages } = this.props.intl;
		if (!this.props.loading) {
			if (values.email !== "" && values.password !== "") {
				// Attempt to login
				this.props.user_login_request(this.state.loginUser, this.props.history)
				.then(res=>{
				
					let {status, explanation} = this.props.akaysisauth;
					if(status==="Failed"){
						store.addNotification({
							title: status,
							message: explanation ? explanation  : "Error",
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
				.catch((err) => {				
				});
			};
		} 
	}
    //*****************************************************************************************************************************************/
	onChangeLoginData=(field,value)=>{
		this.setState((prevState) => {
			let loginUser = Object.assign({}, prevState.loginUser);  
			loginUser[field] = value;                             
			return { loginUser};  
		});
	}

    //*****************************************************************************************************************************************/
    render() {
        return (
            <>
                <div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                    
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><IntlMessages id="menu.akaysis"/></h3>
                                    </div>
                                    <div className="card-body">
                         
                                        <div className="form-group">
                                            <label className="form-label">Email-Address</label>
                                            <input className="form-control" placeholder="your-email@domain.com" value={this.state.loginUser.email} onChange={(e)=>this.onChangeLoginData("email",e.target.value)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <input type="password" className="form-control" onChange={(e)=>this.onChangeLoginData("password",e.target.value)} />
                                        </div>
                                        <div className="form-footer">
                                            <button className="btn btn-primary btn-block" onClick={this.onUserLogin}><IntlMessages id="user.signin"/></button>
                                        </div>
                           
                                    </div>
                                </div>
                
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    akaysisauth: state.akaysisauth
})

const mapDispatchToProps =  ({user_login_request})

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AkaysisLogin)));