import {
    USER_LOGIN_ACTION,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_REAUTH_ACTION,
    USER_REAUTH_FAILURE,
    USER_REAUTH_SUCCESS,
	USER_LOGOUT_ACTION,
	CLEAR_ERRORS
} from '../actions';

const INIT_STATE = {
    email: "",
    token: "",
	user: "",
	role:null,
    loading: false}
   

const auth = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USER_LOGIN_ACTION:
			return {
				email: action.email,
				status: "pending",
			};
		case USER_LOGIN_FAILURE:
			return {
				status: "Failed",
				explanation:action.response.explanation
			};
        case USER_LOGIN_SUCCESS: 
            // localStorage.setItem('user_email', action.response.data.email);
            // localStorage.setItem('user_token', action.response.data.token);
			// localStorage.setItem('user', action.response.data.firstname +" "+action.response.data.lastname);
			// localStorage.setItem('role', action.response.data.role);
			// localStorage.setItem('status', action.response.data.status);
			return {
				status: "successful",
				email: action.response.data.email,
				token: action.response.data.token,
				user: action.response.data.firstname +" "+action.response.data.lastname,
				role:action.response.data.role,
				user_img:action.response.data.user_img,
			}
		case USER_REAUTH_ACTION: {
			return {
				...state,
				reauthing: true
			}
		}
		case USER_REAUTH_SUCCESS: {
			return {
				...state,
				status: "successful",
				email: action.response.data.email,
				user: action.response.data.firstname +" "+action.response.data.lastname,
				role:action.response.data.role,
				user_img:action.response.data.user_img,
				reauthing: null
				
			}
        }
		case USER_LOGOUT_ACTION: {
            // localStorage.removeItem('user_token');
            // localStorage.removeItem('user_email');
			// localStorage.removeItem('user');
			// localStorage.removeItem('role');
			// localStorage.removeItem('status');
			return {
				status: "",
				token: "",
				email: "",
				role:null,
				user:null
			}
		}
		case USER_REAUTH_FAILURE: {
            // localStorage.removeItem('user_token');
            // localStorage.removeItem('user_email');
			// localStorage.removeItem('user');
			// localStorage.removeItem('role');
			return {
				...state,
				reauthing: null,
				status: "failed",
				explanation: "Re-Authing Failed"
			}
		}
		case CLEAR_ERRORS:{
			return {
				...state,
				status: "",
				explanation:""
			}
		}
        default: return state;
			
	}
}

export default auth;
