import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import RowsDetails from './RowsDetails'



const BackGround = '#FFFFFF'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
        borderTopWidth: 1,
        borderColor:"blue",
        marginTop: '5',
    },
    rowDescriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        ///height: 50,
    },
    ProductImages: {
        width: 30,
        marginLeft: '0',
        backgroundColor: BackGround
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 200,
        marginLeft: '2',
        backgroundColor: BackGround,
    },
    rowDetailContainer: {
        flexDirection: 'column'     
    },
  });


  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;


  width:45px;
  `;
  const ProductName = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: left;
  `;
  const ProductCode = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
  const InfoRow = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
 
  const Qty = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;

  `;

const OfferTableRow = ({data,Dil}) => {

    const urun_miktari = parseFloat(data.urun_miktari).toFixed(2);

    const birim = Dil ==="tr" ? "Adet " : "Pcs"
    const bodylabel = Dil ==="tr" ? "Gövde Rengi=>  " : "Body Color=>"
    const driverlabel = Dil ==="tr" ? "Sürücü=>   " : "LED Driver=>"

    let img = data.urun_id && data.urun_id.urun_model && data.urun_id.urun_model.model_resim ? data.urun_id.urun_model.model_resim[0].base64:"";
    let code = {};
    let info = {};


    code = (data.urun_kodu[0] && data.urun_kodu[0]!=="" ? data.urun_kodu[0] + "-":"")
    + (data.urun_kodu[1] && data.urun_kodu[1]!=="" ?  data.urun_kodu[1]+"-":"")
    + (data.urun_kodu[2] && data.urun_kodu[2]!=="" ?  data.urun_kodu[2]+"-":"")
    + (data.urun_kodu[3] && data.urun_kodu[3]!=="" ?  data.urun_kodu[3]:"") 
    +(data.urun_kodu[4] && data.urun_kodu[4]!=="" ? "-"+data.urun_kodu[4]:"" )
    +(data.urun_kodu[5] && data.urun_kodu[5]!=="" ? "-"+data.urun_kodu[5]:"" )
    info = (data.urun_kodu[6] && data.urun_kodu[6]!=="" ? bodylabel + data.urun_kodu[6]+"  ":"")
    + (data.urun_kodu[7] && data.urun_kodu[7]!=="" ?   "LED=> " + data.urun_kodu[7]+" ":"")
    + (data.urun_kodu[8] && data.urun_kodu[8]!=="" ?  driverlabel + data.urun_kodu[8]:"")


    return(

        <View style={styles.rowContainer}>
            
            <View style={styles.rowDescriptionContainer}>
                {img ? 
                <Image style={styles.ProductImages} src={img}/>
                : <NoImage>  </NoImage>}
                
                <View style={styles.ProductNameContainer}>
                    <ProductName style={styles.ProductName}>{data.urun_adi}</ProductName>
                    <ProductCode style={styles.ProductName}>{code}</ProductCode>
                    <InfoRow style={styles.infoRow}>{info ? info: " "}</InfoRow>
                    <Qty>Sipariş Miktarı {urun_miktari+" "+birim}</Qty>
                </View >
                
                <View style={styles.rowDetailContainer}>
                    {data.Delivery && data.Delivery.map((delivery) => {return( <RowsDetails delivery={delivery} id={data._id} key={delivery._id}/>)})}
                </View> 

            </View>
            

        </View>
  
)};
  
export default OfferTableRow