import {
	ORDERITEM_DETAILS_ACTION,
	ORDERITEM_DETAILS_SUCCESS,
	ORDERITEM_DETAILS_ERROR,
	UPDATE_ORDERITEM_ACTION,
	UPDATE_ORDERITEM_SUCCESS,
	UPDATE_ORDERITEM_FAILURE,
	CREATE_ORDERITEM_ACTION,
	CREATE_ORDERITEM_SUCCESS,
	CREATE_ORDERITEM_FAILURE,
	DELETE_ORDERITEM_ACTION,
	DELETE_ORDERITEM_SUCCESS,
	DELETE_ORDERITEM_FAILURE,
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ORDERITEM_DETAILS_ACTION: //GET OFFERITEM DETAILS
			return { ...state, loading: true };
		case ORDERITEM_DETAILS_SUCCESS:
            return { ...state,loading: false, data: action.response};
		case ORDERITEM_DETAILS_ERROR:
            return { ...state, loading: false };	
		case UPDATE_ORDERITEM_ACTION: //UPDATE OFFERITEM
			return { ...state, loading: true };
		case UPDATE_ORDERITEM_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_ORDERITEM_FAILURE:
			return { ...state, loading: false,status:action.response };
		case CREATE_ORDERITEM_ACTION: //UPDATE OFFERITEM
			return { ...state, loading: true };
		case CREATE_ORDERITEM_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_ORDERITEM_FAILURE:
			return { ...state, loading: false,status:action.response };
		case DELETE_ORDERITEM_ACTION: //DELETE OFFERITEM
			return { ...state, loading: true };
		case DELETE_ORDERITEM_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_ORDERITEM_FAILURE:
			return { ...state, loading: false }; 
		default: {
			return state
		}
	}
}
