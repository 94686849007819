import React, { Component } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import { connect } from "react-redux";
import {getOfferDetails,getCompany} from '../actions';
import { injectIntl } from "react-intl";
import InvoiceT0 from "./OfferPDF/Type0/Invoice";
import InvoiceT1 from "./OfferPDF/Type1/Invoice";

class OfferPDFT1 extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    componentDidMount() {
      this.props.getOfferDetails(this.props.match.params);
      this.props.getCompany({id:1},this.props.match.params);
    };
    
    render() {     
        const {data} = this.props.offerDetails;
        const  invoiceType = this.props.invoiceType;
        const { messages } = this.props.intl;
        const company = this.props.company.data;
        const {locale} =  this.props;
        const Lang = locale;
       // console.log(invoiceType)
        return !this.props.offerDetails.loading && !this.props.company.loading ?  (
            <div>
              { data && company  ?                
              <PDFViewer   style={{height: '100vh',  width: '100vw',top: 0,left: 0}}>
                
                {Number(invoiceType)  === 1 ? 
                    <InvoiceT1 data={data} company={company} Lang={Lang} messages={messages}/>
                :
                    <InvoiceT0 data={data} company={company} Lang={Lang} messages={messages}/>
                
                }

              </PDFViewer>:<div className="loader" />}       
            </div>
        ) : (
            <div className="loader" />
        );
    }
}

const mapStateToProps = state  => ({
  offerDetails : state.offerDetail,
  company: state.myCompany,
  locale:state.settings.locale,
  invoiceType:state.settings.invoiceType,
});

 const mapDispatchToProps  =({getOfferDetails,getCompany})
 export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OfferPDFT1));

