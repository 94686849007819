import {
	GWCOMMANDS_LIST_ACTION,
	GWCOMMANDS_LIST_SUCCESS,
	GWCOMMANDS_LIST_FAILURE,
	GWCOMMANDS_ADD_ACTION,
	GWCOMMANDS_ADD_SUCCESS,
	GWCOMMANDS_ADD_FAILURE
} from '../actions';
import axios from 'axios';

import {store} from '../store';




export const gwcommands_list_set_pending = (deviceId) => ({
	type: GWCOMMANDS_LIST_ACTION,
	deviceId: deviceId
});

export const gwcommands_list_set_successful = (res) => ({
	type: GWCOMMANDS_LIST_SUCCESS,
	response: res
});

export const gwcommands_list_set_failed = () => ({
	type: GWCOMMANDS_LIST_FAILURE
});

export const gwcommands_list_request = (deviceId) => dispatch => {
	dispatch(gwcommands_list_set_pending(deviceId));
	return new Promise((resolve, reject) => {
		axios.get('https://api.akaysis.com/gateway_commands/list/' + deviceId,{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwcommands_list_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwcommands_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwcommands_list_set_failed());
			reject();
		});
	});
}

export const gwcommands_list_refresh_request = (deviceId) => dispatch => {
	return new Promise((resolve, reject) => {
		axios.get('https://api.akaysis.com/gateway_commands/list/' + deviceId,{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwcommands_list_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwcommands_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwcommands_list_set_failed());
			reject();
		});
	});
}


export const gwcommands_add_set_pending = (deviceId, command, payload) => ({
	type: GWCOMMANDS_ADD_ACTION,
	deviceId: deviceId,
	command: command,
	payload: payload
});

export const gwcommands_add_set_successful = (res) => ({
	type: GWCOMMANDS_ADD_SUCCESS,
	response: res
});

export const gwcommands_add_set_failed = () => ({
	type: GWCOMMANDS_ADD_FAILURE
});

export const gwcommands_add_request = (deviceId, command, payload) => dispatch => {
	dispatch(gwcommands_add_set_pending(deviceId, command, payload));
	return new Promise((resolve, reject) => {
		axios.post('https://api.akaysis.com/gateway_commands/create/', {
			device_id: deviceId,
			command: command,
			payload: payload
		}, {
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				dispatch(gwcommands_add_set_successful(res.data));

				dispatch(gwcommands_list_refresh_request(deviceId));			
				resolve();
			} else {
				dispatch(gwcommands_add_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwcommands_add_set_failed());
			reject();
		});
	});
}
