import { createStore,applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { SetTransform } from './transform';
import { wsMiddleware } from './middleware/ws';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { user_reauth_request } from './actions/auth';



const persistConfig = {
	key: 'root',
	storage,
	transforms: [SetTransform],
	whitelist: ['auth', 'settings',]
}

const reduxWebsocketMiddleware = reduxWebsocket();
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
    compose(
      	applyMiddleware(thunk,reduxWebsocketMiddleware,wsMiddleware),
      	// window.navigator.userAgent.includes('Chrome') ?
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : 
		// null,
    ),
);

export const persistor = persistStore(store, {}, () => {
    if(store.getState().auth.token && store.getState().auth.token !== "")
      	store.dispatch(user_reauth_request(store.getState().auth.token));
});
  


