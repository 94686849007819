import {
	MODEL_DETAILS_ACTION,
	MODEL_DETAILS_SUCCESS,
	MODEL_DETAILS_ERROR,
	CREATE_MODEL_ACTION,
	CREATE_MODEL_SUCCESS,
	CREATE_MODEL_ERROR,
	UPDATE_MODEL_ACTION,
	UPDATE_MODEL_SUCCESS,
	UPDATE_MODEL_FAILURE,
	DELETE_MODEL_ACTION,
	DELETE_MODEL_SUCCESS,
	DELETE_MODEL_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case MODEL_DETAILS_ACTION: //GET OFFER DETAILS
			return { ...state, loading: true };
		case MODEL_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case MODEL_DETAILS_ERROR:
            return { ...state, loading: false };	
            	
		case CREATE_MODEL_ACTION: //CREATE MODEL
			return { ...state, loading: true };
		case CREATE_MODEL_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_MODEL_ERROR:
			return { ...state, loading: false,data:{},status:action.response };	
		case UPDATE_MODEL_ACTION: //UPDATE MODEL
			return { ...state, loading: true };
		case UPDATE_MODEL_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_MODEL_FAILURE:
			return { ...state, loading: false ,status:action.response};
		case DELETE_MODEL_ACTION: //DELETE MODEL
			return { ...state, loading: true };
		case DELETE_MODEL_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_MODEL_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
