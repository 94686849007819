/* Moduled A.Ş. Language Texts

Table of Contents

General
User Login, Logout, Register
01. Dashboard
02.Companies / Firmalar / 


04.Error Page
*/


module.exports = {

  /* 01.Menu */
"menu.welcome": "Welcome to ",
"menu.productionDashBoardMessage":"Üretim Operasyon Özetleri",

"menu.web":"Web Interface",
"menu.dashboard": "Main",
"menu.login":"Sign In",


"menu.companies": "Companies",
"menu.companyDetail":"Company Details",
"menu.users":"Users",
"menu.companysetting":"Company Settings",
"menu.tcmb":"Currency Managment",
"menu.imagemanagment":"Image Managment",



"menu.productmanagment":"Product Managment",
"menu.modeldetail":"Model Details",
"menu.productDetail":"Product",
"menu.models":"Models",
"menu.products":"Products",
"menu.materials":"Components",

"menu.offermanagment":"Offer Managment",
"menu.offers":"Offers",
"menu.offersetting":"Offer Settings",
"menu.offer":"Offer",




"menu.ordermanagment":"Order Managment",
"menu.orderDashBoard":"Order Dashboard",
"menu.orders":"Orders",
"menu.complated":"Complated",
"menu.order":"Order Details",


"menu.productionmanagment":"Production Managment",
"menu.productionDashBoard":"Produuction Dashboard",
"menu.productionlist":"Production",
"menu.orderItemList":"Product List",
"menu.productiondetail":"Production Detail",
"menu.orderitemdetail":"Stock Production",
"menu.componentlist":"Component List",


"menu.labeleditor":"Labels Editor",
"menu.productslabels":"Products Labels",

"menu.search":"Search..",
"menu.all":"All",
"menu.choosee":"Choosee",
"menu.sort":"Short",





"menu.complatedorders":"Complated Orders",
"menu.deliveriedorders":"Deliveried Orders",





"menu.akaysis":"Akaysis",
"menu.akaysislogin":"Login",
"menu.gatewaylist":"Gateway List",
"menu.devicedetails":"Device Details",






/* GENEL TERİMLER */
"genel.unit":"Unit",
"genel.Adet":"Pcs",
"genel.Kg":"Kg",
"genel.Metre":"Meter",
"genel.Paket":"Box",
"genel.Takım":"Set",
"genel.choose":"Choose",
"genel.date":"Date",
"genel.usdSelling":"USD Selling",
"genel.eurSelling":"EUR Selling",   
"genel.usdBuying":"USD Buying",
"genel.eurBuying":"EUR Buying",   
"genel.kdv":"KDV",
"genel.all":"all",
"genel.tcmbupdate":"TCMB Currency Update",



/*DashBoard */
"dashboard.message":"Tooday  ",
"dashboards.logs": "Logs",
"dashboards.calendar":"Calendar",
"dashboards.product-categories":"Product Categories",


/*COMPANY*/
"company.Suppliers":"Supplier",
"company.Customer":"Customer",
"company.aboutme":"About",
"company.status" :"Company Status",
"company.name" :"Company Name",
"company.address" :"Company Address",
"company.contact" :"Company Contact",
"company.taxinfo" :"Tax Info",
"company.orderbyName":"Company Name",
"company.orderbyCategory":"Category",
"company.orderbyStatus":"Status",
"company.COMPANY":"COMPANY",
"company.ADDRESS":"ADDRESS",
"company.viewdetails":"Edit",
"company.email":"Send Email",
"company.sms":"SMS",
"company.info":"Company Info",
"company.detail":"Details",
"company.reportimages":"Report Logo Update",
"company.qcquestion":"QC Question",
"company.certificates":"Certificate Lists",
"company.banklist":"Bank Lists",
"company.orderedlist":"Company History",
"company.deliveryList":"Delivery List",


"image.edit":"Edit Image",
"image.model":"Model",
"image.description":"Description",
"image.models":"Moodels",
"image.image":"Image",



"mycompany.qc":"Quality  Conntrol Question",
"mycompany.logo":"Logo",
"mycompany.bwlogo":"BW Logo",
"mycompany.certificate":"Certificate",
"mycompany.certificateedit":"Certificate Edit",
"mycompany.question":"Question",
"mycompany.qcno":"No",
"mycompany.qcquestionedit":"Q.C  Question Edit",

/*BUTTON*/
"button.save":"Save",
"button.close":"Close",
"button.delete":"Edit",
"button.add":"Add",
"button.settings":"Setting",
"button.addrow":"Add Roow",
"button.rowdelete":"Remove Row",
"button.viewrowdetail":"Edit",
"button.changepassword":"Password Change",
"button.print":"Print",
"button.requestoffer":"Request Offer",
"button.productlist":"Product List",
"button.datasheet":"DataSheet",
"button.createpdf":"Create PDF",
"button.label":"Label",
"button.action":"Actions",
"button.email":"E-Mail",

/*USER*/
"user.signout":"Sign Out",
"user.signin":"Sign In",
"user.0": "Developer",
"user.1": "Manager",
"user.2": "Office Person",
"user.3": "Person",
"user.4": "Main Dealer",
"user.5": "Dealer",
"user.6": "Customer",
"user.7": "Guest",
"user.info":"User Info",
"user.detail":"Details",
"user.sortfirstname":"Short by Name",
"user.sortlastname":"Short by LastName",
"user.firstname":"Name",
"user.role":"User Role",
"user.email":"E-Mail",
"user.mobile":"Mobile Phone",
"user.changepassword":"Change Password",
"user.selectimages":"Select Image",
"user.lastname":"Last Name",
"user.viewdetails":"Edit",
"user.profile":"User Proofile",
"user.usercompany":"User Company",
"user.createddate":"Kayıt Tarihi",
"user.username":"UserName",
"user.sms":"SMS",
"user.status":"User Status",



/*MODEL  */
"model.detail":"Model Details",
"model.specs":"Model Specifications",
"model.images":"Model Images",
"model.production":"Prodution Details",
"model.kategori0":"Street Light",
"model.kategori1":"Urban Light",
"model.kategori2":"Led Flood",
"model.kategori3":"Solar Street Lamps",
"model.kategori4":"Indutrıal Lighting",
"model.kategori5":"DC Fixtures",
"model.kategori6":"LED Module",
"model.kategori7":"Solar Panel",
"model.kategori8":"Battery Groups",
"model.name":"Model Name",
"model.category":"Categories",
"model.description":"Desciription",
"model.code":"Model Code *",
"model.sira":"No",
"model.tags":"Model Labels",
"model.label":"Label",
"model.feature":"Feature",
"model.operation":"Operation",
"model.operationDescription":"Desciription",
"model.operationComponent":"Operation Components",
"model.operationedit":"Edit Ooperation",
"model.featureedit":"Edit Feature",
"model.mainphoto":"Main Photo",
"model.shortcategory":"Short by Categories",
"model.shortname":"Short by Name",
"model.downloads":"Doownloads",
"model.certificates":"Certificates",

/*ÜRÜNLER  */
"product.viewdetails":"Edit",
"product.filter":"Filter",
"product.orderbyPrice":"Short by Price",
"product.orderbyCategory":"Short by Categories",
"product.orderbyPower":"Short by Power",
"product.detail":"Product Details",
"product.specs":"Product Specifications",
"product.production":"Production Details",
"product.images":"Images",
"product.price":"Price",
"product.name":"Product",
"product.category":"Category",
"product.sira":"No",
"product.model":"Model",
"product.code":"Product Code",
"product.optic":"Optik",
"product.cct":"CCT",
"product.battery":"Battery",
"product.powerLumen":"Power /Lumen",
"product.capacity":"Product Capacity",
"product.dimensions":"Dimensions",
"product.weight":"Weight",
"product.lumen":"Product Lumen",
"product.power":"Product Power",
"product.solarpower":"Solar Power",
"product.currency":"Currency",
"product.label":"Label",
"product.feature":"Feature",
"product.operation":"Operation",
"product.materialcategory":"Category",
"product.materialgroup":"Group",
"product.materialqty":"Qty",
"product.materialprice":"Price",
"product.USDprice":"Total $",
"product.rowtotal":"Total $",
"product.operationedit":"Operation Edit",
"product.totalPriceUSD":"Row Total $",
"product.priceTL":"₺ Price",
"product.grandTotal":"Row Total $",
"product.datasheet":"DataSheet",
"product.generalspecs":"general Specifications",
"product.electricalspecs":"Electrical Specifications",
"product.downloads":"Downloads",
"product.productListSettings":"List Settings",
"product.productListType":"List Type",
"product.pricelist":"Price List",
"product.productListTextOpen":"Price List Text",
"product.pricelistuser":"Prepared",
"product.tecnicaldrawing":"Technical Drawing",
"product.image":"Image",
"product.moredetails":"More Details",
"product.labeleditor":"Label Editor",
"product.productLabelType":"Label Type",
"product.labelLanguage":"Label Language",
"product.logoOpen":"Logo Visible",
"product.qrOpen":"QR Visible",
"product.voltage":"Vorking  Voltage",
"product.boxQty":"Box Qty",
"product.operationcount":"Count",
"product.stockoperations":"Stock Operations",
"product.stockin":"Stock In",
"product.stockout":"Stock Out",
"product.stock":"Stock",
"product.spec":"Specification",
"product.newstockoperation":"New Stock Operation",

"product.copy":"Copy Specifications",
"product.paste":"Paste Specifications",
"product.complated":"Comlated",
"product.qty":"Qty",
"product.produced":"Produced",
/*MARERIAL  */
"material.qc":"Malzeme Kalite Kontrol",
"material.detail":"Malzeme Bilgileri",
"material.price":"Fiyatı",
"material.name":"Malzeme",
"material.viewdetails":"Detaylar",
"material.stock":"Stok Durumu",
"menu.materialDetail":"Malzeme",
"material.category":"Malzeme Kategorisi",
"material.group" :"Malzeme Grubu",
"material.currency":"Doviz",
"material.image":"Malzeme Resim",
"material.updateStock":"Stok Güncelleme",
/*STOCK  */

"stock.date":"Tarih",
"stock.materials":"Malzeme",
"stock.operation":"İşlem",
"stock.operationcenter":"İşlem Birimi",
"stock.oty":"Miktar",
"stock.price":"Birim Fiyat",
"stock.stock":"Stok",
"stock.edit":"Düzenle",
"stock.category":"Kategori",
"stock.group":"Grup",
"stock.unit":"Birimi",
"stock.qty":"Miktar",
"stock.description":"Açıklama",
"stock.doviz":"Doviz",
"stock.material":"Malzeme",
"stock.stockin":"Stok Girişi",
"stock.stockout":"Stok Çıkışı",
"stock.newStockOperation":"Yeni Stok Hareketi",
"stock.todaystockoutlist":"Use Today Material List",
"stock.detail":"Stock Detail",

/* OFFER */
"offer.title":" PROFORMA INVOICE ",
"offer.buyer":"BUYER",
"offer.taxoffice":"TAX OFFICE",
"offer.taxno":"TAX NO",
"offer.deliveryaddress":"DELIVERY ADDRESS",
"offer.OFFERNO":"INVOICE NO",
"offer.DATE":"DATE",

"offer.date":"Date",
"offer.company":"Company",
"offer.total":"Total",
"offer.status":"Status",
"offer.OfferStatus0": "Offer",
"offer.OfferStatus1": "Order",
"offer.OfferStatus2": "Complated",
"offer.OfferStatus3": "Deliveried",
"offer.OfferStatus4": "Canceled",
"offer.orderbyName" :"Short by Company Name",
"offer.orderbyDate" :"Short by Date", 
"offer.person":"To",
"offer.subject":"Invoice Subject",
"offer.detail":"Invoice Info",
"offer.setting":"Invoice Settting",
"offer.currencyDetail":"Currency Details",
"offer.USDDetail":"USD Info",
"offer.EURDetail":"EUR Info",
"offer.currency":"Invice Currency",
"offer.product":"Product",
"offer.qty":"Qty",
"offer.unit":"Unit",
"offer.price":"Price",
"offer.tax":"VAT",
"offer.rowtotal":"Total",
"offer.rowgrandtotal":"G.Total",
"offer.newcompany":"Add New Company",
"offer.companyedit":"Edit Company",
"offer.rowedit":"Edit Row",
"offer.modelCode":"Model",
"offer.pcbCode":"PCB",
"offer.optik":"Optic",
"offer.cct":"CCT",
"offer.solar":"Solar Panel",
"offer.battery":"Battery",
"offer.offerrowGrandTotal":"Satır  Genel Toplamı",
"offer.offerrowKDV":"Row VAT Total",
"offer.offerrowTotal":"Total",
"offer.product₺price":"Price ₺",
"offer.offerTotal":"Total",
"offer.offerKDV":"VAT",
"offer.offerGrandTotal":"General Total",
"offer.rowDelete":"Remove Row",
"offer.addRow":"Add Row",
"offer.selectProduct":"Select Product",
"offer.selectMaterial":"Select Material",
"offer.productPhoto":"Invoice Photo",
"offer.productionDetail":"Production Detail",
"offer.bodyColor":"Body Color",
"offer.LED":"LED Mark",
"offer.LEDSurucu":"LED Driver",
"offer.viewDate":"Show the Date",
"offer.stampsignOffer":"Sign and Stamp Active",
"offer.stampOffer":"Only Stamp Active",
"offer.commercialInvoice":"Commercial Invoice Active",
"offer.referance":"Reference No",
"offer.district":"District",
"offer.city":"City",
"offer.address":"Address",
"offer.country":"Country",
"offer.email":"E-Mail",
"offer.creator":"Creator",
"offer.updater":"Modifier",
"offer.offerNotes":"Invoice Notes",
"offer.offerSubNotes":"Invoice Sub Information",
"offer.viewdetails":"Edit",
"offer.PDF":"PDF View",
"offer.delete":"Delete Invoice",
"offer.copy":"Coopy Invoice",
"offer.takeoffstock":"Take Off Stock",



"order.date":"Order Date",
"order.product":"Product",
"order.percomlated":"Tamamlanma %",
"order.status":"Prooduction",
"order.viewdetails":"Details",
"order.operationStatus0":"Waiting",
"order.operationStatus1":"Devam Ediyor",
"order.operationStatus2":"Coomplated",
"order.operation":"Operation",
"order.category":"Category",
"order.productcode":"Product Code",
"order.group":"Group",
"order.material":"Material",
"order.qty":"Qty",
"order.totalqty":"Total",
"order.stock":"Stock",
"order.editoperation":"Edit",
"order.startdate":"Start Date",
"order.finishdate":"Finish Date",
"order.operationstaff":"Person",
"order.refreshCompList":"Update Material List",
"order.pdf":"PDF",
"order.save":"Update",




"order.orderStatus0": "Offer",
"order.orderStatus1": "Waiting",
"order.orderStatus2": "Devam Ediyor",
"order.orderStatus3": "Kontrol Ediliyor",
"order.orderStatus4": "Complated",
"order.orderStatus5": "Deliveried",
"order.orderStatus6": "Stock Operation",
"order.detail": "Order Details",
"order.production":"Production",
"order.delivery":"Delivery",
"order.person":"to",
"order.phone":"Phone",
"order.email":"E-Mail",
"order.bodycolor":"Boody Color :",
"order.ledmark":"LED Mark :", 
"order.driverMark":"Led Driver Mark :",
"order.cct":"LED Color :",
"order.optik":"Optic Type :",

"order.orderbyDate":"Sipariş Tarihi ile Sırala",
"order.orderbyStatus":"Durumu ile Sırala",
"order.orderbyName":"Ürün Adı ile Sırala",
"order.orderNotReady":"Ürün Listesi Eksikleri Var",
"order.orderbyOperation":"Operasyon İsmi İle Sırala",

"order.addnewOperation":"Add Operation",
"order.deleteOperation":"Delete OOperation",
"order.addNewOperation":"New Operation",
"order.operationstart":"Start",
"order.operationfinish":"Finish",
"order.operationreset":"Reset",
"order.operationcomplated":"Complated",
"order.materialcategory":"Component Category",
"order.materialgroup":"Malzeme Grup",
"order.materialqty":"Qty",
"order.deliverydate":"Delivery Date",
"order.deliverer":"Teslim Eden",
"order.receiver":"Teslim Alan",
"order.deliveryqty":"Qty",
"order.offeredit":"Edit offer",
"order.stokupdate" :"Updated Stock",
"order.adddeliveryRow":"Add Delivery",
"order.deliveryedit":"Edit Delivery",
"order.printdeliveryRow":"Print Delivery",
"order.operationInfo":"Operation Info",
"order.itemdetail":"Product İnformation",
"order.operationedit":"Edit Operation",
"order.newStockProduction":"Stock Production",
"order.productiondetails":"Production Edit",
"order.semiproducts":"Semi Products Status",
"order.description":"Description",
"order.transfertoStock":"Transfer to Stock",


"menu.buyingmanagment":"Buying Managment",
"menu.buying":"Buyings",






"logs.todayloglist":"Today's Logs",
"logs.person":"Person",
"logs.title":"Subject",
"logs.description":"Description",
"logs.date":"Date",


"delivery.receiver":"Receiver",
"delivery.deliverer":"Deliverer",
"delivery.date":"Date",
"delivery.qty":"Qty",
"delivery.status":"Status",
"delivery.total":"Total",
"delivery.price":"Price",
"delivery.status0":"Open",
"delivery.status1":"Invoiced",
"delivery.status2":"Closed",
"delivery.product":"Product",
"delivery.material":"Materials",


"buying.createreguest":"Create Reguest",
"buying.notes":"Buying Notes",
"buying.materialid":"Material ID",
"buying.materialcategory":"Material Catagory",
"buying.materialgroup":"Material Group",
"buying.material":"Material",
"buying.qty":"Request Qty",
"buying.unit":"Material Unit",

"buying.totalprice":"Total Price",
"buying.eposta" :"E-Mail",
"buying.price" :"Price",
"buying.edit":"Edit Request",




/*UYARILAR  */
"uyari.basarili":"Success",
"uyari.hata":"Error",

"uyari.sifrelerikontrolediniz":"Sifreleri Kontrol Ediniz",
"uyari.sifredegistirilmedi":"Şifre Değişttirilemedi",
"bilgi.firmaguncellendi":"Firma güncellendi.",
"bilgi.kullaniciguncellendi":"Kullanıcı güncellendi.",
"bilgi.kullaniciolusturuldu":"Kullanıcı oluşturuldu.",
"bilgi.firmaolusturuldu":"Firma oluşturuldu.",
"bilgi.sifredegistirildi":"Şifre değiştirildi",
"bilgi.modelguncellendi":"Model güncellendi.",
"bilgi.modelolusturuldu":"Model oluşturuldu.",
"bilgi.urunguncellendi":"Ürün Güncellendi.",
"bilgi.urunolusturuldu":"Ürün oluşturuldu.",
"bilgi.malzemeguncellendi":"Malzeme güncellendi.",
"bilgi.malzemeolusturuldu":"Malzeme oluşturuldu.",
"bilgi.stokhareketiguncellendi":"Stok Hareketi  Güncellendi.",
"bilgi.stokhareketiolusturuldu":"Stok Hareketi oluşturuldu.",
"bilgi.toplamlardatutarsızlık":"Toplamlarda tutarsızlık mevcut",
"bilgi.firmabilgileridoldurunuz":"Firma Bilgileri Ülke veya Firma Adı eksik",
"bilgi.teklifolusturuldu":"Teklif oluşturuldu.",
"bilgi.teklifolusturulamadı":"Teklif oluşturulamadı.",
"bilgi.teklifguncellendi":"Teklif güncellendi.",
"bilgi.satirguncellendi":"Satır Güncellendi",
"bilgi.siparisguncellendi":"Sipariş Güncellendi",
"bilgi.companyguncellendi":"Firma Bilgileri Güncellendi",
"bilgi.companyolusturuldu":"Firma oluşturuldu",
"bilgi.talepolusturuldu":"Yeni Talep Oluşturuldu",
"bilgi.satinnalmaguncellendi":"Satınalma Güncellendi",


"bilgi.sistemgirisibasarili":"Sistem  Girişi Başarılı",








"akaysis.macaddress":"Mac Address",
"akaysis.devicename":"Device Name",
"akaysis.devicecity":"City",
"akaysis.status":"Status",
};
