import React from "react";
import { Colxx } from "./CustomBootstrap";

class Pagination extends React.Component {
  componentDidMount() {}
  onChangePage(e) {
    this.props.onChangePage(e);
  }
  render() {
    const {
      totalPage = 0,
      currentPage = 1,
      numberLimit = 5,
      lastIsActive = true,
      firstIsActive = true
    } = this.props;

    let startPoint = 1;
    let endPoint = numberLimit;

    if (numberLimit > totalPage) {
      startPoint = 1;
      endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
      startPoint = 1;
      endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
      startPoint = currentPage - parseInt(numberLimit / 2, 10);
      endPoint = currentPage + parseInt(numberLimit / 2, 10);
    } else {
      startPoint = totalPage - (numberLimit - 1);
      endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;
    const points = [];
    for (var i = startPoint; i <= endPoint; i++) {
      points.push(i);
    }

    // let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    // let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    // let prevPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    // let nextPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    return totalPage > 1 ? (

      <ul className="pagination mt-2">
        { firstIsActive &&     
        <li className="page-item">
          <button className="page-link" onClick={() => this.onChangePage(1)}>
            <i className="fa fa-backward" aria-hidden="true"></i>
          </button>
        </li>}

        <li className="page-item">
          <button className="page-link" onClick={() => this.onChangePage(currentPage === 1 ? 1 : currentPage - 1)}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        </li>
          {points.map(i => {
            return (
              <li className={`page-item ${currentPage === i && "active"}`} key={i}>
                <button className="page-link" onClick={() => this.onChangePage(i)}>
                {i}
                </button>
              </li>
            );
          })}
          <li className="page-item">
            <button className="page-link" onClick={() => this.onChangePage(currentPage === totalPage ? totalPage : currentPage + 1)}>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </li>
          {lastIsActive && (
            <li className="page-item">
            <button className="page-link" onClick={() => this.onChangePage(totalPage)}>
              <i className="fa fa-forward" aria-hidden="true"></i>
            </button>
          </li>
          )}
      
      </ul>
    ) : (
      <Colxx xxs="12" className="mt-2" />
    );
  }
}

export default Pagination;
