import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom';
import './App.css';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import OrderTracking from './components/emails/ordertracking'
import PDFLayout from './components/Shared/pdfLayout';
import WebLayout from './components/Shared/WebLayout';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';

import { connect as wsConnect, disconnect as wsDisconnect } from '@giantmachines/redux-websocket';
import { store } from './store';
import {getCompany,getTcmbData} from './actions' 

const PrivateRoute = ({ component: Component,auth, ...rest }) => (
	<Route
	  {...rest}
	  render={props =>
		auth.status ===  "successful" && auth.role<=3 ? (
		  <Component {...rest}  {...props}/>
		) : (
		  <Redirect
		  {...props}
			to={{
			  pathname:  '/web',
			  state: { from: props.location },
			}}
		  />
		)
	  }
	/>
  );

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			visibility: true,
		};
	  }
//******************************************************************************************************************************************************************/
	componentDidMount(){
		
		
		store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));
		!this.props.tcmb &&  this.props.getTcmbData();
		this.props.company && this.props.company.data &&  !this.props.company.data.sirket_adi &&  this.props.getCompany({id:1})
		// this.props.getProductList(10000,1,"urun_adi","");
	}
//******************************************************************************************************************************************************************/
	componentWillUnmount(){
		store.dispatch(wsDisconnect());
	}
//******************************************************************************************************************************************************************/
	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType ,auth,locale} = this.props
		const currentAppLocale = AppLocale[locale];
		let {reauthing} = this.props.auth;
		if(reauthing){
			return (
				<div className="dimmer active">
					<div className="dimmer-content">
						<div  className="loader"></div>
					</div>
				</div>
			)
		}else{

			return (
				<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "sidebar_dark" : ""} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
					<ReactNotification />
					<IntlProvider
						locale={currentAppLocale.locale}
						messages={currentAppLocale.messages}
					>
						<Router>
							<Switch>
								<Route path="/pdf" component={PDFLayout} />
								<Route path="/web" component={WebLayout} />
								<Route path="/signup" component={SignUp} />
								<Route path="/login" component={Login} />
								<Route path="/ordertracking/:id/:companyid/:token" component={OrderTracking} />
								<Route path="/forgotpassword" component={ForgotPassword} />
								<Route path="/notfound" component={NotFound} />
								<Route path="/internalserver" component={InternalServer} />
								<Route path="/web" component={WebLayout} />
								<PrivateRoute component={Layout} auth={auth}/>
							</Switch>
						</Router>
					</IntlProvider>
					
				</div>
			);
		}
	}
}
const mapStateToProps = state => ({
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
	locale:state.settings.locale,
	auth:state.auth,
	tcmb:state.tcmb.tcmb,
	company:state.myCompany
})

const mapDispatchToProps  = ({getCompany,getTcmbData})
export default connect(mapStateToProps, mapDispatchToProps)(App)