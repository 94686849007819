import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
   
      LabelContainer: {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        height: 20,
        width:550
    },

   
});
const ProductDetails = styled.Text`
paddingLeft:2px;
margin-right: 2px;
font-size: 10px;
text-align: left;
width: 200px;
`;
const Date = styled.Text`
paddingLeft:2px;
margin-left: 2px;
font-size: 10px;
text-align: left;
width: 60px;
`;
const ImgLabel = styled.Text`
paddingLeft:2px;
margin-left: 2px;
font-size: 10px;
text-align: left;
width: 30px;
`;
const ProductQty = styled.Text`
paddingLeft:2px;
font-size: 10px;
text-align: center;
width: 60px;
`;
const Name = styled.Text`
paddingLeft:2px;
margin-right: 2px;
font-size: 10px;
text-align: right;
width: 90px;
`;
  const InvoiceItemsTable = ({Dil}) => (
    
    <View style={styles.LabelContainer}>
        <ImgLabel>{Dil==="tr" ? "Resim ":"Photo"}</ImgLabel>
        <ProductDetails>{Dil==="tr" ? "Ürün ":"Products"}</ProductDetails>
        <Date>{Dil==="tr" ? "Tarih":"Date"}</Date>
        <ProductQty>{Dil==="tr" ? "Miktar":"Qty "}</ProductQty>
        <Name>{Dil==="tr" ? "Teslim Eden":"Person"}</Name>
        <Name>{Dil==="tr" ? "Teslim Alan":"Buyer Person"}</Name>
    </View>
  );
  
  export default InvoiceItemsTable