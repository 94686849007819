import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getCompany,getProductDetails,changeLocale } from '../actions';
import LEDLightDataSheet from "./product.Datasheet/led.dataSheet";
import SolarDataSheet from "./product.Datasheet/solar.dataSheet";

class DataSheet extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        loading: true,
      };
    }

    componentDidMount() {
      const locale = this.props.match.params.lang=== '0' ?"tr":"en";
      this.props.dispatch(changeLocale(locale));

      this.props.dispatch(getProductDetails({id:this.props.match.params.id}));
      this.props.dispatch(getCompany({id:1}))
    };


    render() {     
        const product = this.props.product.data;
        const company = this.props.company.data;
        const {locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const { messages } = this.props.intl;


        return !this.props.product.loading ? (
            <Fragment>
              { product && company  ?                
              <PDFViewer  style={{height: '100vh',  width: '100vw',top: 0,left: 0}} >
                {product.model_category === 7 ?
                  <SolarDataSheet company={company} product={product} messages={messages}  Lang={Lang}/>
                  :
                  <LEDLightDataSheet company={company} product={product} messages={messages}  Lang={Lang}/>
                  }
              </PDFViewer>:<div className="loading" />}       
            </Fragment>
        ) : (
            <div className="loading" />
        );
    }
}


const mapStateToProps = state  => ({
  product: state.productDetail,
  company: state.myCompany,
  locale:state.settings.locale
});
export default injectIntl(connect(mapStateToProps)(DataSheet));