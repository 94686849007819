import {
	DEVICE_LIST_ACTION,// DEVICE LIST
	DEVICE_REFRESH_ACTION,
	DEVICE_LIST_SUCCESS,
	DEVICE_LIST_FAILURE,
} from '../actions'

const devices = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case DEVICE_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case DEVICE_REFRESH_ACTION:
			return {
				...state,
				loading: true
			};
		case DEVICE_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case DEVICE_LIST_FAILURE:
		return {
			...state,
			data: [],
			loading: false
		};
		default:{
			return state
		}
			
	}
}

export default devices;
