import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
   
    TitleContainer:{
        marginTop: 2,
        flexDirection: "row",
        borderBottomWidth: 0.2,
        width:550,
        fontFamily: 'OpenSansFontBold',
    },
});

const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
`;
  const Title = ({data,messages}) => {
    return (
    <View style={styles.TitleContainer}>
        {data.commercial_invoice===true ? <Titlestyle># COMMERCIAL INVOICE #</Titlestyle> : <Titlestyle>{messages["offer.title"]}</Titlestyle>}
    </View>
  )};
  
  export default Title