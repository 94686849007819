import React, { Component } from 'react'
import { connect } from 'react-redux';
import FileBase64 from '../../helpers/FileBase64';
import { store } from 'react-notifications-component';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getModelList,getMaterialsList,createModel,updateModel,deleteModel,getModelDetails,getCompany,copyProductSpecs,getProductList} from '../../actions';


const createOption = (label) => ({
    label,
    value: label
});
  
type State = {
materialCategory: [{ [string]: string }],
value: string | void,
};

class ModelDetails extends Component < * ,State>{
    constructor(props) {
        super(props);
        this.inputReference = React.createRef();
        this.state = {
            fileUploadState:"",
            specIndex:null,
            specRowModal:false,
            productionRowModal:false,
            isLoading:false,
            modelList:this.props.modelList.data,
            errors:{
                model_adi:"",
                model_kategori:"",
                model_kodu:""
            },
            categoryList : [
                {value : 0 , label : "model.kategori"+0},
                {value : 1 , label : "model.kategori"+1},
                {value : 2 , label : "model.kategori"+2},
                {value : 3 , label : "model.kategori"+3},
                {value : 4 , label : "model.kategori"+4},
                {value : 5 , label : "model.kategori"+5},
                {value : 6 , label : "model.kategori"+6},
                {value : 7 , label : "model.kategori"+7},
                {value : 8 , label : "model.kategori"+8},
            ],
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            KDVList : [
                { value: '18', label: '18' },
                { value: '8', label: '8' },
                { value: '0', label: '0' }
            ],
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        if(this.props.match.params.id==="new"){
            this.setState({
                model:{
                    model_sira:1,
                    model_adi:["","-"],
                    model_kodu:"",
                    model_aciklama:["","-"],
                    model_birimi:"Adet",
                    model_kdv:"18",
                    model_kategori:"",
                    model_durumu:true,
                    modeldata:["",""],
                    productiondetail:[],
                    ModelFeatures:[],
                    model_resim:[]
                }
            }); 
        }else{
            this.props.getModelDetails({id:this.props.match.params.id})
            .then(res=>{
                this.setState({
                    model: this.props.modelDetail.data,
        
                }); 
            })
        }
        this.props.getModelList("productionlist","");
        !this.props.company.data.certificates && this.props.getCompany({id:1},this.props.match.params);

    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.modelDetail.data !== nextProps.modelDetail.data) {
        this.setState({
            model: nextProps.modelDetail.data,
        }); 
        };  
    }
//***********************************************************************************************************************************************************/
    onChangeModelArrayData = (field,array,data) => {
        const { locale } = this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        let errorVar = {};
        let newArray = [];
        let err = ""
        newArray.push(Lang===0 ? data :array[0] && array[0]!=="" ? array[0]:"");
        newArray.push(Lang===1 ? data :array[1] && array[0]!=="" ? array[1]:"-");
        if(field==="model_adi"){
            err = data.length < 4 ? "Model Adı Çok Kısa /  Model Name Very  Short"  :  ""
            errorVar.model_adi = err  ;
        }
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model); 
            let errors = Object.assign({}, prevState.errors);  
            model[field] = newArray;  
            errors[field] = err
            return { model,errors };    
        });
    }
//***********************************************************************************************************************************************************/
    onChangeModelData = (field,data) => {
        const {errors} = this.state;
        switch (field) {
			case 'model_kodu': 
			errors.model_kodu = data.length < 2
				? 'Write to Model Code'
				: '';
			break;
			case 'model_kategori': 
				errors.model_kategori = data ===""
				? 'Write to Model Category'
				: '';
				break;                      
			default:
				break;
		}
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            let errors = Object.assign({}, prevState.errors); 
            model[field] = data;   
            return { model,errors };    
        });
    }
//***********************************************************************************************************************************************************/
    handleTagChange = (field,data) => { 
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  // creating copy of state variable jasper
            //let model_etiket = model_etiket
            model[field] = data;                     // update the name property, assign a new value                 
            return { model };    
        });
    };
//******************************************************************************************************************************************************************/
    modelSave=()=>{
        const { messages } = this.props.intl;
        if(this.state.model._id){
            this.props.updateModel(this.state.model)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.modelguncellendi"],
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
            })
            .catch(err=>{
                if(err){
                    store.addNotification({
                        title: this.props.modelDetail.status.statusText,
                        message: this.props.modelDetail.status.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                }
            })
        }else{
            this.props.createModel({...this.state.model}, this.props.history)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.modelolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });    
            })
        }
    }
//******************************************************************************************************************************************************************/
    modelDelete=()=>{
        let id = this.state.model._id;
		if (window.confirm("Modeli Silmek istiyor musunuz ?")) {
			this.props.deleteModel(id)
			.then(res=>{
                this.props.history.push({pathname : `/models`})
			}) 
		}else{
		
		}
    }
//******************************************************************************************************************************************************************/
    gotoModels=()=>{
        this.props.history.push({pathname : `/models`})
    }
//******************************************************************************************************************************************************************/
    toggleSpecRowModal=(id)=>{
        this.setState({
            specRowModal: !this.state.specRowModal,
            specIndex:id
        });
    }
//***********************************************************************************************************************************************************/
    AddNewSpecRow = () => {
        var newItemArray = this.state.model.ModelFeatures;
        const newRow = {
            no:newItemArray.length ? newItemArray.length +1 :1,
            label:["",""],
            feature: ["",""]
        }
        newItemArray.push(newRow);
        const id = newItemArray.length-1
        this.setState((prevState) => {
            let model = Object.assign({}, prevState.model);  // creating copy of state variable 
            model.ModelFeatures = newItemArray;                     // update the offer property, assign a newitemval               
        return { model,specIndex:id ,specRowModal: !this.state.specRowModal };  
        });
    };
//***********************************************************************************************************************************************************/
    deleteSpecRow = index => {
        if (this.state.model.ModelFeatures.length>0){ 
            var newItemArray = this.state.model.ModelFeatures;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let model = Object.assign({}, prevState.model); 
                model.ModelFeatures= newItemArray;                                
                return { model,specRowModal:false }
            });
        }
    };
//***********************************************************************************************************************************************************/
    onChangeSpecRow = (field ,array,data,index,lang) => {
        let newArray = []
        if(field==="no"){
            newArray=data;
        }else{
            newArray.push(lang===0 ? data :array[0] && array[0]!=="" ? array[0]:"");
            newArray.push(lang===1 ? data :array[1] && array[0]!=="" ? array[1]:"-");
        }
        let newItemArray = this.state.model.ModelFeatures.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, [field]: newArray};
        });
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.ModelFeatures = newItemArray;                               
        return { model }
        });
    }
//******************************************************************************************************************************************************************/
    toggleProductionRowModal=(id)=>{
        this.props.modelList.data.length &&  this.props.materials.data.length &&
        this.setState({
            productionRowModal: !this.state.productionRowModal,
            productionIndex:id
        });
    }
    onClickProductionTab=()=>{
        !this.props.materials.data.length && this.props.getMaterialsList("list");
        this.props.getProductList("list","model_category") 
    }

//***********************************************************************************************************************************************************/
    AddNewProductionRow = () => {
        var newItemArray = this.state.model.productiondetail;
        const newRow = {
            no:newItemArray.length ? newItemArray.length +1 :1,
            Operation:"",
            OperationDescription:"",
            OperationComponent:[]
        }
        newItemArray.push(newRow);
        const id = newItemArray.length-1
        this.setState((prevState) => {
            let model = Object.assign({}, prevState.model);  // creating copy of state variable 
            model.productiondetail = newItemArray;                     // update the offer property, assign a newitemval               
        return { model,productionIndex:id ,productionRowModal: !this.state.productionRowModal };  
        });
    };
//***********************************************************************************************************************************************************/
    deleteProductionRow = index => {
        if (this.state.model.productiondetail.length>0){ 
            var newItemArray = this.state.model.productiondetail;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let model = Object.assign({}, prevState.model); 
                model.productiondetail= newItemArray;                                
                return { model,productionRowModal:false }
            });
        }
    };
//***********************************************************************************************************************************************************/
    onChangeProductionRow = (field,data,index) => {
        let newArray = []
        if(field==="No" || field==="OperationDescription"){
            newArray=data;
        }else{

        }
        let newItemArray = this.state.model.productiondetail.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, [field]: newArray};
        });
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.productiondetail = newItemArray;                               
        return { model }
        });
    }
//******************************************************************************************************************************************************************/
    productionRowModalonOpen=()=>{
        let _oplist =[];
        for (let x of  this.props.modelList.data) {
            if(x.productiondetail){
                for (let i of x.productiondetail) { 
                _oplist.push(i.Operation)
                }
            }
        }
        let operationList=[];
        [...new Map(_oplist && _oplist.map(x=> [x,x])).values()].forEach(row => {
          operationList.push({
                value: row,
                label: row
          });
        });
        operationList.push({value:"Yarı Mamül",label:"Yarı Mamül"})
        this.setState({
            operationList:operationList,
        }); 
    }
//***********************************************************************************************************************************************************/
    onCreatableSelect = (index,field,newValue) => {
        const { messages } = this.props.intl;
        let materialOptions=[]; // Material Seçim Listesi

        let newItemArray = this.state.model.productiondetail.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,[field]:  newValue && newValue.value ? newValue.value:""};
        });
        if(newValue.value==="Yarı Mamül"){
          
            const {productList} = this.props;
            [...new Map(productList.data && productList.data.map(x=> [x.model_category,x])).values()].forEach(row => {
                materialOptions.push({
                    value: row.model_category,
                    label: messages["model.kategori"+row.model_category]
                });
            });
        }else{
            const {materials} = this.props;
            [...new Map(materials.data && materials.data.map(x=> [x.material_category,x])).values()].forEach(row => {
                materialOptions.push({
                    value: row.material_category,
                    label: row.material_category
                });
            });
        }
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.productiondetail = newItemArray                          
        return { model, materialOptions:materialOptions};    
        });
    };
//***********************************************************************************************************************************************************/
    handleCreate = (inputValue) => {
        this.setState({ isLoading: true });
        const { operationList } = this.state;
        const newOption = createOption(inputValue);
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.Operation = newOption && newOption.value ? newOption.value:"";                          
            return { model,isLoading: false, operationList: [...operationList, newOption]};    
        });
    };
//***********************************************************************************************************************************************************/
    handleChangeOperationComponent = (index,evt) => {
        let newItemArray = this.state.model.productiondetail.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,OperationComponent: evt};
        });
        this.setState((prevState) => {
            let model = Object.assign({}, prevState.model);  // creating copy of state variable jasper
            model.productiondetail = newItemArray;                     // update the name property, assign a new value                 
            return { model };  
        });
    }
//******************************************************************************************************************************************************************/
    getFiles=(files)=>{
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  // creating copy of state variable jasper
            model.modelDownloads = files;                     // update the name property, assign a new value                 
            return { model };    
        });
    }
//******************************************************************************************************************************************************************/
    modelDownloadsRowDelete=(index)=>{
        if (this.state.model.modelDownloads.length>0){ 
            var newItemArray = this.state.model.modelDownloads;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let model = Object.assign({}, prevState.model); 
                model.modelDownloads= newItemArray;                                
                return { model }
            });
        }
    }
//******************************************************************************************************************************************************************/
    modelSpecCopy=()=>{
        let newItemArray = this.state.model.ModelFeatures
        this.props.copyProductSpecs(newItemArray,"modelSpecs")
    }
//******************************************************************************************************************************************************************/
    modelSpecPaste=()=>{
        const newItemArray = this.props.copy;
        const  copyForm = this.props.copyForm;
        if(newItemArray &&  copyForm === "modelSpecs"){
            this.setState((prevState) => {
                let model = Object.assign({}, prevState.model);  // creating copy of state variable 
                model.ModelFeatures = newItemArray;                     // update the offer property, assign a newitemval               
                return { model};  
            });
        }else{
            if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
        }
    }
//******************************************************************************************************************************************************************/
    render() {
        const { locale} = this.props;
        const {certificates}  = this.props.company.data
        const Lang = locale === "tr" ? 0  : 1 ;
        const { messages } = this.props.intl;
        const { model,errors ,categoryList,UnitList ,KDVList,specIndex,productionIndex,isLoading,operationList,materialOptions} = this.state;


        return (
            <div  className={this.props.match.params.id!=="new" && this.props.modelDetail.loading ? "dimmer active" : ""}>
                {this.props.match.params.id!=="new" && this.props.modelDetail.loading && <div className="loader"/>}
                <div className={this.props.match.params.id!=="new" && this.props.modelDetail.loading ? "dimmer-content"  : ""}>
                    <div className="section-body">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#model-detail"><IntlMessages id="model.detail"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#model-specs"><IntlMessages id="model.specs"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#model-production" onClick={()=>this.onClickProductionTab()}><IntlMessages id="model.production"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#model-images"><IntlMessages id="model.images"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#model-downloads"><IntlMessages id="model.downloads"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-3">
                        <div className="container-fluid">
                            <div className="tab-content taskboard">
                                <div className="tab-pane fade show active" id="model-detail" role="tabpanel">
                                    <div className="card">
                                    <div className="card-body">
                                    {model && model.model_adi && 
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.sira" /></label>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Model Sira"
                                                            value={model &&  model.model_sira ? model.model_sira :"" }
                                                            onChange={(evt)=>this.onChangeModelData("model_sira",evt.target.value)}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.name" /></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className={errors.model_adi === "" ? "form-control is-valid" :"form-control is-invalid"}
                                                        placeholder="Model Adı"
                                                        value={model &&  model.model_adi[Lang] ? model.model_adi[Lang] :"" }
                                                        onChange={(evt)=>this.onChangeModelArrayData("model_adi",model.model_adi ,evt.target.value)}
                                                    />
                                                    <div className="invalid-feedback">{errors.model_adi}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.code"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className={errors.model_kodu === "" ? "form-control is-valid" :"form-control is-invalid"}
                                                        placeholder="Model Kodu"
                                                        value={model &&  model.model_kodu ? model.model_kodu :"" }
                                                        onChange={(evt)=>this.onChangeModelData("model_kodu" ,evt.target.value)}
                                                    />
                                                    <div className="invalid-feedback">{errors.model_kodu}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.description"/></label>
                                                    <textarea
                                                        type="text"
                                                        rows={6}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Model Açıklama"
                                                        value={model &&  model.model_aciklama[Lang] ? model.model_aciklama[Lang] :"" }
                                                        onChange={(evt)=>this.onChangeModelArrayData("model_aciklama",model.model_aciklama ,evt.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.category"/></label>
                                                    <select 
                                                        className={errors.model_kategori === "" ? "form-control is-valid" :"form-control is-invalid"}
                                                        onChange={(evt)=>this.onChangeModelData("model_kategori",evt.target.value)}
                                                        defaultValue={model.model_kategori && model.model_kategori}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {categoryList.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                            )
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.model_kategori}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="genel.unit"/></label>
                                                    <select 
                                                        className="form-control"
                                                        onChange={(evt)=>this.onChangeModelData("model_birimi",evt.target.value)}
                                                        defaultValue={model.model_birimi && model.model_birimi}
                                                    >
                                                        {UnitList.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="genel.kdv"/></label>
                                                    <select 
                                                        className="form-control"
                                                        onChange={(evt)=>this.onChangeModelData("model_kdv",evt.target.value)}
                                                        defaultValue={model.model_kdv && model.model_kdv}
                                                    >
                                                        {KDVList.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{x.label} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.tags"/></label>
                                                    <TagsInput
                                                        value={model.model_etiket ? model.model_etiket :[]}
                                                        onChange={(e)=>this.handleTagChange("model_etiket",e)}
                                                    />
                                                </div>
                                            </div>
                                            {certificates && <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.certificates"/></label>
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            value={model.certificates}
                                                            onChange={(e)=>this.onChangeModelData("certificates",e)}
                                                            options={certificates.map(x=>({label:x.certificate,value:x.certificate}))}
                                                        />
                                                </div>
                                            </div>}

                                        </div>
                                    }
                                    </div>
                                    <div className="card-footer text-right">
                                        {model  && model._id &&
										<a href="#1" className="btn btn-outline-danger" onClick={()=>this.modelDelete()}>
											<IntlMessages id="button.delete" />
										</a>}{" "}
                                        <a href="#1" className="btn btn-outline-success" onClick={()=>this.modelSave()}><IntlMessages id="button.save"/></a>{" "}
                                        <a href="#1" className="btn btn-outline-info"  onClick={()=>this.gotoModels()}>
											<IntlMessages id="button.close" />
										</a>
                                    </div>
                                </div>

                            </div>
                                <div className="tab-pane fade show" id="model-specs" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            {/* <h4 className="card-title"><a href={`/order/${row._id}`} >{row.firma_adi ? (rowIndex+1) + " - " +row.firma_adi : null}</a></h4> */}
                                            <div className="card-options">
                                                <div className="item-action dropdown ml-2">
                                                    <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#1" className="dropdown-item" onClick={this.modelSpecCopy}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.copy"/></a>
                                                        <a href="#2" className="dropdown-item" onClick={this.modelSpecPaste}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.paste"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w40">#</th>
                                                            <th><IntlMessages id="model.label"/></th>
                                                            <th><IntlMessages id="model.feature"/></th>
                                                            <th className="w40" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {model && model.ModelFeatures && model.ModelFeatures.length ?
                                                            model.ModelFeatures.map((featuresRow,specKeyIndex)=>{
                                                                return(
                                                                    <tr key={specKeyIndex}>
                                                                        <td>
                                                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{specKeyIndex+1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{featuresRow.label[0]}</h6>
                                                                            <span>{featuresRow.label[1]}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{featuresRow.feature[0]}</h6>
                                                                            <span>{featuresRow.feature[1]}</span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="item-action dropdown">
                                                                                <a href="fake_url" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.toggleSpecRowModal(specKeyIndex)}><i className="dropdown-icon fa fa-eye"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                    <a href="#2" className="dropdown-item" onClick={()=>this.deleteSpecRow(specKeyIndex)}><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="button.rowdelete"/></a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )})
                                                                :null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <a href="#1" className="btn btn-outline-warning" onClick={()=>this.AddNewSpecRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></a>{" "}
                                            <a href="#1" className="btn btn-outline-success" onClick={()=>this.modelSave()}><IntlMessages id="button.save"/></a>{" "}
                                            <a href="#1" className="btn btn-outline-info"  onClick={()=>this.gotoModels()}><IntlMessages id="button.close" /></a>{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="model-production" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w40">#</th>
                                                            <th className="w170"><IntlMessages id="model.operation"/></th>
                                                            <th className="w170"><IntlMessages id="model.operationDescription"/></th>
                                                            <th><IntlMessages id="model.operationComponent"/></th>
                                                            <th className="w40" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {model && model.productiondetail && model.productiondetail.length ?
                                                            model.productiondetail.map((productionRow,productionKey)=>{
                                                                return(
                                                                    <tr key={productionKey}>
                                                                        <td> 
                                                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{productionRow.No}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{productionRow.Operation}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{productionRow.OperationDescription}</h6>
                                                                        </td>
                                                                        <td>
                                                                            {productionRow && productionRow.OperationComponent && productionRow.OperationComponent.map((component,ComponentIndex)=>{
                                                                                return(
                                                                                    <span className="tag tag-green mr-1 mb-1" key={ComponentIndex}>{component.label}</span>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                        <td>
                                                                            <div className="item-action dropdown">
                                                                                <a href="fake_url" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.toggleProductionRowModal(productionKey)}><i className="dropdown-icon fa fa-eye"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                    <a href="#2" className="dropdown-item" onClick={()=>this.deleteProductionRow(productionKey)}><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="button.rowdelete"/></a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )})
                                                                :null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <a href="#1" className="btn btn-outline-warning" onClick={()=>this.AddNewProductionRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></a>{" "}
                                            <a href="#1" className="btn btn-outline-success" onClick={()=>this.modelSave()}><IntlMessages id="button.save"/></a>{" "}
                                            <a href="#1" className="btn btn-outline-info"  onClick={()=>this.gotoModels()}><IntlMessages id="button.close" /></a>{" "}
                                        </div>
                                    </div>

                                </div>
                                <div className="tab-pane fade show" id="model-downloads" role="tabpanel">                              
                                    <div className="row row-cards">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="page-options d-flex">
                                                        <FileBase64 multiple={ true }  onDone={this.getFiles} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-deck">
                                        {model && model.modelDownloads && model.modelDownloads.length &&
                                            model.modelDownloads.map((downloadsRow,downloadKey)=>{
                                                return(                                  
                                                    <div className="col-sm-6 col-lg-4" key={downloadKey}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "image" &&  <img src={downloadsRow.base64} alt="moduled" className="rounded" />}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "application" && <iframe title={downloadsRow.file} src={downloadsRow.base64} width="100%" height="100%"/>}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "application" &&  downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "image" && <img src="../assets/images/ry.png" alt="moduled" className="rounded" />}
                                                            </div>
                                                            <div className="card-footer row  text-right">
                                                                <div className="col-10 py-1"><strong>{downloadsRow.name} / {downloadsRow.size}</strong></div>
                                                                <div className="col-2 py-1"><a href="#1" className="btn btn-outline-danger" onClick={()=>this.modelDownloadsRowDelete(downloadKey)}><IntlMessages id="button.delete" /></a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.specRowModal}
                        toggle={this.toggleSpecRowModal}
                        size="lg"
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="model.featureedit"/>
                    </ModalHeader>
                        <ModalBody>
                            {model && model.ModelFeatures && 
                                <div className="row clearfix">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                        <label className="form-label">#</label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Sira"
                                                                value={model &&  model.ModelFeatures[specIndex] && model.ModelFeatures[specIndex].no ? model.ModelFeatures[specIndex].no :"" }
                                                                onChange={(evt)=>this.onChangeSpecRow("no",evt,evt.target.value,specIndex)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="model.label" /></label>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Etiket"
                                                                value={model &&  model.ModelFeatures[specIndex] && model.ModelFeatures[specIndex].label ? model.ModelFeatures[specIndex].label[0] :"" }
                                                                onChange={(evt)=>this.onChangeSpecRow("label",model.ModelFeatures[specIndex].label,evt.target.value,specIndex,0)}
                                                            />
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Label"
                                                                value={model &&  model.ModelFeatures[specIndex] && model.ModelFeatures[specIndex].label ? model.ModelFeatures[specIndex].label[1] :"" }
                                                                onChange={(evt)=>this.onChangeSpecRow("label",model.ModelFeatures[specIndex].label,evt.target.value,specIndex,1)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="model.feature" /></label>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Özellik"
                                                                value={model &&  model.ModelFeatures[specIndex] && model.ModelFeatures[specIndex].feature ? model.ModelFeatures[specIndex].feature[0] :"" }
                                                                onChange={(evt)=>this.onChangeSpecRow("feature",model.ModelFeatures[specIndex].feature,evt.target.value,specIndex,0)}
                                                            />
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Feature"
                                                                value={model &&  model.ModelFeatures[specIndex] && model.ModelFeatures[specIndex].feature ? model.ModelFeatures[specIndex].feature[1] :"" }
                                                                onChange={(evt)=>this.onChangeSpecRow("feature",model.ModelFeatures[specIndex].feature,evt.target.value,specIndex,1)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </ModalBody>
                        <ModalFooter>
                            {model && model.ModelFeatures &&
                            <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteSpecRow(specIndex)}>
                                <IntlMessages id="button.delete" />
                            </button>}{"  "}
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleSpecRowModal()}>
                                <IntlMessages id="button.close" />
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        isOpen={this.state.productionRowModal}
                        toggle={this.toggleProductionRowModal}
                        size="lg"
                        //wrapClassName="modal-right"
                        onOpened={() => this.productionRowModalonOpen()} 
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="model.operationedit"/>
                    </ModalHeader>
                    <ModalBody>
                        {model && model.productiondetail && model.productiondetail[productionIndex] &&
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label">#</label>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Operasyon Sira"
                                                            value={model &&  model.productiondetail[productionIndex] && model.productiondetail[productionIndex].No ? model.productiondetail[productionIndex].No :"" }
                                                            onChange={(evt)=>this.onChangeProductionRow("No",evt.target.value,productionIndex)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.operation" /></label>
                                                        <CreatableSelect
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isDisabled={isLoading}
                                                            isLoading={isLoading}
                                                            onCreateOption={this.handleCreate}
                                                            value={operationList && operationList.length &&  operationList.filter(option => option.value === model.productiondetail[productionIndex].Operation)}
                                                            onChange={(e)=>this.onCreatableSelect(productionIndex,"Operation",e)} 
                                                 
                                                            options={operationList}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.operationDescription" /></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Açıklama"
                                                            value={model.productiondetail[productionIndex].OperationDescription ? model.productiondetail[productionIndex].OperationDescription :"" }
                                                            onChange={(evt)=>this.onChangeProductionRow("OperationDescription",evt.target.value,productionIndex)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="model.operationComponent" /></label>
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select w-40"
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            value={model.productiondetail[productionIndex].OperationComponent}
                                                            onChange={(e)=>this.handleChangeOperationComponent(productionIndex,e)}
                                                            options={materialOptions}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>
                        {model && model.ModelFeatures &&
                        <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteProductionRow(productionIndex)}>
                            <IntlMessages id="button.delete" />
                        </button>}{"  "}
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleProductionRowModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    modelDetail:state.modelDetail,
    modelList:state.modelList,
    productList:state.productList,
    materials:state.materials,
    company:state.myCompany,
    locale:state.settings.locale,
    copy:state.settings.copy,
    copyForm:state.settings.copyForm
})

const mapDispatchToProps  =({getModelList,getMaterialsList,createModel,updateModel,deleteModel,getModelDetails,getCompany,copyProductSpecs,getProductList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ModelDetails)));
