import React, { Component } from 'react'
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import FileBase64 from '../../../helpers/FileBase64';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { store } from 'react-notifications-component';
import IntlMessages from "../../../helpers/IntlMessages";
import {getCompany,updatemyCompany,createmyCompany} from '../../../actions';

class myCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myCompany:this.props.company.data,
            certificateRowModal:false,
            QCRowModal:false


        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getCompany({id:1},this.props.match.params);
    };
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.company.data !== nextProps.company.data) {
            this.setState({
                myCompany: nextProps.company.data,
            }); 
        };    
    }
//******************************************************************************************************************************************************************/
    gotoDashboard=()=>{
        this.props.history.push({pathname : `/`})
    }
//******************************************************************************************************************************************************************/
    togglecertificateRowModal=(certificateKeyIndex)=>{
        this.setState({
            certificateKeyIndex:certificateKeyIndex,
            certificateRowModal: !this.state.certificateRowModal,
        }); 
    }
//***********************************************************************************************************************************************************/
    AddNewCertificateRow = () => {
        var newItemArray = this.state.myCompany.certificates;
        const newRow = {
            certificate:"",
            file:"",
            bwfile: ""
        }
        newItemArray.push(newRow);
        const id = newItemArray.length ? newItemArray.length -1 :  0
        this.setState((prevState) => {
            let myCompany = Object.assign({}, prevState.myCompany);  // creating copy of state variable 
            myCompany.certificates = newItemArray;                     // update the offer property, assign a newitemval               
        return { myCompany,certificateKeyIndex:id ,certificateRowModal: !this.state.certificateRowModal };  
        });
    };
//***********************************************************************************************************************************************************/
    deleteCertificatesRow = index => {
        if (this.state.myCompany.certificates.length>0){ 
            var newItemArray = this.state.myCompany.certificates;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let myCompany = Object.assign({}, prevState.myCompany); 
                myCompany.certificates= newItemArray;                                
                return { myCompany,certificateRowModal:false }
            });
        }
    };
//***********************************************************************************************************************************************************/
    onChangeCertificatesRow = (field,data,index) => {
        let newItemArray = this.state.myCompany.certificates.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, [field]: data};
        });
        this.setState(prevState => {
            let myCompany = Object.assign({}, prevState.myCompany);  
            myCompany.certificates = newItemArray;                               
        return { myCompany }
        });
    }
//******************************************************************************************************************************************************************/
    getLogo=(field,files,index)=>{
        let newItemArray = this.state.myCompany.certificates.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,[field]: files.base64};
        });
        this.setState(prevState => {
            let myCompany = Object.assign({}, prevState.myCompany);  // creating copy of state variable jasper
            myCompany.certificates = newItemArray;
            return { myCompany };    
        });
    }
//******************************************************************************************************************************************************************/
    myCompanySave = () => {
        const { messages } = this.props.intl;
        if (this.state.myCompany && this.state.myCompany._id){
          this.props.updatemyCompany({...this.state.myCompany}, this.props.history)
          .then(res=>{
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.companyguncellendi"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
        })
        .catch(err=>{
            if(err){
            store.addNotification({
                title: this.props.myCompany.status.statusText,
                message: this.props.myCompany.status.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
            }
        })
        }else{
          this.props.createmyCompany({...this.state.myCompany}, this.props.history)
          .then(res=>{
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.companyolusturuldu"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
        })
        .catch(err=>{
            if(err){
            store.addNotification({
                title: this.props.myCompany.status.statusText,
                message: this.props.myCompany.status.data,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
            }
        })
        }
      }
//******************************************************************************************************************************************************************/
    toggleQCRowModal=(QCKeyIndex)=>{
        this.setState({
            QCKeyIndex:QCKeyIndex,
            QCRowModal: !this.state.QCRowModal,
        }); 
    }
//***********************************************************************************************************************************************************/
    AddNewQCRow = () => {
        var newItemArray = this.state.myCompany.QCQuestions;
        const newRow = {
            no:"",
            Question:"",
        }
        newItemArray.push(newRow);
        const id = newItemArray.length ? newItemArray.length -1 :  0
        this.setState((prevState) => {
            let myCompany = Object.assign({}, prevState.myCompany);  // creating copy of state variable 
            myCompany.QCQuestions = newItemArray;                     // update the offer property, assign a newitemval               
        return { myCompany,QCKeyIndex:id ,QCRowModal: !this.state.QCRowModal };  
        });
    };
//***********************************************************************************************************************************************************/
    deleteQCRow = index => {
        if (this.state.myCompany.QCQuestions.length>0){ 
            var newItemArray = this.state.myCompany.QCQuestions;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let myCompany = Object.assign({}, prevState.myCompany); 
                myCompany.QCQuestions= newItemArray;                                
                return { myCompany,QCRowModal:false }
            });
        }
    };
//***********************************************************************************************************************************************************/
    onChangeQCRow = (field,data,index) => {
        let newItemArray = this.state.myCompany.QCQuestions.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, [field]: data};
        });
        this.setState(prevState => {
            let myCompany = Object.assign({}, prevState.myCompany);  
            myCompany.QCQuestions = newItemArray;                               
        return { myCompany }
        });
    }
//******************************************************************************************************************************************************************/
    getCompanyReportImages=(field,files)=>{
        this.setState(prevState => {
            let myCompany = Object.assign({}, prevState.myCompany);  // creating copy of state variable jasper
            myCompany[field] = files.base64;
            return { myCompany };    
        });
    }
//******************************************************************************************************************************************************************/
    onChangeCompanyData=(field,data)=>{
        this.setState(prevState => {
            let myCompany = Object.assign({}, prevState.myCompany);  // creating copy of state variable jasper
            myCompany[field] = data;
            return { myCompany };    
        });
    }
//******************************************************************************************************************************************************************/
    render() {
       // const { locale} = this.props;
        // const Lang = locale === "tr" ? 0  : 1 ;
        // const { messages } = this.props.intl;
        const { myCompany,certificateKeyIndex,QCKeyIndex} = this.state;

        return (
            <>
                <div className={this.props.company.loading  ? "dimmer active" : ""}>
                    {this.props.company.loading && <div className="loader"/>}
                    <div className={this.props.company.loading ? "dimmer-content"  : ""}>
                        <div className="container-fluid">
                            <div className="card" >
                                <div className="card-header">
                                    <h3 className="card-title"><IntlMessages id="company.info" /></h3>
                                    <div className="card-options">
                                        <div className="item-action dropdown ml-2">
                                            <a href="fake_url" data-toggle="dropdown">
                                                <i className="fe fe-more-vertical" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-eye" /> View Details{' '}
                                                </a>
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-share-alt" /> Share{' '}
                                                </a>
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-cloud-download" /> Download
                                            </a>
                                                <div className="dropdown-divider" />
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-copy" /> Copy to
                                            </a>
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-folder" /> Move to
                                            </a>
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-edit" /> Rename
                                            </a>
                                                <a href="fake_url" className="dropdown-item">
                                                    <i className="dropdown-icon fa fa-trash" /> Delete
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma Adı"
                                                    value={myCompany.sirket_adi ? myCompany.sirket_adi:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_adi",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea
                                                    rows={1}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Address"
                                                    value={myCompany.sirket_adres  ? myCompany.sirket_adres :""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_adres",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma İlçe"
                                                    value={myCompany.sirket_ilce ? myCompany.sirket_ilce:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_ilce",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma İl"
                                                    value={myCompany.sirket_il ? myCompany.sirket_il:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_il",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma Ulke"
                                                    value={myCompany.sirket_ulke ? myCompany.sirket_ulke:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_ulke",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma PK"
                                                    value={myCompany.sirket_pk ? myCompany.sirket_pk:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_pk",e.target.value)}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma E-Posta1"
                                                    value={myCompany.sirket_email ? myCompany.sirket_email:""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_email",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Firma E-Posta2"
                                                    value={myCompany.sirket_email2 ? myCompany.sirket_email2 :""}
                                                    onChange={(e)=>this.onChangeCompanyData("sirket_email2",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="icon-globe" />
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Firma Web Adresi"
                                                        value={myCompany.sirket_web ? myCompany.sirket_web:""}
                                                        onChange={(e)=>this.onChangeCompanyData("sirket_web",e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-primary" onClick={()=>this.myCompanySave()}><IntlMessages id="button.save"/></button>{"  "}
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.gotoDashboard()}>
                                        <IntlMessages id="button.close" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid ml-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#reportLogo"><IntlMessages id="company.reportimages"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Qcmenu"><IntlMessages id="company.qcquestion"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#certificates"><IntlMessages id="company.certificates"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#banklist"><IntlMessages id="company.banklist"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="card" >
                                <div className="tab-content taskboard">
                                    <div className="tab-pane fade show active" id="reportLogo" role="tabpanel">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="row clearfix row-deck">
                                                <div className="col-sm-12  col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="circle">
                                                                <a href="#0" className="mb-3">
                                                                    <img className="rounded" src={myCompany.sirket_rapor_logo ? myCompany.sirket_rapor_logo : "../assets/images/ry.png"} alt="Moduled"  />
                                                                </a>
                                                                <FileBase64 multiple={false} onDone={(file)=>this.getCompanyReportImages("sirket_rapor_logo",file)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12  col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="circle">
                                                                <a href="#0" className="mb-3">
                                                                    <img className="rounded" src={myCompany.sirket_kase ? myCompany.sirket_kase : "../assets/images/ry.png"} alt="Moduled"  />
                                                                </a>
                                                                <FileBase64 multiple={false} onDone={(file)=>this.getCompanyReportImages("sirket_kase",file)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12  col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="circle">
                                                                <a href="#0" className="mb-3">
                                                                    <img className="rounded" src={myCompany.sirket_kaseimza ? myCompany.sirket_kaseimza : "../assets/images/ry.png"} alt="Moduled"  />
                                                                </a>
                                                                <FileBase64 multiple={false} onDone={(file)=>this.getCompanyReportImages("sirket_kaseimza",file)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12  col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="circle">
                                                                <a href="#0" className="mb-3">
                                                                    <img className="rounded" src={myCompany.bwlogo ? myCompany.bwlogo : "../assets/images/ry.png"} alt="Moduled"  />
                                                                </a>
                                                                <FileBase64 multiple={false} onDone={(file)=>this.getCompanyReportImages("bwlogo",file)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Qcmenu" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="w40">#</th>
                                                                <th><IntlMessages id="mycompany.qc"/></th>
                                                                <th className="w40" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {myCompany && myCompany.QCQuestions && myCompany.QCQuestions.length ?
                                                                myCompany.QCQuestions.map((qc,qcKeyIndex)=>{
                                                                    return(
                                                                        <tr key={qcKeyIndex}>
                                                                            <td>
                                                                                <h6><span className="tag tag-primary">{qc.no}</span></h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6><span className="tag tag-primary">{qc.Question}</span></h6>
                                                                            </td>
                                                                            <td>
                                                                                <div className="item-action dropdown">
                                                                                    <a href="#0" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                        <a href="#1" className="dropdown-item" onClick={()=>this.toggleQCRowModal(qcKeyIndex)}><i className="dropdown-icon fa fa-edit"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                        <a href="#2" className="dropdown-item" onClick={()=>this.deleteQCRow(qcKeyIndex)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="button.rowdelete"/></a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )})
                                                                    :null
                                                                }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right">
                                                <button type="button" className="btn btn-info" onClick={()=>this.AddNewQCRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></button>{"  "}
                                                <button type="button" className="btn btn-primary" onClick={()=>this.myCompanySave()}><IntlMessages id="button.save"/></button>{"  "}
                                                <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.gotoDashboard()}><IntlMessages id="button.close" /></button>{"  "}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="certificates" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th><IntlMessages id="mycompany.logo"/></th>
                                                                <th><IntlMessages id="mycompany.bwlogo"/></th>
                                                                <th><IntlMessages id="mycompany.certificate"/></th>
                                                                <th className="w40" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {myCompany && myCompany.certificates && myCompany.certificates.length ?
                                                                myCompany.certificates.map((certificate,certificateKeyIndex)=>{
                                                                    return(
                                                                        <tr key={certificateKeyIndex}>
                                                                            <td>
                                                                                <img
                                                                                    src={certificate.file ? certificate.file:"../assets/images/ry.png" }
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    alt="Moduled"
                                                                                    className="rounded-circle img-thumbnail w40"
                                                                                    data-original-title="Moduled"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <img
                                                                                    src={certificate.bwfile ? certificate.bwfile:"../assets/images/ry.png" }
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    alt="Moduled"
                                                                                    className="rounded-circle img-thumbnail w40"
                                                                                    data-original-title="Moduled"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <h6><span className="tag tag-primary">{certificate.certificate}</span></h6>
                                                                            </td>
                                                                            <td>
                                                                                <div className="item-action dropdown">
                                                                                    <a href="#0" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                        <a href="#1" className="dropdown-item" onClick={()=>this.togglecertificateRowModal(certificateKeyIndex)}><i className="dropdown-icon fa fa-edit"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                        <a href="#2" className="dropdown-item" onClick={()=>this.deleteCertificatesRow(certificateKeyIndex)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="button.rowdelete"/></a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )})
                                                                    :null
                                                                }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right">
                                                <button type="button" className="btn btn-info" onClick={()=>this.AddNewCertificateRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></button>{"  "}
                                                <button type="button" className="btn btn-primary" onClick={()=>this.myCompanySave()}><IntlMessages id="button.save"/></button>{"  "}
                                                <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.gotoDashboard()}><IntlMessages id="button.close" /></button>{"  "}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="banklist" role="tabpanel">
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal 
                        isOpen={this.state.certificateRowModal}
                        toggle={this.togglecertificateRowModal}
                        size="lg"
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="mycompany.certificateedit"/>
                    </ModalHeader>
                    <ModalBody>
                        {myCompany && myCompany.certificates && myCompany.certificates[certificateKeyIndex] &&
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">    
                                        <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="mycompany.logo"/></label>
                                                    <div className="row"> 
                                                        <img
                                                            src={myCompany.certificates[certificateKeyIndex].file !== "" ? myCompany.certificates[certificateKeyIndex].file:"../assets/images/ry.png" }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            alt="Moduled"
                                                            className="rounded-circle img-thumbnail w40 mr-2"
                                                        />
                                                        <FileBase64 multiple={ false }  onDone={(file)=>this.getLogo("file",file,certificateKeyIndex)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="mycompany.bwlogo"/></label>
                                                    <div className="row">
                                                        <img
                                                            src={myCompany.certificates[certificateKeyIndex].bwfile !== "" ? myCompany.certificates[certificateKeyIndex].bwfile:"../assets/images/ry.png" }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            alt="Moduled"
                                                            className="rounded-circle img-thumbnail w40 mr-2"
                                                            data-original-title="Moduled"
                                                        />
                                                        <FileBase64 multiple={ false }  onDone={(file)=>this.getLogo("bwfile",file,certificateKeyIndex)}/>
                                                    </div>
                                                </div>
                                            </div>                                        
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group ">
                                                    <label className="form-label"><IntlMessages id="mycompany.certificate"/></label>
                                                        <input
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={myCompany.certificates[certificateKeyIndex]? myCompany.certificates[certificateKeyIndex].certificate:""}
                                                            onChange={(evt)=>this.onChangeCertificatesRow("certificate",evt.target.value,certificateKeyIndex)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>

                        <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteCertificatesRow(certificateKeyIndex)}>
                            <IntlMessages id="button.delete" />
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.togglecertificateRowModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                    </Modal>
                    <Modal 
                        isOpen={this.state.QCRowModal}
                        toggle={this.toggleQCRowModal}
                        size="lg"
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="mycompany.qcquestionedit"/>
                    </ModalHeader>
                    <ModalBody>
                        {myCompany && myCompany.QCQuestions && myCompany.QCQuestions[QCKeyIndex]  &&
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="mycompany.qcno"/></label>
                                                        <input
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={myCompany.QCQuestions[QCKeyIndex]? myCompany.QCQuestions[QCKeyIndex].no:""}
                                                            onChange={(evt)=>this.onChangeQCRow("no",evt.target.value,QCKeyIndex)}
                                                        />
                                                    </div>
                                            </div>                              
                                            <div className="col-sm-12 col-md-10">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="mycompany.question"/></label>
                                                        <input
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={myCompany.QCQuestions[QCKeyIndex]? myCompany.QCQuestions[QCKeyIndex].Question:""}
                                                            onChange={(evt)=>this.onChangeQCRow("Question",evt.target.value,QCKeyIndex)}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>

                        <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteQCRow(certificateKeyIndex)}>
                            <IntlMessages id="button.delete" />
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleQCRowModal(null)}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                    </Modal>
                </div>
            </>
        )
    }
}
const mapStateToProps = state  => ({
    company: state.myCompany,
    locale:state.settings.locale,
  });
  
const mapDispatchToProps  =({getCompany,updatemyCompany,createmyCompany})
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(myCompany));


