import React, { Component } from 'react'
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import { store } from 'react-notifications-component';
import IntlMessages from "../../helpers/IntlMessages";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {getOrderItemList,updateOrderItem,getMaterialsList,setOperationFilter,setOperationComplatedFilter,createBuying} from '../../actions';


class ComponentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrderOption: { column: "mCategory", label: <IntlMessages id="company.orderbyCategory" /> },
            sortColumn:"mCategory",
            operationFilter:this.props.operationFilter || "Tümü",
            filter0:1,
            filter1:4,  
            totalItemCount: 0,
            buyingModal:false,
            orderItemList:this.props.orderItemList.data ? this.props.orderItemList.data :[],
            materials:this.props.materials.data,
            search: "",
            buyingDetail:{},
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "Operation", label:"order.operation" },
                { column: "mGroup",label :"company.orderbyCategory"},
                { column: "mCategory", label:"order.materialgroup"},
                { column: "Qty", label:"product.materialqty" }
              ],
            OrderStatus: [
                {value : 1 , label:"order.orderStatus1" },
                {value : 2 , label:"order.orderStatus2" },
                {value : 3 , label:"order.orderStatus3" },
                {value : 4 , label:"order.orderStatus4" }
              ] 
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getMaterialsList("list")
        .then(res=>{
            this.props.getOrderItemList("list",this.state.sortColumn,this.state.filter0,this.state.filter1);
        });
        
        
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.orderItemList.data !== nextProps.orderItemList.data) {
          //  console.log(nextProps.orderItemList.data);
            let materialList =[];
            for (let item of nextProps.orderItemList.data) {
                if(item.Operations && item.Operations.length  ) {
                   console.log(item.Operations)
                    for (let operation of item.Operations) {
                        
                        if(operation.OperationDetails && operation.Operation !=="E-Test"){
                            for(let material of operation.OperationDetails){
                                if(!material.StockUpdate){
                                    let XC = this.props.materials.data.filter(x=>x._id ===material.material)[0]
                                    let row = {};
                                    row.Operation= operation.Operation;
                                    row.mCategory = material.material_category;
                                    row.mGroup = material.material_group;
                                    row.Material = XC && XC.material_name  ? XC.material_name :"Belirlenmemiş";
                                    row.materialID = material.material;
                                    row.Qty =material.totalQty;
                                    row.Stock=  XC && XC.stockSum  ? XC.stockSum :0;
                                    row.Unit =material.unit
                                    materialList.push(row);
                                }
                            }
                        }                      
                    }
                }
            }
            const temp = [];
            materialList.forEach(function (a) {
               if (!this[a.materialID]) {
                   this[a.materialID] = { material: a.materialID, Operation: a.Operation, Stock: a.Stock,Material: a.Material,mCategory:a.mCategory ,mGroup:a.mGroup,sum:0, };
                   temp.push(this[a.materialID]);
               } 
                   this[a.materialID].sum += a.Qty;
           }, Object.create(null));


            this.setState({
                ComponetList: temp.sort((a, b) => a.mCategory.localeCompare(b.mCategory)) 
            }); 
        };  
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials: nextProps.materials.data,
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
            sortColumn:column,
            },()=>this.sortByColumn(column));
    };
//******************************************************************************************************************************************************************/
    sortByColumn = column => {
        let temp = this.state.ComponetList
        if(temp  && column==="Qty")
        {
            temp = temp.sort((a, b) => (a.Stock - b.Stock))
        }else if (temp  && column==="Operation") 
        {
            temp = temp.sort((a, b) => a.Operation.localeCompare(b.Operation))
        }else if (temp  && column==="mGroup") 
        {
            temp = temp.sort((a, b) => a.mGroup.localeCompare(b.mGroup))
        }else if (temp  && column==="mCategory") 
        {
            temp = temp.sort((a, b) => a.mCategory.localeCompare(b.mCategory))
        }
        this.setState({
            ComponetList: temp
        }); 
    }
//******************************************************************************************************************************************************************/
    gotoDetails = detail => {
        console.log(detail)
        if (detail.Operation==="Yarı Mamül"){

        }else{
            detail.materialID && this.props.history.push({pathname : `/material/`+detail.materialID})
        }
        
    }
//******************************************************************************************************************************************************************/
    onOpenBuyingModal=(detail)=>{
      //  console.log(detail)
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail); 
         
            buyingDetail.siparis_notu = ""; 

            buyingDetail.material_id = detail.material; 
            buyingDetail.material_name = detail.Material; 
            buyingDetail.material_group = detail.mGroup; 
            buyingDetail.material_category = detail.mCategory; 
            buyingDetail.material_qty = detail.sum; 
            buyingDetail.material_unit = "adet"; 

            buyingDetail.satinalma_durumu = 0; 
            buyingDetail.related_person = ""; 
           
            return { buyingDetail, buyingModal: true };    
        })
    }
//******************************************************************************************************************************************************************/
    toggleBuyingModal=()=>{
        this.setState({
            buyingModal: !this.state.buyingModal
        });
    }
//******************************************************************************************************************************************************************/
    onOpenedBuyingModal=()=>{
        this.setState({
           
        });
    }
//******************************************************************************************************************************************************************/
    onChangeBuyingData=(field,data)=>{
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  
            buyingDetail[field] = data;                          
            return { buyingDetail };    
        });
    }
//******************************************************************************************************************************************************************/
    buyingSave=()=>{
        const { messages } = this.props.intl;
        this.props.createBuying(this.state.buyingDetail)
        .then(res=>{
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.talepolusturuldu"],//{messages[x.label]} 
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });   
        })
        this.setState({
            buyingModal: !this.state.buyingModal
        });
    }
//******************************************************************************************************************************************************************/
    render() {
        const {ComponetList,orderOptions,buyingDetail,UnitList} = this.state;
        const { messages } = this.props.intl;
        return (
            <>
            <div  className={this.props.orderItemList.loading ? "dimmer active" : ""}>
                {this.props.orderItemList.loading && <div className="loader"/>}
                <div className={this.props.orderItemList.loading ? "dimmer-content"  : ""}>
                <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div>
                                    <div className="row clearfix">
                                        <div className="">
                                            <select 
                                                className="custom-select mb-1" 
                                                onChange={(evt)=>this.changeOrderBy(evt.target.value)}
                                            >
                                                {orderOptions.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.column}>{messages[x.label]} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>


                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="card-title"><a href="#1"><strong><IntlMessages id="menu.componentlist"/></strong></a></h6>
                            </div>
                        </div>
                                <div className="table-responsive" id="users">
                                    <table className="table table-hover table-vcenter text-nowrap table_custom border-style list">
                                        <thead>
                                            <tr>
                                                <th >#</th>
                                                <th ><IntlMessages id="order.operation"/></th>
                                                <th ><IntlMessages id="order.category"/></th>
                                                <th ><IntlMessages id="order.group"/></th>
                                                <th ><IntlMessages id="order.material"/></th>
                                                <th className="text-center"><IntlMessages id="order.totalqty"/></th>
                                                <th className="text-center"><IntlMessages id="order.stock"/></th>
                                                <th className="w20"/>
                                            </tr>
                                        </thead>
                                        {ComponetList ?  ComponetList.map((detail,detailIndex)=>{
                                        
                                            return(
                                                <tbody key={detailIndex}>
                                                    <tr  key={detailIndex}>
                                                        <td className="width35 hidden-xs">
                                                            <span onClick={()=>this.gotoDetails(detail)}  className={detail.Stock>0 ? "mail-star": "mail-star love" }>
                                                                <i className="fa fa-circle" />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>{detail.Operation}</span>
                                                        </td>
                                                        <td>
                                                            <span>{detail.mCategory.length ===1 ? messages["model.kategori"+detail.mCategory]:detail.mCategory}</span>
                                                        </td>
                                                        <td>
                                                            <span>{detail.mGroup}</span>
                                                        </td>
                                                        <td>
                                                            <span>{detail.Material}</span>
                                                        </td>
                                                        <td  className="text-center">
                                                            <span>{detail.sum}</span>
                                                        </td>
                                                        <td  className="text-center">
                                                            <span>{detail.Stock}</span>
                                                        </td>
                                                        <td className="text-right">
                                                            {detail.Operation !== "Yarı Mamül" && 
                                                            <a
                                                                className="btn btn-sm btn-link"
                                                                onClick={()=>this.onOpenBuyingModal(detail)}
                                                                data-toggle="tooltip"
                                                                title="buying"
                                                            >
                                                                <i className="fa fa-shopping-cart" />
                                                            </a>}
                                                            <a
                                                                className="btn btn-sm btn-link"
                                                                href={"mailto:bilgi@moduled.com.tr?subject="+detail.Stock +" Adet "+detail.Material+" Stoğu Hakkında"}
                                                                data-toggle="tooltip"
                                                                title="Mail"
                                                            >
                                                                <i className="fa fa-envelope" />
                                                            </a>
 
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                )
                                            }):null
                                        }
                                    </table>
                                </div>
                    
                        
                    </div>
                </div>    
                <Modal
                    isOpen={this.state.buyingModal}
                    toggle={this.toggleBuyingModal}
                    onOpened={this.onOpenedBuyingModal}
                    size="lg"
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="buying.createreguest"/>
                </ModalHeader>
                    <ModalBody>
                        {buyingDetail && 
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.notes"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.siparis_notu }
                                                        onChange={(evt)=>this.onChangeBuyingData("siparis_notu",evt.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <hr/>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.materialid"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.material_id && buyingDetail.material_id }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.materialcategory"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.material_category && buyingDetail.material_category }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.materialgroup"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.material_group && buyingDetail.material_group }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.material"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.material_name && buyingDetail.material_name }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.qty"/></label>
                                                    <input
                                                        type="number"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={buyingDetail &&  buyingDetail.material_qty ? buyingDetail.material_qty :1 }
                                                        onChange={(evt)=>this.onChangeBuyingData("material_qty",evt.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="buying.unit"/></label>
                                                    <select 
                                                        className="form-control"
                                                        onChange={(evt)=>this.onChangeBuyingData("material_unit",evt.target.value)}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {UnitList.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value} selected={buyingDetail.material_unit === x.value}>{messages[x.label]} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>


                                    </div>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>

                        <button type="button" className="btn btn-round btn-primary" onClick={()=>this.buyingSave()}>
                            <IntlMessages id="button.save" />
                        </button>
                        <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleBuyingModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal>                 
           
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    orderItemList:state.orderItemList,
    locale:state.settings.locale,
    auth:state.auth,
    orderItemDetail:state.orderItemDetail,
    materials:state.materials,
    operationFilter:state.settings.operationFilter,
    operationComplatedFilterValue:state.settings.operationComplatedFilterValue
})

const mapDispatchToProps = ({getOrderItemList,updateOrderItem,getMaterialsList,setOperationFilter,setOperationComplatedFilter,createBuying})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ComponentList)));
