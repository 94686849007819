import {
	GWCOMMANDS_LIST_ACTION,
	GWCOMMANDS_LIST_SUCCESS,
	GWCOMMANDS_LIST_FAILURE
} from '../actions'

const gwcommands = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case GWCOMMANDS_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case GWCOMMANDS_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case GWCOMMANDS_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default gwcommands;
