import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
const styles = StyleSheet.create({
    OfferInfoContainer: {
        marginTop: 5,
        marginLeft: 50,
        flexDirection: "column",
        width:200
    },
    ViewRowContainer:{
        flexDirection: "row",
    }
  });
const OfferNo = styled.Text`
marginTop:1.5px;
padding:2px
font-size: 10px;
text-align: left;
backgroundColor: #E7E7E7;
height :20px;
width:140px;
`;
const OfferRefferance = styled.Text`
marginTop:1.5px;
padding:2px
font-size: 10px;
text-align: left;
backgroundColor: #E7E7E7;
height :20px;
width:140px;
`;
const Label = styled.Text`
marginTop:1.5px;
marginRight:1.5px;
padding:2px
font-size: 10px;
text-align: right;
backgroundColor: #E7E7E7;
height :20px;
width:65px;
`;

const OfferInfo = ({data,Dil}) => (
  
<View style={styles.OfferInfoContainer}>
    <View style={styles.ViewRowContainer}>
        <Label>{Dil==="tr" ? "Sipariş Tarihi : ":"Invoice No : "}</Label>
        <OfferNo>{data.siparis_tarihi && moment(data.siparis_tarihi).format('DD.MM.YYYY')}</OfferNo>
    </View>
    <View style={styles.ViewRowContainer}>
        <Label>{Dil==="tr" ? "Sipariş No : ":"Order No : "}</Label>
        <OfferRefferance>{data.teklif_no}</OfferRefferance>
    </View>    
   
</View>
);
  
export default OfferInfo