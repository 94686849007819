import {
    GET_COMPANY_DELIVERY_LIST,
    GET_COMPANY_DELIVERY_LIST_SUCCESS,
	GET_COMPANY_DELIVERY_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_COMPANY_DELIVERY_LIST: // GEL LIST 
			return { ...state,data:[], loading: true };
		case GET_COMPANY_DELIVERY_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.deliveried,  
            };
		case GET_COMPANY_DELIVERY_LIST_ERROR:
			return { ...state,data:[], loading: false };
		default: return  {...state};
	}
}
