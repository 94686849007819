import {
	MODEL_DETAILS_ACTION,
	MODEL_DETAILS_SUCCESS,
    MODEL_DETAILS_ERROR,
	CREATE_MODEL_ACTION,
	CREATE_MODEL_SUCCESS,
	CREATE_MODEL_ERROR,
	UPDATE_MODEL_ACTION,
	UPDATE_MODEL_SUCCESS,
	UPDATE_MODEL_FAILURE,
	DELETE_MODEL_ACTION,
	DELETE_MODEL_SUCCESS,
	DELETE_MODEL_FAILURE

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from './auth';

// Get - Offer Details ****************************************************************************************************************************************************
export const model_details_pending = () => ({
	type: MODEL_DETAILS_ACTION
});

export const model_details_success = (res) => ({
	type: MODEL_DETAILS_SUCCESS,
	response: res
});

export const model_details_failure = () => ({
	type: MODEL_DETAILS_ERROR,
});

export const getModelDetails = (id) => dispatch =>{
	const url = `/models/${id.id}`
	dispatch(model_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(model_details_success(res.data))
                resolve(true);
            } else {
				dispatch(model_details_failure());
                reject();
            }
        })
        .catch((err) => {
		
            dispatch(model_details_failure());
            if (err.response && err.response.status && err.response.status === 401) {
                dispatch(user_reauth_request(store.getState().auth.token));
            }
            reject(err);
            
        });
    });
};

// POST - model Create ****************************************************************************************************************************************************
export const create_model_pending = () => ({
	type: CREATE_MODEL_ACTION
});

export const create_model_success = (res) => ({
	type: CREATE_MODEL_SUCCESS,
	response: res
});

export const create_model_failure = (response) => ({
	type: CREATE_MODEL_ERROR,
	response:response
});

export const createModel = (data,history) => dispatch => {
    const url = `/models/create/`
	dispatch(create_model_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_model_success(res.data));
				history.push({
					pathname : "/model/" + res.data._id
				})
				resolve(true);
			} else {
				dispatch(create_model_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_model_failure(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err);
		});
	});
}
// update Offer ****************************************************************************************************************************************************
export const update_model_pending = (modelId) => ({
	type: UPDATE_MODEL_ACTION,
	companyId: modelId
});

export const update_model_successful = (res) => ({
	type: UPDATE_MODEL_SUCCESS,
	response: res
});

export const update_model_failed = (response) => ({
	type: UPDATE_MODEL_FAILURE,
	response:response
});

export const updateModel = (data) => dispatch => {
	const url = `/models/update/${data._id}`
	dispatch(update_model_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_model_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_model_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_model_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err.response);
		});
	});
}

// delete Model ****************************************************************************************************************************************************
export const delete_model_pending = () => ({
	type: DELETE_MODEL_ACTION,
});

export const delete_model_successful = (res) => ({
	type: DELETE_MODEL_SUCCESS,
	response:res
	
});

export const delete_model_failed = (res) => ({
	type: DELETE_MODEL_FAILURE,
	response:res
});

export const deleteModel = (id) => dispatch => {
	const url = `/models/delete/${id}`
	dispatch(delete_model_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_model_successful(res.data));         
				resolve(true);
			} else {
				dispatch(delete_model_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_model_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
