import {
	MATERIAL_DETAILS_ACTION,
	MATERIAL_DETAILS_SUCCESS,
    MATERIAL_DETAILS_ERROR,
	CREATE_MATERIAL_ACTION,
	CREATE_MATERIAL_SUCCESS,
	CREATE_MATERIAL_ERROR,
	UPDATE_MATERIAL_ACTION,
	UPDATE_MATERIAL_SUCCESS,
	UPDATE_MATERIAL_FAILURE,
	DELETE_MATERIAL_ACTION,
	DELETE_MATERIAL_SUCCESS,
	DELETE_MATERIAL_FAILURE
} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from './auth';

// Get - Material Details ****************************************************************************************************************************************************
export const material_details_pending = () => ({
	type: MATERIAL_DETAILS_ACTION
});

export const material_details_success = (res) => ({
	type: MATERIAL_DETAILS_SUCCESS,
	response: res
});

export const material_details_failure = () => ({
	type: MATERIAL_DETAILS_ERROR
});

export const getMaterialDetails = (id) => dispatch =>{
	const url = `/materials/${id.id}`
	dispatch(material_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(material_details_success(res.data))
                resolve(true);
            } else {
				dispatch(material_details_failure());
                reject();
            }
        })
        .catch((err) => {
		
            dispatch(material_details_failure());
            if (err.response && err.response.status && err.response.status === 401) {
                dispatch(user_reauth_request(store.getState().auth.token));
            }
            reject(err);
            
        });
    });
};

// POST - Material Create ****************************************************************************************************************************************************
export const create_material_pending = () => ({
	type: CREATE_MATERIAL_ACTION
});

export const create_material_success = (res) => ({
	type: CREATE_MATERIAL_SUCCESS,
	response: res
});

export const create_material_failure = (response) => ({
	type: CREATE_MATERIAL_ERROR,
	response:response
});

export const createMaterial = (data,history) => dispatch => {
    const url = `/materials/create/`
	dispatch(create_material_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_material_success(res.data));
				resolve(true);
				history.push({
					pathname : "/material/" + res.data._id
				})
				
			} else {
				dispatch(create_material_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_material_failure(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err);
		});
	});
}
// update Material ****************************************************************************************************************************************************
export const update_material_pending = (material_ID) => ({
	type: UPDATE_MATERIAL_ACTION,
	materialID: material_ID
});

export const update_material_successful = (res) => ({
	type: UPDATE_MATERIAL_SUCCESS,
	response: res
});

export const update_material_failed = (response) => ({
	type: UPDATE_MATERIAL_FAILURE,
	response:response
});

export const updateMaterial = (data) => dispatch => {
	const url = `/materials/update/${data._id}`
	dispatch(update_material_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_material_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_material_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_material_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err.response);
		});
	});
}

// delete Model ****************************************************************************************************************************************************
export const delete_material_pending = () => ({
	type: DELETE_MATERIAL_ACTION,
});

export const delete_material_successful = (res) => ({
	type: DELETE_MATERIAL_SUCCESS,
	response:res
	
});

export const delete_material_failed = (res) => ({
	type: DELETE_MATERIAL_FAILURE,
	response:res
});

export const deleteMaterial = (id) => dispatch => {
	const url = `/materials/delete/${id}`
	dispatch(delete_material_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_material_successful(res.data));           
				resolve(true);
			} else {
				dispatch(delete_material_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_material_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }
			reject(err);
		});
	});
}
