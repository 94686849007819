import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from "../../common/Pagination";
import { withRouter } from 'react-router';
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import FileBase64 from '../../../helpers/FileBase64';
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { store } from 'react-notifications-component';
import {getUserList,createUser,updateUser,deleteUser,getUserDetails,changePassword,getCompanyList} from '../../../actions';
import moment from "moment";
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            //selectedOrderOption: { column: "deviceno", label: <IntlMessages id="user.orderbyName" /> },
            sortColumn:"firstname",
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            companyModalOpen:false,
            errors:{
                firstname:"",
                lastname:"",
                email:"",
			},
			errorPassword:{
				password:"",
				password2:""
			},
			userRole: [
				{ value: 0, label: "user.0"},
				{ value:1, label: "user.1" },
				{ value:2, label: "user.2" },
				{ value:3, label: "user.3" },
				{ value:4, label: "user.4" },
				{ value:5, label: "user.5" },
				{ value:6, label: "user.6" },
				{ value:7, label: "user.7" },
			]
        };
      }
//******************************************************************************************************************************************************************/
    componentDidMount() {
		this.props.getUserList();
		this.props.companyList && this.props.companyList.data && !this.props.companyList.data.length && this.props.getCompanyList()
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.userList.data !== nextProps.userList.data) {
            this.setState({
                userList: nextProps.userList.data,
                totalItemCount:nextProps.userList.totalItemCount,
                totalPage: Math.ceil(nextProps.userList.totalItemCount/this.state.selectedPageSize) 
            }); 
		};  
		if (this.props.userDetail.data !== nextProps.userDetail.data) {
            this.setState({
                user: nextProps.userDetail.data,
                totalItemCount:this.props.userList.totalItemCount,
                totalPage: Math.ceil(this.props.userList.totalItemCount/this.state.selectedPageSize) 
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page
        });
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
		filteredList =  this.props.userList.data.filter(item => {return item.firstname.toLowerCase().includes(search.target.value.toLowerCase())
			|| (item.lastname && item.lastname.toLowerCase().includes(search.target.value.toLowerCase()))
		})
        if (search && search.target.value===""){
            this.setState({
                userList:this.props.userList.data,
                totalItemCount:this.props.userList.data &&  this.props.userList.data.length ? this.props.userList.data.length:0,
                totalPage: Math.ceil((this.props.userList.data &&  this.props.userList.data.length  ? this.props.userList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
                userList:filteredList,
                totalItemCount:this.state.userList &&  this.state.userList.length ? this.state.userList.length:0,
                totalPage: Math.ceil((this.state.userList &&  this.state.userList.length ? this.state.userList.length : 0)/this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        this.setState({
            sortColumn:column
        });
	};
//******************************************************************************************************************************************************************/
	toggleUserModal=(id)=>{
		this.setState({
			userModalOpen: !this.state.userModalOpen,
			userID:id
		});
	}
//******************************************************************************************************************************************************************/
	userModalonOpen=()=>{
		if(this.state.userID){
			this.props.getUserDetails({id:this.state.userID})
			.then(res=>{
				this.setState({
					user: this.props.userDetail.data,
					errors:{
						firstname:this.props.userDetail.data.firstname.length>2 ?"":"Write Name please",
						lastname:this.props.userDetail.data.lastname.length>2 ?"":"Write LastName please",
						email:this.props.userDetail.data.email.length>2 ?"":"Write E-Mail please",
					}
				});
			})
			.catch(err => {
				if(err){
					if(err.response && err.response.status && err.response.status === 403){
					}
				}
			})
		}else{
			this.setState({
				user: {
					_id:null,
					firstname:"",
					lastname: "",
					email: "",
					mobile:"",
					role: 7,
					user_img: "",
					usercompany_name: "",
					userstate:false,
				},
				errors:{
					firstname:"Write user Name please",
					lastname:"Write Email Address please",
					email:"Write City Name please",
				},
				errorPassword:{
					password:"",
					password2:""
				}
			});
		}
			let companyOption=[];
			this.props.companyList.data && this.props.companyList.data.forEach(row => {
				companyOption.push({
					value: row._id,
					label: row.company_name
			  });
			});
			this.setState({
				companyOption: companyOption,
			}); 
	



	}
//******************************************************************************************************************************************************************/
	onChangeUserData = (field,data) => {
		const {errors} = this.state ;
		// eslint-disable-next-line
		const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
		switch (field) {
			case 'firstname': 
			errors.firstname = data.length < 2
				? 'User Name must be 3 characters long!'
				: '';
			break;
			case 'email': 
				errors.email = 
				validEmailRegex.test(data)
				? ''
				: 'Email is not valid!';
				break;
			case 'lastname': 
				errors.lastname = data.length < 3
				? 'Lastname must be 3 characters long!'
				: '';
				break;                        
			default:
				break;
		}
		this.setState(prevState => {
			let user = Object.assign({}, prevState.user); 
			let errors = Object.assign({}, prevState.errors);
			user[field] = data;   

			return { user,errors };    
		});
	}
//******************************************************************************************************************************************************************/
	getImages=(files)=>{
		this.setState(prevState => {
		  let user = Object.assign({}, prevState.user); 
		  user.user_img = files.base64;                           
		  return { user };    
		});
	  }
//******************************************************************************************************************************************************************/
	onChangePasswordData = (field,data) => {
		const {errorPassword,user} = this.state ;
		let data2 = "";
		if(field==="password"){
			data2 = user.password2
		}else{
			data2 = user.password
		}
		// eslint-disable-next-line
		if (data2 < 4 || data < 4 ){
			errorPassword.password= 'password must be 4 characters long!'
		}else if (data2 !== data){
			errorPassword.password= " Not equal to Passwords"
		}else{
			errorPassword.password= ""
		}
		this.setState(prevState => {
			let user = Object.assign({}, prevState.user); 
			let errorPassword = Object.assign({}, prevState.errorPassword);
			user[field] = data;   

			return { user,errorPassword };    
		});

	}
//******************************************************************************************************************************************************************/
	validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
		// if we have an error string set valid to false
		(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}
//******************************************************************************************************************************************************************/
	handleSubmitMyForm = () => {   
		const isOK = this.validateForm(this.state.errors);
		const { messages } = this.props.intl;
		if(isOK){
			if (this.state.user._id ){
				this.props.updateUser({...this.state.user})
				.then(res=>{
					store.addNotification({
						title: messages["uyari.basarili"],
						message: messages["bilgi.kullaniciguncellendi"],
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					//});
					},()=>this.toggleUserModal());
					
				})
				.catch(err=>{
					if(err){
						store.addNotification({
							title: this.props.userDetail.status.statusText,
							message: this.props.userDetail.status.data,
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
			}else{
				this.props.createUser({...this.state.user})
				.then(res=>{
					store.addNotification({
						title: messages["uyari.basarili"],
						message: messages["bilgi.kullaniciolusturuldu"],//{messages[x.label]} 
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					});                    
				})
				.catch(err=>{
					if(err){
						store.addNotification({
							title: this.props.userDetail.status.statusText,
							message: this.props.userDetail.status.data,
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
			}
			
			this.props.getUserList();
		}else{

		}

	}
//******************************************************************************************************************************************************************/
	deleteForm = () =>{
		let id = this.state.user._id;
		if (window.confirm("Kullanıcıyı Silmek istiyor musunuz ?")) {
			this.props.deleteUser(id)
			.then(res=>{
				this.toggleUserModal();
				this.props.getUserList();
			}) 
		}else{
		
		}
	}
//******************************************************************************************************************************************************************/
	newForm = () => {
		this.toggleUserModal();
	}
//******************************************************************************************************************************************************************/
	onChangePassword=()=>{
		const {user,errorPassword} = this.state ;
		const { messages } = this.props.intl;
		if(user.password.length>3 &&  errorPassword.password===""){
		this.props.changePassword({id:user._id,password:user.password})
		.then(res=>{
			store.addNotification({
				title: messages["uyari.basarili"],
				message: messages["bilgi.sifredegistirildi"],//{messages[x.label]} 
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				duration: 3000,
				onScreen: true
				}
			});                    
		})
		.catch(err=>{
			if(err){
				store.addNotification({
					title: ["uyari.hata"],
					message: messages["uyari.sifredegistirilmedi"],
					type: "danger",
					insert: "top",
					container: "top-right",
					dismiss: {
					duration: 3000,
					onScreen: true
					}
				});
			}
		})
		}else{
			store.addNotification({
				title: messages["uyari.hata"],
				message: messages["uyari.sifrelerikontrolediniz"],
				type: "danger",
				insert: "top",
				container: "top-right",
				dismiss: {
				duration: 3000,
				onScreen: true
				}
			});
		}
	  }
//******************************************************************************************************************************************************************/
	render() {

		//const { fixNavbar } = this.props;
		const { messages } = this.props.intl;
        const {userList,user,selectedPageSize,currentPage,userModalOpen,errors,sortColumn,totalItemCount,errorPassword,companyOption,userRole} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
		const endIndex = currentPage * selectedPageSize;
        sortColumn==="firstname" ? userList  && userList.sort((a, b) => a.firstname.localeCompare(b.firstname)) : userList  && (userList.sort((a, b) => (a[sortColumn] > b[sortColumn]) ? 1 : (a[sortColumn] === b[sortColumn]) ? ((a.userstate > b.userstate) ? 1 : -1) : -1 ));

		return (
			<>
                <div  className={this.props.userList.loading ? "dimmer active" : ""}>
                    {this.props.userList.loading && <div className="loader"/>}
                    <div className={this.props.userList.loading ? "dimmer-content"  : ""}>
						<div className="container-fluid">
							<div className="row row-cards">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
								
											<div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount &&  totalItemCount} `}</div>
											<div className="page-options d-flex">
												<select className="custom-select w-auto"  onChange={(evt)=>this.changeOrderBy(evt.target.value)}>
													<option value={"firstname"}>{messages["user.sortfirstname"]} </option>
													<option value={"lastname"}>{messages["user.sortlastname"]}</option>
												</select>
												<div className="input-icon ml-2">
													<span className="input-icon-addon">
														<i className="fe fe-search" />
													</span>
													<input type="text" className="form-control" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)}/>
												</div>
												<button type="button" className="btn btn-primary ml-2" onClick={()=>this.newForm()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.add"/></button>
											</div>
										</div>
									</div>
								</div>
							</div>						
							<div className="table-responsive">
								<table className="table table-vcenter table_custom spacing5 border-style mb-0">
									<thead>
										<tr>
											<th className="w20">#</th>
											<th><IntlMessages id="user.username"/></th>
											<th />
											<th className="text-center"><IntlMessages id="user.createddate"/></th>
											<th className="w250"><IntlMessages id="user.usercompany"/></th>
											<th className="w40"></th>
										</tr>
									</thead>
									{userList &&  userList.length ?
										<tbody>
											{userList.filter(x=>x[this.state.filterField] === this.state.filter).slice(startIndex, endIndex).map((row,i)=>{
											return(
												<tr key={i}>
													<td>
														{row.user_img ==="" ? 
														<span
															className="avatar avatar-blue"
															data-toggle="tooltip"
															data-placement="top"
															data-original-title="Avatar Name"
														>
															{(row.firstname.substring(0, 1)+row.lastname.substring(0,1)).toUpperCase()}
														</span>  
														:
														<img
															src={row.user_img}
															data-toggle="tooltip"
															data-placement="top"
															alt="Avatar"
															className="avatar"
															data-original-title="Avatar Name"
														/>}
													</td>
													<td>
														<h6 className="mb-0"><a href="#1" onClick={()=>this.toggleUserModal(row._id)}>{row.firstname  +" " + row.lastname}</a></h6>
														<span><a href={"mailto:"+row.email}>{row.email}</a></span>
													</td>
													<td>
														<span className={row.role===0 ?"tag tag-success": row.role===1 ?"tag tag-info":  row.role===2 ?"tag tag-warning": row.role===3 ?"tag tag-black": row.role===4 ?"tag tag-danger": "tag tag-danger"}>{messages["user."+row.role]}</span>
													</td>
													<td className="text-center">{moment(row.date).format('DD.MM.YYYY')}</td>
													<td>{row.usercompany_id  && row.usercompany_id.length && row.usercompany_id[0].company_name}</td>
													<td>
														<div className="item-action dropdown">
															<a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
															<div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
																<a href="#1"className="dropdown-item" onClick={()=>this.toggleUserModal(row._id)}><i className="dropdown-icon fa fa-eye" /><IntlMessages id="user.viewdetails"/></a>
																<a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="user.email"/></a>
																<a href="#3" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /><IntlMessages id="user.sms"/></a>
															</div>
														</div>
													</td>
												</tr>
												)
											})}
										</tbody>:
										null}
								</table>
								<Pagination
									currentPage={this.state.currentPage}
									totalPage={this.state.totalPage}
									onChangePage={i => this.onChangePage(i)}
								/>
								<Modal
									isOpen={userModalOpen}
									toggle={this.toggleUserModal}
									size="lg"
									onOpened={() => this.userModalonOpen()} 
									backdrop="static"
								>
									<ModalHeader> 
										<ul className="nav nav-tabs page-header-tab">
											<li className="nav-item"><a className="nav-link active" id="Resumes-tab" data-toggle="tab" href="#Resumes-list"><IntlMessages id="user.info"/></a></li>
											<li className="nav-item"><a className="nav-link" id="Resumes-tab" data-toggle="tab" href="#Resumes-grid"><IntlMessages id="user.detail"/></a></li>
										</ul>
									</ModalHeader>
									<ModalBody>
										<div className="tab-content">
											{user && 
											<div className="tab-pane fade show active" id="Resumes-list" role="tabpanel">
													<div className="row clearfix">
														<div className="col-lg-6 col-md-12">
															<ul className="list-group mb-3">
																<li className="list-group-item">
																	<div className="media mb-0">
																		<img
																			className="rounded-circle img-thumbnail w100"
																			src={user && user.user_img ? user.user_img:"../assets/images/xs/avatar4.jpg"}
																			alt={user.name}
																		/>
																		<div className="media-body">
																			<h5 className="m-0">{user.firstname+" "+user.lastname}</h5>
																			<p className="text-muted mb-0">{messages["user."+user.role]}</p>
																		</div>
																	</div>
																</li>
																<li className="list-group-item">
																	<small className="text-muted"><IntlMessages id="user.createddate" /></small>
																	<p className="mb-0">{moment(user.date).format('DD.MM.YYYY')}</p>
																</li>
																<li className="list-group-item">
																	<div>In Progress</div>
																	<div className="progress progress-xs mt-2">
																		<div
																			className="progress-bar"
																			data-transitiongoal={67}
																			aria-valuenow={67}
																			style={{ width: '67%' }}
																		/>
																	</div>
																</li>
																<li className="list-group-item">
																	<IntlMessages id="user.status" />
																	<div className="float-right">
																		<label className="custom-switch">
																			<input type="checkbox" 
																				name="option" 
																				checked={user && user.userstate &&user.userstate ===true  }
																				className="custom-switch-input"  
																				onChange={(evt)=>this.onChangeUserData("userstate",!user.userstate)}
																			/>
																			<span className="custom-switch-indicator" />
																			
																		</label>
																	</div>
																</li>
																<li className="list-group-item">
																	Messages
																	<div className="float-right">
																		<label className="custom-control custom-checkbox">
																			<input
																				type="checkbox"
																				className="custom-control-input"
																				name="example-checkbox1"
																				defaultValue="option1"
																				defaultChecked
																			/>
																			<span className="custom-control-label">&nbsp;</span>
																		</label>
																	</div>
																</li>
																<li className="list-group-item">
																	Message email
																	<div className="float-right">
																		<label className="custom-control custom-checkbox">
																			<input
																				type="checkbox"
																				className="custom-control-input"
																				name="example-checkbox1"
																				defaultValue="option1"
																			/>
																			<span className="custom-control-label">&nbsp;</span>
																		</label>
																	</div>
																</li>
															</ul>
														</div>
														<div className="col-lg-6 col-md-12">
															<div className="clearfix">
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.firstname" /></label>
																		<input
																			type="text"
																			autoComplete="off"
																			className={errors.firstname === "" ? "form-control is-valid" :"form-control is-invalid"}
																			placeholder="Firstname"
																			value={user &&  user.firstname ? user.firstname :"" }
																			onChange={(evt)=>this.onChangeUserData("firstname",evt.target.value)}
																		/>
																		<div className="invalid-feedback">{errors.firstname}</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.lastname" /></label>
																		<input
																			type="text"
																			autoComplete="off"
																			className={errors.lastname === "" ? "form-control is-valid" :"form-control is-invalid"}
																			placeholder="Lastname"
																			value={user &&  user.lastname ? user.lastname :"" }
																			onChange={(evt)=>this.onChangeUserData("lastname",evt.target.value)}
																		/>
																		<div className="invalid-feedback">{errors.lastname}</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.email" /></label>
																		<div className="input-group">
																			<div className="input-group-prepend">
																				<span className="input-group-text">
																					<i className="fa fa-envelope" />
																				</span>
																			</div>
																			<input
																				type="text"
																				autoComplete="off"
																				className={errors.email === "" ? "form-control is-valid" :"form-control is-invalid"}
																				placeholder="e-Mail"
																				value={user &&  user.email ? user.email :"" }
																				onChange={(evt)=>this.onChangeUserData("email",evt.target.value)}
																			/>

																			<div className="invalid-feedback">{errors.email}</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.mobile" /></label>
																		<div className="input-group">
																			<div className="input-group-prepend">
																				<span className="input-group-text">
																					<i className="fa fa-fax" />
																				</span>
																			</div>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="Mobile"
																				data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
																				value={user &&  user.mobile ? user.mobile :"" }
																				onChange={(evt)=>this.onChangeUserData("mobile",evt.target.value)}
																			/>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.role" /></label>
																		<select 
																			className="form-control"
																			onChange={(evt)=>this.onChangeUserData("role",evt.target.value)}
																			defaultValue={user.role && user.role}
																		>
																			{userRole.map((x,y)=>{
																				return(
																					<option key={y}  value={x.value}>{messages[x.label]} </option>
																				)
																			})}
																		</select>
																		<div className="invalid-feedback">{errors.company_category}</div>
																	</div>
																</div>
																<hr/>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.changepassword" /></label>
																		<input
																			type="password"
																			className={errorPassword.password === "" ? "form-control is-valid" :"form-control is-invalid"}
																			placeholder="Password"
																			onChange={(evt)=>this.onChangePasswordData("password",evt.target.value)}
																		/>
																		<div className="invalid-feedback">{errorPassword.password}</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<input
																			type="password"
																			className={errorPassword.password === "" ? "form-control is-valid" :"form-control is-invalid"}
																			placeholder="Confirm Password"
																			onChange={(evt)=>this.onChangePasswordData("password2",evt.target.value)}
																		/>
																		<div className="invalid-feedback">{errorPassword.password}</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<button type="button" className="btn btn-primary sm" onClick={()=>this.onChangePassword()}><IntlMessages id="button.changepassword"/></button>
																</div>

																<hr/>
																<div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="form-group">
																		<label className="form-label"><IntlMessages id="user.selectimages" /></label>
																		<FileBase64
																			multiple={ false }
																			onDone={ this.getImages} 
																		/>
																	</div>
																</div>
															</div>
														</div>														

													</div>
													
											</div>}
											<div className="tab-pane fade" id="Resumes-grid" role="tabpanel">

												<Select
													className="react-select"
													classNamePrefix="react-select"
													name="material_unit"
													value={user && user.usercompany_id &&companyOption&&  companyOption.length && companyOption.filter(x => x.value === user.usercompany_id)}
													onChange={(e) => this.onChangeUserData("usercompany_id", e.value)}
													options={companyOption}
												/>


												{this.props.auth.role <2 && 
													<div className="col-12">
														<hr className="mt-4" />
														<h6>Module Permission</h6>
														<div className="table-responsive">
															<table className="table table-striped">
																<thead>
																	<tr>
																		<th />
																		<th>Read</th>
																		<th>Write</th>
																		<th>Delete</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>Super Admin</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																	</tr>
																	<tr>
																		<td>Admin</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																	</tr>
																	<tr>
																		<td>Employee</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																	</tr>
																	<tr>
																		<td>HR Admin</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																		<td>
																			<label className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					className="custom-control-input"
																					name="example-checkbox1"
																					defaultValue="option1"
																					defaultChecked
																				/>
																				<span className="custom-control-label">
																					&nbsp;
																				</span>
																			</label>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>}
											</div>
											
										</div>
									</ModalBody>
									<ModalFooter>
										{user  && user._id &&
										<button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteForm()}>
											<IntlMessages id="button.delete" />
										</button>}{"  "}
										<button type="button" className="btn btn-round btn-primary" onClick={()=>this.handleSubmitMyForm()}>
											<IntlMessages id="button.save" />
										</button>{' '}
										&nbsp;&nbsp;
										<button type="button" className="btn btn-round btn-default" onClick={()=>this.toggleUserModal()}>
											<IntlMessages id="button.close" />
										</button>
									</ModalFooter>
								</Modal>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	userList: state.userList,
	companyList:state.companyList,
	userDetail:state.userDetail,
	auth:state.auth
})

const mapDispatchToProps =  ({getUserList,getUserDetails,changePassword,createUser,updateUser,getCompanyList,deleteUser})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Users)));