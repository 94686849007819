import React, { Component } from 'react'
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker,{registerLocale} from "react-datepicker"
import tr from "date-fns/locale/tr"; 
import "react-datepicker/dist/react-datepicker.css";
import FileBase64 from '../../helpers/FileBase64';
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from 'react-router-dom';
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getOfferDetails,getCompanyList,updateCompany,getCompanyDetails,createCompany,offerDovizOpen,offerNotesOpen,getProductList,getModelList,createOffer,updateOffer,deleteOffer,setLoading,setInvoiceType,getMaterialsList} from '../../actions';
import Countries from "../../data/countries"


registerLocale("tr", tr);


class OrderTracking extends Component {
	constructor(props) {
		super(props);
		this.state = {
            offerCurrency:true,
            offerRowModal:false,
            productListModal:false,
            companyModal:false,
            operationCenterList:[
                {value: "fatura", label: "Fatura"},
                {value: "ithalat", label: "İthalat"},
                {value: "uretim", label: "Üretim"},
                {value: "fire", label: "Fire"},
                ],
                operationTypeSelect:[
                { value: "giris", label: "Giriş" },
                { value: "cikis", label: "Çıkış" }],
        
                UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
                ],
                CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
                ],
                InvoiveType: [
                { value: 0, label: 'T0' },
                { value: 1, label: 'T1' },
                { value: 2, label: 'T2' }
                ],
                KDVList : [
                { value: '18', label: '18' },
                { value: '8', label: '8' },
                { value: '0', label: '0' }
            ],
            productList:this.props.productList.data,
            modelList:this.props.modelList.data,
            companyList:this.props.companyList.data,
            orderError:[],
            errors:{
                firma_adi:"",
                firma_ulke:"",
                tutarhatasi:""
            },
            company_errors:{
                company_name:"",
                company_email:"",
                company_city:"",
                company_country:"",
                company_category:""
            }
        };
	}
    //******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getOfferDetails(this.props.match.params)          
    }

    //******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.offerDetail.data !== nextProps.offerDetail.data) {
            this.setState({
                order:nextProps.offerDetail.data
            }); 
        };  
    }

//*****************************************************************************************************************************************/
	render() {

        const {order} = this.state;
        const {locale} =  this.props;
        const { messages } = this.props.intl;
        const Lang = locale === "tr" ? 0  : 1 ;

		return (
            <>
                
                    <div  className={this.props.offerDetail.loading  ? "dimmer active" : ""}>
                        {this.props.offerDetail.loading  && <div className="loader"/>}
                        <div className={this.props.offerDetail.loading  ? "dimmer-content"  : ""}>

                            <div className="container">
                                <div className="card" >
                                    <div className="card-status card-status-left bg-info" />
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-7">
                                                <div className="form-group">
                                                <label className="form-label"><IntlMessages id="offer.subject"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.teklif_konu  ? order.teklif_konu :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                <label className="form-label"><IntlMessages id="offer.person"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order &&  order.related_person  ? order.related_person :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>                      
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group text-right">
                                                    <label className="form-label"><IntlMessages id="offer.date"/></label>
                                                    <DatePicker
                                                        readOnly
                                                        className="form-control text-right"
                                                        selected={order && order.teklif_tarihi && new Date(order.teklif_tarihi)}
                                                        dateFormat="dd.MM.yyyy"
                                                        locale={locale}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                      

                            <div className="container">
             
                                <div className="card" >
                                    <div className={order && order.firma_id  ?"card-status card-status-left bg-success":"card-status card-status-left bg-danger"} />
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-9">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="offer.company"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.firma_adi  ? order.firma_adi :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>                                   
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="offer.referance"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.teklif_referansno  ? order.teklif_referansno :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.firma_adres  ? order.firma_adres :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        readOnly
                                                        value={order &&  order.firma_ilce  ? order.firma_ilce :"" }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.firma_il  ? order.firma_il :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        readOnly
                                                        value={order && order.firma_ulke  ? order.firma_ulke :"" }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        readOnly
                                                        value={order && order.firma_eposta  ? order.firma_eposta :"" }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.teklif_no  ? order.teklif_no :"" }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                    
                                                <div className="form-group">
                                                    <div className="selectgroup w-100">
                                                        {order && (order.teklif_durumu === 0  || order.teklif_durumu === 4) &&
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="teklif_durumu"  className="selectgroup-input"  readOnly defaultChecked={order.teklif_durumu === 0} />
                                                            <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus0"/></span>
                                                        </label>}
                                                        {order && (order.teklif_durumu === 0  || order.teklif_durumu === 1) &&
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="teklif_durumu" className="selectgroup-input" readOnly checked={order.teklif_durumu === 1}/>
                                                            <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus1"/></span>
                                                        </label>}

                                                        {order && order.teklif_durumu === 1   &&
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="teklif_durumu"  className="selectgroup-input" readOnly checked={order.teklif_durumu === 3}/>
                                                            <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus4"/></span>
                                                        </label>}

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={`card`}>
                                    <div className="card-status card-status-left bg-warning"/>
                                    <div className="card-header">
                                        <h3 className="card-title"><IntlMessages id="offer.currencyDetail"/>{" =>  "}<IntlMessages id="offer.currency"/>{" "+(order && order.teklif_dovizi)+" "}</h3>
                                        <div className="card-options">
                                            <span className="card-options-collapse"><i className="fe fe-chevron-up" /></span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="offer.USDDetail"/></label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={order && order.teklif_usdkur  ? order.teklif_usdkur :"" }
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="offer.EURDetail"/></label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={order && order.teklif_eurkur  ? order.teklif_eurkur :"" }
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="offer.currency"/></label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        value={order && order.teklif_dovizi  ? order.teklif_dovizi :"" }
                                                        readOnly
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <>

                                    {order &&  order.offeritem && order.offeritem.length  && order.offeritem.map((row,itemIndex)=>{
                                        const ModelKodu = row.urun_kodu[0] ? row.urun_kodu[0] :""
                                        const PCBKodu = row.urun_kodu[1] ? ("-"+row.urun_kodu[1]):""
                                        const Optik = row.urun_kodu[2] ? ("-"+row.urun_kodu[2]):""
                                        const CCT  = row.urun_kodu[3] ? ("-"+row.urun_kodu[3]):""
                                        const Solar  = row.urun_kodu[4] ? ("-"+row.urun_kodu[4]):""
                                        const Battery  = row.urun_kodu[5] ? ("-"+row.urun_kodu[5]):""    
                                        let image = "../assets/images/ry.png" ;
                                        if(row  && row.urun_id &&  row.urun_id.productImages && row.urun_id.productImages.length && row.urun_id.productImages[0].image  && row.urun_id.productImages[0].image.smallimage ){
                                            image = row.urun_id.productImages[0].image.smallimage;
                                        }else{
                                            image = "../assets/images/ry.png" ;
                                        }
                                        

                                        return (
                                        <div key={itemIndex}>
                                            {true ?
                                                <div className={`card  ${false ? 'card-collapsed' : ""}`}>
                                                    <div className={row.ready ? "card-status card-status-left bg-success":"card-status card-status-left bg-danger"}/>
                                                    <div className="card-header">
                                                        <h6 className="card-title"><a href="#1" >{(row.urun_adi ? row.urun_adi : null)+" "}<strong>({ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}) </strong>{row.urun_miktari+" "+(messages[ "genel."+row.urun_birimi])+" "}</a></h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row  mb-2">
                                                            <div className="col-sm-2  col-md-2 col-3">
                                                                <div className="circle">
                                                                    <a href="#0" className="mb-3">
                                                                        <img className="rounded-circle" src={image  ? image : "../assets/images/ry.png"} alt="Moduled"  />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  col-md-3 col-9">
                                                                <strong><IntlMessages id="order.optik"/> {row.urun_kodu[2] ? row.urun_kodu[2]:"Belirtilmemiş"}</strong> <br />
                                                                <strong><IntlMessages id="order.cct"/> {row.urun_kodu[3] ? row.urun_kodu[3]:"Belirtilmemiş"}</strong> <br />
                                                                <strong><IntlMessages id="order.bodycolor"/> {row.urun_kodu[6] ? row.urun_kodu[6]:"Belirtilmemiş"}</strong> <br />
                                                                <strong><IntlMessages id="order.ledmark"/> {row.urun_kodu[7]  ? row.urun_kodu[7]:"Belirtilmemiş"}</strong> <br />
                                                                <strong><IntlMessages id="order.driverMark"/>  {row.urun_kodu[8] ? row.urun_kodu[8]:"Belirtilmemiş"}</strong> <br />
                                                            </div>
                                                            <div className="col-sm-3 col-md-3 col-12">
                                                                <strong><IntlMessages id="order.date"/></strong> <small className="float-right">{moment(row.siparis_tarihi).format("DD.MM.YYYY")}</small><br />
                                                                <div className="clearfix">
                                                                    <div className="float-left"><strong>{parseFloat(row.tamamlanmaOrani).toFixed(1)+"%"}</strong></div>
                                                                    <div className="float-right"><small className="text-muted">{messages["order.orderStatus"+row.siparis_durumu]}</small></div>
                                                                </div>
                                                                <div className="progress progress-xs">
                                                                    <div className="progress-bar bg-azure" role="progressbar" style={{ width: row.tamamlanmaOrani+'%' }} aria-valuenow={row.tamamlanmaOrani} aria-valuemin={0} aria-valuemax={100} />
                                                                </div>
                                                                <div>
                                                                    <div className="text-muted-dark">{row.teklifno  ? row.teklifno._id : "Stok Üretimi / Alt Montaj "}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2 col-md-2 col-6 text-center">
                                                                <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {row.deliveried_id.reduce((acc, curr) => acc + parseInt(curr.qty, 10), 0)}</h2>
                                                                <div className="text-muted-dark">{messages["genel."+row.urun_birimi]}</div>
                                                                <div className="text-muted-dark">{messages["order.orderStatus5"]}</div>
                                                            </div>
                                                            <div className="col-sm-2 col-md-2 col-6 text-center">
                                                                <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {row.urun_miktari}</h2>
                                                                <div className="text-muted-dark">{messages["genel."+row.urun_birimi]}</div>
                                                                <div className="text-muted-dark">{messages["offer.OfferStatus1"]}</div>
                                                            </div>
                                                        </div>
                                                        {row.order_type && <span className={"tag tag-success "}>{row.order_type}</span>}
                                                        {row.order_type==="production" && !row.Operations.length && <span className="tag tag-danger ">Üretim Planlanmamış</span>}

                                                        <span className={row.siparis_durumu  === 4  ? "tag tag-success ml-2":row.siparis_durumu  === 2 ? "tag tag-warning ml-2": "tag tag-danger ml-2"}>{messages["order.orderStatus"+row.siparis_durumu]}</span>
                                                        {row.ready === false  && <span className="tag tag-warning ml-2">{messages["order.orderNotReady"]}</span>}
                                                        {<span className="tag tag-info ml-2">{" Siparişten itibaren "+parseFloat((moment(new Date()) - moment(row.siparis_tarihi))/ (1000 * 3600 * 24)).toFixed()+" Gün GEÇTİ"}</span>}
                                                        <div className="table-responsive">
                                                            <table className="table table_custom spacing5 border-style mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th  ><IntlMessages id="order.operation"/></th>
                                                                        <th className=" text-center"><IntlMessages id="order.startdate"/></th>
                                                                        <th className=" text-center"><IntlMessages id="order.finishdate"/></th>
                                                                        <th ><IntlMessages id="order.operationstaff"/></th>
                                                                        <th className="w150"><IntlMessages id="order.percomlated"/></th>
                                                                        <th className="w20"/>
                                                                    </tr>
                                                                </thead>
                                                                    {row.Operations  ?  row.Operations.map((operation, operationIndex)=>{
                                                                        return(
                                                                        <tbody key={operationIndex}>
                                                                            <tr >
                                                                                <td>
                                                                                    <h6 className="mb-0">{operation.Operation ? ((operation.Operation+" "+operation.Qty+" "+row.urun_birimi)) : null}</h6>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className="mb-0">{operation.OperationStartTime ? moment(operation.OperationStartTime).format("DD.MM.YYYY"):"--.--.---"}</h6>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className="mb-0">{operation.OperationFinishTime ? moment(operation.OperationFinishTime).format("DD.MM.YYYY"):"--.--.---"}</h6>
                                                                                </td>                                                    
                                                                                <td >
                                                                                    <h6 className="mb-0">{operation.OperationStaff ? operation.OperationStaff : null}</h6>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="clearfix">
                                                                                        <div className="float-left"><strong>{operation.OperationPercent.toFixed(1)+"%"}</strong></div>
                                                                                        <div className="float-right"><small className="text-muted">{messages["order.operationStatus"+operation.OperationStatus]}</small></div>
                                                                                    </div>
                                                                                    <div className="progress progress-xs">
                                                                                        <div className="progress-bar bg-azure" role="progressbar" style={{ width: operation.OperationPercent+'%' }} aria-valuenow={operation.OperationPercent} aria-valuemin={0} aria-valuemax={100} />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
 
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                }):""}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            </div>
                                    )})}
                                    {/* <Pagination
                                    currentPage={this.state.currentPage}
                                    totalPage={this.state.totalPage}
                                    onChangePage={i => this.onChangePage(i)}
                                    />   */}
                                </>



                                
                            </div>  
    
                        
                        </div>
                    
                    </div>
            
        
                
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth:state.auth,
    tcmb:state.tcmb.tcmb,
    locale:state.settings.locale,
    dovizRowVisible:state.settings.offerDovizRow,
    offerNotesVisible:state.settings.offerNotesRow,
    invoiceType:state.settings.invoiceType,
    offerDetail:state.offerDetail,
    companyList : state.companyList,
    productList:state.productList,
    materials:state.materials,
    modelList:state.modelList,
    companyDetail:state.companyDetail
})

const mapDispatchToProps  =({getOfferDetails,getCompanyList,updateCompany,createCompany,getCompanyDetails,offerDovizOpen,offerNotesOpen,getProductList,getModelList,createOffer,updateOffer,deleteOffer,setLoading,setInvoiceType,getMaterialsList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderTracking)));
