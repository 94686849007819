import React, { Component } from 'react'
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'

///import IntlMessages from "../../helpers/IntlMessages";
import {device_list_request} from '../../actions';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

const Map = ReactMapboxGl({
	accessToken: 'pk.eyJ1IjoiZnVhdHNlbmd1bCIsImEiOiJjazgzZ3lsMG4wNDZ2M2VqdTJxem1hbXd2In0.SFayuiX3qPQAp_nk_i9mjw'
});

class GatewayList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            deviceList:[]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
 
            this.props.device_list_request();
    }
//******************************************************************************************************************************************************************/
    clusterMarker(coordinates, pointCount){
        return (
            <Marker coordinates={coordinates}>
                    <span circular>{pointCount}</span>
            </Marker>
        );
    }

//******************************************************************************************************************************************************************/
    onClickDetail=(id)=>{
        this.props.history.push({pathname : `/devicedetails/`+id})
    }
//******************************************************************************************************************************************************************/
    render() {
        //const { messages } = this.props.intl;
        //const {deviceList} = this.state


        return (
            <>
            <div  className={this.props.deviceList.loading ? "dimmer active" : ""}>
                {this.props.deviceList.loading && <div className="loader"/>}
                <div className={this.props.deviceList.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <Map
                                    // eslint-disable-next-line
									style="mapbox://styles/mapbox/streets-v9"
									center={[32.85427, 39.91987]}
									zoom={[17]}
                                    containerStyle={{
                                        height: '100vh',
                                        width: '100vw'
                                      }}

									>
										{/* <Cluster ClusterMarkerFactory={this.clusterMarker}> */}
											{this.props.deviceList.data.map(device => {
												
													return (
														<>
												
                                                            {device.map_lat && device.map_lng && <div>
                                                                <Marker
                                                                    coordinates={[device.map_lng, device.map_lat]}>
                                                                        <i className="fa fa-star"/>
                                                                </Marker> 
                                                    
                                                                {device.mesh_items.map(item => {
                                                             
                                                                        return (
                                                                            item.map_lat && item.map_lng  &&
                                                                                <Marker
                                                                                    coordinates={[item.map_lng, item.map_lat]}>
                                                                                        <i className="fa fa-star"/>
                                                                                </Marker>
                                                                    
                                                                        );
                                                                    
                                                                })}

                                                            </div>}
														</>
													);
												
											})	
											}
											{/* </Cluster> */}
								</Map>	
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    deviceList: state.deviceList

})

const mapDispatchToProps = ({device_list_request})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GatewayList)));
