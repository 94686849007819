import React, { Component } from 'react'
import { connect } from 'react-redux';
import Barchart from '../common/barchart';
import { injectIntl } from "react-intl";
import {getOrderItemList} from '../../actions';


class ordersDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
//******************************************************************************************************************************************************************/
	componentDidMount() {
		this.props.getOrderItemList("detaillist",this.state.sortColumn,1,4);
    }
	componentWillReceiveProps(nextProps){
		let orderedProductList = []
		if (this.props.orderItemList.data !== nextProps.orderItemList.data) {
			 const modelSum = [];
			 nextProps.orderItemList.data.forEach(function (a) {
				if (!this[a.urun_kodu[0]]) {
                    
					this[a.urun_kodu[0]] = { model: a.urun_kodu[0], siparis: 0, teslimat:0 ,resim:a.urun_id && a.urun_id.productImages && a.urun_id.productImages[0] ? a.urun_id.productImages[0].image.smallimage : "",birim:a.urun_birimi};
					modelSum.push(this[a.urun_kodu[0]]);
				} 
					this[a.urun_kodu[0]].siparis += a.urun_miktari;
					this[a.urun_kodu[0]].teslimat += a.teslim_edilmis;
            }, Object.create(null));

			let data = nextProps.orderItemList.data;
            data &&  data.length   && 
            [...new Map( data.map(x=> [x.urun_adi,x])).values()].forEach(row => {
                orderedProductList.push({
                    product: row.urun_adi,
					qty: row.urun_miktari,
					unit: row.urun_birimi,
					price:row.urun_fiyati,
                    currency:row.urun_dovizi,
                    perVal:row.tamamlanmaOrani
                });
			}) 
            orderedProductList  && (orderedProductList.sort((a, b) => (a.qty > b.qty) ? -1 : 1 ))
			this.setState({
				modelSum:modelSum,
				orderedProductList:orderedProductList
			}); 
		};  
    }
    

    render() {
        const { orderedProductList,modelSum} = this.state;
        const { messages } = this.props.intl;
        return (
            <>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="row clearfix row-deck">
                            <div className="col-xl-12 col-lg-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Sipariş/Teslimat Ürün Model Grafigi</h3>
									</div>
									<div className="card-body">
										{this.state.modelSum &&this.state.modelSum.length && <Barchart data={this.state.modelSum}></Barchart>}
									</div>
								</div>
							</div>
                            <div className="col-lg-6 col-md-6">
                                {true ?
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Ürün Listesi</h3>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped mt-4">
                                                <tbody>
                                                    {orderedProductList && orderedProductList.map((product,pindex)=>{
                                                        return(
                                                        <tr key={pindex}>
                                                            <td>
                                                                <label className="d-block">{product.product} <span className="float-right"> %{parseFloat(product.perVal).toFixed(1)}</span></label>
                                                                <div className="progress progress-xs">
                                                                    <div className="progress-bar bg-blue" role="progressbar" aria-valuenow={product.perVal} aria-valuemin={0} aria-valuemax={100} style={{ width: product.perVal+'%' }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {true ?
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Model Listesi</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 mb-0">
                                                    <tbody>
                                                        {modelSum &&  modelSum.map((item,index)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td className="w80">
                                                                        <img
                                                                            src={item.resim ? item.resim:"../assets/images/ry.png" }
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            alt="Moduled"
                                                                            className="rounded-circle img-thumbnail w80"
                                                                            data-original-title="Moduled"
                                                                        />
                                                       
                                                                    </td>
                                                                    <td>
                                                                        <small>{item.model}</small>
                                                                        <h6 className="mb-0">{item.siparis+" "+messages["genel."+item.birim]+ " Sipariş / "+item.teslimat+" "+messages["genel."+item.birim]+ " Teslim Edilen"}</h6>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
	auth:state.auth,
	orderItemList:state.orderItemList,
    locale:state.settings.locale,
})

const mapDispatchToProps = ({getOrderItemList})
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ordersDashBoard));