import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';

//************************************************ */
import Header from './Header'
import Buyer from './Buyer'
import Title from './Title'
import OfferInfo from './OfferInfo'

import OfferRows from './OfferRows'
import Foother from './Foother'
import source from "../../fonts/SourceSansPro-It.ttf"

Font.register({ family: 'SourceSansPro', src: source  });


const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'SourceSansPro',
        paddingTop: 20,
        paddingLeft:20,
        paddingRight:20,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 1,
        flexDirection: "row",
    },     
    RowContainer: {
        marginTop: 2,
        marginButtom: 3,
        flexDirection: "column",
        borderBottom: 1,
        borderColor:"blue",
        width:550
    },
    Foother: {
        position: 'absolute',
        flexDirection: "row",
        border:0.3,
        width:550,
        alignItems:'center',
        bottom: 30,
        right:30,
        height:60
    }
  });

const WorkOrder = ({data,company}) =>{
    
    const Dil = localStorage.getItem('currentLanguage')
    return (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header company={company} Dil={Dil}/>
            <Title/>
            <View style={styles.ViewContainer}>
                <Buyer data={data} Dil={Dil}/>
                <OfferInfo data={data} Dil={Dil}/>
            </View>
            
            <View style={styles.RowContainer}>
                {data ? data.offeritem.map((a) => {return( <OfferRows data={a} doviz={data.teklif_dovizi} key={a._id} Dil={Dil}/>);}): ""}
            </View>
            
       

            <View style={styles.Foother} fixed>
               <Foother id={data._id}/>
            </View>
          
           
        </Page>
    </Document>
)};
  
export default WorkOrder