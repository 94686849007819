import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
   
    TitleContainer:{
        marginTop: 10,
        flexDirection: "row",
        borderBottomWidth: 0.2,
        width:550
    },
});

const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
`;
  const Title = (data) => (
    <View style={styles.TitleContainer}>
        {localStorage.getItem('currentLanguage')==="tr" ?
        <Titlestyle># İŞ EMRİ #</Titlestyle> :
        <Titlestyle># WORKORDERS #</Titlestyle>}
    </View>
  );
  
  export default Title