import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import IntlMessages from "../../helpers/IntlMessages";
import moment from "moment";
import {changeLocale,logoutUser,getTcmbData,getCompanyList,getProductList,getModelList,getTodoList} from '../../actions' 

class Header extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  loading: false,
		  data :[{}]  
	
		};
	  };
	//******************************************************************************************************************************************************************/
	componentDidMount() {
        this.props.getTodoList("list")
    }
	//******************************************************************************************************************************************************************/
	handleChangeLocale = (locale) => {
		this.props.changeLocale(locale);
	};

	//******************************************************************************************************************************************************************/
	logOut = () =>{
		this.props.logoutUser();
		//window.location.reload();
	}
	test=()=>{
	   const {formName} = this.props
	   
	   if (formName==="products"){
		//this.props.history.push({pathname : `/pdf/pricelist`})
		
	   }
    }
	//******************************************************************************************************************************************************************/
	render() {
		const { fixNavbar, darkHeader } = this.props;
		return (
			<div>
				<div
					id="page_top"
					// className={isFixNavbar ? "sticky-top" : "" + this.props.dataFromParent === 'dark' ? 'section-body top_dark' : 'section-body'}
					className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
				>
					<div className="container-fluid">
						<div className="page-header">
							<div className="left">
								<h1 className="page-title">{<IntlMessages id={this.props.dataFromSubParent} />}</h1>
								<div>
									<span>  </span>
								</div>
							</div>
							<div className="right">
								<ul className="nav nav-pills">
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle"
											data-toggle="dropdown"
											href="fake_url"
											role="button"
											aria-haspopup="true"
											aria-expanded="false"
										>
											{this.props.locale === "tr" ? "Türkçe" : "English"}
										</a>
										<div className="dropdown-menu">
											<button className="dropdown-item" onClick={() => this.handleChangeLocale("en")}>
												<img
													className="w20 mr-2"
													src="/assets/images/flags/us.svg"
													alt="tr"
												/>
												English
											</button>
											<div className="dropdown-divider" />
											<button className="dropdown-item" onClick={() => this.handleChangeLocale("tr")}>
												<img
													className="w20 mr-2"
													src="/assets/images/flags/tr.svg"
													alt="en"
												/>
												Türkçe
											</button>
										</div>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle"
											data-toggle="dropdown"
											href="fake_url"
											role="button"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Reports
										</a>
										<div className="dropdown-menu">
											<a className="dropdown-item" href="#1" onClick={()=>this.test()}>
												<i className="dropdown-icon fa fa-file-excel-o" /> MS Excel
											</a>
											<a className="dropdown-item" href="fake_url">
												<i className="dropdown-icon fa fa-file-word-o" /> MS Word
											</a>
											<a className="dropdown-item" href="fake_url">
												<i className="dropdown-icon fa fa-file-pdf-o" /> PDF
											</a>
										</div>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle"
											data-toggle="dropdown"
											href="fake_url"
											role="button"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Project
										</a>
										<div className="dropdown-menu">
											<a className="dropdown-item" href="fake_url">
												Graphics Design
											</a>
											<a className="dropdown-item" href="fake_url">
												Angular Admin
											</a>
											<a className="dropdown-item" href="fake_url">
												PSD to HTML
											</a>
											<a className="dropdown-item" href="fake_url">
												iOs App Development
											</a>
											<div className="dropdown-divider" />
											<a className="dropdown-item" href="fake_url">
												Home Development
											</a>
											<a className="dropdown-item" href="fake_url">
												New Blog post
											</a>
										</div>
									</li>
								</ul>
								<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-try" />
											<span className="badge badge-success nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="right_chat list-unstyled w250 p-0">
												<li className="online">
													<div className="media">
														<div className="media-body  text-right">
															<span className="name mb-2"><IntlMessages id="genel.usdSelling"/> {this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[0].ForexSelling}</span>
															<span className="name"><IntlMessages id="genel.usdBuying"/> {this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[0].ForexSelling}</span>
														</div>
													</div>
												</li>
												<li className="online">
													<div className="media">
														<div className="media-body  text-right">
															<span className="name mb-2"><IntlMessages id="genel.eurSelling"/> {this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[1].ForexSelling}</span>
															<span className="name"><IntlMessages id="genel.eurBuying"/> {this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[1].ForexSelling}</span>
														</div>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<a href="#1" className="dropdown-item text-center text-muted-dark readall" onClick={()=>this.props.getTcmbData()}>
												<IntlMessages id="genel.tcmbupdate"/>
											</a>
										</div>
									</div>
								<div className="notification d-flex">
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-envelope" />
											<span className="badge badge-success nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="right_chat list-unstyled w250 p-0">
												<li className="online">
													<a href="fake_url">
														<div className="media">
															<img
																className="media-object "
																src="../assets/images/xs/avatar4.jpg"
																alt="fake_url"
															/>
															<div className="media-body">
																<span className="name">Donald Gardner</span>
																<span className="message">Designer, Blogger</span>
																<span className="badge badge-outline status" />
															</div>
														</div>
													</a>
												</li>
												<li className="online">
													<a href="fake_url">
														<div className="media">
															<img
																className="media-object "
																src="../assets/images/xs/avatar5.jpg"
																alt="fake_url"
															/>
															<div className="media-body">
																<span className="name">Wendy Keen</span>
																<span className="message">Java Developer</span>
																<span className="badge badge-outline status" />
															</div>
														</div>
													</a>
												</li>
												<li className="offline">
													<a href="fake_url">
														<div className="media">
															<img
																className="media-object "
																src="../assets/images/xs/avatar2.jpg"
																alt="fake_url"
															/>
															<div className="media-body">
																<span className="name">Matt Rosales</span>
																<span className="message">CEO, Epic Theme</span>
																<span className="badge badge-outline status" />
															</div>
														</div>
													</a>
												</li>
												<li className="online">
													<a href="fake_url">
														<div className="media">
															<img
																className="media-object "
																src="../assets/images/xs/avatar3.jpg"
																alt="fake_url"
															/>
															<div className="media-body">
																<span className="name">Phillip Smith</span>
																<span className="message">Writter, Mag Editor</span>
																<span className="badge badge-outline status" />
															</div>
														</div>
													</a>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<a
												href="fake_url"
												className="dropdown-item text-center text-muted-dark readall"
											>
												Mark all as read
											</a>
										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-bell" />
											<span className={this.props.todo && this.props.todo.data && this.props.todo.data.length  ? "badge badge-primary nav-unread" : "badge badge-primary"} />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">

												{this.props.todo && this.props.todo.data ?  this.props.todo.data.map((todorow,index)=>{
											
													return(
														<li key={index}>
															<div className="feeds-left">
																{todorow.read ?  <i className="fa fa-check" /> : <i className="fa fa-close" />}
															</div>
															<div className="feeds-body">
																<h4 className="title text-danger">
																	{todorow.person}
																	<small className="float-right text-muted">{moment(todorow.date).format("DD.MM.YYYY HH:MM")}</small>
																</h4>
																<small>{todorow.task}</small>
															</div>
														</li>
													)
												}):null}
											</ul>
											<div className="dropdown-divider" />
											<a
												href="#1"

												className="dropdown-item text-center text-muted-dark readall"
											>
												Mark all as read
											</a>
										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-user" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											{this.props.auth.status !== "failed" &&  this.props.auth.role !==null  ?
												<div>
													<NavLink to="/profile" className="dropdown-item">
														<i className="dropdown-icon fe fe-user" /> <IntlMessages id="user.profile" />
													</NavLink>
													<a className="dropdown-item" href="fake_url">
														<i className="dropdown-icon fe fe-settings" /> Settings
													</a>
													<a className="dropdown-item" href="fake_url">
														<span className="float-right">
															<span className="badge badge-primary">6</span>
														</span>
														<i className="dropdown-icon fe fe-mail" /> Inbox
													</a>

													<div className="dropdown-divider" />
													<a href="/web" className="dropdown-item btn-block" onClick={()=>this.logOut()}>
														<i className="fa fa-sign-out" /> <IntlMessages id="user.signout" />
													</a>
												</div>
												:
												<a className="dropdown-item" href="/login">
													<i className="dropdown-icon fe fe-send" /> <IntlMessages id="user.signin"/>
												</a>
												}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	darkHeader: state.settings.isDarkHeader,
	locale:state.settings.locale,
	auth:state.auth,
	todo:state.todoList,
	tcmb:state.tcmb.tcmb,
	
})

const mapDispatchToProps = ({changeLocale,logoutUser,getTcmbData,getCompanyList,getProductList,getModelList,getTodoList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header)));