import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    BuyerContainer: {
        marginTop: 10,
        flexDirection: "column",
        width:300,
        border:0.5,
        borderRadius:5
    },
    TaxContainer: {
        flexDirection: "row",
    },
    AdressContainer:{
        flexDirection: "row",
        width:550
    }
  });
const BuyerCompanyName = styled.Text`
font-size: 9px;
text-align: left;
height :18px;
margin:3px;
`;
const BuyerAdres1 = styled.Text`
font-size: 9px;
text-align: left;
height :18px;
margin:3px;
`;
const BuyerAdres2 = styled.Text`
font-size: 9px;
text-align: left;
height :18px;
margin:3px;
`;
const TaxOffice = styled.Text`
margin:3px;
font-size: 9px;
text-align: left;
height :18px;
width:149px;
`;
const TaxNo = styled.Text`
marginLeft:2px;
margin:3px;
font-size: 9px;
text-align: left;
height :18px;
width:149px;
color : #004678;
`;
const BuyerTitle = styled.Text`
font-family:"OpenSansFontBold";
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
height :18px;
margin:3px;
`;
const DeliveryAddress = styled.Text`
    margin: 0px;
    font-size: 8px;
    text-align: left;
`;

const BuyerInfo = ({data,messages}) => {
    const deliveryadresscontainer = data.teklif_sevkadresi==="" ? false : true;
    const taxcontaineropen = data.firma_vergidairesi==="" ? false : true
    return(
    
<View style={styles.BuyerContainer}>
    <BuyerTitle style={styles.billTo}>{messages["offer.buyer"]}</BuyerTitle>
    <BuyerCompanyName>{data.firma_adi +" ("+data.related_person+")"}</BuyerCompanyName>
    <BuyerAdres1>{(data.firma_adres && data.firma_adres) +" "+(data.firma_ilce && data.firma_ilce)}</BuyerAdres1>
    <BuyerAdres2>{(data.firma_il && data.firma_il)+" "+(data.firma_ulke && data.firma_ulke)}</BuyerAdres2>
   
    {taxcontaineropen && 
    <View style={styles.TaxContainer}> 
        <TaxOffice>{messages["offer.taxoffice"]} : { data.firma_vergidairesi}</TaxOffice>
        <TaxNo>{messages["offer.taxno"]} : {data.firma_vergino}</TaxNo>
    </View>}
    {deliveryadresscontainer && 
    <View style={styles.AdressContainer}>
        <DeliveryAddress>{messages["offer.deliveryaddress"]} : {data.teklif_sevkadresi}</DeliveryAddress>
    </View>}
</View>
)};
  
export default BuyerInfo