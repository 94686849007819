import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { loginUser ,clearErrors} from "../../actions";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import IntlMessages from "../../helpers/IntlMessages";
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loginUser:{}
		};
	}
//*****************************************************************************************************************************************/
	onUserLogin = (values) => {
		//const { messages } = this.props.intl;
		if (!this.props.loading) {
			if (values.email !== "" && values.password !== "") {
				// Attempt to login
				this.props.loginUser(this.state.loginUser, this.props.history)
				.then(res=>{
				
					let {status, explanation} = this.props.auth;
					if(status==="Failed"){
						store.addNotification({
							title: status,
							message: explanation ? explanation  : "Error",
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
				.catch((err) => {				
				});
			};
		} 
	}
//*****************************************************************************************************************************************/
	componentDidUpdate(){
		let {status, explanation} = this.props.auth;
		if(status==="Failed"){
			store.addNotification({
				title: status,
				message: explanation ? explanation  : "Error",
				type: "danger",
				insert: "top",
				container: "top-right",
				dismiss: {
				duration: 3000,
				onScreen: true
				}
			});
			this.props.clearErrors();
		}
	}
//*****************************************************************************************************************************************/
	onChangeLoginData=(field,value)=>{
		this.setState((prevState) => {
			let loginUser = Object.assign({}, prevState.loginUser);  
			loginUser[field] = value;                             
			return { loginUser};  
		});
	}
//*****************************************************************************************************************************************/
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-2">
							<Link className="header-brand" to="/">
								<i className="fe fe-command brand-logo" />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Login to your account</div>
							{/* <div className="form-group">
								<select className="custom-select">
									<option>HR Dashboard</option>
									<option>Project Dashboard</option>
									<option>Job Portal</option>
								</select>
							</div> */}
							<div className="form-group">
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Enter email"
									value={this.state.email}
									onChange={(e)=>this.onChangeLoginData("email",e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label className="form-label">
									Password
									<Link className="float-right small" to="/forgotpassword">
										I forgot password
									</Link>
								</label>
								<input
									type="password"
									className="form-control"
									id="exampleInputPassword1"
									placeholder="Password"
									onChange={(e)=>this.onChangeLoginData("password",e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input"  />
									<span className="custom-control-label">Remember me</span>
								</label>
							</div>
							<div className="form-footer">
								<button className="btn btn-primary btn-block"  onClick={this.onUserLogin}>
									<IntlMessages id="user.signin"/>
								</button>
							</div>
							<div className="form-footer">
								<a className="btn btn-primary btn-block" href="/web">
									<IntlMessages id="menu.web"/>
								</a>
							</div>
						</div>
						<div className="text-center text-muted">
							Don't have account yet? <Link to="/signup">Sign Up</Link>
						</div>
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={2000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="assets/images/SLS1.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>SLS1 Serisi Solar Armatürler</h4>
									<p>Hızlı Sarj, Lityum Pil ve akıllı yönetim sisttemleri ile donanımlı</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/SLS3.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>SLS3 Serisi Solar Armatürler</h4>
									<p>Yüksek Güç, Yüksek Enerji Gerektiren her yerde</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/SLS-ALM.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>SLS-ALM Serisi Armatürler</h4>
									<p>Tamamen Geri Dönüşümlü Mazlzemeler ile üretilmiştir</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state  => ({
	auth:state.auth,
	isAuthenticated: state.auth.isAuthenticated,
	loading : state.auth.loading,
	locale:state.settings.locale,
  });
  
  const mapDispatchToProps  =({loginUser,clearErrors})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login)));

