import {
    BUYINGS_GET_LIST,
    BUYINGS_GET_LIST_SUCCESS,
	BUYINGS_GET_LIST_ERROR
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Offer List GET ****************************************************************************************************************************************************

export const buyings_list_set_pending = () => ({
	type: BUYINGS_GET_LIST
});

export const buyings_list_set_successful = (res) => ({
	type: BUYINGS_GET_LIST_SUCCESS,
	response: res
});

export const buyings_list_set_failed = () => ({
	type: BUYINGS_GET_LIST_ERROR
});
 
export const getBuyingsList = (type,sortColunm,filter1,filter2) => dispatch =>{
	const apiUrl_list = `/buying/list`;
	dispatch(buyings_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?filter1=${filter1}&filter2=${filter2}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
		
			if (res.status === 200) {
				dispatch(buyings_list_set_successful(res.data));
				resolve(true);
			} else {
				dispatch(buyings_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(buyings_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject();
		});
	});
};
