import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
const styles = StyleSheet.create({
    OfferInfoContainer: {
        marginTop: 10,
        marginLeft: 50,
        flexDirection: "column",
        textAlign: 'right',
        border:0.5,
        borderRadius:5,
        width:200
    },
    ViewRowContainer:{
        flexDirection: "row",
    }
  });
const Date = styled.Text`
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
height :18px;
margin:3px;
width:118px
`;
const OfferNo = styled.Text`
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
height :18px;
margin:3px;
width:118px
`;
const OfferRefferance = styled.Text`
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
height :18px;
margin:3px;
width:118px
`;
const Label = styled.Text`
font-family:"OpenSansFontBold";
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
height :18px;
margin:3px;
width:70px;
`;

const OfferInfo = ({data,messages}) => (
  
<View style={styles.OfferInfoContainer}>
    <View style={styles.ViewRowContainer}>
        <Label>{messages["offer.DATE"]} :</Label>
        <Date>{data.teklif_tarih && moment(data.teklif_tarihi).format('DD.MM.YYYY')}</Date>
    </View>
    <View style={styles.ViewRowContainer}>
        <Label>{messages["offer.OFFERNO"]} :</Label>
        <OfferNo>{data.teklif_no}</OfferNo>
    </View>    
    <View style={styles.ViewRowContainer}>
        <Label>{(messages["offer.referance"]).toUpperCase()} :</Label>
        <OfferRefferance>{data.teklif_referansno}</OfferRefferance>
    </View>     

</View>
);
  
export default OfferInfo