/* Moduled A.Ş. Language Texts

Table of Contents

General
User Login, Logout, Register
01. Dashboard
02.Companies / Firmalar / 


04.Error Page
*/


module.exports = {

      /* 01.Menu */
    "menu.welcome": "Hoş  Geldin ",
    "menu.productionDashBoardMessage":"Üretim Operasyon Özetleri",

    "menu.web":"Web Arayüz",
    "menu.dashboard": "Ana Menü",
    "menu.login":"Giriş",


    "menu.companies": "Firmalar",
    "menu.companyDetail":"Firma Detayları",
    "menu.users":"Kullanıcılar",
    "menu.companysetting":"Firma Ayarları",
    "menu.tcmb":"Kur Yönetimi",
    "menu.imagemanagment":"Resim Yönetimi",



    "menu.productmanagment":"Ürün Yönetimi",
    "menu.modeldetail":"Model Detayları",
    "menu.productDetail":"Ürün",
    "menu.models":"Modeller",
    "menu.products":"Ürünler",
    "menu.materials":"Malzemeler",

    "menu.offermanagment":"Teklif Yönetimi",
    "menu.offers":"Teklifler",
    "menu.offersetting":"Teklif Ayarlar",
    "menu.offer":"Teklif",




    "menu.ordermanagment":"Sipariş Yönetimi",
    "menu.orderDashBoard":"Sipariş Özet",
    "menu.orders":"Siparişler",
    "menu.complated":"Tamamlanan",
    "menu.order":"Sipariş Detaylar",


    "menu.productionmanagment":"Üretim Yönetimi",
    "menu.productionDashBoard":"Üretim Özet",
    "menu.productionlist":"Üretim",
    "menu.orderItemList":"Üretim Listesi",
    "menu.productiondetail":"Üretim Detay",
    "menu.orderitemdetail":"Stok Üretimi",
    "menu.componentlist":"Malzeme Listesi",

    "menu.labeleditor":"Etiketleme",
    "menu.productslabels":"Ürün Etiketleme",

    "menu.search":"Ara..",
    "menu.all":"Tümü",
    "menu.choosee":"Seçiniz",
    "menu.sort":"Sırala",





    "menu.complatedorders":"Tamamlanan Siparişler",
    "menu.deliveriedorders":"Teslim Edilen Siparişler",



    "menu.akaysis":"Akaysis",
    "menu.akaysislogin":"Giriş",
    "menu.gatewaylist":"Cihaz Listesi",
    "menu.devicedetails":"Cihaz Detayları",
    "menu.akaysismap":"Harita",

    "menu.test":"test",








    /* GENEL TERİMLER */
    "genel.unit":"Birimi",
    "genel.Adet":"Adet",
    "genel.Kg":"Kg",
    "genel.Metre":"Metre",
    "genel.Paket":"Paket",
    "genel.Takım":"Takım",
    "genel.choose":"Seçiniz",
    "genel.date":"Tarih",
    "genel.usdSelling":"USD Satış",
    "genel.eurSelling":"EUR Satış",   
    "genel.usdBuying":"USD Alış",
    "genel.eurBuying":"EUR Alış",   
    "genel.kdv":"KDV",
    "genel.all":"Tümü",
    "genel.tcmbupdate":"TCMB Kur Güncelle",







    /* General */
    "general.copyright": "Moduled AS © 2021  ",
    /*Error Page */
    "pages.error-title": "Hopsss... Aradığınız Sayfa Yok!",
    "pages.error-code": "Hata Kodu",
    "pages.go-back-home": "Ana Menüye Dön",


    /*DashBoard */
    "dashboard.message":"Bugün ",
    "dashboards.logs": "Kayıt Geçmişi",
    "dashboards.calendar":"Takvim",
    "dashboards.product-categories":"Ürün Kategoriler",


    /*COMPANY*/
    "company.Suppliers":"Tedarikçi",
    "company.Customer":"Müşteri",
    "company.aboutme":"Hakkında",
    "company.status" :"Firma Durumu",
    "company.name" :"Firma Adı",
    "company.address" :"Firma Adres",
    "company.contact" :"Firma İletişim",
    "company.taxinfo" :"Vergi  Bilgileri",
    "company.orderbyName":"Firma Adı",
    "company.orderbyCategory":"Kategori",
    "company.orderbyStatus":"Durum",
    "company.COMPANY":"FİRMA",
    "company.ADDRESS":"ADRES",
    "company.viewdetails":"Düzenle",
    "company.email":"Posta Gönder",
    "company.sms":"SMS",
    "company.info":"Firma Bilgileri",
    "company.detail":"Detaylar",
    "company.reportimages":"Rapor Logo Bilgileri",
    "company.qcquestion":"QC Soruları",
    "company.certificates":"Sertifika Listesi",
    "company.banklist":"Banka Listesi",
    "company.orderedlist":"Firma Geçmişi",
    "company.deliveryList":"Teslimat Listesi",
    

    "image.edit":"Resim Düzenle",
    "image.model":"Geçerli Modeller",
    "image.description":"Açıklama",
    "image.models":"Modeller",
    "image.image":"Resim",



 
    "mycompany.qc":"Kalite Kontrol Sorusu",
    "mycompany.logo":"Logo",
    "mycompany.bwlogo":"BW Logo",
    "mycompany.certificate":"Sertifika",
    "mycompany.certificateedit":"Sertifika Düzenle",
    "mycompany.question":"Soru",
    "mycompany.qcno":"No",
    "mycompany.qcquestionedit":"K.K. Sorusu Düzenle",

    /*BUTTON*/
    "button.save":"Kaydet",
    "button.close":"Kapat",
    "button.delete":"Sil",
    "button.add":"Yeni",
    "button.settings":"Ayarlar",
    "button.addrow":"Satır Ekle",
    "button.rowdelete":"Satır Sil",
    "button.viewrowdetail":"Düzenle",
    "button.changepassword":"Şifre Değiştir",
    "button.print":"Yazdır",
    "button.requestoffer":"Teklif Talebi",
    "button.productlist":"Ürün Listesi",
    "button.datasheet":"Teknik Dosya",
    "button.createpdf":"PDF Oluştur",
    "button.label":"Etiket",
    "button.action":"Aksiyonlar",
    "button.email":"Eposta",
    


    /*USER*/
    "user.signout":"Güvenli Çıkış",
    "user.signin":"Sistem Girişi",
    "user.0": "Geliştirici",
    "user.1": "Yönetici",
    "user.2": "İdari Personel",
    "user.3": "Personel",
    "user.4": "Ana Bayii",
    "user.5": "Bayii",
    "user.6": "Müşteri",
    "user.7": "Kullanıcı",
    "user.info":"Kullanıcı Bilgileri",
    "user.detail":"Detaylar",
    "user.sortfirstname":"Ad ile Sırala",
    "user.sortlastname":"Soyad ile Sırala",
    "user.firstname":"İsim",
    "user.role":"Kullanıcı Rolü",
    "user.email":"E-Posta",
    "user.mobile":"Mobil Telefon",
    "user.changepassword":"Şifre Değiştirme",
    "user.selectimages":"Resim Şeç",
    "user.lastname":"Soyisim",
    "user.viewdetails":"Düzenle",
    "user.profile":"Kullanıcı Profili",
    "user.usercompany":"Kullanıcı Firması",
    "user.createddate":"Kayıt Tarihi",
    "user.username":"Kullanıcı",
    "user.sms":"SMS",
    "user.status":"Kullanıcı Durumu",



    /*MODEL  */
    "model.detail":"Model Detayları",
    "model.specs":"Model Özellikleri",
    "model.images":"Model Resimleri",
    "model.production":"Üretim Detayları",
    "model.kategori0":"Yol Armatürleri",
    "model.kategori1":"Park Bahçe Armatürleri",
    "model.kategori2":"Led Projektörler",
    "model.kategori3":"Güneş Enerjili Armatürler",
    "model.kategori4":"Endüstriyel Armatürler",
    "model.kategori5":"DC Armatürler",
    "model.kategori6":"LED Modül",
    "model.kategori7":"Solar Panel",
    "model.kategori8":"Pil Grupları",
    "model.name":"Model Adı",
    "model.category":"Kategori",
    "model.description":"Açıklama",
    "model.code":"Model Kodu *",
    "model.sira":"Sıra",
    "model.tags":"Model Etiketler",
    "model.label":"Etiket",
    "model.feature":"Özellik",
    "model.operation":"Operasyon",
    "model.operationDescription":"Açıklama",
    "model.operationComponent":"Operasyon Malzemeleri",
    "model.operationedit":"Operasyon Düzenleme",
    "model.featureedit":"Özellik Düzenleme",
    "model.mainphoto":"Ana Görsel",
    "model.shortcategory":"Kategori ile Sırala",
    "model.shortname":"Model adı ile Sırala",
    "model.downloads":"İndirilebilir Dosyalar",
    "model.certificates":"Sertifikalar",

    /*ÜRÜNLER  */
    "product.viewdetails":"Düzenle",
    "product.filter":"Filitrele",
    "product.orderbyPrice":"Fiyata Göre",
    "product.orderbyCategory":"Kategoriye Göre",
    "product.orderbyPower":"Güç'e Göre",
    "product.detail":"Ürün Detayları",
    "product.specs":"Ürün Özellikleri",
    "product.production":"Üretim Detayları",
    "product.images":"Ürün Resimler",
    "product.price":"Fiyatı",
    "product.name":"Ürün",
    "product.category":"Kategori",
    "product.sira":"Ürün Sıra",
    "product.model":"Model",
    "product.code":"Ürün Kodu",
    "product.optic":"Optik",
    "product.cct":"CCT",
    "product.battery":"Batarya",
    "product.powerLumen":"Güç /Lumen",
    "product.dimensions":"Boyutlar",
    "product.weight":"Ağırlık",
    "product.lumen":"Ürün Lümen",
    "product.power":"Ürün Gücü",
    "product.solarpower":"Solar Panel",
    "product.capacity":"Ürün Kapasitesi",
    "product.currency":"Döviz",
    "product.label":"Etiket",
    "product.feature":"Özellik",
    "product.operation":"Operasyon",
    "product.materialcategory":"Kategori",
    "product.materialgroup":"Grup",
    "product.materialqty":"Miktar",
    "product.materialprice":"Fiyat",
    "product.USDprice":"Fiyat $",
    "product.rowtotal":"Toplam $",
    "product.operationedit":"Operasyon Düzenle",
    "product.totalPriceUSD":"Satır Toplam $",
    "product.priceTL":"TL Fiyatı",
    "product.grandTotal":"Satır Toplamı $",
    "product.datasheet":"Ürün PDF",
    "product.generalspecs":"Genel Özellikler",
    "product.electricalspecs":"Elektriksel Özellikler",
    "product.downloads":"İndirilebilir Dosyalar",
    "product.productListSettings":"Liste Ayarları",
    "product.productListType":"Liste Tipi",
    "product.pricelist":"Fiyat Listesi",
    "product.productListTextOpen":"Fiyat Listesi Önyazısı",
    "product.pricelistuser":"Hazırlayan",
    "product.tecnicaldrawing":"Teknik Çizim",
    "product.image":"Resim",
    "product.moredetails":"Daha Fazla Detay",
    "product.labeleditor":"Etiket Düzenleme",
    "product.productLabelType":"Etiket Tipi",
    "product.labelLanguage":"Etiket Dil",
    "product.logoOpen":"Logo Açık",
    "product.qrOpen":"QR Açık",
    "product.voltage":"Çalışma Voltajı",
    "product.boxQty":"Kutu Adeti",
    "product.operationcount":"Kat Sayı",
    "product.stockoperations":"Stok Hareketleri",
    "product.stockin":"Stok Girişi",
    "product.stockout":"Stok Çıkışı",
    "product.stock":"Stok",
    "product.spec":"Özellik",
    "product.newstockoperation":"Yeni Stok  Hareketi",



    "product.copy":"Kopyala",
    "product.paste":"Yapıştır",
    "product.complated":"Tamamlandı",
    "product.qty":"Miktar",
    "product.produced":"Üretilecek",
     /*MARERIAL  */
    "material.qc":"Malzeme Kalite Kontrol",
    "material.detail":"Malzeme Bilgileri",
    "material.price":"Fiyatı",
    "material.name":"Malzeme",
    "material.viewdetails":"Detaylar",
    "material.stock":"Stok Durumu",
    "menu.materialDetail":"Malzeme",
    "material.category":"Malzeme Kategorisi",
    "material.group" :"Malzeme Grubu",
    "material.currency":"Doviz",
    "material.image":"Malzeme Resim",
    "material.updateStock":"Stok Güncelleme",
 /*STOCK  */

   "stock.date":"Tarih",
   "stock.materials":"Malzeme",
   "stock.operation":"İşlem",
   "stock.operationcenter":"İşlem Birimi",
   "stock.oty":"Miktar",
   "stock.price":"Birim Fiyat",
   "stock.stock":"Stok",
   "stock.edit":"Düzenle",
   "stock.category":"Kategori",
   "stock.group":"Grup",
   "stock.unit":"Birimi",
   "stock.qty":"Miktar",
   "stock.description":"Açıklama",
   "stock.doviz":"Doviz",
   "stock.material":"Malzeme",
   "stock.stockin":"Stok Girişi",
   "stock.stockout":"Stok Çıkışı",
   "stock.newStockOperation":"Yeni Stok Hareketi",
   "stock.todaystockoutlist":"Bugün Kullanılan Malzeme Listesi",
   "stock.detail":"Stok Detay",

/* OFFER */
   "offer.title":" TEKLİFTİR ",
   "offer.buyer":"MÜŞTERİ",
   "offer.taxoffice":"VERGİ DAİRESİ",
   "offer.taxno":"VERGİ NO",
   "offer.deliveryaddress":"SEVK ADRESİ",
   "offer.OFFERNO":"TEKLİF NO",
   "offer.DATE":"TARİH",

   "offer.date":"Tarih",
   "offer.company":"Firma",
   "offer.total":"Tutar",
   "offer.status":"Durum",
   "offer.OfferStatus0": "Teklif",
   "offer.OfferStatus1": "Sipariş",
   "offer.OfferStatus2": "Tamamlandı",
   "offer.OfferStatus3": "Teslim Edildi",
   "offer.OfferStatus4": "İptal",
   "offer.orderbyName" :"Firma Adı ile Sırala",
   "offer.orderbyDate" :"Tarih ile Sırala", 
   "offer.person":"İlgili",
   "offer.subject":"Teklif Konusu",
   "offer.detail":"Teklif Bilgileri",
   "offer.setting":"Teklif Ayarları",
   "offer.currencyDetail":"Döviz Detayları",
   "offer.USDDetail":"USD Bilgileri",
   "offer.EURDetail":"EUR Bilgileri",
   "offer.currency":"Teklif Dövizi",
   "offer.product":"Ürün",
   "offer.qty":"Miktar",
   "offer.unit":"Birimi",
   "offer.price":"Fiyatı",
   "offer.tax":"KDV",
   "offer.rowtotal":"Toplam",
   "offer.rowgrandtotal":"G.Toplam",
   "offer.newcompany":"Yeni Firma Ekle",
   "offer.companyedit":"Firma Düzenle",
   "offer.rowedit":"Satır Düzenle",
   "offer.modelCode":"Model",
   "offer.pcbCode":"PCB",
   "offer.optik":"Optik",
   "offer.cct":"CCT",
   "offer.solar":"Panel",
   "offer.battery":"Batarya",
   "offer.offerrowGrandTotal":"Satır  Genel Toplamı",
   "offer.offerrowKDV":"Satır  KDV Toplamı",
   "offer.offerrowTotal":"Satır KDV'siz Toplam",
   "offer.product₺price":"Ürün ₺ Fiyatı",
   "offer.offerTotal":"Toplam",
   "offer.offerKDV":"KDV",
   "offer.offerGrandTotal":"Genel Toplam",
   "offer.rowDelete":"Satır Sil",
   "offer.addRow":"Satır Ekle",
   "offer.selectProduct":"Ürün Seç",
   "offer.selectMaterial":"Malzeme Seç",
   "offer.productPhoto":"Teklif Ürünü Resim",
   "offer.productionDetail":"Üretim Detayları",
   "offer.bodyColor":"Gövde Rengi",
   "offer.LED":"LED Markası",
   "offer.LEDSurucu":"LED Sürücü",
   "offer.viewDate":"Tarih Göster",
   "offer.stampsignOffer":"Kaşe İmza Aktif",
   "offer.stampOffer":"Kaşe Aktif",
   "offer.commercialInvoice":"Ticari Fatura Aktif",
   "offer.referance":"Referans No",
   "offer.district":"İlçe",
   "offer.city":"Şehir",
   "offer.address":"Adres",
   "offer.country":"Ülke",
   "offer.email":"E-Mail",
   "offer.creator":"Oluşturan",
   "offer.updater":"Değiştiren",
   "offer.offerNotes":"Teklif Notları",
   "offer.offerSubNotes":"Teklif Alt Bilgi",
   "offer.viewdetails":"Düzenle",
   "offer.PDF":"PDF Önizle",
   "offer.delete":"Teklifi Sil",
   "offer.copy":"Teklifi  Kopyala",
   "offer.takeoffstock":"Stoktan Düş",



   "order.date":"Sipariş Tarihi",
   "order.product":"Ürün",
   "order.percomlated":"Tamamlanma %",
   "order.status":"Üretim",
   "order.viewdetails":"Detaylar",
   "order.operationStatus0":"Bekliyor",
   "order.operationStatus1":"Devam Ediyor",
   "order.operationStatus2":"Tamamlandı",
   "order.operation":"Operasyon",
   "order.category":"Kategori",
   "order.productcode":"Ürün Kodu",
   "order.group":"Grup",
   "order.material":"Malzeme",
   "order.qty":"Miktar",
   "order.totalqty":"Toplam",
   "order.stock":"Stok",
   "order.editoperation":"Düzenle",
   "order.startdate":"Başlama Tarihi",
   "order.finishdate":"Bitiş Tarihi",
   "order.operationstaff":"Personel",
   "order.refreshCompList":"Malzeme Listesi Güncelle",
   "order.pdf":"PDF",
   "order.save":"Güncelle",




   "order.orderStatus0": "Teklif",
   "order.orderStatus1": "Bekliyor",
   "order.orderStatus2": "Devam Ediyor",
   "order.orderStatus3": "Kontrol Ediliyor",
   "order.orderStatus4": "Tamamlandı",
   "order.orderStatus5": "Teslim Edildi",
   "order.orderStatus6": "Stok Hareketi",
   "order.detail": "Sipariş Detayları",
   "order.production":"Üretim",
   "order.delivery":"Teslimat",
   "order.person":"İlgili",
   "order.phone":"Telefon",
   "order.email":"E-Posta",
   "order.bodycolor":"Gövde Rengi :",
   "order.ledmark":"LED Markası :", 
   "order.driverMark":"Sürücü Markası :",
   "order.cct":"Işıma Rengi :",
   "order.optik":"Lens Tipi :",

   "order.orderbyDate":"Sipariş Tarihi ile Sırala",
   "order.orderbyStatus":"Durumu ile Sırala",
   "order.orderbyName":"Ürün Adı ile Sırala",
   "order.orderNotReady":"Ürün Listesi Eksikleri Var",
   "order.orderbyOperation":"Operasyon İsmi İle Sırala",

   "order.addnewOperation":"Operasyon Ekle",
   "order.deleteOperation":"Operasyon Sil",
   "order.addNewOperation":"Yeni Operasyon Ekle",
   "order.operationstart":"Başlat",
   "order.operationfinish":"Bitir",
   "order.operationreset":"Sıfırla",
   "order.operationcomplated":"Tamamla",
   "order.materialcategory":"Malzeme Kategori",
   "order.materialgroup":"Malzeme Grup",
   "order.materialqty":"Birim Miktar",
   "order.deliverydate":"Teslim Tarihi",
   "order.deliverer":"Teslim Eden",
   "order.receiver":"Teslim Alan",
   "order.deliveryqty":"Miktar",
   "order.offeredit":"Teklif Düzenle",
   "order.stokupdate" :"Stok Güncellendi",
   "order.adddeliveryRow":"Teslimat Ekle",
   "order.deliveryedit":"Teslimat Düzenle",
   "order.printdeliveryRow":"Teslimat Yazdır",
   "order.operationInfo":"Operasyon Bilgileri",
   "order.itemdetail":"Ürün Bilgileri",
   "order.operationedit":"Operasyon Düzenle",
   "order.newStockProduction":"Stok Üretimi",
   "order.productiondetails":"Üretim Düzenle",
   "order.semiproducts":"Yarı Mamül Üretimi",
   "order.description":"Açıklama",
   "order.transfertoStock":"Stoğa Aktar",



   "menu.buyingmanagment":"Satınalma Yönetimi",
   "menu.buying":"Satınalmalar",





   "delivery.receiver":"Teslim Alan",
   "delivery.deliverer":"Teslim Edilen",
   "delivery.date":"Tarih",
   "delivery.qty":"Miktar",
   "delivery.status":"Durum",
   "delivery.total":"Toplam",
   "delivery.price":"Birim Fiyat",
   "delivery.status0":"Açık",
   "delivery.status1":"Fatura Edildi",
   "delivery.status2":"Kapandı",
   "delivery.product":"Ürün",
   "delivery.production":"Üretim",
   "delivery.material":"Malzeme",
   "delivery.suply":"Tedarik",
   "delivery.default":"Belirsiz",
   "delivery.takeoff_stock":"Stok Ürün",
   "delivery.statusdescrription":"Durum Açıklama",
   "delivery.modifieduser":"Düzenleyen",
   "delivery.modifieddate":"Düzenleme Tarihi",

   "buying.createreguest":"Talep Oluşturma",
   "buying.notes":"Satın Alma Notu",
   "buying.materialid":"Malzeme ID",
   "buying.materialcategory":"Malzeme Kategori",
   "buying.materialgroup":"Malzeme Grup",
   "buying.material":"Malzeme",
   "buying.qty":"Talep Miktarı",
   "buying.unit":"Malzeme  Birimi",
   "buying.buyingStatus0" :"Talep",
   "buying.buyingStatus1" :"Sipariş",
   "buying.buyingStatus2" :"Kontrol",
   "buying.buyingStatus3" :"Tamamlandı",
   "buying.buyingStatus4" :"İptal",
   "buying.totalprice":"Toplam Tutar",
   "buying.eposta" :"E-posta",
   "buying.price" :"B.Fiyat",
   "buying.edit":"Satınalma Düzenle",




   "logs.todayloglist":"Bugüne ait İşlemler",
   "logs.person":"Personel",
   "logs.title":"Konu",
   "logs.description":"Açıklama",
   "logs.date":"Tarih",


    /*UYARILAR  */
    "uyari.basarili":"Başarılı",
    "uyari.hata":"Hata",

    "uyari.sifrelerikontrolediniz":"Sifreleri Kontrol Ediniz",
    "uyari.sifredegistirilmedi":"Şifre Değişttirilemedi",
    "bilgi.firmaguncellendi":"Firma güncellendi.",
    "bilgi.kullaniciguncellendi":"Kullanıcı güncellendi.",
    "bilgi.kullaniciolusturuldu":"Kullanıcı oluşturuldu.",
    "bilgi.firmaolusturuldu":"Firma oluşturuldu.",
    "bilgi.sifredegistirildi":"Şifre değiştirildi",
    "bilgi.modelguncellendi":"Model güncellendi.",
    "bilgi.modelolusturuldu":"Model oluşturuldu.",
    "bilgi.urunguncellendi":"Ürün Güncellendi.",
    "bilgi.urunolusturuldu":"Ürün oluşturuldu.",
    "bilgi.malzemeguncellendi":"Malzeme güncellendi.",
    "bilgi.malzemeolusturuldu":"Malzeme oluşturuldu.",
    "bilgi.stokhareketiguncellendi":"Stok Hareketi  Güncellendi.",
    "bilgi.stokhareketiolusturuldu":"Stok Hareketi oluşturuldu.",

    "bilgi.teklifolusturuldu":"Teklif oluşturuldu.",
    "bilgi.teklifolusturulamadı":"Teklif oluşturulamadı.",
    "bilgi.teklifguncellendi":"Teklif güncellendi.",
    "bilgi.satirguncellendi":"Satır Güncellendi",
    "bilgi.siparisguncellendi":"Sipariş Güncellendi",
    "bilgi.companyguncellendi":"Firma Bilgileri Güncellendi",
    "bilgi.companyolusturuldu":"Firma oluşturuldu",
    "bilgi.sistemgirisibasarili":"Sistem  Girişi Başarılı",
    "bilgi.resimolusturuldu":"Resim oluşturuldu",
    "bilgi.resimguncellendi":"Resim Güncellendi",
    "bilgi.teslimatguncellendi":"Teslimat  Güncelleme Başarılı",
    "bilgi.talepolusturuldu":"Yeni Talep Oluşturuldu",
    "bilgi.satinnalmaguncellendi":"Satınalma Güncellendi",


    "akaysis.macaddress":"Mac Adresi",
    "akaysis.devicename":"Cihaz Adı",
    "akaysis.devicedescription":"Cihaz Açıklama",
    "akaysis.devicecity":"Şehir",


   "akaysis.sunriseTime":"Gün Batımı",
   "akaysis.sunsetTime":"Gün Doğumu",
   "akaysis.websocketPort":"Web Soket Port",
   "akaysis.root_mesh_ip":"Root Mesh IP",
   "akaysis.eMeterReadTimestamp":"Son Sayaç Okuma Zamanı",
   "akaysis.status":"Durum",
   "akaysis.deviceinfo":"Cihaz Bilgileri",
   "akaysis.energymeter":"Enerji Metre",
   "akaysis.programs":"Program",
   "akaysis.location":"Konumlandırma",

   "akaysis.time":"Saat",
   "akaysis.value":"Değer",
   "akaysis.weekday1":"Pazartesi",
   "akaysis.weekday2":"Salı",
   "akaysis.weekday3":"Çarşamba",
   "akaysis.weekday4":"Perşembe",
   "akaysis.weekday5":"Cuma",
   "akaysis.weekday6":"Cumartesi",
   "akaysis.weekday7":"Pazar",
   "akaysis.edit":"Düzenle",
   "akaysis.remove":"Sil",
   "akaysis.completed":"Tamamlandı",
   "akaysis.failed":"Geçersiz",

   "akaysis.date":"Tarih",
   "akaysis.command":"Komut",
   "akaysis.user":"Kullanıcı",

   "akaysis.history":"Komut  Geçmişi",
   "sys.timed-action":"Zamanlanmış Görev",
   "sys.register-action":"Otomatik Güncelleme",



  };
  