import React, { Component } from 'react'
import { connect } from 'react-redux';


import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";

import 'react-circular-progressbar/dist/styles.css';
import {updateOrderItem,getMaterialsList,getOrderItemDetails,getProductDetails,setLabelData,changeLocale,getCompany} from '../../actions';

class ProductionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [6, 12, 18, 30, 50, 100],
            selectedOrderOption: { column: "Operation", label: <IntlMessages id="order.orderbyOperation" /> },
            sortColumn:"Operation",
            operationFilter:this.props.operationFilter || "Tümü",
            filter0:1,
            filter1:4,  
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            orderItemList:this.props.orderItemList.data ? this.props.orderItemList.data :[],
            materials:this.props.materials.data,
            search: "",
            detailModalOpen:false,
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "Operation", label:"order.orderbyOperation"},
                { column: "siparis_tarihi", label:"order.orderbyDate"},
                { column: "urun_adi", label:"order.orderbyName" }
              ],
            OrderStatus: [
                {value : 1 , label:"order.orderStatus1" },
                {value : 2 , label:"order.orderStatus2" },
                {value : 3 , label:"order.orderStatus3" },
                {value : 4 , label:"order.orderStatus4" }
              ],
            selectLabel: [
                {value : "Ürün" , label : "Ürün Etiketi"},
                {value : "Kutu Etiketi" , label : "Kutu Etiketi"},
                {value : "Pil Etiketi" , label : "Pil Etiketi"},
                {value : "Güneş Paneli Etiketi" , label : "Güneş Paneli Etiketi"},
            ]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.company && !this.props.company.data && this.props.getCompany({id:1})
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){

    }

//***********************************************************************************************************************************************************/
    onChangeSelectLabel=(field,evt)=>{

        const Product_id = null
        this.setState(prevState => {
            let Label = {};  
            Label[field] = evt;  
            Label.logoOpen = true;         
            Label.Lang = 0;                 
            return { Label };    
        });
        if(evt==="Kutu Etiketi" ){


                const  Certificates = [];

                this.setState(prevState => {
                    let Label = Object.assign({}, prevState.Label);  
                    Label.ProductID=Product_id;
                    Label.Lang=this.props.locale==="tr"?0:1;
                    Label.model= "";  
                    Label.productName = "";  
                    Label.productCode = "";    
                    Label.Power =  " W";                      
                    Label.Voltage = "180-265 V AC 50Hz";
                    Label.Dimensions="";
                    Label.Weight=" Kg";
                    Label.Unit="Adet";
                    Label.BoxQty=1;
                    Label.Certificates = Certificates

                    return { Label,CerList:Certificates };    
                });
     
        }else if(evt==="Güneş Paneli Etiketi" &&  Product_id){

                
                this.setState(prevState => {
                    let Label = Object.assign({}, prevState.Label);  
                    Label.ProductID=Product_id;
                    Label.Lang=this.props.locale==="tr"?0:1;
                    Label.model= "5BB";  
                    Label.productName = "";  
                    Label.productCode =  "";    
                    Label.Power =  " Wp";                      

                    Label.Dimensions="";
                    Label.Weight=" Kg";
                    Label.Specs="";
                    Label.Certificates = "";
                    Label.warning = "Warning Electric HAZARD This Solar Module produces electrical voltage when exposed to sunlight or intense artifical lights. Proper precautions associated with electrical power systems must be taken while handling and installing this product.";

                    return { Label };    
                });
     
        }
    }
//***********************************************************************************************************************************************************/
    onChangeLabelData=(field,evt)=>{
        if(field==="Lang"){
            const locale = evt==="0" ?"tr":"en";
            this.setState(prevState => {
                let Label = Object.assign({}, prevState.Label);  
                Label[field] = evt;    
                Label.model= this.props.productDetail.data.urun_model.model_adi[evt];   
                Label.productName = this.props.productDetail.data.urun_adi[evt];  
                return { Label };    
            },()=>this.props.changeLocale(locale));
        }else{
            this.setState(prevState => {
                let Label = Object.assign({}, prevState.Label);  
                Label[field] = evt;                          
                return { Label };    
            });
        }
    }
//***********************************************************************************************************************************************************/
    onChangeLabelCertificete=(index,val)=>{
        const x = this.state.Label.Certificates;
        if(val===false){
            x.splice(index, 1);
        }else{
            x.push(this.state.CerList[index]);
        }
        this.setState(prevState => {
            let Label = Object.assign({}, prevState.Label);  
            Label.Certificates = x;           
            return { Label };    
        });
    }
//***********************************************************************************************************************************************************/
    onCreatePDF=()=>{
        const Label = this.state.Label;
        this.props.setLabelData(Label)
        Label.type === "Kutu Etiketi" &&  window.open('../pdf/label10x10/',"_blank");
        Label.type === "Güneş Paneli Etiketi" &&  window.open('../pdf/label8x10/',"_blank");
    
    }
//******************************************************************************************************************************************************************/
    onBoxOpen=(rowIndex)=>{
        this.setState(prevState => {
            let boxOpen = Object.assign({}, prevState.boxOpen);  // creating copy of state variable jasper
            boxOpen[rowIndex] = !this.state.boxOpen[rowIndex]
            return { boxOpen };    
        });
    }
//******************************************************************************************************************************************************************/
    onOpenModal=(opIndex)=>{
        this.setState({
            opIndex:opIndex,
        }); 
    }
//***********************************************************************************************************************************************************/
    onChangeOperationData = (field,evt) => {
        const  opIndex = this.state.opIndex;
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            return { ...operation,
                [field]:evt
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.Operations = newItemArray;
            return { orderItem };    
        });
    }
//***********************************************************************************************************************************************************/
gotoProductionList=()=>{
        this.props.history.push({pathname : `/orderitemlist`})
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {selectLabel,Label,CerList,UnitList} = this.state


        return (
            <>
            <div  className={this.props.orderItemDetail.loading ? "dimmer active" : ""}>
                {this.props.orderItemDetail.loading && <div className="loader"/>}
                <div className={this.props.orderItemDetail.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row clearfix">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.productLabelType"/></label>
                                            <select 
                                                className="custom-select w-auto" 
                                                onChange={(evt)=>this.onChangeSelectLabel("type",evt.target.value)}
                                                value={Label && Label.type  ? Label.type  :""}
                                            >
                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                {selectLabel.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.value}>{x.label} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <div className="form-label"><IntlMessages id="product.logoOpen"/></div>
                                            <label className="custom-switch">
                                                <input type="checkbox" name="custom-switch-checkbox" checked={Label  && Label.logoOpen ? Label.logoOpen :false} className="custom-switch-input" onChange={()=>this.onChangeLabelData("logoOpen",!Label.logoOpen)}/>
                                                <span className="custom-switch-indicator" />
                                                <span className="custom-switch-description">Açık / Kapalı</span>
                                            </label>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <div className="form-label"><IntlMessages id="product.qrOpen"/></div>
                                            <label className="custom-switch">
                                                <input type="checkbox" name="custom-switch-checkbox" checked={Label  && Label.qrOpen ? Label.qrOpen :false} className="custom-switch-input" onChange={()=>this.onChangeLabelData("qrOpen",!Label.qrOpen)}/>
                                                <span className="custom-switch-indicator" />
                                                <span className="custom-switch-description">Açık / Kapalı</span>
                                            </label>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.labelLanguage"/></label>
                                            <select name="beast" id="select-beast" className="form-control custom-select" onChange={(evt)=>this.onChangeLabelData("Lang",evt.target.value)}>
                                                <option value={0}>Türkçe</option>
                                                <option value={1}>İngilizce</option>
                                            </select>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
										{this.props.company.data &&  Label && Label.logoOpen  && 
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="mycompany.logo"/></label>
                                            <img className="rounded" src={this.props.company.data && this.props.company.data.bwlogo  ? this.props.company.data.bwlogo : "../assets/images/ry.png"} alt="Moduled"  />
										</div>}
									</div>
                                </div>
                                {Label &&  Label.type === "Kutu Etiketi" ?
                                    <div  className={Label  ? "" : "dimmer active"}>
                                    {!Label && <div className="loader"/>}
                                        <div className={Label? ""  : "dimmer-content"}>
                                            <div className="row clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.model"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.model && Label.model} onChange={(evt)=>this.onChangeLabelData("model",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.name"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.productName && Label.productName} onChange={(evt)=>this.onChangeLabelData("productName",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.code"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.productCode && Label.productCode} onChange={(evt)=>this.onChangeLabelData("productCode",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.power"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Power && Label.Power} onChange={(evt)=>this.onChangeLabelData("Power",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.voltage"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Voltage && Label.Voltage} onChange={(evt)=>this.onChangeLabelData("Voltage",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Dimensions && Label.Dimensions} onChange={(evt)=>this.onChangeLabelData("Dimensions",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Weight && Label.Weight} onChange={(evt)=>this.onChangeLabelData("Weight",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.boxQty"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.BoxQty && Label.BoxQty} onChange={(evt)=>this.onChangeLabelData("BoxQty",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="genel.unit"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeLabelData("Unit",evt.target.value)}
                                                            defaultValue={Label && Label.Unit && Label.Unit}
                                                        >
                                                            {UnitList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row gutters-sm">
                                                    {CerList  && Label.Certificates  &&
                                                    CerList.map((cer,index)=>{
                                                        let val = cer.certificate  === Label.Certificates[index].certificate;
                                                        return(
                                                            <div className="col-3 col-sm-2  col-md-2" key={index}>
                                                                <label className="imagecheck mb-3">
                                                                    <input name="imagecheck" type="checkbox" checked={val} className="imagecheck-input" onChange={()=>this.onChangeLabelCertificete(index,!val)} />
                                                                    <figure className="imagecheck-figure">
                                                                        <img src={cer.file} alt={cer.certificate} className="imagecheck-image" />
                                                                    </figure>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :null
                                }
                                {Label &&  Label.type === "Güneş Paneli Etiketi" &&
                                <div  className={Label ? "" : "dimmer active"}>
                                {Label && !Label.model && <div className="loader"/>}
                                    <div className={Label && Label.model ? ""  : "dimmer-content"}>
                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.model"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.model && Label.model} onChange={(evt)=>this.onChangeLabelData("model",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.name"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productName && Label.productName} onChange={(evt)=>this.onChangeLabelData("productName",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.code"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productCode && Label.productCode} onChange={(evt)=>this.onChangeLabelData("productCode",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.power"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Power && Label.Power} onChange={(evt)=>this.onChangeLabelData("Power",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Dimensions && Label.Dimensions} onChange={(evt)=>this.onChangeLabelData("Dimensions",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Weight && Label.Weight} onChange={(evt)=>this.onChangeLabelData("Weight",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="row clearfix" >
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.label"/></label></div>
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.feature"/></label></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                {Label &&  Label.Specs  ? Label.Specs.map((spec,sindex)=>{
                                                    return(
                                                        <div className="row clearfix"  key={sindex}>
                                                            <div className="col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control" value={spec.urun_ozellik} onChange={(evt)=>this.onChangeLabelSpecData("urun_ozellik",evt.target.value)}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control" value={spec.urun_aciklama} onChange={(evt)=>this.onChangeLabelSpecData("urun_aciklama",evt.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }):null}
                                            </div>
                                            <div className="row gutters-sm">
                                                {CerList  && Label.Certificates  &&
                                                CerList.map((cer,index)=>{
                                                    let val = cer.certificate  === Label.Certificates[index].certificate;
                                                    return(
                                                        <div className="col-3 col-sm-2  col-md-2" key={index}>
                                                            <label className="imagecheck mb-3">
                                                                <input name="imagecheck" type="checkbox" checked={val} className="imagecheck-input" onChange={()=>this.onChangeLabelCertificete(index,!val)} />
                                                                <figure className="imagecheck-figure">
                                                                    <img src={cer.file} alt={cer.certificate} className="imagecheck-image" />
                                                                </figure>
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <textarea type="text" className="form-control" value={Label.warning} onChange={(evt)=>this.onChangeLabelData("warning",evt.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
							</div>
							<div className="card-footer">
                                <a href="#1" className="btn btn-outline-info" onClick={()=>this.onChangeLabelData("type","")} ><IntlMessages id="button.close"/></a>
                                {Label && Label.type && <a href="#1" className="btn btn-outline-success ml-2" onClick={()=>this.onCreatePDF()}><IntlMessages id="button.createpdf"/></a>}
							</div>
                        </div>
                    </div>
                </div>





            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    orderItemList:state.orderItemList,
    productDetail:state.productDetail,
    locale:state.settings.locale,
    auth:state.auth,
    orderItemDetail:state.orderItemDetail,
    materials:state.materials,
    company:state.myCompany,
})

const mapDispatchToProps = ({updateOrderItem,getMaterialsList,getOrderItemDetails,getProductDetails,setLabelData,changeLocale,getCompany})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductionDetail)));
