import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';


const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 40,
        fontSize: 10,      
    },
    column1: {
        flexDirection: 'row',
        alignItems: 'left',
        height: 40,
        fontSize: 10,     
        width:200 
    },
    column2: {
        flexDirection: 'column',
        alignItems: 'right',
        borderLeft:0.3,
        height: 40,
        fontSize: 10,      
        width:200
    },
    column3: {
        flexDirection: 'column',
        alignItems: 'center',
        borderLeft:0.3,
        height: 40,
        fontSize: 10,      
        width:150
    },

    Description: {
        flexDirection: 'column',
        alignSelf:'center',
        width: 140,
        left:3
    },
    description: {
        textAlign: 'center',
        paddingRight: 12,
        borderBottom:0.3,
        width:150,
        height:16

    },
    User: {
        textAlign: 'center',
        paddingRight: 12,
        width:150,
        height:20
    }
  });

  const Onay = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: right;
  `;
  const User = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: right;
  `;
const  workOrderFooter= ({user}) => {


    return(    
        <View style={styles.row}>

            <View style={styles.column1}>
                <View style={styles.QRImages}>
                   
                </View>
                <View style={styles.Description}>
               
                </View>
            </View>
            <View style={styles.column2}>

            </View>
            <View style={styles.column3}>
                <Onay style={styles.description}>OLUŞTURAN</Onay>
                {user &&  <User style={styles.User}>{user}</User>}
            </View>


        </View>
    )
};
  
  export default workOrderFooter