import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import QRCode from 'qrcode';

const BackGround = '#FFFFFF'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
    
    },
    rowQRContainer: {
        flexDirection: 'column',
        alignItems: 'center',
 
    },
    QRImages: {
        width: 50,

        alignItems:'center',
        backgroundColor: BackGround,
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 75,

        textAlign:'center',
        backgroundColor: BackGround,
    }
  });


  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  width:45px;
  `;
  const ProductName = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  `;


const orderRowDetail = ({operation,id}) => {
    let img=""
    let value = window.location.origin+"/orderoperations/"+id+"/"+operation._id
    QRCode.toDataURL(value, function(err, url) {
      img=  url;
    });
    return(

        <View style={styles.rowContainer}>
            <View style={styles.rowQRContainer}>

                {img ? 
                <Image style={styles.QRImages} src={img}/>
                : <NoImage>  </NoImage>}
                
                <View style={styles.ProductNameContainer}>
                    <ProductName style={styles.ProductName}>{operation.Operation}</ProductName>

                </View >
            </View>
        </View>

  
)};
  
export default orderRowDetail