import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import IntlMessages from "../../helpers/IntlMessages";
import { store } from 'react-notifications-component';
import {getProductList,getModelList,setProductsPerVal,setProductsFilter,setPriceListText,setPriceListType,setPriceListTextOpen,setCalculateData,createProduct} from '../../actions';


class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            sortColumn:"model_category",
            filter:this.props.productsFilter || 0,
            filterField:"",  
            currentPage: 1,
            search: "",
            isLoading:false,
            calculateRate:this.props.calculateRate|| false,
            type:this.props.calculateRate ? "detaillist":"list",
            listeOrani:this.props.listPerVal || 30,
            pricelisttext:this.props.pricelisttext,
            modelList: this.props.modelList.data,
            productList:this.props.productList.data,
            totalItemCount:this.props.productList.data.length,
            totalPage: Math.ceil(this.props.productList.data.length/10),
            orderOptions: [
                { column: "model_category", label:"product.orderbyCategory"},
                { column: "urun_guc", label:"product.orderbyPower"},
                { column: "urun_fiyat", label:"product.orderbyPrice"}
            ],
            categoryList : [
                {value : 0 , label : "model.kategori"+0},
                {value : 1 , label : "model.kategori"+1},
                {value : 2 , label : "model.kategori"+2},
                {value : 3 , label : "model.kategori"+3},
                {value : 4 , label : "model.kategori"+4},
                {value : 5 , label : "model.kategori"+5},
                {value : 6 , label : "model.kategori"+6},
                {value : 7 , label : "model.kategori"+7},
                {value : 8 , label : "model.kategori"+8},
            ]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getProductList(this.state.type,this.state.sortColumn)  
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.productList.data !== nextProps.productList.data) {
            const {filter} = this.state;
            let productLists = [];
            if(filter==="Tümü"){
                productLists =  nextProps.productList.data
            }else{
                productLists =  nextProps.productList.data.filter(x=>x.model_category ===filter)
            }
            this.setState({
                productList: productLists,
                totalItemCount:productLists.length,
                totalPage: Math.ceil(productLists.length/this.state.selectedPageSize)
            },()=>this.onChangeFilter("",this.props.productsFilter))
        };  
        if (this.props.modelList.data !== nextProps.modelList.data) {
            this.setState({
                modelList: nextProps.modelList.data,
            })
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
                sortColumn:column
            },()=>this.props.getProductList(this.state.type,this.state.sortColumn));
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangePerVal = value => {
        this.setState({
            listeOrani: value,
        },()=>this.props.setProductsPerVal(value));
    };
//******************************************************************************************************************************************************************/
    setPriceListText=(e)=>{
        this.setState({
            pricelisttext: e,
        },()=>this.props.setPriceListText(e));
    };
    
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        const  productList = this.props.productList.data;
        let productCount   = "";
        let _filter = "" ;
        f === "Tümü" ? _filter  = "" : _filter = Number(f) ;
        if (f!=="Tümü"){
            productCount = (productList.filter(x=>x.model_category ===_filter)).length;
            this.setState(
                {
                    productList:productList.filter(x=>x.model_category ===_filter),
                    currentPage:1,
                    totalItemCount:productCount,
                    totalPage: Math.ceil(productCount/this.state.selectedPageSize) || 1
                }
            );
        } else {
            productCount = productList.length;
            this.setState(
                {
                    productList:productList,
                    currentPage:1,
                    totalItemCount:productCount,
                    totalPage: Math.ceil(productList.length/this.state.selectedPageSize)
                }
            );
        }
        this.props.setProductsFilter(f);
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.productList.data.filter(item => {return item.urun_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.urun_model.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
            productList:this.props.productList.data,
            totalItemCount:this.props.productList.data &&  this.props.productList.data.length ? this.props.productList.data.length:0,
            totalPage: Math.ceil((this.props.productList.data &&  this.props.productList.data.length  ? this.props.productList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
            productList:filteredList,
            totalItemCount:this.state.productList &&  this.state.productList.length ? this.state.productList.length:0,
            totalPage: Math.ceil((this.state.productList &&  this.state.productList.length  ? this.state.productList.length : 0) /this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    newProduct=(id)=>{
        this.props.history.push({pathname : `/product/new`})
    }
//******************************************************************************************************************************************************************/
    gotoDetail=(id)=>{
        this.props.history.push({pathname : `/product/`+id})
    }
//******************************************************************************************************************************************************************/
    openPriceList=()=>{
        window.open('../pdf/pricelist',"_blank"); 
    }
//******************************************************************************************************************************************************************/
    onChangeCalculate=(e)=>{
        this.setState({
            calculateRate: e,
            type: e ? "detaillist":"list"
        },()=>this.props.setCalculateData(e));
        const  x =e ? "detaillist":"list"
        this.props.getProductList(x,this.state.sortColumn);
    
    };
//******************************************************************************************************************************************************************/
    CopyProduct=(row)=>{
        console.log(row)
        const { messages } = this.props.intl;
        this.props.createProduct(row,null)
        .then(res=>{
            this.props.getProductList("list",this.state.sortColumn)
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.urunolusturuldu"],//{messages[x.label]} 
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });    
        })
    }
//******************************************************************************************************************************************************************/
    render() {
        
        const { messages } = this.props.intl;
        const {productList,selectedPageSize,currentPage,totalItemCount,categoryList,listeOrani,calculateRate,filter} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
        //const {locale} = this.props;
        //const Lang = locale==="tr" ? 0:1 
       // console.log(this.state)
        return (
            <>
                <div  className={this.props.productList.loading   ? "dimmer active" : ""}>
                    {this.props.productList.loading &&  <div className="loader"/>}
                    <div className={this.props.productList.loading  ? "dimmer-content"  : ""}>
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-md-flex justify-content-between  align-items-center ">
                                                <div className="form-group">
                                                    <div className="selectgroup w-100">
                                                        <select 
                                                            className="custom-select" 
                                                            onChange={(evt)=>this.onChangeFilter("model_category",evt.target.value)}
                                                            value={this.props.productsFilter}
                                                        >
                                                                    <option value={"Tümü"} >{messages["genel.choose"]}</option>
                                                            {categoryList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                                )
                                                            })}
                                                        </select>
                                                        <input type="range" className="form-control custom-range mb-1 ml-3" value={listeOrani} step={5} min={0} max={50} onChange={(evt)=>this.onChangePerVal(evt.target.value)}/>
                                                        <p className="font-14 ml-2 mb-0">%{listeOrani}</p>
                                                    </div>
                                                    
                                                </div>
                                                <div className="d-flex align-items-center sort_stat">
                                                    <div className="d-flex ml-3">
                                                        <div className="ml-2">
                                                            <p className="mb-0 font-11">{/* messages["model.kategori"+filter].toUpperCase() */}</p>
                                                            <h5 className="font-16 mb-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group">
                                                <input type="text" className="form-control search" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)} />
                                                <div className="input-group-append">
                                                    <button type="button" className="btn btn-primary"><IntlMessages id="button.action"/></button>
                                                    <button data-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle" />
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href="#2" onClick={()=>this.newProduct()}	><IntlMessages id="button.add"/></a>
                                                        <a className="dropdown-item" href="#2" 	data-toggle="modal"  data-target="#settingModal"><IntlMessages id="button.settings"/></a>
                                                        <a className="dropdown-item" href="#1" onClick={()=>this.openPriceList()}><IntlMessages id="product.pricelist"/></a>
                                                        <a className="dropdown-item" href="#3" onClick={()=>this.onChangeCalculate(!calculateRate)}>{calculateRate ? "Hesaplamayı Kapat" : " Hesaplamayı Aç"  }</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                <thead>
                                    <tr>
                                        <th className="w80">#</th>
                                        <th ><IntlMessages id="product.name"/></th>
                                        <th ><IntlMessages id="product.spec"/></th>
                                        <th className="text-center w150"><IntlMessages id="product.category"/></th>
                                        <th ><IntlMessages id="product.price"/></th>
                                        <th ><IntlMessages id="product.stock"/></th>
                                        {calculateRate  &&<th></th>}
                                        <th className="w20"></th>
                                    </tr>
                                </thead>
                                {productList ?
                                    <tbody>
                                        {
                                            productList.slice(startIndex, endIndex).map((productRow,i)=>{
                                            let  productRate = 0;
            
                                            productRate = calculateRate &&  productRow.urun_ozellikler && productRow.urun_ozellikler.length>=3 ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.urun_adi && productRow.urun_adi[1] !== "-"? productRate+10 : productRate;
                                           // productRate = calculateRate &&  productRow.urun_model && productRow.urun_model.model_adi[1] !== "-"? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.production && productRow.production.length>=3 ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.production && (productRow.production.filter(x=> x.op_qty>=1).length)>=3 ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.technicalDrawing &&  productRow.technicalDrawing!=="" ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.product_weight && productRow.product_weight !== 0 ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.product_dimensions &&  productRow.product_dimensions!== "XX-XX-XX mm" ? productRate+10 : productRate;

                                            productRate = calculateRate &&  productRow.model_category  !== 7 &&  productRow.urun_lumen!== "" ? productRate+10 : productRate;
                                            productRate = calculateRate &&  productRow.model_category  === 7 &&  productRow.urun_guc!== "" ? productRate+10 : productRate;

                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <a href={`#1`} onClick={()=>this.gotoDetail(productRow._id)}>
                                                            <img
                                                                src={productRow.productImages ? productRow.productImages :"../assets/images/ryModuled.jpg" }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                alt="Moduled"
                                                                className="rounded-circle img-thumbnail w80"
                                                                data-original-title="Moduled"
                                                            />
                                                        </a>

                                                    </td>
                                                    <td>
                                                        <a href={`#1`} onClick={()=>this.gotoDetail(productRow._id)}><h6>{productRow.urun_adi[0]}</h6></a>
                                                        <a href={`#2`} onClick={()=>this.gotoDetail(productRow._id)}><span>{productRow.urun_adi[1]}</span></a>
                                                    </td>
                                                    <td>{productRow.model_category<7 ?
                                                        <div>
                                                            <h6>{productRow.urun_guc+" W"}</h6>
                                                            <span>{productRow.urun_lumen+" lm"}</span>
                                                        </div>
                                                        : productRow.model_category===7 ?
                                                        <div>
                                                            <h6>{productRow.urun_guc+" W"}</h6>
                                                        </div>
                                                        :
                                                        <div>
                                                            <h6>{productRow.urun_kapasite+" Ah"}</h6>
                                                            <span>{productRow.urun_voltaj+" VDC"}</span>
                                                        </div>}
                                                    </td>
                                                    <td className="text-center">
                                                        <span className={productRow.model_category===0 ?"tag tag-success": productRow.model_category===1 ?"tag tag-info":  productRow.model_category===2 ?"tag tag-warning": productRow.model_category===3 ?"tag tag-black": productRow.model_category===4 ?"tag tag-danger": "tag tag-danger"}>{messages["model.kategori"+productRow.model_category]}</span>
                                                    </td>
                                                    <td className="text-center">{parseFloat(productRow.urun_fiyat+ ((productRow.urun_fiyat/(100-listeOrani))*listeOrani)).toFixed(2).replace(".", ",")+" $"}</td>
                                                    <td className="text-center">{productRow.stockSum ? parseFloat(productRow.stockSum).toFixed(2).replace(".", ","):0}</td>
                                                    {calculateRate&&<td>
                                                        <h6>{productRate+" %"}</h6>
                                                        <span>{}</span>
                                                    </td>}
                                                    <td>
                                                        <div className="item-action dropdown">
                                                            <a href="#0"  data-toggle="dropdown" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                <a href="#1"  className="dropdown-item" onClick={()=>this.gotoDetail(productRow._id)}><i className="dropdown-icon fa fa-edit" /><IntlMessages id="product.viewdetails"/></a>
                                                                <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-file-pdf-o" /><IntlMessages id="product.datasheet"/></a>
                                                                <div className="dropdown-divider" />
                                                                <a href="#2" className="dropdown-item" onClick={()=>this.CopyProduct(productRow)}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.copy"/></a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            )}
                                    </tbody>:null}
                            </table>
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                onChangePage={i => this.onChangePage(i)}
                            />  
                        </div>
                    </div>
                </div>
                <div
					className="modal fade"
					id="settingModal"
					tabIndex={-1}
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
                                    <IntlMessages id="product.productListSettings"/>
								</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">X</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row clearfix">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <div className="form-label"><IntlMessages id="product.productListTextOpen"/></div>
                                            <label className="custom-switch">
                                                <input type="checkbox" name="custom-switch-checkbox" checked={this.props.priceListTextOpen} className="custom-switch-input" onChange={()=>this.props.setPriceListTextOpen(!this.props.priceListTextOpen)}/>
                                                <span className="custom-switch-indicator" />
                                                <span className="custom-switch-description">Açık / Kapalı</span>
                                            </label>
                                        </div>
                                    </div>
									<div className="col-md-12 col-sm-12">
										<div className="form-group">
                                            <textarea 
                                                className="form-control" 
                                                rows={4} 
                                                value={this.state.pricelisttext} 
                                                onChange={(e)=>this.setPriceListText(e.target.value)}
                                            />
										</div>
									</div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.productListType"/></label>
                                            <select name="beast" id="select-beast" className="form-control custom-select" onChange={(evt)=>this.props.setPriceListType(evt.target.value)}>
                                                <option value={0}>T0</option>
                                                <option value={1}>T1</option>
                                                <option value={2}>T2</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.pricelistuser"/></label>
                                            <input type="text" className="form-control" value={this.props.auth.user} readOnly/>
                                        </div>
                                    </div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    <IntlMessages id="button.close"/>
								</button>
							</div>
						</div>
					</div>
				</div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    productsFilter:state.settings.productsFilter,
    listPerVal:state.settings.listPerVal,
    pricelisttext:state.settings.pricelisttext,
    priceListTextOpen:state.settings.priceListTextOpen,
    productList:state.productList,
    modelList:state.modelList,
    locale:state.settings.locale,
    auth:state.auth,
    calculateRate:state.settings.calculateRate
})

const mapDispatchToProps = ({getProductList,getModelList,setProductsPerVal,setProductsFilter,setPriceListText,setPriceListType,setPriceListTextOpen,setCalculateData,createProduct})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Products)));
