import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    BuyerContainer: {
        marginTop: 5,
        flexDirection: "column",
        width:300
    },
    AdressContainer:{
        marginTop: 10,
        flexDirection: "row",
        width:550
    }
  });
const BuyerCompanyName = styled.Text`
marginTop:1.5px;
padding:2px
font-size: 10px;
text-align: left;
backgroundColor: #E7E7E7;
height :20px;
`;
const BuyerAdres1 = styled.Text`
marginTop:1.5px;
padding:2px
font-size: 10px;
text-align: left;
backgroundColor: #E7E7E7;
height :20px;
`;
const BuyerLabel = styled.Text`
padding:2px
font-size: 10px;
text-align: left;
backgroundColor: #E7E7E7;
borderBottomWidth: 1;
borderColor: blue;
height :20px;
`;
const DeliveryAddress = styled.Text`
    margin: 0px;
    font-size: 10px;
    text-align: left;
`;

const BuyerInfo = ({data, Dil}) => {
    return(
    
        <View style={styles.BuyerContainer}>
            <BuyerLabel style={styles.billTo}>{Dil==="tr" ? " MÜŞTERİ :" : "BUYER : "}</BuyerLabel>
            <BuyerCompanyName>{data.firma_adi}</BuyerCompanyName>
            <BuyerAdres1>{(data.firma_adres && data.firma_adres) +" "+(data.firma_ilce && data.firma_ilce)+ " " + (data.firma_il && data.firma_il)+" "+(data.firma_ulke && data.firma_ulke)}</BuyerAdres1>
            <View style={styles.AdressContainer}>
                <DeliveryAddress>{ data.teklif_sevkadresi && (Dil==="tr" ?  "SEVK ADRESİ : " :"DELIVERY ADRRESS : ")} {data.teklif_sevkadresi}</DeliveryAddress>
            </View>


        </View>
    )
};
  
export default BuyerInfo