
//device_list_refresh_request,getTasksList
import {getOrderItemList,getMaterialsList,getOfferDetails,getStockOutList,getlogList ,device_refresh_details,getProductList} from '../actions';
//import moment from "moment";
export const wsMiddleware = store => next => action => {
    const today  = new Date();
	if(action.type === "REDUX_WEBSOCKET::MESSAGE"){
        console.log("Message Hadled");
        let msg = JSON.parse(action.payload.message);
       
        if(msg && msg.collection){
			if(msg.collection==="Logs"){
                if(msg.operation==="insert"){
                    store.dispatch(getlogList());
                }
            }
            else if(msg.collection==="offer"){
                if(msg.operation==="change"){
                    store.dispatch(getOfferDetails({id:msg.objectId}));
                }
            }
            else if(msg.collection==="gateway_commands"){
                if(msg.operation==="change"  && store.getState().deviceDetails.data && Object.keys(store.getState().deviceDetails.data).length > 0){
                     store.dispatch(device_refresh_details(store.getState().deviceDetails.data._id))
                    // .then(res=>{
                    //     // const  OrderID = store.getState().orderItemDetail.data.teklifno._id;
                    //     // store.dispatch(getOfferDetails({id:OrderID}));
                        
                    // });
                    store.dispatch(getOrderItemList("list","siparis_tarihi",1,4));
                }
            }else if(msg.collection==="Materials"){
                console.log("Materials List  Güncelleniyor..")
                store.dispatch(getMaterialsList("list"))
                store.dispatch(getStockOutList(today))

            }else if(msg.collection==="Tasks"){
                if(msg.operation==="insert"){
                    //store.dispatch(getTasksList())
                }else if(msg.operation==="update"){
                   // store.dispatch(getTasksList())
                }
            }else if(msg.collection==="Product"){
                if(msg.operation==="update"){
                    store.dispatch(getProductList("list","model_category"))
                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else if(msg.collection==="Model"){
                if(msg.operation==="update"){
                    //store.dispatch(getModelList("detail","model_kategori"))
                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else{
                return
            }

			
		}

    }
    return next(action)
}