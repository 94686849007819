import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'

import { connect } from "react-redux";
import { getCompany,getOrderItemList } from '../actions';
import WorkOrderList from "./productionList/productList";
import QRCode from 'qrcode';
class PDFViewerPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        selectedOrderOption:"urun_adi",
        sortColumn:"Operation",
        filter0:1,
        filter1:3,  
      };
    }
//******************************************************************************************************************************************************************/
componentDidMount() {
  this.props.dispatch(getOrderItemList("detaillist",this.state.sortColumn,this.state.filter0,this.state.filter1));
  this.props.dispatch(getCompany({id:1}))
};
//******************************************************************************************************************************************************************/
componentWillReceiveProps(nextProps){
  if (this.props.orderItemList.data !== nextProps.orderItemList.data) {

      let orderList =[];
      if(nextProps.orderItemList.data.length){
        for (let i of nextProps.orderItemList.data) {
  
                let row = {};
                row.Operations= i.Operations;
                row.productImage =  i.urun_id && i.urun_id.productImages &&   i.urun_id.productImages[0] && i.urun_id.productImages[0].image  && i.urun_id.productImages[0].image.smallimage;
                row.product=i.urun_adi;
                row.company=i.teklifno ? i.teklifno.firma_adi:" Stok Üretimi / Alt Montaj ";
                row.productCode=i.urun_kodu;
                row.Qty = i.urun_miktari;
                row.Unit = i.urun_birimi;
                row.orderItemID = i._id;
                row.orderStatus  = i.siparis_durumu;
                let value = window.location.origin+"/orderproductiondetail/"+i._id;
                QRCode.toDataURL(value, function(err, url) {
                  row.QRimage=  url;
                });
                orderList.push(row); 
           
           
        }
      }
    this.setState({
      orderList: orderList
    }); 
  }; 
};
//******************************************************************************************************************************************************************/


    render() {     
        const data = this.state.orderList;
        const company = this.props.company.data;
        return data && company ? (
            <Fragment>
              { data && data.length &&  company.sirket_adi ?                
              <PDFViewer  style={{height: '100vh',  width: '100vw',top: 0,left: 0}}>
                  <WorkOrderList data={data} company ={company}  />
              </PDFViewer>
              :
              <div className="loading" />}       
            </Fragment>
        ) : (
            <div className="loading" />
        );
    }
}
const mapStateToProps = state  => ({
  orderItemList:state.orderItemList,
  company: state.myCompany
});
export default connect (mapStateToProps)(PDFViewerPage);
