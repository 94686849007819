import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from "moment";
import { store } from 'react-notifications-component';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {updateOrderItem,getMaterialsList,getOrderItemDetails,getProductDetails,setLabelData,changeLocale,getCompany,getProductList} from '../../actions';

class ProductionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [6, 12, 18, 30, 50, 100],
            selectedOrderOption: { column: "Operation", label: <IntlMessages id="order.orderbyOperation" /> },
            sortColumn:"Operation",
            operationFilter:this.props.operationFilter || "Tümü",
            filter0:1,
            filter1:4,  
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            orderItemList:this.props.orderItemList.data ? this.props.orderItemList.data :[],
            materials:this.props.materials.data,
            search: "",
            detailModalOpen:false,
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "Operation", label:"order.orderbyOperation"},
                { column: "siparis_tarihi", label:"order.orderbyDate"},
                { column: "urun_adi", label:"order.orderbyName" }
              ],
            OrderStatus: [
                {value : 1 , label:"order.orderStatus1" },
                {value : 2 , label:"order.orderStatus2" },
                {value : 3 , label:"order.orderStatus3" },
                {value : 4 , label:"order.orderStatus4" }
              ],
            selectLabel: [
                {value : "Ürün" , label : "Ürün Etiketi"},
                {value : "Kutu Etiketi" , label : "Kutu Etiketi"},
                {value : "Pil Etiketi" , label : "Pil Etiketi"},
                {value : "Güneş Paneli Etiketi" , label : "Güneş Paneli Etiketi"},
            ]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getOrderItemDetails({id:this.props.match.params.id})
        this.props.getMaterialsList("list");
        this.props.getProductList("list","model_category");
        this.props.company && !this.props.company.data && this.props.getCompany({id:1})
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.orderItemDetail.data !== nextProps.orderItemDetail.data) {
            let boxOpen = [];
            nextProps.orderItemDetail.data && nextProps.orderItemDetail.data.Operations  &&
            nextProps.orderItemDetail.data.Operations.forEach(element => {
                boxOpen.push(false);
            });

            this.setState({
                orderItem: nextProps.orderItemDetail.data,
                boxOpen:boxOpen
            }); 
        }
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials: nextProps.materials.data,
            }); 
        };  
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList:nextProps.productList.data,
            }); 
        };   
    }
//***********************************************************************************************************************************************************/
    OperationStart = (opIndex) => {
        let sID = (this.state.orderItem.Operations[opIndex].OperationDetails.filter(x=> x.material ==="" && x.component===true)).length
        if(sID>=1){
            if (window.confirm(sID +" Adet Satır Malzemesi Belirsiz devam edemezsiniz")) {

            }
        }else{
            const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
            if (opIndex !== sindex) return operation;
                return { ...operation,
                    OperationStartTime: new Date(),
                    OperationStaff: this.props.auth.user,
                    OperationStatus: 1,
                    OperationFinishTime:"",
                    OperationPercent : 5
                };
            });
            this.setState(prevState => {
                let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
                orderItem.Operations = newItemArray;
                return { orderItem };    
            },()=>this.calculateRate(opIndex));
        }
    }
//***********************************************************************************************************************************************************/
    OperationFinish = (opIndex) => {
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            return { ...operation,
                OperationStaff: this.props.auth.user,
                OperationStatus: 2,
                OperationFinishTime:new Date(),
                OperationPercent : 100
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.Operations = newItemArray;
            return { orderItem };    
        },()=>this.calculateRate(opIndex));
    }
//***********************************************************************************************************************************************************/
    OperationReset = (opIndex) => {
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            return { ...operation,
                OperationStaff: "",
                OperationStatus: 0,
                OperationFinishTime:"",
                OperationStartTime: "",
                OperationPercent : 0,
                OperationComplated:0,
                OperationComplatedList:[],
                reset:true
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.Operations = newItemArray;
            return { orderItem };    
        },()=>this.calculateRate(opIndex));
    }
//***********************************************************************************************************************************************************/
    OperationComplated = (opIndex) => {
        let mateiallist = []; // alt malzemeleri sorgulamak için malzemeler listesi te
        let OperationType = "";
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            const productionlist = operation.OperationComplatedList ? operation.OperationComplatedList:  [];
            const  productionRow = {
                Staff: this.props.auth.user,
                Qty:operation.counter,
                Date:new Date(),
                stockupdate:false
            }
                mateiallist=operation.OperationDetails;
                OperationType=operation.Operation
                productionlist.push(productionRow);
                const qty =  operation.Qty ? operation.Qty  : 0;
                const comlatedqty = operation.OperationComplated ? operation.OperationComplated :0;
                const rate = ((Number(comlatedqty) + Number(operation.counter))/Number(qty))*100;
                const status = rate === 100 ? 2 : rate >0 ?1:0;
                return { ...operation,
                    OperationStaff: this.props.auth.user,
                    OperationStatus: status,
                    OperationFinishTime:Number(comlatedqty) + Number(operation.counter)  >= Number(qty) ? new Date():"",
                    OperationPercent : rate,
                    OperationComplated:Number(comlatedqty) + Number(operation.counter),
                    OperationComplatedList:productionlist,
                    save:true
                };
        });
       // console.log(mateiallist)
        let stock_err=[];
        mateiallist && mateiallist.length && mateiallist.forEach(row =>{
            
            if(row.totalQty>=row.status && OperationType==="Yarı Mamül" ) {// Malzeme Stoğuu yetersiz ise 
                //console.log(row)
                const err ={
                    Hata : "Malzeme Stoğu Yetersiz",
                    Malzeme:row.material,
                }
                stock_err.push(err)
            } // Malzeme Eksi ise 
        })
        let ok = 0;
        //console.log(stock_err)
        if (stock_err && stock_err.length){
            stock_err.forEach(row =>{
                if (window.confirm(row.Malzeme+" "+row.Hata +" devam etmek istiyor musunuz ?")) {
                    return ok=ok+1
                }else{
    
                }
            })

        }
        if(ok===stock_err.length){
            this.setState(prevState => {
                let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
                orderItem.Operations = newItemArray;
                return { orderItem };    
            },()=>this.calculateRate(opIndex));
        }
    }
//***********************************************************************************************************************************************************/
    onChangeComplatedVal = (opIndex,count) => {
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            return { ...operation,
                counter:count
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.Operations = newItemArray;
            return { orderItem };    
        });
    }
//******************************************************************************************************************************************************************/
    calculateRate=(opIndex)=>{
        const orderItem = this.state.orderItem;
        let bitisTarihi = orderItem.bitis_tarihi;
        let baslamaTarihi = orderItem.baslama_tarihi;
        let siparisDurumu = orderItem.siparis_durumu;
        let Bolen = orderItem.Operations.length;
        let SonucOran = (orderItem.Operations.reduce((acc, curr) => acc + parseInt(curr.OperationPercent, 10), 0))/Bolen;
        if(SonucOran===100) {
            bitisTarihi = new Date();
            siparisDurumu = 4;
        }else{
            bitisTarihi = "";

            if(SonucOran>60){
                siparisDurumu = 3
            }else{
                siparisDurumu = 2
            }
        }
        if(!baslamaTarihi) {
            baslamaTarihi = new Date();
        }
        this.setState((prevState) => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.bitis_tarihi= bitisTarihi;
            orderItem.tamamlanmaOrani= SonucOran;
            orderItem.baslama_tarihi= baslamaTarihi;
            orderItem.siparis_durumu=siparisDurumu;
            return {orderItem};   
        },()=>this.SaveOrderItem()); 
    };
//***********************************************************************************************************************************************************/
    SaveOrderItem = () => {
        const { messages } = this.props.intl;
        this.props.updateOrderItem({...this.state.orderItem})
        .then(res => {
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.satirguncellendi"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });        
        })
        .catch(err=>{
            if(err){
                store.addNotification({
                    title: this.props.orderItemDetail.status.statusText,
                    message: this.props.orderItemDetail.status.data,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
            }
        })
    }
//***********************************************************************************************************************************************************/
    onChangeSelectLabel=(field,evt)=>{
        const {orderItem}  = this.state;
        const Product_id = orderItem && orderItem.urun_id &&  orderItem.urun_id._id
        this.setState(prevState => {
            let Label = {};  
            Label[field] = evt;  
            Label.logoOpen = true;         
            Label.Lang = 0;                 
            return { Label };    
        });
        if(evt==="Kutu Etiketi" &&  Product_id){
            this.props.company && !this.props.company.data && this.props.getCompany({id:1});
            this.props.getProductDetails({id:Product_id})
            .then(res=>{
                        const  productDetails = this.props.productDetail.data;
                        const ModelKodu = orderItem && orderItem.urun_kodu[0] ? orderItem.urun_kodu[0] :""
                        const PCBKodu =  orderItem && orderItem.urun_kodu[1]? ("-"+orderItem.urun_kodu[1]):""
                        const Optik =  orderItem && orderItem.urun_kodu[2] ? ("-"+orderItem.urun_kodu[2]):""
                        const CCT  =  orderItem &&orderItem.urun_kodu[3]? ("-"+orderItem.urun_kodu[3]):""
                        const Solar  =  orderItem && orderItem.urun_kodu[4] ? ("-"+orderItem.urun_kodu[4]):""
                        const Battery  =  orderItem && orderItem.urun_kodu[5] ? ("-"+orderItem.urun_kodu[5]):""
                        const Power  =  productDetails && productDetails.urun_guc ? (productDetails.urun_guc):""
                        const Dimensions  =  productDetails && productDetails.product_dimensions ? (productDetails.product_dimensions):""
                        const Weight  =  productDetails && productDetails.product_weight ? (productDetails.product_weight):""

                        const  Certificates = [];
                        for (let certificate of productDetails.urun_model.certificates) { 
                            let cer = this.props.company.data.certificates.filter(x=>x.certificate === certificate.value)[0]
                            Certificates.push(cer)
                        }
                this.setState(prevState => {
                    let Label = Object.assign({}, prevState.Label);  
                    Label.ProductID=Product_id;
                    Label.Lang=this.props.locale==="tr"?0:1;
                    Label.model= this.props.productDetail.data.urun_model.model_adi[0];  
                    Label.productName = this.props.productDetail.data.urun_adi[0];  
                    Label.productCode =  ModelKodu+PCBKodu+Optik+CCT+Solar+Battery;    
                    Label.Power =  Power + " W";                      
                    Label.Voltage = "180-265 V AC 50Hz";
                    Label.Dimensions=Dimensions;
                    Label.Weight=Weight+" Kg";
                    Label.Unit="Adet";
                    Label.BoxQty=1;
                    Label.Certificates = Certificates

                    return { Label,CerList:Certificates };    
                });
            })
        }else if(evt==="Güneş Paneli Etiketi" &&  Product_id){
            this.props.getProductDetails({id:Product_id})
            .then(res=>{
                const productDetails = this.props.productDetail.data;
                const ModelKodu = orderItem && orderItem.urun_kodu[0] ? orderItem.urun_kodu[0] :""
                const UrunKodu =  orderItem && orderItem.urun_kodu[1]? ("-"+orderItem.urun_kodu[1]):""

                const Power  =  productDetails && productDetails.urun_guc ? (productDetails.urun_guc):""
                const Dimensions  =  productDetails && productDetails.product_dimensions ? (productDetails.product_dimensions):""
                const Weight  =  productDetails && productDetails.product_weight ? (productDetails.product_weight):""

                const  Certificates = [];
                for (let certificate of productDetails.urun_model.certificates) { 
                    let cer = this.props.company.data.certificates.filter(x=>x.certificate === certificate.value)[0]
                    Certificates.push(cer)
                }
                const  Specs = [];
                for (let spec of productDetails.urun_ozellikler) { 
                    let row ={
                        urun_ozellik :spec.urun_ozellik[1],

                        urun_aciklama :spec.product_description,
                    }
                    Specs.push(row)
                }
                
                this.setState(prevState => {
                    let Label = Object.assign({}, prevState.Label);  
                    Label.ProductID=Product_id;
                    Label.Lang=this.props.locale==="tr"?0:1;
                    Label.model= this.props.productDetail.data.urun_model.model_adi[1];  
                    Label.productName = this.props.productDetail.data.urun_adi[1];  
                    Label.productCode =  ModelKodu+UrunKodu;    
                    Label.Power =  Power + " Wp";                      

                    Label.Dimensions=Dimensions;
                    Label.Weight=Weight+" Kg";
                    Label.Specs=Specs;
                    Label.Certificates = Certificates;
                    Label.warning = "Warning Electric HAZARD This Solar Module produces electrical voltage when exposed to sunlight or intense artifical lights. Proper precautions associated with electrical power systems must be taken while handling and installing this product.";

                    return { Label,CerList:Certificates };    
                });
            })
        }else if(evt==="Pil Etiketi" &&  Product_id){
            this.props.getProductDetails({id:Product_id})
            .then(res=>{

                const productDetails = this.props.productDetail.data;

                console.log(productDetails)
                 const ModelKodu = productDetails && productDetails.urun_kodu ? productDetails.urun_kodu :""
                 const Capacity =  productDetails && productDetails.urun_kapasite? productDetails.urun_kapasite :""

                // const Power  =  productDetails && productDetails.urun_guc ? (productDetails.urun_guc):""
                const Dimensions  =  productDetails && productDetails.product_dimensions ? (productDetails.product_dimensions):""
                const Weight  =  productDetails && productDetails.product_weight ? (productDetails.product_weight):""

                // const  Certificates = [];
                // for (let certificate of productDetails.urun_model.certificates) { 
                //     let cer = this.props.company.data.certificates.filter(x=>x.certificate === certificate.value)[0]
                //     Certificates.push(cer)
                // }
                const  Specs = [];
                // for (let spec of productDetails.urun_ozellikler) { 
                //     let row ={
                //         urun_ozellik :spec.urun_ozellik[1],

                //         urun_aciklama :spec.product_description,
                //     }
                //     Specs.push(row)
                // }
                const Lang =  this.props.locale==="tr"?0:1;
                this.setState(prevState => {
                    let Label = Object.assign({}, prevState.Label);  
                    Label.ProductID=Product_id;
                    Label.Lang=Lang;
                    Label.model= this.props.productDetail.data.urun_model.model_adi[Lang];  
                    Label.productName = this.props.productDetail.data.urun_adi[Lang];  
                    Label.productCode =  ModelKodu;    
                    Label.Capacity =  Capacity + " Ah";                      

                    Label.Dimensions=Dimensions;
                    Label.Weight=Weight+" Kg";
                    Label.Specs=Specs;
                    Label.warning = "Warning ! Don't disassemble battery or repack the battery. Don't make the battery  short  circuit. Don't put the  battery into humid place or in wather. Don't put the battery near to high  temperature ennvironment.";

                    return { Label};    
                },()=>console.log(this.state.Label));
            })
        }
    }
//***********************************************************************************************************************************************************/
    onChangeLabelData=(field,evt)=>{
        if(field==="Lang"){
            const locale = evt==="0" ?"tr":"en";
            this.setState(prevState => {
                let Label = Object.assign({}, prevState.Label);  
                Label[field] = evt;    
                Label.model= this.props.productDetail.data.urun_model.model_adi[evt];   
                Label.productName = this.props.productDetail.data.urun_adi[evt];  
                return { Label };    
            },()=>this.props.changeLocale(locale));
        }else{
            this.setState(prevState => {
                let Label = Object.assign({}, prevState.Label);  
                Label[field] = evt;                          
                return { Label };    
            });
        }
    }
//***********************************************************************************************************************************************************/
    onChangeLabelCertificete=(index,val)=>{
        const x = this.state.Label.Certificates;
        if(val===false){
            x.splice(index, 1);
        }else{
            x.push(this.state.CerList[index]);
        }
        this.setState(prevState => {
            let Label = Object.assign({}, prevState.Label);  
            Label.Certificates = x;           
            return { Label };    
        });
    }
//***********************************************************************************************************************************************************/
    onCreatePDF=()=>{

        const Label = this.state.Label;
        this.props.setLabelData(Label)
        Label.type === "Kutu Etiketi" &&  window.open('../pdf/label10x10/',"_blank");
        Label.type === "Güneş Paneli Etiketi" &&  window.open('../pdf/label8x10/',"_blank");
        Label.type === "Pil Etiketi" &&  window.open('../pdf/label8x6/',"_blank");
    
    }
//******************************************************************************************************************************************************************/
    onBoxOpen=(rowIndex)=>{
        this.setState(prevState => {
            let boxOpen = Object.assign({}, prevState.boxOpen);  // creating copy of state variable jasper
            boxOpen[rowIndex] = !this.state.boxOpen[rowIndex]
            return { boxOpen };    
        });
    }
//******************************************************************************************************************************************************************/
    onOpenModal=(opIndex)=>{
        this.setState({
            opIndex:opIndex,
        }); 
    }
//***********************************************************************************************************************************************************/
    onChangeOperationData = (field,evt) => {
        const  opIndex = this.state.opIndex;
        const newItemArray = this.state.orderItem.Operations.map((operation, sindex) => {
        if (opIndex !== sindex) return operation;
            return { ...operation,
                [field]:evt
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.Operations = newItemArray;
            return { orderItem };    
        });
    }
//***********************************************************************************************************************************************************/
    gotoProductionList=()=>{
        this.props.history.push({pathname : `/orderitemlist`})
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {orderItem,materials,selectLabel,Label,CerList,UnitList,boxOpen,productList} = this.state
        let image = "../assets/images/ry.png" ;
        if(orderItem  && orderItem.urun_id &&  orderItem.urun_id.productImages && orderItem.urun_id.productImages.length && orderItem.urun_id.productImages[0].image  && orderItem.urun_id.productImages[0].image.smallimage ){
            image = orderItem.urun_id.productImages[0].image.smallimage;
        }else{
            image = "../assets/images/ry.png" ;
        }
        const {role} = this.props.auth;  
        let  sID = 0 ;

        return (
            <>
            <div  className={this.props.orderItemDetail.loading ? "dimmer active" : ""}>
                {this.props.orderItemDetail.loading && <div className="loader"/>}
                <div className={this.props.orderItemDetail.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        {orderItem &&  <div className="card">
                            <div className="card-header">
                                <h6 className="card-title"><strong>{orderItem.urun_adi}</strong></h6>
                                <div className="card-options">
                                    <span  onClick={()=>this.gotoProductionList()}><i className="fe fe-x" /></span>
                                     <div className="item-action dropdown ml-2">
                                        <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#1" 	data-toggle="modal"  data-target="#boxLabel"><i className="dropdown-icon fa fa-file-pdf-o" /><IntlMessages id="button.label"/></a>
                                            <a className="dropdown-item" href="#2" onClick={()=>this.SaveOrderItem()}><i className="dropdown-icon fa fa-save" /><IntlMessages id="button.save"/></a>
                                            <a className="dropdown-item" href="#3" onClick={()=>this.gotoProductionList()}><i className="dropdown-icon fa fa-list" /><IntlMessages id="button.close"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-2  col-md-2 col-3">
                                        <div className="circle">
                                            <a href="#0" className="mb-3">
                                                <img className="rounded-circle" src={image  ? image : "../assets/images/ry.png"} alt="Moduled"  />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-3  col-md-3 col-9">
                                        <strong><IntlMessages id="order.optik"/> {orderItem.urun_kodu[2] ? orderItem.urun_kodu[2]:"Belirtilmemiş"}</strong> <br />
                                        <strong><IntlMessages id="order.cct"/> {orderItem.urun_kodu[3] ? orderItem.urun_kodu[3]:"Belirtilmemiş"}</strong> <br />
                                        <strong><IntlMessages id="order.bodycolor"/> {orderItem.urun_kodu[6] ? orderItem.urun_kodu[6]:"Belirtilmemiş"}</strong> <br />
                                        <strong><IntlMessages id="order.ledmark"/> {orderItem.urun_kodu[7]  ? orderItem.urun_kodu[7]:"Belirtilmemiş"}</strong> <br />
                                        <strong><IntlMessages id="order.driverMark"/>  {orderItem.urun_kodu[8] ? orderItem.urun_kodu[8]:"Belirtilmemiş"}</strong> <br />
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-12">
                                        <strong><IntlMessages id="order.date"/></strong> <small className="float-right">{moment(orderItem.siparis_tarihi).format("DD.MM.YYYY")}</small><br />
                                        <div className="clearfix">
                                            <div className="float-left"><strong>{parseFloat(orderItem.tamamlanmaOrani).toFixed(1)+"%"}</strong></div>
                                            <div className="float-right"><small className="text-muted">{messages["order.orderStatus"+orderItem.siparis_durumu]}</small></div>
                                        </div>
                                        <div className="progress progress-xs">
                                            <div className="progress-bar bg-azure" role="progressbar" style={{ width: orderItem.tamamlanmaOrani+'%' }} aria-valuenow={orderItem.tamamlanmaOrani} aria-valuemin={0} aria-valuemax={100} />
                                        </div>
                                        <div>
                                            <div className="text-muted-dark">{orderItem.teklifno  ? orderItem.teklifno.firma_adi : "Stok Üretimi / Alt Montaj "}</div>
                                        </div>

                                    </div>
                                    <div className="col-sm-2 col-md-2 col-6 text-center">
                                        <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {orderItem.deliveried_id.reduce((acc, curr) => acc + parseInt(curr.qty, 10), 0)}</h2>
                                        <div className="text-muted-dark">{messages["genel."+orderItem.urun_birimi]}</div>
                                        <div className="text-muted-dark">{messages["order.orderStatus5"]}</div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-6 text-center">
                                        <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {orderItem.urun_miktari}</h2>
                                        <div className="text-muted-dark">{messages["genel."+orderItem.urun_birimi]}</div>
                                        <div className="text-muted-dark">{messages["offer.OfferStatus1"]}</div>
                                    </div>
                                </div> 
                            </div>
                        </div>}

                        {orderItem && orderItem.Operations.map((Operation,opIndex)=>{
                            return(
                                 <div className={`card  ${!boxOpen[opIndex] ? 'card-collapsed' : ""}`} key={opIndex}>
                                    <div className={Operation.OperationStatus ===  0 ? "card-status card-status-left bg-danger":Operation.OperationStatus ===  1 ?"card-status card-status-left bg-warning":"card-status card-status-left bg-success"}/>
                                    <div className="card-header">
                                        <div >
                                            <div className="row">
                                                <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                                                    <CircularProgressbar value={Operation.OperationPercent} text={`%${parseFloat(Operation.OperationPercent).toFixed(1)}`} strokeWidth={5} styles={buildStyles({ pathColor: `rgb(110, 118, 135)` })} />
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-10">
                                                    <div className="clearfix">
                                                        <div className="text-muted-dark">{Operation.Operation}</div>
                                                        <div className="text-muted-dark">{Operation.OperationStaff}</div>
                                                        <div className="text-muted-dark">{moment(Operation.OperationStartTime).format("DD.MM.YYYY")} - {moment(Operation.OperationFinishTime).format("DD.MM.YYYY")}</div>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                                    <div className="text-center" onClick={()=>this.onChangeComplatedVal(opIndex,(Operation.Qty-Operation.OperationComplated))}>
                                                        <h5 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {Operation.Qty}</h5>
                                                        <div className="text-muted-dark">{messages["genel."+orderItem.urun_birimi]}</div>
                                                        <div className="text-muted-dark">{messages["product.produced"]}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                                    <div className="text-center">
                                                        <h5 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {Operation.OperationComplated}</h5>
                                                        <div className="text-muted-dark">{messages["genel."+orderItem.urun_birimi]}</div>
                                                        <div className="text-muted-dark">{messages["product.complated"]}</div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                                    <div className="form-group text-center">
                                                        <label className="form-label"><IntlMessages id="product.qty" /></label>
                                                            <input
                                                                type="number"
                                                                max={Operation.Qty-Operation.OperationComplated}
                                                                min={0}
                                                                autoComplete="off"
                                                                className="form-control text-center"
                                                                value={Operation.counter ? Operation.counter  :0 }
                                                                onChange={(evt)=>this.onChangeComplatedVal(opIndex,evt.target.value)}
                                                            />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-6">
                                                    <label className="form-label"><IntlMessages id="product.operation" /></label>
                                                    {Operation.OperationStatus === 0 &&<a href="#1" className="btn btn-outline-success" onClick={()=>this.OperationStart(opIndex)}><IntlMessages id="order.operationstart"/></a>}
                                                    {/* <a href="#2" className="btn btn-outline-info" onClick={()=>this.OperationFinish(opIndex)}><IntlMessages id="order.operationfinish"/></a> */}
                                                    {Operation.OperationStatus === 1 && <a href="#1" className={Operation.counter > 0 && (Number(Operation.counter)+Number(Operation.OperationComplated))<=Number(Operation.Qty) ? "btn btn-outline-warning":"btn btn-outline-warning disabled"} onClick={()=>this.OperationComplated(opIndex)}><IntlMessages id="order.operationcomplated"/></a>}
                                                    {Operation.OperationStatus === 2 && <a href="#4" className="btn btn-outline-danger" onClick={()=>this.OperationReset(opIndex)}><IntlMessages id="order.operationreset"/></a> }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-options">
                                            <span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.onBoxOpen(opIndex)}><i className="fe fe-chevron-up x2" /></span>
                                            {role<2 && <span data-toggle="modal" data-target="#operationedit" onClick={() => this.onOpenModal(opIndex)}><i className="fe fe-edit" /></span>}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table_custom spacing5 border-style mb-0">
                                                <thead>
                                                    <tr>
                                                        <th><IntlMessages id="order.startdate"/></th>
                                                        <th><IntlMessages id="product.qty"/></th>
                                                        <th><IntlMessages id="order.operationstaff"/></th>
                                                        <th><IntlMessages id="material.stock"/></th>
                                                    </tr>
                                                </thead>
                                                    {Operation.OperationComplatedList  ?  Operation.OperationComplatedList.map((complated, comlatedIndex)=>{
                                                        return(
                                                        <tbody key={comlatedIndex}>
                                                            <tr >
                                                                <td>
                                                                    <h6 className="mb-0">{complated.Date ? moment(complated.Date).format("DD.MM.YYYY"):"--.--.---"}</h6>
                                                                </td>
                                                                <td className=" text-center">
                                                                    <h6 className="mb-0">{complated.Qty ? complated.Qty : null} {messages["genel."+orderItem.urun_birimi]}</h6>
                                                                </td>                                                    
                                                                <td>
                                                                    <h6 className="mb-0">{complated.Staff ? complated.Staff : null}</h6>
                                                                </td>
                                                                <td>{complated.stockupdate===true ? "Düştü" : complated.stockupdate}</td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }):""}
                                            </table>
                                        </div>
                                    </div>
                                
                                </div>
                            )
                        })}

                    </div>
                    <div  className={this.props.materials.loading ? "dimmer active" : ""}>
                        {this.props.materials.loading && <div className="loader"/>}
                        <div className={this.props.materials.loading ? "dimmer-content"  : ""}>
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table_custom spacing5 border-style mb-0">
                                                <thead>
                                                    <tr>
                                                        <th ><IntlMessages id="order.operation"/></th>
                                                        <th ><IntlMessages id="order.category"/></th>
                                                        <th ><IntlMessages id="order.group"/></th>
                                                        <th ><IntlMessages id="order.material"/></th>
                                                        <th className="text-center"><IntlMessages id="order.qty"/></th>
                                                        <th className="text-center"><IntlMessages id="order.totalqty"/></th>
                                                        <th className="text-center"><IntlMessages id="order.stock"/></th>
                                                        <th className="w20"/>
                                                    </tr>
                                                </thead>
                                                {orderItem && orderItem.Operations ? orderItem.Operations.map((Operation,opIndex)=>{
                                                    return(  
                                                        Operation.OperationDetails ? Operation.OperationDetails.map((detail,detailIndex)=>{
                                                            let  materialData = [];
                                                          
                                                            if(Operation.Operation!=="Yarı Mamül"){
                                                                let rowMaterialName = [];
                                                                materialData = detail.material && materials.filter(x =>  x._id === detail.material)[0];
                                                                materials && materials.length && materials.forEach(row => {
                                                                    if  ( detail.material_category === row.material_category && detail.material_group === row.material_group ){
                                                                        rowMaterialName.push({
                                                                            label: row.material_name,
                                                                            value: row._id,
                                                                            price: row.material_price,
                                                                            doviz: row.material_doviz,
                                                                            stock:row.stockSum,
                                                                            img:row.material_img
                                                                        });
                                                                    }
                                                                });
                    
                                                                detail.status =  materialData && materialData.stockSum;

                                                                if (rowMaterialName.length===1 && detail.material  ==="" ){
                                                                    detail.material = rowMaterialName.filter(option => option.value === rowMaterialName[0].value)[0].value
                                                                } 
                                                            }else{
                                                                materialData = detail.material && productList && (productList.filter(x =>  x._id === detail.material))[0];
                                                                detail.status =  materialData && materialData.stockSum;
                                                            }


                                                            if(detail.material===""){
                                                                sID=sID+1
                                                            }
                                                            return(
                                                                <tbody key={Operation._id+detailIndex}>
                                                                    <tr>
                                                                        <td>
                                                                            <span className={Operation.OperationStatus === 0  ? "tag tag-red":Operation.OperationStatus === 1 ? "tag tag-blue":"tag tag-green"}>{Operation.Operation  && Operation.Operation}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span >{detail.material_category && Operation.Operation==="Yarı Mamül" ? messages["model.kategori"+detail.material_category] : detail.material_category}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span >{detail.material_group && detail.material_group}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span className={materialData ? "tag tag-teal":"tag tag-red"}>{materialData ? materialData.material_name  : "Seçim Yapılmalı"}</span>
                                                                        </td>
                                                                        <td  className="text-center">
                                                                            <span>{detail.materialQty && detail.materialQty}</span>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <span>{detail.totalQty && detail.totalQty}</span>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <span className={detail.StockUpdate ? "tag tag-cyan":"tag tag-gray-dark"}>{detail.status && parseFloat(detail.status).toFixed(2)}</span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="item-action dropdown">
                                                                                <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)'}}>

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}):null
                                                        )})
                                                    :null}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
					className="modal fade"
					id="boxLabel"
                    tabIndex={-1}
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
                                    <IntlMessages id="product.labeleditor"/>
								</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">X</span>
								</button>
							</div>
							<div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.productLabelType"/></label>
                                            <select 
                                                className="custom-select w-auto" 
                                                onChange={(evt)=>this.onChangeSelectLabel("type",evt.target.value)}
                                                value={Label && Label.type  ? Label.type  :""}
                                            >
                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                {selectLabel.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.value}>{x.label} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <div className="form-label"><IntlMessages id="product.logoOpen"/></div>
                                            <label className="custom-switch">
                                                <input type="checkbox" name="custom-switch-checkbox" checked={Label  && Label.logoOpen ? Label.logoOpen :false} className="custom-switch-input" onChange={()=>this.onChangeLabelData("logoOpen",!Label.logoOpen)}/>
                                                <span className="custom-switch-indicator" />
                                                <span className="custom-switch-description">Açık / Kapalı</span>
                                            </label>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <div className="form-label"><IntlMessages id="product.qrOpen"/></div>
                                            <label className="custom-switch">
                                                <input type="checkbox" name="custom-switch-checkbox" checked={Label  && Label.qrOpen ? Label.qrOpen :false} className="custom-switch-input" onChange={()=>this.onChangeLabelData("qrOpen",!Label.qrOpen)}/>
                                                <span className="custom-switch-indicator" />
                                                <span className="custom-switch-description">Açık / Kapalı</span>
                                            </label>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        {Label && Label.type && <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.labelLanguage"/></label>
                                            <select name="beast" id="select-beast" className="form-control custom-select" onChange={(evt)=>this.onChangeLabelData("Lang",evt.target.value)}>
                                                <option value={0}>Türkçe</option>
                                                <option value={1}>İngilizce</option>
                                            </select>
                                        </div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6">
										{this.props.company.data &&  Label && Label.logoOpen  && 
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="mycompany.logo"/></label>
                                            <img className="rounded" src={this.props.company.data && this.props.company.data.bwlogo  ? this.props.company.data.bwlogo : "../assets/images/ry.png"} alt="Moduled"  />
										</div>}
									</div>
                                </div>
                                {Label &&  Label.type === "Kutu Etiketi" ?
                                    <div  className={Label && Label.model ? "" : "dimmer active"}>
                                    {Label && !Label.model && <div className="loader"/>}
                                        <div className={Label && Label.model ? ""  : "dimmer-content"}>
                                            <div className="row clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.model"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.model && Label.model} onChange={(evt)=>this.onChangeLabelData("model",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.name"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.productName && Label.productName} onChange={(evt)=>this.onChangeLabelData("productName",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.code"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.productCode && Label.productCode} onChange={(evt)=>this.onChangeLabelData("productCode",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.power"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Power && Label.Power} onChange={(evt)=>this.onChangeLabelData("Power",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.voltage"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Voltage && Label.Voltage} onChange={(evt)=>this.onChangeLabelData("Voltage",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Dimensions && Label.Dimensions} onChange={(evt)=>this.onChangeLabelData("Dimensions",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.Weight && Label.Weight} onChange={(evt)=>this.onChangeLabelData("Weight",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.boxQty"/></label>
                                                        <input type="text" className="form-control" value={Label && Label.BoxQty && Label.BoxQty} onChange={(evt)=>this.onChangeLabelData("BoxQty",evt.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="genel.unit"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeLabelData("Unit",evt.target.value)}
                                                            defaultValue={Label && Label.Unit && Label.Unit}
                                                        >
                                                            {UnitList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row gutters-sm">
                                                    {CerList  && Label.Certificates  &&
                                                    CerList.map((cer,index)=>{
                                                        let val = cer.certificate  === Label.Certificates[index].certificate;
                                                        return(
                                                            <div className="col-3 col-sm-2  col-md-2" key={index}>
                                                                <label className="imagecheck mb-3">
                                                                    <input name="imagecheck" type="checkbox" checked={val} className="imagecheck-input" onChange={()=>this.onChangeLabelCertificete(index,!val)} />
                                                                    <figure className="imagecheck-figure">
                                                                        <img src={cer.file} alt={cer.certificate} className="imagecheck-image" />
                                                                    </figure>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :null
                                }
                                {Label &&  Label.type === "Güneş Paneli Etiketi" &&
                                <div  className={Label && Label.model ? "" : "dimmer active"}>
                                {Label && !Label.model && <div className="loader"/>}
                                    <div className={Label && Label.model ? ""  : "dimmer-content"}>
                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.model"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.model && Label.model} onChange={(evt)=>this.onChangeLabelData("model",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.name"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productName && Label.productName} onChange={(evt)=>this.onChangeLabelData("productName",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.code"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productCode && Label.productCode} onChange={(evt)=>this.onChangeLabelData("productCode",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.power"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Power && Label.Power} onChange={(evt)=>this.onChangeLabelData("Power",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Dimensions && Label.Dimensions} onChange={(evt)=>this.onChangeLabelData("Dimensions",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Weight && Label.Weight} onChange={(evt)=>this.onChangeLabelData("Weight",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="row clearfix" >
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.label"/></label></div>
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.feature"/></label></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                {Label &&  Label.Specs  ? Label.Specs.map((spec,sindex)=>{
                                                    return(
                                                        <div className="row clearfix"  key={sindex}>
                                                            <div className="col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control" value={spec.urun_ozellik} onChange={(evt)=>this.onChangeLabelSpecData("urun_ozellik",evt.target.value)}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control" value={spec.urun_aciklama} onChange={(evt)=>this.onChangeLabelSpecData("urun_aciklama",evt.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }):null}
                                            </div>
                                            <div className="row gutters-sm">
                                                {CerList  && Label.Certificates  &&
                                                CerList.map((cer,index)=>{
                                                    let val = cer.certificate  === Label.Certificates[index].certificate;
                                                    return(
                                                        <div className="col-3 col-sm-2  col-md-2" key={index}>
                                                            <label className="imagecheck mb-3">
                                                                <input name="imagecheck" type="checkbox" checked={val} className="imagecheck-input" onChange={()=>this.onChangeLabelCertificete(index,!val)} />
                                                                <figure className="imagecheck-figure">
                                                                    <img src={cer.file} alt={cer.certificate} className="imagecheck-image" />
                                                                </figure>
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <textarea type="text" className="form-control" value={Label.warning} onChange={(evt)=>this.onChangeLabelData("warning",evt.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {Label &&  Label.type === "Pil Etiketi" &&
                                <div  className={Label && Label.model ? "" : "dimmer active"}>
                                {Label && !Label.model && <div className="loader"/>}
                                    <div className={Label && Label.model ? ""  : "dimmer-content"}>
                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.model"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.model && Label.model} onChange={(evt)=>this.onChangeLabelData("model",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.name"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productName && Label.productName} onChange={(evt)=>this.onChangeLabelData("productName",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.code"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.productCode && Label.productCode} onChange={(evt)=>this.onChangeLabelData("productCode",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.power"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Capacity && Label.Capacity} onChange={(evt)=>this.onChangeLabelData("Power",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Dimensions && Label.Dimensions} onChange={(evt)=>this.onChangeLabelData("Dimensions",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                    <input type="text" className="form-control" value={Label && Label.Weight && Label.Weight} onChange={(evt)=>this.onChangeLabelData("Weight",evt.target.value)}/>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="row clearfix" >
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.label"/></label></div>
                                                    <div className="col-md-6 col-sm-6"><label className="form-label"><IntlMessages id="product.feature"/></label></div>
                                                </div>
                                            </div>


                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <textarea type="text" className="form-control" value={Label.warning} onChange={(evt)=>this.onChangeLabelData("warning",evt.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }




							</div>
							<div className="modal-footer">
                                <a href="#1" className="btn btn-outline-info" data-dismiss="modal"><IntlMessages id="button.close"/></a>
                                {Label && Label.type && <a href="#1" className="btn btn-outline-success" onClick={()=>this.onCreatePDF()}><IntlMessages id="button.createpdf"/></a>}
							</div>
						</div>
					</div>
				</div>


                <div
					className="modal fade"
					id="operationedit"
                    tabIndex={-1}
					role="dialog"
					aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
                                    <IntlMessages id="order.operationedit"/>
								</h5>
							</div>
							{orderItem  && orderItem.Operations  ? 
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.produced"/></label>
                                            <input type="number" 
                                            min={0}   
                                            className="form-control" 
                                            value={orderItem.Operations[this.state.opIndex] && orderItem.Operations[this.state.opIndex].Qty  ? this.state.orderItem.Operations[this.state.opIndex].Qty :  0} 
                                            onChange={(evt)=>this.onChangeOperationData("Qty",evt.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><IntlMessages id="product.complated"/></label>
                                            <input type="number" 
                                            min={0}   
                                            className="form-control" 
                                            value={orderItem.Operations[this.state.opIndex] && orderItem.Operations[this.state.opIndex].OperationComplated  ? this.state.orderItem.Operations[this.state.opIndex].OperationComplated :  0} 
                                            onChange={(evt)=>this.onChangeOperationData("OperationComplated",evt.target.value)}/>
                                        </div>
                                    </div>
                                </div>
							</div>:null}
							<div className="modal-footer">
                                <a href="#1" className="btn btn-outline-info" data-dismiss="modal"><IntlMessages id="button.close"/></a>
							</div>
						</div>
					</div>
				</div>



            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    orderItemList:state.orderItemList,
    productDetail:state.productDetail,
    productList:state.productList,
    locale:state.settings.locale,
    auth:state.auth,
    orderItemDetail:state.orderItemDetail,
    materials:state.materials,
    company:state.myCompany,
})

const mapDispatchToProps = ({updateOrderItem,getMaterialsList,getOrderItemDetails,getProductDetails,setLabelData,changeLocale,getCompany,getProductList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductionDetail)));
