import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from "moment";
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import Roundy from 'roundy';
import {device_get_details,gwcommands_add_request,gwprograms_list_request,gwcommands_list_request} from '../../actions';
import { store } from '../../store';
import { connect as wsConnect, disconnect as wsDisconnect } from '@giantmachines/redux-websocket';

class DeviceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {



        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.device_get_details(this.props.match.params.id)
        store.dispatch(wsConnect(String("https://api.akaysis.com").replace("https", "wss").replace("http", "ws") + "/ws"));
    }
//******************************************************************************************************************************************************************/
    componentWillUnmount(){
        store.dispatch(wsDisconnect());
    }
//******************************************************************************************************************************************************************/
    itemModalOpen(macs, value){
    
        this.setState({
            macs:macs,
            value:value
        })
    }
//******************************************************************************************************************************************************************/
	setBrightness( value){
		console.log("setBrightness", value);
		this.props.gwcommands_add_request(this.props.deviceDetails.data._id, "set_mesh_status", {macs: this.state.macs, cid: 1, value: parseInt(value)});
	}
//******************************************************************************************************************************************************************/
    getPrograms(){
        this.props.gwprograms_list_request(this.props.deviceDetails.data._id);
    }
	getCommandHistory(){
		this.props.gwcommands_list_request(this.props.deviceDetails.data._id);
	}

//******************************************************************************************************************************************************************/
    render() {
       // const { locale} = this.props;
       // const Lang = locale === "tr" ? 0  : 1 ;
        const { messages } = this.props.intl;
        const  deviceDetails = this.props.deviceDetails.data;
        const voltage = deviceDetails &&  deviceDetails.eMeterVoltageValue && deviceDetails.eMeterVoltageValue.split(' / ');
        const current = deviceDetails &&  deviceDetails.eMeterCurrentValue && deviceDetails.eMeterCurrentValue.split(' / ');
        //"219.88 / 1.94 / 1.84"
        console.log("setstate", this.state.macs, this.state.value);
    
        return (
            <div  className={this.props.deviceDetails.loading ? "dimmer active" : ""}>
                {this.props.deviceDetails.loading && <div className="loader"/>}
                <div className={this.props.deviceDetails.loading ? "dimmer-content"  : ""}>
                    <div className="section-body">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#device-detail"><IntlMessages id="akaysis.deviceinfo"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#device-energymeter"><IntlMessages id="akaysis.energymeter"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#device-programs"  onClick={()=>this.getPrograms()}><IntlMessages id="akaysis.programs"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#device-specs"><IntlMessages id="akaysis.location"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#device-history"  onClick={()=>this.getCommandHistory()}><IntlMessages id="akaysis.history"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="tab-content taskboard">
                            <div className="tab-pane fade show active" id="device-detail" role="tabpanel">
                                <div className="card">
                                    <div className="card-body">
                                        {deviceDetails &&  
                                            <div className="row">
                                                <div className="col-sm-2  col-md-2 col-3">
                                                    <div className="circle">
                                                        <a href="#0" className="mb-3">
                                                            <img className="rounded-circle" src={"../assets/images/ry.png"} alt="Moduled"  />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10  col-md-10 col-9">
                                                    <div className="table-responsive">
                                                        <table className="table table_custom spacing5 border-style mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.status"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_status}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.devicename"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_name}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.devicedescription"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_description}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.devicecity"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_city_details && deviceDetails.device_city_details.name}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.sunriseTime"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_city_details && deviceDetails.device_city_details.sunriseTime}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.sunsetTime"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.device_city_details && deviceDetails.device_city_details.sunsetTime}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.websocketPort"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.websocketPort}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.root_mesh_ip"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.root_mesh_ip}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span><IntlMessages id="akaysis.eMeterReadTimestamp"/></span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{deviceDetails.eMeterReadTimestamp}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> 
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="device-energymeter" role="tabpanel">
                                <div className="card">
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-6  col-md-6 col-12">
                                                {voltage && <div className="card-text">
                                                    <div className="mt-0">
                                                        <small className="float-right text-muted">{voltage[0]+ " VAC"}</small>
                                                        <h6>R Voltage</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width: (voltage[0]/3)+'%'}} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <small className="float-right text-muted">{voltage[1]+ " VAC"}</small>
                                                        <h6>S Voltage</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width:  (voltage[1]/3)+'%' }} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <small className="float-right text-muted">{voltage[2]+ " VAC"}</small>
                                                        <h6>T Voltage</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width:  (voltage[2]/3)+'%' }} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="col-sm-6  col-md-6 col-12">
                                                {current && <div className="card-text">
                                                    <div className="mt-0">
                                                        <small className="float-right text-muted">{current[0]+ " A"}</small>
                                                        <h6>R Currency</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width: current[0]+'%' }} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <small className="float-right text-muted">{current[1]+ " A"}</small>
                                                        <h6>S Currency</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width: current[1]+'%'  }} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <small className="float-right text-muted">{current[2]+ " A"}</small>
                                                        <h6>T Currency</h6>
                                                        <div className="progress progress-sm">
                                                            <div style={{ width: current[2]+'%'  }} className="progress-bar" />
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane fade show" id="device-programs" role="tabpanel">
                                <div className="card">
                                    <div className="card-body" >
                                        <div className="table-responsive">
                                            <table className="table table_custom spacing5 border-style mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.time"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.value"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday1"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday2"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday3"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday4"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday5"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday6"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.weekday7"/></th>
                                                        <th className="w20"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.devicePrograms.data ? this.props.devicePrograms.data.map((program,pIndex)=>{
                                                        return(
                                                            <tr key={pIndex}>
                                                                <td className="text-center w50"><span>{program.user_defined_time}</span></td>
                                                                <td className="text-center w50"><span>{program.brightness+" %"}</span></td>
                                                                <td className="text-center w50">{program.monday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.tuesday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.wednesday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.thursday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.friday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.saturday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td className="text-center w50">{program.sunday ? <i className="fa fa-check-square-o"/> :<i className="fa fa-square-o" />}</td>
                                                                <td>
                                                                    <div className="item-action dropdown ml-2">
                                                                        <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <a href="#1" className="dropdown-item"><i className="dropdown-icon fa fa-edit" /> <IntlMessages id="akaysis.edit"/></a>
                                                                            <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-trash" /> <IntlMessages id="akaysis.remove"/></a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):null }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="device-history" role="tabpanel">
                                <div className="card">
                                    <div className="card-body" >
                                        <div className="table-responsive">
                                            <table className="table table_custom spacing5 border-style mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.date"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.command"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.user"/></th>
                                                        <th className="text-center w50"><IntlMessages id="akaysis.status"/></th>
                                                    </tr>
                                                </thead>
                                                {!this.props.deviceCommands.loading ? 
                                                <tbody>
                                                    {this.props.deviceCommands.data ? this.props.deviceCommands.data.map((command,pIndex)=>{
                                                        return(
                                                            <tr key={pIndex}>
                                                                <td className="text-center w50"><span>{moment(command.timestamp).format("DD.MM.YYYY HH:MM")}</span></td>
                                                                <td className="text-center w50"><span>{command.command}</span></td>
                                                                <td className="text-center w50"><IntlMessages id={command.user}/></td>
                                                                <td><span className={command.status==="completed" ? "tag tag-green":"tag tag-red"}>{messages["akaysis."+command.status]} </span></td>
                                                            </tr>
                                                        )
                                                    }):null }
                                                </tbody>:<div className="loader"></div>
                                                
                                            }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Mesh Cihazları </h3>
                                <div className="card-options">
                                    <a href="/#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a>
                                    <a href="/#" className="card-options-fullscreen" data-toggle="card-fullscreen"><i className="fe fe-maximize" /></a>
                                    <a href="/#" className="card-options-remove" data-toggle="card-remove"><i className="fe fe-x" /></a>
                                    <div className="item-action dropdown ml-2">
                                        <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-eye" /> View Details </a>
                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /> Share </a>
                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /> Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{minHeight:"200px"}}>
                                    <table className="table table-hover text-nowrap mb-0">
                                        <tbody>
                                            {deviceDetails  && deviceDetails.mesh_items ? deviceDetails.mesh_items.map((item,index)=>{
                                                return(
                                                    
                                                        <tr key={index}>
                                                            
                                                                <td><a href="#2" data-toggle="modal"  data-target="#meshitemmodal" onClick={()=>this.itemModalOpen(item.mac_addr,item.brightness)}>{item.mac_addr}</a></td>
                                                                <td><a href="#2" data-toggle="modal"  data-target="#meshitemmodal"><span>{item.name}</span></a></td>
                                                                <td><a href="#2" data-toggle="modal"  data-target="#meshitemmodal">{item.rssi}</a></td>
                                                                <td><a href="#2" data-toggle="modal"  data-target="#meshitemmodal">{item.version}</a></td>
                                                                <td style={{ width: 150 }}>

                                                                    <div className="clearfix">
                                                                        <div className="float-left"><strong>{item.brightness+" %"}</strong></div>
                                                                        <div className="float-right"><small className="text-muted">{item.connected ? "Active" : "Offline"}</small></div>
                                                                    </div>
                                                                    <div className="progress progress-xs">
                                                                        <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{ width: item.brightness+'%' }}></div>
                                                                    </div>
                                                                </td>
                                                                <td width="20"> 
                                                                    <div className="item-action dropdown ml-2">
                                                                        <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <div className="col">
                                                                                <span className="ml-2">Parlaklık {item.brightness+" %"}</span>
                                                                                <input type="range" className="form-control custom-range  dropdown-item" step={5} min={0} max={100} onChange={(e)=>this.setBrightness(item.mac_addr,e.target.value)}/>
                                                                            </div>
                                                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-eye" /> View Details </a>
                                                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /> Share </a>
                                                                            <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /> Download</a>

                                                                        </div>

                                                                    </div> 
                                                                </td>
                                                            
                                                        </tr>
                                                    
                                                )
                                            }):null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id="meshitemmodal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        <IntlMessages id="product.productListSettings"/>
                                    </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                
   
                                    <Roundy
                                        value={this.state.value}
                                        rotationOffset={0}
                                        strokeWidth={18}
                                        thumbSize={18}
                                        arcSize={180}
                                        min={0}
                                        max={100}
                                        stepSize={5}
                                        radius={100}
                                        color='green'
                                        onChange={value => this.setState({value})}
                                        onAfterChange={value => this.setBrightness(value.value)}
                                    />
                                    <span aria-hidden="true">{this.state.value}</span>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                        <IntlMessages id="button.close"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>             
             






                


                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({

    deviceDetails:state.deviceDetails,
    devicePrograms:state.devicePrograms,
    deviceCommands:state.deviceCommands

})

const mapDispatchToProps  =({device_get_details,gwcommands_add_request,gwprograms_list_request,gwcommands_list_request})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceDetails)));
