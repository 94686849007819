import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import {getTcmbList} from '../../../actions';
import moment from "moment";
class TCMB extends Component {
    constructor(props) {
        super(props);
        this.state = {

            totalItemCount: 0,


			userRole: [
				{ value: 0, label: "user.0"},
				{ value:1, label: "user.1" },
				{ value:2, label: "user.2" },
				{ value:3, label: "user.3" },
				{ value:4, label: "user.4" },
				{ value:5, label: "user.5" },
				{ value:6, label: "user.6" },
				{ value:7, label: "user.7" },
			]
        };
      }
//******************************************************************************************************************************************************************/
    componentDidMount() {
		this.props.getTcmbList();
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.tcmblist.data !== nextProps.tcmblist.data) {
            this.setState({
                tcmblist: nextProps.tcmblist.data,
                totalItemCount:nextProps.tcmblist.data.length,
            }); 
		};  

    }
//******************************************************************************************************************************************************************/
	toggleUserModal=(id)=>{
		this.setState({
			userModalOpen: !this.state.userModalOpen,
			userID:id
		});
	}
//******************************************************************************************************************************************************************/
	render() {

		//const { fixNavbar } = this.props;
		//const { messages } = this.props.intl;
        const {tcmblist} = this.state
		return (
			<>
                <div  className={this.props.tcmblist.loading ? "dimmer active" : ""}>
                    {this.props.tcmblist.loading && <div className="loader"/>}
                    <div className={this.props.tcmblist.loading ? "dimmer-content"  : ""}>
						<div className="container-fluid">
							<div className="row row-cards">
								<div className="col-12">
									<div className="card">

									</div>
								</div>
							</div>						
							<div className="table-responsive">
								<table className="table table-vcenter table_custom spacing5 border-style mb-0">
									<thead>
										<tr>
											<th className="w20">#</th>
											<th><IntlMessages id="genel.date"/></th>
											<th className="text-right"><IntlMessages id="genel.usdSelling"/></th>
											<th className="text-right"><IntlMessages id="genel.usdBuying"/></th>
											<th className="text-right"><IntlMessages id="genel.eurSelling"/></th>
											<th className="text-right"><IntlMessages id="genel.eurBuying"/></th>
											<th className="w40"></th>
										</tr>
									</thead>
									{tcmblist &&  tcmblist.length ?
										<tbody>
											{tcmblist.map((row,i)=>{
											return(
												<tr key={i}>
													<td>
														<span
															className="avatar avatar-blue"
															data-toggle="tooltip"
															data-placement="top"
															data-original-title="Avatar Name"
														>
															{i}
														</span>  
													</td>
													<td>{moment(row.tarih).format('DD.MM.YYYY HH:mm')}</td>
													<td className="text-right"><span>{row.Data[0].ForexSelling}</span></td>
													<td className="text-right"><span>{row.Data[0].ForexBuying}</span></td>
													<td className="text-right"><span>{row.Data[1].ForexSelling}</span></td>
													<td className="text-right"><span>{row.Data[1].ForexBuying}</span></td>
													<td></td>
												</tr>
												)
											})}
										</tbody>:
										null}
								</table>

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	tcmblist:state.tcmbList,
	auth:state.auth
})

const mapDispatchToProps =  ({getTcmbList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(TCMB)));