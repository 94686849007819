import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import {getProductList,getModelList} from '../../actions';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";

class WebProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedPageSize: 9,
          pageSizes: [6, 12, 24, 96],
          sortColumn:"model_category",
          currentPage: 1,
          totalItemCount: 0,
          totalPage: 1,
          search: "",
          filter:"",
          filterField:"",
          orderOptions: [
            { column: "model_category", label:"product.orderbyCategory"},
            { column: "urun_guc", label:"product.orderbyPower"},
            { column: "urun_fiyat", label:"product.orderbyPrice"}
          ],
          categoryList : [
            {value : 0 , label : "model.kategori"+0},
            {value : 1 , label : "model.kategori"+1},
            {value : 2 , label : "model.kategori"+2},
            {value : 3 , label : "model.kategori"+3},
            {value : 4 , label : "model.kategori"+4},
            {value : 5 , label : "model.kategori"+5},
            {value : 6 , label : "model.kategori"+6},
            {value : 7 , label : "model.kategori"+7},
            {value : 8 , label : "model.kategori"+8},
          ],
          modelList: this.props.modelList.data,
          productList: this.props.productList.data,
        };
      }


//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getProductList("details",this.state.sortColumn);
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList: nextProps.productList.data,
                totalItemCount:nextProps.productList.data.length,
                totalPage: Math.ceil(nextProps.productList.data.length/this.state.selectedPageSize)
            })
        };  
        if (this.props.modelList.data !== nextProps.modelList.data) {
            this.setState({
                modelList: nextProps.modelList.data,
            })
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
                sortColumn:column
            },()=>this.props.getProductList("detail",this.state.sortColumn));
    };
//******************************************************************************************************************************************************************/
    changePageSize = size => {
        this.setState({
            selectedPageSize: size,
            currentPage: 1,
            totalPage: Math.ceil(this.state.totalItemCount/size)
            });
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        const  {productList} = this.state;
        let productCount   = "";
        let _filter = "" ;
        f === "Tümü" ? _filter  = "" : _filter = Number(f) ;

        if (f!=="Tümü"){
          productCount = productList.filter(x=>x.model_category ===_filter).length;
          this.setState(
            {
                filterField: e,
                filter: _filter,
                currentPage:1,
                totalItemCount:productCount,
                totalPage: Math.ceil(productCount/this.state.selectedPageSize) || 1
            }
          );
        } else {
          productCount = this.state.productList.length;
          this.setState(
            {
                filterField: e,
                filter: _filter,
                currentPage:1,
                totalItemCount:productCount,
                totalPage: Math.ceil(this.state.productList.length/this.state.selectedPageSize)
            }
          );
        }
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.productList.data.filter(item => {return item.urun_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.urun_model.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
            productList:this.props.productList.data,
            totalItemCount:this.props.productList.data &&  this.props.productList.data.length ? this.props.productList.data.length:0,
            totalPage: Math.ceil((this.props.productList.data &&  this.props.productList.data.length  ? this.props.productList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
            productList:filteredList,
            totalItemCount:this.state.productList &&  this.state.productList.length ? this.state.productList.length:0,
            totalPage: Math.ceil((this.state.productList &&  this.state.productList.length  ? this.state.productList.length : 0) /this.state.selectedPageSize)
            })
        }
    };

    render() {
        const { locale } = this.props;
        const { messages } = this.props.intl;
        const {productList,selectedPageSize,currentPage,totalItemCount,categoryList,filter,filterField,orderOptions} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
        const Lang = locale==="tr" ? 0:1 
        const _filter = filterField === "model_kategori" ? Number(filter) :filter;
        
        return (
            <div  className={this.props.productList.loading ? "dimmer active" : ""}>
                {this.props.productList.loading &&  <div className="loader"/>}
                <div className={this.props.productList.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <label><IntlMessages id="menu.search" /></label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                onKeyPress={e => this.onSearchKey(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <label><IntlMessages id="product.filter" /></label>
                                        <div className="multiselect_div">
                                            <select className="custom-select" 
                                                onChange={(evt)=>this.onChangeFilter("model_category",evt.target.value)}
                                            >
                                                    <option value="Tümü">{messages["menu.all"]}</option>
                                                {categoryList.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <label><IntlMessages id="menu.sort" /></label>
                                        <div className="form-group">
                                            <select className="custom-select" onChange={(evt)=>this.changeOrderBy(evt.target.value)}>
                                                <option value="Tümü">{messages["menu.choosee"]}</option>
                                                {orderOptions.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.column}>{messages[x.label]} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {productList ?
                            <div className="row clearfix row-deck">
                                    {filter!=="" ?
                                    productList.filter(x=>x.model_category ===_filter).slice(startIndex, endIndex).map((row,i)=>{
                              
                                        const productImage = row.productImages ? row.productImages : "../assets/images/ryModuled.jpg"
       
                                        return(
                                            <div className="col-lg-4 col-md-4 col-sm-12" key={i}>
                                                <div className="card">
                                                    <div className="card-header">
                                                    {row.model_category===7  ? 
                                                        <h3 className="card-title">{row.urun_model.model_kodu + "-"+row.urun_kodu}</h3>
                                                        :
                                                        <h3 className="card-title">{row.urun_model.model_kodu + "-"+row.urun_kodu + "-" + row.urun_optik+"-"+row.urun_cct+ (row.urun_aku && row.urun_panel ? "-"+row.urun_panel+"-"+row.urun_aku : "")}</h3>
                                                    }
                                                        <div className="card-options">
                                                            <span className="card-options-remove" data-toggle="card-remove" ><i className="fe fe-x" /></span>
                                                            {/* <div className="item-action dropdown ml-2">
                                                                <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-eye" /> View Details </a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /> Share </a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /> Download</a>
                                                                    <div className="dropdown-divider" />
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-copy" /> Copy to</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-folder" /> Move to</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-edit" /> Rename</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-trash" /> Delete</a>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="card-body ribbon">
                                                        <div className="ribbon-box green">{messages["model.kategori"+row.urun_model.model_kategori]}</div>
                                                        <a href={"/web/"+row._id+"/"+Lang} className="mb-3">
                                                            <img src={ productImage} alt="moduled" className="rounded" />
                                                        </a>
                                                    </div>
                                                    <div className="card-footer">
                                                        <strong>{row.urun_adi[Lang]}</strong>
                                                    </div>
                                                    <div className="card-footer">
                                                        
                                                        {row.model_category===7  ? 
                                                             <strong>{row.urun_guc+" W "}</strong>
                                                            :
                                                            <strong>{row.urun_guc+" W  / "+ row.urun_lumen+ " lm"}</strong>
                                                        }
                                                    </div>
                                                    {row.urun_fiyat && <div className="card-footer">
                                                        <strong>{row.urun_fiyat}  {row.urun_doviz}</strong>
                                                    </div>}
                                                </div>
                                            </div>
                                            )
                                        })
                                    :
                                    productList.slice(startIndex, endIndex).map((row,i)=>{
                                        const productImage = row.productImages ? row.productImages : "../assets/images/ryModuled.jpg"
                                        return(
                                            <div className="col-lg-4 col-md-4 col-sm-12" key={i}>
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">{row.urun_model.model_kodu + "-"+row.urun_kodu + "-" + row.urun_optik+"-"+row.urun_cct+ (row.urun_aku && row.urun_panel ? "-"+row.urun_panel+"-"+row.urun_aku : "")}</h3>
                                                        <div className="card-options">
                                                            <span className="card-options-remove" data-toggle="card-remove" ><i className="fe fe-x" /></span>
                                                            {/* <div className="item-action dropdown ml-2">
                                                                <a href="fake_url" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-eye" /> View Details </a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /> Share </a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /> Download</a>
                                                                    <div className="dropdown-divider" />
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-copy" /> Copy to</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-folder" /> Move to</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-edit" /> Rename</a>
                                                                    <a href="fake_url" className="dropdown-item"><i className="dropdown-icon fa fa-trash" /> Delete</a>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="card-body ribbon">
                                                        <div className="ribbon-box green">{messages["model.kategori"+row.model_category]}</div>
                                                        <a href={"/web/"+row._id+"/"+Lang} className="mb-3">
                                                        <img src={ productImage} alt="moduled" className="rounded" />
                                                        </a>
                                                    </div>
                                                    <div className="card-footer">
                                                        <strong>{row.urun_adi[Lang]}</strong>
                                                    </div>
                                                    <div className="card-footer">
                                                        <strong>{row.urun_guc+" W  / "+ row.urun_lumen+ " lm"}</strong>
                                                    </div>
                                                    {row.urun_fiyat && <div className="card-footer">
                                                        <strong>{row.urun_fiyat}  {row.urun_doviz}</strong>
                                                    </div>}
                                                </div>
                                            </div>

                                            )
                                        })
                                    }
                                                    
                            </div>  :
                        <div className="loader"/>}
                    </div>
                    <Pagination
                        currentPage={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        onChangePage={i => this.onChangePage(i)}
                    />    
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    productList:state.productList,
    modelList:state.modelList,
    locale:state.settings.locale
})

const mapDispatchToProps = ({getProductList,getModelList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(WebProduct)));
