import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from "../../helpers/IntlMessages";
import { store } from 'react-notifications-component';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import {getBuyingsList,deleteBuying,getCompanyList,updateBuying,getMaterialsList,createBuying} from '../../actions';

class Buyings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [6, 12, 18, 30, 50, 100],
            selectedOrderOption: { column: "siparis_tarihi", label: <IntlMessages id="order.orderbyDate" /> },
            sortColumn:"siparis_tarihi",
            orderItemFilter:this.props.orderItemFilter ||  0,
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            search: "",
            filterStatus:0,
            buyingDetail:"",
            openBuyingDetail:false,
            materialListModal:false,
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "urun_adi", label:"order.orderbyName"},
                { column: "siparis_tarihi", label:"order.orderbyDate"},
                //{ column: "siparis_durumu", label:"order.orderbyStatus" }
              ],
            BuyingStatus: [
                {value : 0 , label:"buying.buyingStatus0" },
                {value : 1 , label:"buying.buyingStatus1" },
                {value : 2 , label:"buying.buyingStatus2" },
                {value : 3 , label:"buying.buyingStatus3" },
                {value : 4 , label:"buying.buyingStatus4" },
            ],
            CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
            ],
            KDVList : [
                { value: '18', label: '18' },
                { value: '8', label: '8' },
                { value: '0', label: '0' }
            ]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        
        this.props.getBuyingsList("list",this.state.sortColumn,0,0)
        .then(res=>{
            const data =this.props.buyingList.data;
            const Total_Doviz = data  && data.reduce((xtotal, data) => xtotal + parseInt(data.Doviz_TotalPrice, 10), 0)
            const Total_TL = data  && data.reduce((xtotal, data) => xtotal + parseInt(data.TL_TotalPrice, 10), 0)
            this.setState({
                buyingList:this.props.buyingList.data,
                Total_Doviz:Total_Doviz,
                Total_TL:Total_TL
            }); 
        })
    }
//******************************************************************************************************************************************************************/
    getBuyingList(x){
        this.props.getBuyingsList("list",this.state.sortColumn,x,x)
        this.setState({
            buyingDetail:"",
            filterStatus:x
        });  
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.buyingList.data !== nextProps.buyingList.data) {
            const data =nextProps.buyingList.data;
            const Total_Doviz = data  && data.reduce((xtotal, data) => xtotal + parseInt(data.Doviz_TotalPrice, 10), 0);
            const Total_TL = data  && data.reduce((xtotal, data) => xtotal + parseInt(data.TL_TotalPrice, 10), 0);
            this.setState({
                buyingList:nextProps.buyingList.data,
                Total_Doviz:Total_Doviz,
                Total_TL:Total_TL
            }); 
        };   
        if (this.props.companyList.data !== nextProps.companyList.data) {
            let companyList=[];
            nextProps.companyList.data.forEach(comp=>{
                companyList.push({
                    id: comp._id,
                    firma_adi : comp.company_name,
                    address:comp.company_adress,
                    district :comp.company_district,
                    city:comp.company_city,
                    country:comp.company_country,
                    email:comp.company_email
                });  
            })
            this.setState({
                companyList:companyList
            });  
        } 
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials:nextProps.materials.data,
            }); 
        }; 

    }
//******************************************************************************************************************************************************************/
    deleteBuying=(id)=>{
        if (window.confirm("Satınalmayı Silmek istiyor musunuz ?")) {
            this.props.deleteBuying(id)
            .then(res=>{
                this.toggleBuyingModal()
                this.props.getBuyingsList("list",this.state.sortColumn,0,0)
            })
            
        }
    }
//******************************************************************************************************************************************************************/
    editBuying=(item)=>{
        this.props.companyList.data  &&  !this.props.companyList.data.length && this.props.getCompanyList();
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  
            buyingDetail.tedarikci_id =  item.tedarikci_id  && item.tedarikci_id ;
            buyingDetail.tedarikci_adi = item.tedarikci_adi  && item.tedarikci_adi;
            buyingDetail.tedarikci_eposta = item.tedarikci_eposta  && item.tedarikci_eposta;
            buyingDetail.material_id =  item.material_id  && item.material_id ;
            buyingDetail.material_name = item.material_name  && item.material_name;
            buyingDetail.material_category = item.material_category  && item.material_category;
            buyingDetail.material_group = item.material_group  && item.material_group;
            buyingDetail.material_doviz =  item.material_doviz  ?  item.material_doviz :item.material_id.material_doviz ;
            buyingDetail.material_price = item.material_price  //? item.material_price :  item.material_id.material_price ;
            buyingDetail.material_qty =  item.material_qty  && item.material_qty ;
            buyingDetail.material_unit = item.material_unit  && item.material_unit;
            buyingDetail.modified_date = item.modified_date  && item.modified_date;
            buyingDetail.modified_user = item.modified_user  && item.modified_user;
            buyingDetail.creator_user = item.creator_user  && item.creator_user;
            buyingDetail.created_date = item.created_date  && item.created_date;
            buyingDetail.related_person = item.related_person  && item.related_person;
            buyingDetail.related_person_email = item.related_person_email  && item.related_person_email;
            buyingDetail.satinalma_durumu = item.satinalma_durumu  && item.satinalma_durumu;
            buyingDetail.siparis_no = item.siparis_no  && item.siparis_no;
            buyingDetail.siparis_notu = item.siparis_notu  && item.siparis_notu;
            buyingDetail.siparis_tarihi = item.siparis_tarihi  && item.siparis_tarihi;
            buyingDetail.talep_tarihi = item.talep_tarihi  && item.talep_tarihi;
            buyingDetail.tedarikci_adi = item.tedarikci_adi  && item.tedarikci_adi;
            buyingDetail.tedarikci_eposta = item.tedarikci_eposta  && item.tedarikci_eposta;
            buyingDetail.teslim_tarihi = item.teslim_tarihi  && item.teslim_tarihi;

            buyingDetail.USD_Kur = item.USD_Kur  ? item.USD_Kur : this.props.tcmb.Data[0].ForexSelling;
            buyingDetail.EUR_Kur = item.EUR_Kur  ? item.EUR_Kur :this.props.tcmb.Data[1].ForexSelling;

            buyingDetail.Doviz_TotalPrice =  item.Doviz_TotalPrice ;
            buyingDetail.TL_TotalPrice =  item.TL_TotalPrice ;

            buyingDetail._id = item._id  && item._id;
            return { buyingDetail,openBuyingDetail: !this.state.openBuyingDetail };    
        });
    }
//******************************************************************************************************************************************************************/
    onChangeBuyerCompany=(selected)=>{
        if(selected[0]  &&  selected[0].customOption) {
            this.setState(prevState => {
                let buyingDetail = Object.assign({}, prevState.buyingDetail);  
                buyingDetail.tedarikci_adi = selected[0].firma_adi; 
                return { buyingDetail };    
            });

        }else if(selected[0]  &&  selected[0].firma_adi){
            this.setState(prevState => {
                let buyingDetail = Object.assign({}, prevState.buyingDetail);  
                buyingDetail.tedarikci_id =  selected[0].id ? selected[0].id :null ;
                buyingDetail.tedarikci_adi = selected[0].firma_adi; 
                buyingDetail.tedarikci_eposta = selected[0].email
                return { buyingDetail };    
            });
        }else{
            this.setState(prevState => {
                let buyingDetail = Object.assign({}, prevState.buyingDetail);  
                buyingDetail.tedarikci_adi = "";  
                return { buyingDetail };    
            });
        }
    }
//******************************************************************************************************************************************************************/
    onChangeBuyingData=(field,data)=>{
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  
            buyingDetail[field] = data; 
            buyingDetail.related_person =  buyingDetail.satinalma_durumu > 0  && this.props.auth.user      
            buyingDetail.related_person_email =  buyingDetail.satinalma_durumu > 0  && this.props.auth.email              
            return { buyingDetail };    
        });
 
        this.calculate()
   
    }
//******************************************************************************************************************************************************************/
    calculate=()=>{
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  
            buyingDetail.Doviz_TotalPrice = buyingDetail.material_doviz  !=="₺"  && buyingDetail.material_qty *   buyingDetail.material_price;  
            buyingDetail.TL_TotalPrice = buyingDetail.material_doviz  ==="₺"  ? buyingDetail.material_qty *   buyingDetail.material_price  : buyingDetail.material_doviz  ==="$" ? buyingDetail.material_qty *   buyingDetail.material_price * buyingDetail.USD_Kur : buyingDetail.material_qty *   buyingDetail.material_price * buyingDetail.EUR_Kur  ;             
            return { buyingDetail };   

        });
    }
//******************************************************************************************************************************************************************/
    handleChangePrice = (field)=>evt=> {
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  
            buyingDetail[field] = evt.floatValue;      
            return { buyingDetail };    
        },()=> this.calculate());
    }
//******************************************************************************************************************************************************************/
    toggleBuyingModal (){
        this.setState({
            openBuyingDetail: !this.state.openBuyingDetail
        });
    }
//******************************************************************************************************************************************************************/
    toggleMaterialListModal=()=>{
            this.setState({
                openBuyingDetail: !this.state.openBuyingDetail,
                materialListModal: !this.state.materialListModal
            });
    }
//******************************************************************************************************************************************************************/
    newBuying(){
        this.props.companyList.data  &&  !this.props.companyList.data.length && this.props.getCompanyList();
       // console.log(this.state.buyingDetail)
        this.setState(prevState => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail); 
        
            buyingDetail.siparis_notu = ""; 

            buyingDetail.material_id = ""; 
            buyingDetail.material_name = ""; 
            buyingDetail.material_group = ""; 
            buyingDetail.material_category = ""; 
            buyingDetail.material_qty = 1; 
            buyingDetail.material_unit = "Adet"; 

            buyingDetail.satinalma_durumu = 0; 
            buyingDetail.related_person = ""; 
        
            return { buyingDetail, openBuyingDetail: true };    
        })
        
    }
//******************************************************************************************************************************************************************/
    saveBuying(){
        const { messages } = this.props.intl;
        const {filterStatus} = this.state;
        if(this.state.buyingDetail._id){
            this.props.updateBuying({...this.state.buyingDetail})
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.satinnalmaguncellendi"],
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                //});
                });
                this.toggleBuyingModal()
                this.props.getBuyingsList("list",this.state.sortColumn,filterStatus,filterStatus)
                
            })
        }else{
            this.props.createBuying(this.state.buyingDetail)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.talepolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });   
                this.toggleBuyingModal()
                this.props.getBuyingsList("list",this.state.sortColumn,filterStatus,filterStatus)
            })
        }

    }
//******************************************************************************************************************************************************************/
    materialListOpened (){
        this.props.materials.data  &&  !this.props.materials.data.length && this.props.getMaterialsList("list")

    }
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =  this.props.materials.data.filter(item => {return item.material_category.toLowerCase().includes(search.target.value.toLowerCase()) || item.material_group.toLowerCase().includes(search.target.value.toLowerCase())|| item.material_name.toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
                materials:this.props.materials.data,
                totalItemCount:this.props.materials.data &&  this.props.materials.data.length ? this.props.materials.data.length:0,
            })
        }else{
            this.setState({
                materials:filteredList,
                totalItemCount:this.state.materials &&  this.state.materials.length ? this.state.materials.length:0,
            })
        }  
    };
//******************************************************************************************************************************************************************/
    selectedMaterial =(item)=>{
        console.log(item)

        this.setState((prevState) => {
            let buyingDetail = Object.assign({}, prevState.buyingDetail);  // creating copy of state variable 
            buyingDetail.siparis_notu = ""; 

            buyingDetail.material_id = item._id 
            buyingDetail.material_name = item.material_name; 
            buyingDetail.material_group = item.material_group; 
            buyingDetail.material_category = item.material_category; 
            buyingDetail.material_qty = 1; 
            buyingDetail.material_unit = item.material_unit; 
            buyingDetail.material_doviz =  item.material_doviz  ?  item.material_doviz :item.material_id.material_doviz ;
            buyingDetail.USD_Kur = item.USD_Kur  ? item.USD_Kur : this.props.tcmb.Data[0].ForexSelling;
            buyingDetail.EUR_Kur = item.EUR_Kur  ? item.EUR_Kur :this.props.tcmb.Data[1].ForexSelling;
            buyingDetail.material_price = item.material_price  //? item.material_price :  item.material_id.material_price ;
            buyingDetail.satinalma_durumu = 0; 
            buyingDetail.related_person = "";                   // update the offer property, assign a newitemval    
            buyingDetail.stock = item.stockSum;                   // update the offer property, assign a newitemval             
            return { buyingDetail ,openBuyingDetail: !this.state.openBuyingDetail,materialListModal:false};  
        });
        this.calculate();

    }
//******************************************************************************************************************************************************************/
	render() {
		const { fixNavbar } = this.props; 
        const { messages } = this.props.intl;
        const {buyingList,buyingDetail,UnitList,companyList,filterStatus,materials,Total_Doviz,Total_TL} = this.state;

		return (
			<>
				<div>
					<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
						<div className="container-fluid"> 
							<div className="row clearfix">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body">
											<div className="d-md-flex justify-content-between">
                                                <div className="form-group">
                                                    <div className="selectgroup w-100">
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="value" className="selectgroup-input" defaultChecked= {filterStatus === 0} onClick={()=>this.getBuyingList(0)} />
                                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus0"/></span>
                                                        </label>
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="value" className="selectgroup-input" defaultChecked= {filterStatus === 1} onClick={()=>this.getBuyingList(1)}/>
                                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus1"/></span>
                                                        </label>
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="value" className="selectgroup-input" defaultChecked= {filterStatus === 2} onClick={()=>this.getBuyingList(2)}/>
                                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus2"/></span>
                                                        </label>
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="value" className="selectgroup-input" defaultChecked= {filterStatus === 3} onClick={()=>this.getBuyingList(3)}/>
                                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus3"/></span>
                                                        </label>
                                                        <label className="selectgroup-item">
                                                            <input type="radio" name="value" className="selectgroup-input" defaultChecked= {filterStatus === 4} onClick={()=>this.getBuyingList(4)}/>
                                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus4"/></span>
                                                        </label>
                                                    </div>
                                                </div>
												<div className="d-flex align-items-center sort_stat">
													<div className="d-flex">
														{/* <span className="bh_income">2,5,1,8,3,6,7,5,3,6,7,5</span> */}
														<div className="ml-2">
															<p className="mb-0 font-11">{messages["buying.buyingStatus"+filterStatus].toUpperCase()}</p>
															<h5 className="font-16 mb-0">{parseFloat(Total_TL).toFixed(2)+" ₺"}</h5>
														</div>
													</div>
													<div className="d-flex ml-3">
														{/* <span className="bh_traffic">5,8,9,10,5,2,5,8,9,10</span> */}
														<div className="ml-2">
															<p className="mb-0 font-11">DOVIZ</p>
															<h5 className="font-16 mb-0">{parseFloat(Total_Doviz).toFixed(2)}</h5>
														</div>
													</div>
												</div>
											</div>
                                            <div className="input-group">
                                                <input type="text" className="form-control search" placeholder="Search..."onKeyPress={e => this.onSearchKey(e)} />
                                                <div className="input-group-append">
                                                    <button type="button" className="btn btn-primary"><IntlMessages id="button.action"/></button>
                                                    <button data-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle" />
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href="#2" onClick={()=>this.newBuying()}	><IntlMessages id="button.add"/></a>
                                                       
                                                    </div>
                                                </div>
                                            </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                    <div  className={this.props.buyingList.loading  ? "dimmer active" : ""}>
                        <div className={this.props.buyingList.loading  ? "dimmer-content"  : ""}>
                            <div className="section-body">
                                <div className="container-fluid">
                                    {buyingList && 
                                    <div className="table-responsive" id="users">
                                        <table className="table table-hover table-vcenter text-nowrap table_custom border-style list">
                                            <tbody>
                                                {buyingList.map((item,itemIndex)=>{
                                                    return(
                                                    <tr key={itemIndex}>
                                                        <td className="width35 hidden-xs">
                                                            <span>{item.material_qty +" "+item.material_unit}</span>
                                                        </td>
                                                        <td>
                                                            <span  onClick={()=>this.editBuying(item)} >{item.material_name}</span>
                                                            <div className="text-muted">{item.material_category +" "+item.material_group }</div>
                                                        </td>
                                                        <td>
                                                            <div>{item.satinalma_durumu === 0 ? item.creator_user : item.related_person}</div>
                                                            <div className="text-muted">{item.satinalma_durumu === 0 ? "" : item.related_person_email}</div>
                                                        </td>
                                                        <td className="hidden-xs">
                                                            <div>{item.satinalma_durumu === 0 ? item.siparis_notu : item.tedarikci_adi}</div>
                                                            <div className="text-muted">{item.satinalma_durumu === 0 ? "" : item.tedarikci_eposta}</div>
                                                        </td>
                                                        {item.satinalma_durumu !== 0 &&
                                                         <td className="hidden-xs">
                                                            <div>{parseFloat(item.Doviz_TotalPrice).toFixed(2)+" "+item.material_doviz}</div>
                                                            <div className="text-muted">{ parseFloat(item.TL_TotalPrice).toFixed(2)+" ₺"}</div>
                                                        </td>}
                                                        <td className="text-right">
                                                            {item.satinalma_durumu !== 0 && 
                                                            <a
                                                                className="btn btn-sm btn-link"
                                                                href={"mailto:"+item.tedarikci_eposta+"?subject="+item.material_qty +" "+item.material_unit+" "+item.material_name+" Siparişimiz Hakkında"}
                                                                data-toggle="tooltip"
                                                                title="Mail"
                                                            >
                                                                <i className="fa fa-envelope" />
                                                            </a>}
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                        </div>
					</div>
                    
                <Modal 
                    isOpen={this.state.openBuyingDetail}
                    toggle={this.toggleBuyingDetail}
                    size="lg"
                    //onOpened={() => this.productionRowModalonOpen()} 
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="buying.edit"/>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                    {buyingDetail && buyingDetail.stock && <div className="col-sm-12 col-md-12"> <div className="alert alert-warning" role="alert" >{"Depodaki Stok Miktarı  "+buyingDetail.stock+" "+buyingDetail.material_unit}</div></div>}
                    {buyingDetail  &&  
                        <div className="row clearfix">
                            {companyList && companyList.length &&
                            <div className="col-sm-12 col-md-8">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.company"/></label>
                                    <div className="input-group">
                                        <Typeahead
                                            id="oppp"
                                            labelKey={"firma_adi"}
                                            options={companyList}
                                            selected={buyingDetail && buyingDetail.tedarikci_adi? [buyingDetail.tedarikci_adi]: []}
                                            onChange={(e)=>this.onChangeBuyerCompany(e)}
                                            ref={(ref) => this._typeahead = ref}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                {option.firma_adi}
                                                <div>
                                                    <small>{option.address+" "+option.district+" "+option.city+" "+option.country}</small>
                                                </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>}
                            {companyList && companyList.length &&
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.eposta"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.tedarikci_eposta }
                                        onChange={(evt)=>this.onChangeBuyingData("tedarikci_eposta",evt.target.value)}
                                    />
                                </div>
                            </div>}
                            <hr/>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.notes"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.siparis_notu }
                                        onChange={(evt)=>this.onChangeBuyingData("siparis_notu",evt.target.value)}
                                    />
                                </div>
                            </div>
                            <hr/>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.materialid"/></label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            value={buyingDetail &&  buyingDetail.material_id && buyingDetail.material_id._id }
                                            readOnly
                                        />
                                        {buyingDetail.material_id === "" && <div className="input-group-append">
                                            <button type="button" className="btn btn-primary" onClick={()=>this.toggleMaterialListModal()} ><IntlMessages id="offer.selectMaterial"/></button>
                                        </div>}
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.materialcategory"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.material_category && buyingDetail.material_category }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.materialgroup"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.material_group && buyingDetail.material_group }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.material"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.material_name && buyingDetail.material_name }
                                        readOnly
                                    />
                                </div>
                            </div>
                            <hr/>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.qty"/></label>
                                    <input
                                        type="number"
                                        autoComplete="off"
                                        className="form-control"
                                        value={buyingDetail &&  buyingDetail.material_qty ? buyingDetail.material_qty :1 }
                                        onChange={(evt)=>this.onChangeBuyingData("material_qty",evt.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.unit"/></label>
                                    <select 
                                        className="form-control"
                                        onChange={(evt)=>this.onChangeBuyingData("material_unit",evt.target.value)}
                                        value={buyingDetail.material_unit}
                                    >
                                        {UnitList.map((x,y)=>{
                                            return(
                                                <option key={y}  value={x.value} >{messages[x.label]} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.price"/></label>
                                    <NumberFormat
                                        decimalScale={4}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        suffix={' '+buyingDetail.material_doviz} 
                                        value={buyingDetail.material_price }  
                                        className="form-control text-right" 
                                        onValueChange={this.handleChangePrice("material_price")}
                                    /> 
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.totalprice"/></label>
                                    <NumberFormat
                                        fixedDecimalScale={true} 
                                        decimalScale={2}
                                        suffix={' '+buyingDetail.material_doviz} 
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        value={buyingDetail.Doviz_TotalPrice}  
                                        className="form-control text-right" 
                                    /> 
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="buying.totalprice"/></label>
                                    <NumberFormat
                                        fixedDecimalScale={true} 
                                        decimalScale={2}
                                        suffix={' ₺'} 
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        value={buyingDetail.TL_TotalPrice}  
                                        className="form-control text-right" 
                                    /> 
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <div className="selectgroup w-100">
                                        {buyingDetail.satinalma_durumu <= 0 &&
                                        <label className="selectgroup-item">
                                            <input type="radio" name="value" defaultValue={0} className="selectgroup-input" defaultChecked= {buyingDetail.satinalma_durumu === 0} onClick={()=>this.onChangeBuyingData("satinalma_durumu",0)} />
                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus0"/></span>
                                        </label>}
                                        {buyingDetail.satinalma_durumu <= 1 &&
                                        <label className="selectgroup-item">
                                            <input type="radio" name="value" defaultValue={1} className="selectgroup-input" defaultChecked= {buyingDetail.satinalma_durumu === 1} onClick={()=>this.onChangeBuyingData("satinalma_durumu",1)}/>
                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus1"/></span>
                                        </label>}
                                        {buyingDetail.satinalma_durumu <= 2 &&
                                        <label className="selectgroup-item">
                                            <input type="radio" name="value" defaultValue={2} className="selectgroup-input" defaultChecked= {buyingDetail.satinalma_durumu === 2} onClick={()=>this.onChangeBuyingData("satinalma_durumu",2)}/>
                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus2"/></span>
                                        </label>}
                                        {buyingDetail.satinalma_durumu <= 3 &&
                                        <label className="selectgroup-item">
                                            <input type="radio" name="value" defaultValue={3} className="selectgroup-input" defaultChecked= {buyingDetail.satinalma_durumu === 3} onClick={()=>this.onChangeBuyingData("satinalma_durumu",3)}/>
                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus3"/></span>
                                        </label>}
                                        <label className="selectgroup-item">
                                            <input type="radio" name="value" defaultValue={4} className="selectgroup-input" defaultChecked= {buyingDetail.satinalma_durumu === 4} onClick={()=>this.onChangeBuyingData("satinalma_durumu",4)}/>
                                            <span className="selectgroup-button"><IntlMessages id="buying.buyingStatus4"/></span>
                                        </label>
                                    </div>
                                </div>
                            </div>   
                        </div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {buyingDetail && 
                    <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteBuying(buyingDetail._id)}>
                        <IntlMessages id="button.delete" />
                    </button>}{"  "}
                    &nbsp;&nbsp;
                    {buyingDetail && 
                    <button type="button" className="btn btn-round btn-info" onClick={()=>this.saveBuying()}>
                        <IntlMessages id="button.save" />
                    </button>}{"  "}
                    <button type="button" className="btn btn-round btn-warning" onClick={()=>this.toggleBuyingModal()}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>           
                
                <Modal
                    isOpen={this.state.materialListModal}
                    toggle={this.toggleMaterialListModal}
                    size="lg"
                    onOpened={()=>this.materialListOpened()}
                    backdrop="static"
                    scrollable={true}
                >
                <ModalHeader>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyPress={e => this.onSearchKey(e)}
                    />
                </ModalHeader>
                <ModalBody>
                <div  className={ this.props.materials.loading ? "dimmer active" : ""}>
                    {this.props.materials.loading && <div className="loader"/>}
                        <div className={this.props.materials.loading ? "dimmer-content"  : ""}>
                            <div className="table-responsive">
                                <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 border-style mb-0">
                                    <tbody>
                                    {
                                        materials && materials.map((materialRow,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td className="w80">
                                                        <img
                                                            className="rounded-circle img-thumbnail w80"
                                                            src={"../assets/images/ry.png"}
                                                            alt="moduled"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="font-15">{materialRow.material_category+"-"+materialRow.material_group}</div>
                                                        <span className="text-muted">{materialRow.material_name}</span>
                                                    </td>
                                                    <td className="font400 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+materialRow.material_doviz} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(materialRow.material_price)}  
                                                        />  
                                                    </td>
                                                    <td className="text-right">
                                                        <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedMaterial(materialRow)}>Seç</button>
                                                    </td>
                                                </tr>
                                            )
                                        })                                    
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleMaterialListModal(null,null)}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>  

				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
    buyingList: state.buyingList,
    companyList : state.companyList,
    auth:state.auth,
    tcmb:state.tcmb.tcmb,
    materials:state.materials,
})

const mapDispatchToProps = ({getBuyingsList,deleteBuying,getCompanyList,updateBuying,getMaterialsList,createBuying})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Buyings)));
