import React, { Component } from 'react'
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import IntlMessages from "../../helpers/IntlMessages";
import FileBase64 from '../../helpers/FileBase64';
import NumberFormat from 'react-number-format';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {getProductDetails,getModelList,updateProduct,createProduct,createProductStock,deleteProduct,getMaterialsList,copyProductSpecs,getProductList,getProductStockList,deleteProductStock,getImageList} from '../../actions';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

const Option = props => {
    const { innerProps, innerRef } = props;
    return (
        <article ref={innerRef} {...innerProps} className="card card-aside">
            <img className="avatar avatar-md mr-3"  alt="LED" href="#1"  src={props.data.image}/>
            <div>
                <a href="#2">{props.data.label}</a>
                <small className="d-block text-muted">{props.data.value}</small>
            </div>
        </article>
    );
};



class ProductDetail extends Component{
    constructor(props) {
        super(props);
        this.inputReference = React.createRef();
        this.state = {
            fileUploadState:"",
            specIndex:null,
            specRowModal:false,
            productionRowModal:false,
            productImagesRowModal:false,
            productstockRowModal:false,
            isLoading:false,
            modelList:this.props.modelList.data,
            errors:{
                urun_adi:"",
                urun_doviz:"",
                urun_model:""
            },
            categoryList : [
                {value : 0 , label : "model.kategori"+0},
                {value : 1 , label : "model.kategori"+1},
                {value : 2 , label : "model.kategori"+2},
                {value : 3 , label : "model.kategori"+3},
                {value : 4 , label : "model.kategori"+4},
                {value : 5 , label : "model.kategori"+5},
                {value : 6 , label : "model.kategori"+6},
                {value : 7 , label : "model.kategori"+7},
                {value : 8 , label : "model.kategori"+8},
            ],
            CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
            ],
            CCTList: [
                { value: 'XXXX', label: 'XXXX' },
                { value: '6500 K', label: '6500 K' },
                { value: '6000 K', label: '6000 K' },
                { value: '5000 K', label: '5000 K' },
                { value: '4000 K', label: '4000 K' },
                { value: '3500 K', label: '3500 K' },
                { value: '3000 K', label: '3000 K' },
            ],
            optikList: [
                { value: 'XX', label: 'XX' },
                { value: 'AS', label: 'AS' },
                { value: 'ST', label: 'ST' },
                { value: 'SP', label: 'SP' },
                { value: '15', label: '15' },
                { value: '30', label: '30' },
                { value: '45', label: '45' },
                { value: '60', label: '60' },
                { value: '90', label: '90' },
            ], 
            Stockerrors:{
                operation_center: "" ,
                operation_type: "",
                price:"",
                stock_name:""
            },
            operationCenterList:[
                {value: "fatura", label: "Fatura"},
                {value: "ithalat", label: "İthalat"},
                {value: "uretim", label: "Üretim"},
                {value: "fire", label: "Fire"},
            ],
            operationTypeSelect:[
                { value: "giris", label: "Giriş" },
                { value: "cikis", label: "Çıkış" }
            ],
    
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        if(this.props.match.params.id==="new"){
            this.setState({
                product:{
                    urun_sira:1,
                    urun_adi:["","-"],
                    urun_model:"",
                    urun_kodu:"",
                    urun_guc:"",
                    urun_lumen:"",
                    urun_durumu:true,
                    urun_doviz:"$",
                    urun_fiyat:"",
                    urun_panel:"",
                    urun_aku:"",
                    urun_cct:"XXXX",
                    product_dimensions:"",
                    product_weight:"",
                    urun_optik:"XX",
                    production:[],
                    urun_ozellikler:[]
                }
            }); 
        }else{
            this.props.getProductDetails({id:this.props.match.params.id})
            .then(res=>{
              this.setState({
                product: this.props.productDetail.data,
              }); 
            })
        }
        //if(!this.props.modelList.data.length){
            this.props.getModelList("list","")  
        ///}
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.productDetail.data !== nextProps.productDetail.data) {
            this.setState({
                product: nextProps.productDetail.data,
            }); 
        };    
        if (this.props.productStocks.data !== nextProps.productStocks.data) {
            let operationList = [];
            let StokGiris = 0 ;
            let StokCikis = 0;
            let StokDurumu = 0;

            let runingTotal = 0
            nextProps.productStocks &&  nextProps.productStocks.data && nextProps.productStocks.data.sort((a,b)=> new Date(a.operation_date)-new Date(b.operation_date)).forEach(mtr=>{
                runingTotal = runingTotal + mtr.sumQty
                operationList.push({
                    runTotal: runingTotal,
                    product_id : mtr._id,
                    order_id : mtr.order_id,
                    orderitem_id:mtr.orderitem_id,
                    operation_center:mtr.operation_center,
                    operation_date : mtr.operation_date,
                    operation_type  : mtr.operation_type,
                    description: mtr.description,
                    qty:mtr.qty,
                    unit:mtr.unit,
                    price : mtr.price,
                    doviz : mtr.doviz,
                    sumQty:mtr.sumQty,
                    _id:mtr._id
                });  
            })
            StokGiris = operationList.filter(x=>x.operation_type==="giris").reduce((prev, current) => (prev + current.sumQty), 0)
            StokCikis = operationList.filter(x=>x.operation_type==="cikis").reduce((prev, current) => (prev + current.sumQty), 0)
            StokDurumu =  StokGiris - Math.abs(StokCikis)
            this.setState({
                StokCikis:Math.abs(StokCikis),
                StokGiris:Math.abs(StokGiris),
                StokDurumu:StokDurumu,
                productStocks: operationList.sort((a,b)=> new Date(b.operation_date)-new Date(a.operation_date)),
                totalPage: Math.ceil(operationList.length/this.state.selectedPageSize)
            }); 
        };
    }
//******************************************************************************************************************************************************************/
    onChangeProductData = (field,data) => {
        const {errors} = this.state;
        switch (field) {
			case 'urun_model': 
			errors.urun_model = data.length < 2
				? 'Write to Model Code'
				: '';
			break;                    
			default:
				break;
		}
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);  
            let errors = Object.assign({}, prevState.errors); 
            product[field] = data;   
            return { product,errors };    
        });
    }
//******************************************************************************************************************************************************************/
    onChangeProductArrayData = (field,array,data) => {
        const { locale } = this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        let errorVar = {};
        let newArray = [];
        let err = ""
        newArray.push(Lang===0 ? data :array[0] && array[0]!=="" ? array[0]:"");
        newArray.push(Lang===1 ? data :array[1] && array[0]!=="" ? array[1]:"-");
        if(field==="urun_adi"){
            err = data.length < 4 ? "Ürün Adı Çok Kısa /  Product Name Very  Short"  :  ""
            errorVar.urun_adi = err  ;
        }
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product); 
            let errors = Object.assign({}, prevState.errors);  
            product[field] = newArray;  
            errors[field] = err
            return { product,errors };    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeNumber = (field )=> (evt)=>{
        this.setState((prevState, props) => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable jasper
            product[field] = evt.floatValue;                    // update the name property, assign a new value                 
            return { product ,};  
        });
    }
//******************************************************************************************************************************************************************/
    productSave=()=>{
        const { messages } = this.props.intl;
        if(this.state.product._id){
            this.props.updateProduct(this.state.product)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.urunguncellendi"],
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
            })
            .catch(err=>{
                if(err){
                    store.addNotification({
                        title: this.props.productDetail.status.statusText,
                        message: this.props.productDetail.status.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                }
            })
        }else{
            this.props.createProduct({...this.state.product}, this.props.history)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.urunolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });    
            })
        }
    }
//******************************************************************************************************************************************************************/
    productDelete=()=>{
        let id = this.state.product._id;
		if (window.confirm("Ürünü Silmek istiyor musunuz ?")) {
			this.props.deleteProduct(id)
			.then(res=>{
                this.props.history.push({pathname : `/products`})
			}) 
		}else{
		
		}
    }
//******************************************************************************************************************************************************************/
    gotoProducts=()=>{
        this.props.history.push({pathname : `/products`})
    }
//******************************************************************************************************************************************************************/
    toggleSpecRowModal=(id)=>{
        this.setState({
            specRowModal: !this.state.specRowModal,
            specIndex:id
        });
    }
//******************************************************************************************************************************************************************/
    AddNewSpecRow = () => {
        var newItemArray = this.state.product.urun_ozellikler;
        const newRow = {
            no:newItemArray.length ? newItemArray.length +1 :1,
            urun_ozellik:["",""],
            urun_aciklama: ["",""]
        }
        newItemArray.push(newRow);
        const id = newItemArray.length-1
        this.setState((prevState) => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable 
            product.urun_ozellikler = newItemArray;                     // update the offer property, assign a newitemval               
        return { product,specIndex:id ,specRowModal: !this.state.specRowModal };  
        });
    };
//******************************************************************************************************************************************************************/
    deleteSpecRow = index => {
        if (this.state.product.urun_ozellikler.length>0){ 
            var newItemArray = this.state.product.urun_ozellikler;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product); 
                product.urun_ozellikler= newItemArray;                                
                return { product,specRowModal:false }
            });
        }
    };
//******************************************************************************************************************************************************************/
    onChangeSpecRow = (field ,array,data,index,lang) => {
        let newArray = []
        if(field==="sira"){
            newArray=data;
        }else{
            newArray.push(lang===0 ? data :array[0] && array[0]!=="" ? array[0]:"");
            newArray.push(lang===1 ? data :array[1] && array[0]!=="" ? array[1]:"-");
        }
        let newItemArray = this.state.product.urun_ozellikler.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, [field]: newArray};
        });
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);  
            product.urun_ozellikler = newItemArray;                               
        return { product }
        });
    }
//******************************************************************************************************************************************************************/
    handleSpecTagChange = (field,data,index) => { 
        let newItemArray = this.state.product.urun_ozellikler.map((item, sindex) => {
            if (index !== sindex) return item;
                return { ...item, [field]: data};
            });
            this.setState(prevState => {
                let product = Object.assign({}, prevState.product);  
                product.urun_ozellikler = newItemArray;                               
            return { product }
            });
    };
//******************************************************************************************************************************************************************/
    onClickProductionDetails=()=>{
      this.props.getMaterialsList("list");
      this.props.getProductList("list","model_category");
    }
//******************************************************************************************************************************************************************/
    toggleProductionRowModal=(id)=>{
        this.setState({
            productionRowModal: !this.state.productionRowModal,
            productionIndex:id
        });
    }
//******************************************************************************************************************************************************************/
    AddNewProductionRow = () => {
        var newItemArray = this.state.product.production ? this.state.product.production :[];
        const newRow = {
            op_name:"",
            material_category:"",
            material_group:"",
            information: "",
            materialqyt:1,
            rowTotal:0
        }
        newItemArray.push(newRow);
        const id = newItemArray.length-1
        this.setState((prevState) => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable 
            product.production = newItemArray;                     // update the offer property, assign a newitemval               
        return { product,productionIndex:id ,productionRowModal: !this.state.productionRowModal };  
        });
    };
//******************************************************************************************************************************************************************/
    deleteProductionRow = index => {
        if (this.state.product.production.length>0){ 
            var newItemArray = this.state.product.production;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product); 
                product.production= newItemArray;                                
                return { product,productionRowModal:false }
            });
        }
    };
//******************************************************************************************************************************************************************/
    onChanceModel = (field,data) =>{
        let model = this.props.modelList.data.filter(option => option._id === data);
        this.setState((prevState) => {
        let product = Object.assign({}, prevState.product);  
            product.urun_model = model[0]
            product.model_sira = model[0].model_sira
            product.model_category = model[0].model_kategori
        return { product };  
        });
    }
//******************************************************************************************************************************************************************/
    productionRowModalonOpen=()=>{
        const {product} =  this.state;
        let OperationNamelist=[];
            product && product.urun_model && product.urun_model.productiondetail && product.urun_model.productiondetail.forEach(row => {
            OperationNamelist.push({
                value: row.Operation,
                label: row.Operation,
                component:row.OperationComponent
            });
          })
        this.setState({
            OperationNamelist:OperationNamelist,
        },()=>this.onLoadSelectLists()); 

    }
//******************************************************************************************************************************************************************/
    onChangeProductionRow = (index,field,evt,NewRow) => {
        let  newItemArray = [];
        //State update 
        if(field === "material_category" ||  field ==="material_group"){
            newItemArray = this.state.product.production.map((item, sindex) => {
                if (index !== sindex) return item;
                    return { ...item,
                    [field]: evt.target.value,
                    materialcurrency: evt.target.doviz,
                };
            });
        }else{
            newItemArray = this.state.product.production.map((item, sindex) => {
                if (index !== sindex) return item;
                return { ...item,
                    [field]: evt,
                //    rowTotal:Number(field === "materialqyt" ? evt : item.materialqyt)*Number(field === "materialprice" ? evt :item.materialprice)
                };
            });
        }
        this.setState((prevState) => {
            let product = Object.assign({}, prevState.product);  
            product.production = newItemArray                       
            return { product };  
        },()=>this.onLoadSelectLists());
    }
//******************************************************************************************************************************************************************/
    onLoadSelectLists=()=>{
        let MaterialGroup = [];
        let components = [];
        let production = {};
        const {materials,productList}  = this.props;
        const {OperationNamelist,productionIndex} = this.state;
        production = this.state.product.production[productionIndex]

        if(production.op_name !=="") {
            if(production.op_name ==="Yarı Mamül"){
                // component Kategori Seçeneklerini yazar
                components = OperationNamelist &&  OperationNamelist.length && (OperationNamelist.filter(option => option.value === production.op_name))[0]  && (OperationNamelist.filter(option => option.value === production.op_name))[0].component
                productList && productList.data && productList.data.length   && 
                productList.data.filter(x=>x.model_category===Number(production.material_category)).forEach(row => {
                    MaterialGroup.push({
                        label: row.urun_adi[0],
                        value: row._id,
                        price: row.urun_fiyat,
                        doviz: row.urun_doviz,
                    });
                }) 
                const GroupFilterResult = MaterialGroup.filter(option => option.value === production.material_group)
                production.materialprice = GroupFilterResult.length ? GroupFilterResult[0].price : 0 ; 
                production.materialcurrency =  GroupFilterResult.length ? GroupFilterResult[0].doviz : 0 ; 
            }else{
                components = OperationNamelist &&  OperationNamelist.length && (OperationNamelist.filter(option => option.value === production.op_name))[0]  && (OperationNamelist.filter(option => option.value === production.op_name))[0].component
                materials && materials.data && materials.data.length   && 
                [...new Map(materials.data.map(x=> [x.material_group,x])).values()].forEach(row => {
                   
                    if  (production.material_category === row.material_category){
                        MaterialGroup.push({
                            label: row.material_group,
                            value: row.material_group,
                            price: row.material_price,
                            doviz: row.material_doviz,
                        });
                    }
                }) 
                const GroupFilterResult = MaterialGroup.filter(option => option.value === production.material_group)

            
                production.materialprice = GroupFilterResult.length ? GroupFilterResult[0].price : 0 ; 
                production.materialcurrency =  GroupFilterResult.length ? GroupFilterResult[0].doviz : 0 ; 
            }                
            this.setState({
                components:components,
                MaterialGroup:MaterialGroup
            });


        }
    }
//******************************************************************************************************************************************************************/
    getFiles=(files)=>{
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable jasper
            product.productDownloads = files;                     // update the name property, assign a new value                 
            return { product };    
        });
    }
//******************************************************************************************************************************************************************/
    getTechnicalFiles=(files)=>{
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable jasper
            product.technicalDrawing = files.base64;                     // update the name property, assign a new value                 
            return { product };    
        });
    }
//******************************************************************************************************************************************************************/
    getStockOperations=()=>{
//{id:this.props.match.params.id}
        this.props.getProductStockList(this.props.match.params.id)
    }
//******************************************************************************************************************************************************************/
    productDownloadsRowDelete=(index)=>{
        if (this.state.product.productDownloads.length>0){ 
            var newItemArray = this.state.product.productDownloads;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product); 
                product.productDownloads= newItemArray;                                
                return { product }
            });
        }
    }
//******************************************************************************************************************************************************************/
    productSpecCopy=()=>{
        let newItemArray = this.state.product.urun_ozellikler
        this.props.copyProductSpecs(newItemArray,"productSpecs")
    }
//******************************************************************************************************************************************************************/
    productSpecPaste=()=>{
        const newItemArray = this.props.copy;
        const  copyForm = this.props.copyForm;
        if(newItemArray &&  copyForm === "productSpecs"){
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product);  // creating copy of state variable 
                product.urun_ozellikler = newItemArray;                     // update the offer property, assign a newitemval               
                return { product};  
            });
        }else{
            if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
        }
    }
//******************************************************************************************************************************************************************/
    productProductionCopy=()=>{
        let newItemArray = this.state.product.production
        this.props.copyProductSpecs(newItemArray,"productProduction")
    }
//******************************************************************************************************************************************************************/
    productProductionPaste=()=>{
        const newItemArray = this.props.copy;
        const  copyForm = this.props.copyForm;
        if(newItemArray &&  copyForm === "productProduction"){
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product);  // creating copy of state variable 
                product.production = newItemArray;                     // update the offer property, assign a newitemval               
                return { product};  
            });
        }else{
            if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
        }
    }
//******************************************************************************************************************************************************************/
    deleteStock=(id)=>{
       this.props.deleteProductStock(this.props.match.params.id, id)
       .then(res=>{
           this.props.getProductStockList(this.props.match.params.id)
       })
    }
//******************************************************************************************************************************************************************/
    AddNewproductImageRow = () => {
        
        var newItemArray = this.state.product.productImages ? this.state.product.productImages :[];
        const newRow = {
            image:"",
            description:"",
        }
        newItemArray.push(newRow);
        const id = newItemArray.length-1
        this.setState((prevState) => {
            let product = Object.assign({}, prevState.product);  // creating copy of state variable 
            product.productImages = newItemArray;                     // update the offer property, assign a newitemval               
        return { product,productImagesIndex:id,productImagesRowModal: !this.state.productImagesRowModal };  
        });
    };
//******************************************************************************************************************************************************************/
    deleteproductImageRow = index => {
        if (this.state.product.productImages.length>0){ 
            var newItemArray = this.state.product.productImages;
            newItemArray.splice(index, 1);
        //delete newItemArray[index];
            this.setState((prevState) => {
                let product = Object.assign({}, prevState.product); 
                product.productImages= newItemArray;                                
                return { product,productImagesRowModal:false }
            });
        }
    };
//******************************************************************************************************************************************************************/
    toggleProductImageRow=(id)=>{
        if(id || id===0){
            this.setState({
                productImagesIndex:id,
                productImagesRowModal: !this.state.productImagesRowModal
            });
        }else{
            this.AddNewproductImageRow();
        }

    }
//******************************************************************************************************************************************************************/
    closeProductImageModal=()=>{
        this.setState({
            productImagesRowModal: !this.state.productImagesRowModal
        });
    }
//******************************************************************************************************************************************************************/
    ProductImageModalOpen=()=>{
        this.props.getImageList(this.state.product.urun_model.model_kodu)
    }
//******************************************************************************************************************************************************************/
    onChangeImageRow = (field ,index,data) => {
        let newItemArray = [];

        if(field==="image"){
            newItemArray = this.state.product.productImages.map((item, sindex) => {
                if (index !== sindex) return item;
                    return { ...item, 
                    image : data.value,
                    tempImg: data.image,
                    description  :  data.label
                };
            });
        }else{
            newItemArray = this.state.product.productImages.map((item, sindex) => {
                if (index !== sindex) return item;
                    return { ...item, 
                    [field] :data
                };
            });
        }

        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);  
            product.productImages = newItemArray;                               
            return { product}
        });
    }
//******************************************************************************************************************************************************************/
    newStockRow=()=>{
        this.setState({
            productStockDetail:{
                operation_date: new Date(),
                operation_center:"",
                operation_type: "",
                productname:this.state.product.urun_adi[0],
                qty:1,  
                unit:"Adet",
                description:"Manuel Stok Hareketi",
                price:0,
                doviz:"$",
                product_id:this.state.product._id
            },
            productstockRowModal: !this.state.productstockRowModal,
        });
    }

//******************************************************************************************************************************************************************/
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
//******************************************************************************************************************************************************************/
    toggleProductStockRowModal=(id)=>{
        this.setState({
            productstockRowModal: !this.state.productstockRowModal,
            productstockRow:id
        });
    }
//**************************************************************************************************************************************************************/
    handleChangeDateLabelOver = date => {
        this.setState(prevState => {
            let productStockDetail = Object.assign({}, prevState.productStockDetail);  // creating copy of state variable jasper
            productStockDetail.operation_date = date;                     // update the name property, assign a new value                 
            return { productStockDetail };    
        })
    };
//******************************************************************************************************************************************************************/
    onChangeStockOperationData=(field,data)=>{
        this.setState(prevState => {
            let productStockDetail = Object.assign({}, prevState.productStockDetail);  
            productStockDetail[field] = data;                          
            return { productStockDetail };    
        });
    }
//******************************************************************************************************************************************************************/
    stockSave=()=>{
        this.setState((prevState) => {
            let productStockDetail = Object.assign({}, prevState.productStockDetail);  // creating copy of state variable jasper     
            let Stockerrors = Object.assign({}, prevState.Stockerrors);  // creating copy of state variable jasper         
            productStockDetail.sumQty= this.state.productStockDetail.operation_type === "giris" ? 0 + this.state.productStockDetail.qty  : 0 - this.state.productStockDetail.qty          
            productStockDetail.sumPrice= this.state.productStockDetail.operation_type === "giris" ? 0 + (this.state.productStockDetail.price*this.state.productStockDetail.qty)  : 0 - (this.state.productStockDetail.price*this.state.productStockDetail.qty)  
            Stockerrors.operation_center=this.state.productStockDetail.operation_center.length > 2 ? "" :"Operasyon Birimi Seçiniz"
            Stockerrors.operation_type=this.state.productStockDetail.operation_type.length > 2 ? "" :"Operasyon Tipini Seçiniz"
            Stockerrors.price=this.state.productStockDetail.price !==0 ?"":"Birim Fiyat Yazınız"
            Stockerrors.productname=this.state.productStockDetail.productname.length > 2  ?"":"Write Company Name please"
        return { productStockDetail,Stockerrors};  
        },()=>this.onSave());
    }
//******************************************************************************************************************************************************************/
    onSave = () =>{
        const isOk = this.validateForm(this.state.Stockerrors);
        const { messages } = this.props.intl;
        if (isOk){
            if(this.state.productStockDetail._id){
                // this.props.updateStock(this.state.productStockDetail)
                // .then(res=>{
                //     this.setState({
                //         stockRowModal: !this.state.stockRowModal
                //     }); 
                //     store.addNotification({
                //         title: messages["uyari.basarili"],
                //         message: messages["bilgi.stokhareketiguncellendi"],
                //         type: "success",
                //         insert: "top",
                //         container: "top-right",
                //         animationIn: ["animate__animated", "animate__fadeIn"],
                //         animationOut: ["animate__animated", "animate__fadeOut"],
                //         dismiss: {
                //         duration: 3000,
                //         onScreen: true
                //         }
                //     });
                // })
                // .catch(err=>{
                //     if(err){
                //         store.addNotification({
                //             title: this.props.productStockDetail.status.statusText,
                //             message: this.props.productStockDetail.status.data,
                //             type: "danger",
                //             insert: "top",
                //             container: "top-right",
                //             dismiss: {
                //             duration: 3000,
                //             onScreen: true
                //             }
                //         });
                //     }
                // })
            }else{
                this.props.createProductStock(this.state.productStockDetail)
                .then(res=>{
                    this.setState({
                        productstockRowModal: !this.state.productstockRowModal
                    }); 
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.stokhareketiolusturuldu"],//{messages[x.label]} 
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });   
                    this.props.getProductStockList(this.props.match.params.id)
                })
            }
            
        }

    }
//******************************************************************************************************************************************************************/
    render() {
        const { locale} = this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const { messages } = this.props.intl;
        const { product,productStockDetail,errors ,CurrencyList,UnitList ,CCTList,optikList,operationTypeSelect,operationCenterList,Stockerrors,specIndex,productionIndex,components,OperationNamelist,MaterialGroup,productStocks,StokGiris,StokCikis,StokDurumu,productImagesIndex} = this.state;
        const USD = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[0].ForexSelling  : 0 
        const EUR = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[1].ForexSelling  : 0 
        let modelList = [];
        let sumTotal = 0;
        this.props.modelList.data.forEach(row => {
            modelList.push({
              label: row.model_kodu,
              value: row._id
            });
        })
        const shareUrl = product &&  product._id &&  "https://moduledv2.akaysis.com/web/"+product._id+"/"+Lang
        const startIndex = 0;
        const endIndex = 1000;
        return (
            <>
            <div  className={this.props.match.params.id!=="new" &&  this.props.productDetail.loading  ? "dimmer active" : ""}>
                {this.props.match.params.id!=="new" &&  this.props.productDetail.loading  && <div className="loader"/>}
                <div className={this.props.match.params.id!=="new" && this.props.productDetail.loading  ? "dimmer-content"  : ""}>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#product-detail"><IntlMessages id="product.detail"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-specs"><IntlMessages id="product.specs"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-production" onClick={()=>this.onClickProductionDetails()}><IntlMessages id="product.production"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-stocks" onClick={()=>this.getStockOperations()}><IntlMessages id="product.stockoperations"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-image"><IntlMessages id="product.image"/></a></li>
                                    {product && <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#" role="button" aria-haspopup="true" aria-expanded="false"><IntlMessages id="product.moredetails"/></a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" id="TaskBoard-tab" data-toggle="tab" href="#product-downloads"><IntlMessages id="product.downloads"/></a>
                                            <NavLink to={"/pdf/datasheet/"+product._id+"/"+Lang} className="dropdown-item" target="_blank" ><IntlMessages id="product.datasheet"/></NavLink>
                                            <NavLink to={"/model/"+product.urun_model._id} className="dropdown-item" target="_blank" ><IntlMessages id="model.detail"/></NavLink>
                                        </div>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="tab-content taskboard">
                                <div className="tab-pane fade show active" id="product-detail" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.sira" /></label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="Ürün Sira"
                                                                value={product &&  product.urun_sira ? product.urun_sira :"" }
                                                                onChange={(evt)=>this.onChangeProductData("urun_sira",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.name" /></label>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className={errors.urun_adi === "" ? "form-control is-valid" :"form-control is-invalid"}
                                                                placeholder="Ürün Adı"
                                                                value={product &&  product.urun_adi[Lang] ? product.urun_adi[Lang] :"" }
                                                            
                                                                onChange={(evt)=>this.onChangeProductArrayData("urun_adi",product.urun_adi ,evt.target.value)}
                                                            />
                                                            <div className="invalid-feedback">{errors.urun_adi}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    {!this.props.modelList.loading && <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="model.category"/></label>
                                                        <select 
                                                            className={errors.urun_model === "" ? "form-control is-valid" :"form-control is-invalid"}
                                                            onChange={(evt)=>this.onChanceModel("urun_model",evt.target.value)}
                                                            value={product && product.urun_model && product.urun_model._id}
                                                        >
                                                                    <option value={""} >{messages["genel.choose"]}</option>
                                                            {modelList && modelList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.urun_model}</div>
                                                    </div>}
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.model"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            disabled
                                                            placeholder="Model"
                                                            value={product &&  product.urun_model &&  product.urun_model.model_kodu ? product.urun_model.model_kodu :"" }
                                                            onChange={(evt)=>this.onChangeProductData("urun_sira",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.code"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Code"
                                                            value={product &&  product.urun_kodu &&  product.urun_kodu ? product.urun_kodu :"" }
                                                            onChange={(evt)=>this.onChangeProductData("urun_kodu",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {product && product.model_category <7 && 
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.optic"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeProductData("urun_optik",evt.target.value)}
                                                            value={product.urun_optik && product.urun_optik}
                                                        >
                                                            {optikList && optikList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>}
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {product && product.model_category < 7 && 
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.cct"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeProductData("urun_cct",evt.target.value)}
                                                            value={product.urun_cct && product.urun_cct}
                                                        >
                                                            {CCTList && CCTList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>}
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {product && product.model_category < 7 && 
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.solarpower"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Solar Panel"
                                                            value={product &&  product.urun_panel ? product.urun_panel :"" }
                                                            onChange={(evt)=>this.onChangeProductData("urun_panel",evt.target.value)}
                                                        />
                                                    </div>}
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {product && product.model_category < 7 && 
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.battery"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Batarya"
                                                            value={product &&  product.urun_aku &&  product.urun_aku ? product.urun_aku :"" }
                                                            onChange={(evt)=>this.onChangeProductData("urun_aku",evt.target.value)}
                                                        />
                                                    </div>}
                                                </div>      
                                                
                                                <div className="col-sm-12 col-md-6">
                                                    {product && product.model_category===8 ?
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.capacity"/></label>
                                                        <NumberFormat
                                                            suffix={' Ah'} 
                                                            thousandsGroupStyle={'thousand'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={product && product.urun_kapasite }  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("urun_kapasite")} 
                                                        />
                                                    </div>:
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.power"/></label>
                                                        <NumberFormat
                                                            suffix={' W'} 
                                                            thousandsGroupStyle={'thousand'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={product && product.urun_guc }  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("urun_guc")} 
                                                        />
                                                    </div>}
                                                </div>       
                                                <div className="col-sm-12 col-md-6">
                                                    {product && product.model_category < 7 ?
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.lumen"/></label>
                                                        <NumberFormat
                                                            suffix={' lm'}
                                                            thousandsGroupStyle={'thousand'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={product.urun_lumen }  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("urun_lumen")} 
                                                        />
                                                    </div>:
                                                    product && product.model_category !== 7 &&  
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.voltage"/></label>
                                                        <NumberFormat
                                                            suffix={' VDC'}
                                                            thousandsGroupStyle={'thousand'} 
                                                            thousandSeparator={false} 
                                                            decimalSeparator={','}  
                                                            value={product && product.urun_voltaj }  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("urun_voltaj")} 
                                                        />
                                                    </div>
                                                    }
                                                </div>    
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.weight"/></label>
                                                        <NumberFormat
                                                            suffix={' Kg'} 
                                                            thousandsGroupStyle={'thousand'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={product && product.product_weight }  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("product_weight")} 
                                                        />
                                                    </div>
                                                </div>       
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.dimensions"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Ölçüler"
                                                            value={product &&  product.product_dimensions &&  product.product_dimensions ? product.product_dimensions :"" }
                                                            onChange={(evt)=>this.onChangeProductData("product_dimensions",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>   
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label text-right"><IntlMessages id="product.price"/></label>
                                                        <NumberFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={product && product.urun_fiyat}  
                                                            className="form-control text-right" 
                                                            onValueChange={this.handleChangeNumber("urun_fiyat")} 
                                                        />
                                                    </div>
                                                </div>      
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.currency"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeProductData("urun_doviz",evt.target.value)}
                                                            value={product && product.urun_doviz && product.urun_doviz}
                                                        >
                                                            {CurrencyList && CurrencyList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>    
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label text-right"><IntlMessages id="product.priceTL"/></label>
                                                        {product && <input
                                                            disabled
                                                            type="text"
                                                            className="form-control text-right"
                                                            value={product.urun_doviz==="$" ?  parseFloat(product.urun_fiyat*USD).toFixed(2).replace(".",",")+" ₺" : parseFloat(product.urun_fiyat*EUR).toFixed(2).replace(".",",")+" ₺"}
                                                        />}
                                                    </div>
                                                </div>   
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label text-right"><IntlMessages id="product.stock"/></label>
                                                        {product && <input
                                                            disabled
                                                            type="text"
                                                            className="form-control text-right"
                                                            value={product.stockSum && parseFloat(product.stockSum)}
                                                        />}
                                                    </div>
                                                </div>                           
                                            </div>
                                            <div className="row">
                                            <div className="row">
                                                    <div className="ml-2">
                                                        <FacebookShareButton
                                                            url={shareUrl}
                                                            quote={product && product.urun_adi[Lang]}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                        <FacebookShareCount url={shareUrl}>
                                                            {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}
                                                        </FacebookShareCount>
                                                    </div>
                                                    <div className="ml-2">
                                                        <TwitterShareButton
                                                            url={shareUrl}
                                                            title={product && product.urun_adi[Lang]}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <TwitterIcon size={32} round />
                                                        </TwitterShareButton>
                                                    </div>
                                                    <div className="ml-2">
                                                        <WhatsappShareButton
                                                            url={shareUrl}
                                                            title={product && product.urun_adi[Lang]}
                                                            separator=":: "
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <WhatsappIcon size={32} round />
                                                        </WhatsappShareButton>
                                                    </div>
                                                    <div className="ml-2">
                                                        <TelegramShareButton
                                                            url={shareUrl}
                                                            title={product && product.urun_adi[Lang]}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <TelegramIcon size={32} round />
                                                        </TelegramShareButton>
                                                    </div>
                                                    <div className="ml-2">
                                                        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                                                            <LinkedinIcon size={32} round />
                                                        </LinkedinShareButton>
                                                    </div>
                                                    <div className="ml-2">
                                                        <EmailShareButton
                                                            url={shareUrl}
                                                            subject={product && product.urun_adi[Lang]}
                                                            body={product && product.urun_adi[Lang]+" Link :"}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                            <EmailIcon size={32} round />
                                                        </EmailShareButton>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            {product  && product._id &&
                                            <button className="btn btn-danger" onClick={()=>this.productDelete()}>
                                                <IntlMessages id="button.delete" />
                                            </button>}{" "}
                                            <button className="btn btn-success" onClick={()=>this.productSave()}><IntlMessages id="button.save"/></button>{" "}
                                            <button className="btn btn-warning"  onClick={()=>this.gotoProducts()}>
                                                <IntlMessages id="button.close" />
                                            </button>
                                        </div>
                                </div>
                            </div>
                                <div className="tab-pane fade show" id="product-specs" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            {/* <h4 className="card-title"><a href={`/order/${row._id}`} >{row.firma_adi ? (rowIndex+1) + " - " +row.firma_adi : null}</a></h4> */}
                                            <div className="card-options">
                                                <div className="item-action dropdown ml-2">
                                                    <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#1" className="dropdown-item" onClick={this.productSpecCopy}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.copy"/></a>
                                                        <a href="#2" className="dropdown-item" onClick={this.productSpecPaste}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.paste"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w40">#</th>
                                                            <th><IntlMessages id="product.label"/></th>
                                                            <th><IntlMessages id="product.feature"/></th>
                                                            <th className="w40" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product && product.urun_ozellikler && product.urun_ozellikler.length ?
                                                            product.urun_ozellikler.map((featuresRow,specKeyIndex)=>{
                                                                return(
                                                                    <tr key={specKeyIndex}>
                                                                        <td>
                                                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{specKeyIndex+1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6><span className="tag tag-info">{featuresRow.urun_ozellik[0]}</span></h6>
                                                                            <h6><span className="tag tag-success">{featuresRow.urun_ozellik[1]}</span></h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{featuresRow.urun_aciklama && featuresRow.urun_aciklama.map((x,y)=>{return(<span key={y} className="tag tag-info ml-1">{x} </span>)})}</h6>
                                                                            <h6>{featuresRow.product_description && featuresRow.product_description.map((x,z)=>{return(<span key={z} className="tag tag-success ml-1">{x}</span>)})}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <div className="item-action dropdown">
                                                                                <a href="#0" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.toggleSpecRowModal(specKeyIndex)}><i className="dropdown-icon fa fa-eye"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                    <a href="#2" className="dropdown-item" onClick={()=>this.deleteSpecRow(specKeyIndex)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="button.rowdelete"/></a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )})
                                                                :null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <a href="#1" className="btn btn-info" onClick={()=>this.AddNewSpecRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></a>{" "}
                                            <a href="#1" className="btn btn-success" onClick={()=>this.productSave()}><IntlMessages id="button.save"/></a>{" "}
                                            <a href="#1" className="btn btn-warning"  onClick={()=>this.gotoProducts()}><IntlMessages id="button.close" /></a>{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-production" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            {/* <h4 className="card-title"><a href={`/order/${row._id}`} >{row.firma_adi ? (rowIndex+1) + " - " +row.firma_adi : null}</a></h4> */}
                                            <div className="card-options">
                                                <div className="item-action dropdown ml-2">
                                                    <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#1" className="dropdown-item" onClick={()=>this.productProductionCopy()}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.copy"/></a>
                                                        <a href="#2" className="dropdown-item" onClick={()=>this.productProductionPaste()}><i className="dropdown-icon fa fa-copy" /><IntlMessages id="product.paste"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {<div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th ><IntlMessages id="product.operation"/></th>
                                                            <th ><IntlMessages id="product.materialcategory"/></th>
                                                            <th ><IntlMessages id="product.materialgroup"/></th>
                                                            <th ><IntlMessages id="product.materialqty"/></th>
                                                            <th className="text-right"><IntlMessages id="product.materialprice"/></th>
                                                            <th className="text-right"><IntlMessages id="product.USDprice"/></th>
                                                            <th className="text-right"><IntlMessages id="product.rowtotal"/></th>
                                                            <th/>
                                                            <th className="w40" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product && product.production && product.production.length  ?
                                                            product.production.map((production,productionKey)=>{
                                                                let USDrow = production.materialcurrency === "₺" ? 
                                                                (production.materialprice/USD): 
                                                                production.materialcurrency === "$" ?
                                                                production.materialprice :
                                                                production.materialcurrency === "€" ?
                                                                production.materialprice*(EUR/USD): 0
                                                                sumTotal = sumTotal + (USDrow*production.materialqyt)
                                                                return(
                                                                    <tr key={productionKey}>
                                                                        <td> 
                                                                            <span className="mb-0">{production.op_name}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="mb-0">{production.op_name === "Yarı Mamül"  ? messages["model.kategori"+production.material_category]:production.material_category}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="mb-0">{production.material_group}</span>
                                                                        </td>
                                                                        <td className={production.op_qty  ? "mb-0 text-center text-red":"mb-0 text-center "}>
                                                                            <span>{production.materialqyt}</span>
                                                                        </td>
                                                                        <td className="mb-0 text-right">
                                                                            <span>{(parseFloat(production.materialprice).toFixed(2)).replace(".",",") +" "+production.materialcurrency }</span>
                                                                        </td>
                                                                        <td className="mb-0 text-right">
                                                                            <span>{(parseFloat(USDrow).toFixed(2)).replace(".",",")+" $"}</span>
                                                                        </td>
                                                                        <td className="mb-0 text-right">
                                                                            <span>{production && production.materialprice && production.materialqyt  && ((parseFloat(USDrow  * production.materialqyt).toFixed(2)).replace(".",","))+" $"}</span>
                                                                        </td>
                                                                        <td className="mb-0 text-right">
                                                                            <span>{production && production.op_qty && production.op_qty}</span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="item-action dropdown">
                                                                                <a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.toggleProductionRowModal(productionKey)}><i className="dropdown-icon fa fa-edit"  /> <IntlMessages id="button.viewrowdetail"/></a>
                                                                                    <a href="#2" className="dropdown-item" onClick={()=>this.deleteProductionRow(productionKey)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="button.rowdelete"/></a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}):null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>}
                                        </div>
                                        <div className="card-footer text-right">
                                            <span className="mr-3">{messages["product.grandTotal"]+" "+(parseFloat(sumTotal).toFixed(2)).replace(".",",")}</span>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-info" onClick={()=>this.AddNewProductionRow()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></button>{" "}
                                            <button className="btn btn-success" onClick={()=>this.productSave()}><IntlMessages id="button.save"/></button>{" "}
                                            <button className="btn btn-warning"  onClick={()=>this.gotoProducts()}><IntlMessages id="button.close" /></button>{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-downloads" role="tabpanel">                              
                                    <div className="row row-cards">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="page-options d-flex">
                                                        <FileBase64 multiple={ true }  onDone={this.getFiles} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-deck">
                                        {product && product.productDownloads && product.productDownloads.length ?
                                            product.productDownloads.map((downloadsRow,downloadKey)=>{
                                                return(                                  
                                                    <div className="col-sm-6 col-lg-4" key={downloadKey}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "image" &&  <img src={downloadsRow.base64} alt="moduled" className="rounded" />}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "application" && <iframe title={downloadsRow.file} src={downloadsRow.base64} width="100%" height="100%"/>}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "application" &&  downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "image" && <img src="../assets/images/ry.png" alt="moduled" className="rounded" />}
                                                            </div>
                                                            <div className="card-footer row  text-right">
                                                                <div className="col-10 py-1"><strong>{downloadsRow.name} / {downloadsRow.size}</strong></div>
                                                                <div className="col-2 py-1"><button type="button" className="btn btn-round btn-danger" onClick={()=>this.productDownloadsRowDelete(downloadKey)}><IntlMessages id="button.delete" /></button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }):null
                                        }
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-technicalfiles" role="tabpanel">                              
                                    <div className="row row-cards">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="page-options d-flex">
                                                        <FileBase64 multiple={ false }  onDone={(e)=>this.getTechnicalFiles(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-deck">
                                        <div className="col-sm-6 col-lg-4">
                                            <div className="card">
                                                <div className="card-body">
                                                        {product && product.technicalDrawing ?  <img src={product.technicalDrawing} alt="moduled" className="rounded" /> : <img src="../assets/images/ry.png" alt="moduled" className="rounded" />}
                                                </div>
                                                <div className="card-footer row  text-right">
                                                    <div className="col-2 py-1"><a href="#1" className="btn btn-outline-danger"  onClick={()=>this.onChangeProductData("technicalDrawing","")}><IntlMessages id="button.delete" /></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-image" role="tabpanel">   
                                    <div className="card">
                                        <div className="card-body">     
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-auto">#</th>
                                                            <th><IntlMessages id="image.description"/></th>
                                                        </tr>
                                                    </thead>
                                                    {product && product.productImages ?
                                                        <tbody>
                                                            {product && product.productImages && product.productImages.length ?
                                                            product.productImages.map((image,imageIndex)=>{
                                                            return(
                                                                <tr key={imageIndex}>
                                                                    <td>
                                                                        <a href="#1" onClick={()=>this.toggleProductImageRow(imageIndex)}>
                                                                            <img
                                                                                src={image && image.tempImg ? image.tempImg : image.image ? image.image.image : "../assets/images/ry.png"}
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                alt=")"
                                                                                className="avatar avatar-xl"
                                                                                data-original-title="Avatar Name"
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                    <td><h6 className="mb-0"><a href="#1" onClick={()=>this.toggleProductImageRow(imageIndex)}>{image.description}</a></h6></td>
                                                                </tr>
                                                                )
                                                            }):null}
                                                        </tbody>:
                                                        null}
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                        <button type="button" className="btn btn-success ml-2"  onClick={()=>this.toggleProductImageRow(null)}><i className="fe fe-plus mr-2" /><IntlMessages id="button.addrow"/></button>{" "}
                                            <button className="btn btn-success" onClick={()=>this.productSave()}><IntlMessages id="button.save"/></button>{" "}
                                            <button className="btn btn-warning"  onClick={()=>this.gotoProducts()}><IntlMessages id="button.close" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-stocks" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            {/* <h4 className="card-title"><a href={`/order/${row._id}`} >{row.firma_adi ? (rowIndex+1) + " - " +row.firma_adi : null}</a></h4> */}
                                            <div className="card-options">
                                                <div className="item-action dropdown ml-2">
                                                    <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item"  href="#product-downloads" onClick={()=>this.newStockRow()}><IntlMessages id="product.newstockoperation"/></a>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.props.productStocks.loading  ? "card-body dimmer active" : "card-body"}>
                                            {this.props.productStocks.loading  && <div className="loader"/>}
                                            <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center"><IntlMessages id="product.stockin"/></th>
                                                            <th className="text-center"><IntlMessages id="product.stockout"/></th>
                                                            <th className="text-center"><IntlMessages id="product.stock"/></th>
                                      
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center text-green">{StokGiris}</td>
                                                            <td className="text-center text-red">{StokCikis}</td>
                                                            <td  className="text-center">{StokDurumu}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            <div  className={this.props.productStocks.loading  ? "table-responsive dimmer active" : "table-responsive"}>
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w120"><IntlMessages id="stock.date"/></th>
                                                            <th><IntlMessages id="stock.description"/></th>
                                                            <th><IntlMessages id="stock.operation"/></th>
                                                            <th ><IntlMessages id="stock.operationcenter"/></th>
                                                            <th className="text-center"><IntlMessages id="stock.oty"/></th>
                                                            <th className="text-right"><IntlMessages id="stock.price"/></th>
                                                            <th className="text-right"><IntlMessages id="stock.stock"/></th>
                                                            <th className="w20"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productStocks && productStocks.length >  0 ? productStocks.slice(startIndex, endIndex).map((StockRow,StockIndex)=>{ 
                                                            return(
                                                                <tr key={StockIndex}>
                                                                    <td>{StockRow.operation_date && moment(StockRow.operation_date).format("DD.MM.YYYY")}</td>
                                                                    <td>{StockRow.description && StockRow.description}</td>
                                                                    <td>{StockRow.operation_type &&<span className={StockRow.operation_type==="giris"?"tag tag-blue":"tag tag-red"}>{(StockRow.operation_type).toUpperCase()}</span>}</td>
                                                                    <td>{StockRow.operation_center &&<span className={StockRow.operation_center==="fatura" ? "tag tag-yellow": StockRow.operation_center==="fire" ? "tag tag-red":"tag tag-primary"}>{(StockRow.operation_center).toUpperCase()}</span>}</td>
                                                                    <td className="text-center">{StockRow.qty &&  <NumberFormat value={StockRow.qty} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  />}</td>
                                                                    <td className="text-right">{StockRow.operation_type &&(parseFloat(StockRow.price).toFixed(2)).replace(".",",")+" "+StockRow.doviz }</td>
                                                                    <td className="text-right"><NumberFormat value={StockRow.runTotal} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  /></td>
                                                                    <td>
                                                                        <div className="item-action dropdown">
                                                                            <a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                                <a href="#1" className="dropdown-item" onClick={()=>this.deleteStock(StockRow._id)}><i className="dropdown-icon fa fa-edit" /><IntlMessages id="button.delete"/></a>
                                                                                {StockRow.orderitem_id  &&  <a  href={`/orderproductiondetail/${StockRow.orderitem_id}`} className="dropdown-item"><i className="dropdown-icon fa fa-info-circle" /><IntlMessages id="order.detail"/></a>}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }):null}
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                        <div className="card-footer text-right">
                                            <a href="#1" className="btn btn-warning"  onClick={()=>this.gotoProducts()}><IntlMessages id="button.close" /></a>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.specRowModal}
                        toggle={this.toggleSpecRowModal}
                        size="lg"
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="model.featureedit"/>
                    </ModalHeader>
                        <ModalBody>
                            {product && product.urun_ozellikler && 
                                <div className="row clearfix">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">#</label>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Sira"
                                                            value={product &&  product.urun_ozellikler[specIndex] && product.urun_ozellikler[specIndex].sira ? product.urun_ozellikler[specIndex].sira :"" }
                                                            onChange={(evt)=>this.onChangeSpecRow("sira",evt,evt.target.value,specIndex)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.label" /></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Etiket"
                                                            value={product &&  product.urun_ozellikler[specIndex] && product.urun_ozellikler[specIndex].urun_ozellik ? product.urun_ozellikler[specIndex].urun_ozellik[0] :"" }
                                                            onChange={(evt)=>this.onChangeSpecRow("urun_ozellik",product.urun_ozellikler[specIndex].urun_ozellik,evt.target.value,specIndex,0)}
                                                        />
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Label"
                                                            value={product &&  product.urun_ozellikler[specIndex] && product.urun_ozellikler[specIndex].urun_ozellik ? product.urun_ozellikler[specIndex].urun_ozellik[1] :"" }
                                                            onChange={(evt)=>this.onChangeSpecRow("urun_ozellik",product.urun_ozellikler[specIndex].urun_ozellik,evt.target.value,specIndex,1)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="product.feature" /></label>
                                                        <TagsInput
                                                            value={product &&  product.urun_ozellikler[specIndex] && product.urun_ozellikler[specIndex].urun_aciklama ? product.urun_ozellikler[specIndex].urun_aciklama :[] }
                                                            onChange={(evt)=>this.handleSpecTagChange("urun_aciklama",evt,specIndex)}
                                                        />
                                                        <TagsInput
                                                            value={product.urun_ozellikler[specIndex] && product.urun_ozellikler[specIndex].product_description ? product.urun_ozellikler[specIndex].product_description :[] }
                                                            onChange={(evt)=>this.handleSpecTagChange("product_description",evt,specIndex)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </ModalBody>
                        <ModalFooter>
                            {product && product.urun_ozellikler &&
                            <button type="button" className="btn btn-danger" onClick={()=>this.deleteSpecRow(specIndex)}>
                                <IntlMessages id="button.delete" />
                            </button>}{"  "}
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-warning" onClick={()=>this.toggleSpecRowModal()}>
                                <IntlMessages id="button.close" />
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal 
                        isOpen={this.state.productionRowModal}
                        toggle={this.toggleProductionRowModal}
                        size="lg"
                        //wrapClassName="modal-right"
                        onOpened={() => this.productionRowModalonOpen()} 
                        backdrop="static"
                    >
                    <ModalHeader>
                        <IntlMessages id="product.operationedit"/>
                    </ModalHeader>
                    <ModalBody>
                        {product && product.production && product.production[productionIndex] &&
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.operation"/></label>
                                                    <select 
                                                        className="form-control" 
                                                        onChange={(evt)=>this.onChangeProductionRow(productionIndex,"op_name",evt.target.value)}
                                                        value={product.production[productionIndex].op_name && product.production[productionIndex].op_name}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {OperationNamelist && OperationNamelist.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{x.label} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.materialcategory"/></label>
                                                    <select 
                                                        className="form-control" 
                                                        onChange={(evt)=>this.onChangeProductionRow(productionIndex,"material_category",evt)}
                                                        value={product.production[productionIndex].material_category && product.production[productionIndex].material_category}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {components && components.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{x.label} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.materialgroup"/></label>
                                                    <select 
                                                        className="form-control" 
                                                        onChange={(evt)=>this.onChangeProductionRow(productionIndex,"material_group",evt)}
                                                        value={product.production[productionIndex].material_group && product.production[productionIndex].material_group}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {MaterialGroup && MaterialGroup.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value}>{x.label} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="product.materialqty"/></label>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Adet"
                                                            value={product && product.production[productionIndex] && product.production[productionIndex].materialqyt ? product.production[productionIndex].materialqyt :1 }
                                                            onChange={(evt)=>this.onChangeProductionRow(productionIndex,"materialqyt",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label text-right"><IntlMessages id="product.materialprice"/></label>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control text-right"
                                                            placeholder="Fiyatı"
                                                            value={product && product.production[productionIndex] && product.production[productionIndex].materialprice ? product.production[productionIndex].materialprice :1 }
                                                            onChange={(evt)=>this.onChangeProductionRow(productionIndex,"materialprice",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label text-right"><IntlMessages id="product.currency"/></label>
                                                    <select 
                                                        className="form-control" 
                                                        onChange={(evt)=>this.onChangeProductionRow(productionIndex,"materialcurrency",evt.target.value)}
                                                        value={product.production[productionIndex].materialcurrency && product.production[productionIndex].materialcurrency}
                                                    >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                        {CurrencyList && CurrencyList.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.value} >{x.label} </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>                                           
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <div className="form-group ">
                                                    <label className="form-label text-right"><IntlMessages id="product.rowtotal"/></label>
                                                        <input
                                                            disabled
                                                            autoComplete="off"
                                                            className="form-control text-right"
                                                            value={(parseFloat(product.production[productionIndex].materialprice  * product.production[productionIndex].materialqyt).toFixed(2)).replace(".",",")+" "+ product.production[productionIndex].materialcurrency}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label text-right"><IntlMessages id="product.totalPriceUSD"/></label>
                                                        <input
                                                            disabled
                                                            autoComplete="off"
                                                            className="form-control text-right"
                                                            value={ ((parseFloat(product.production[productionIndex].materialcurrency === "₺" ? 
                                                                    (product.production[productionIndex].materialprice/USD): 
                                                                    product.production[productionIndex].materialcurrency === "$" ?
                                                                    product.production[productionIndex].materialprice :
                                                                    product.production[productionIndex].materialcurrency === "€" ?
                                                                    product.production[productionIndex].materialprice*(EUR/USD): 0)*product.production[productionIndex].materialqyt).toFixed(2)).replace(".",",")+" $" 
                                                                }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label text-right"><IntlMessages id="product.operationcount"/></label>
                                                    <input type="number" 
                                                        value={product.production[productionIndex].op_qty} 
                                                        className="form-control text-right" 
                                                        onChange={(evt)=>this.onChangeProductionRow(productionIndex,"op_qty",evt.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>
                        {product && product.production &&
                        <button type="button" className="btn btn-danger" onClick={()=>this.deleteProductionRow(productionIndex)}>
                            <IntlMessages id="button.delete" />
                        </button>}{"  "}
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-warning" onClick={()=>this.toggleProductionRowModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                    </Modal>
                    
                    {product && product.productImages &&  product.productImages[productImagesIndex] &&
                    <Modal
                        isOpen={this.state.productImagesRowModal}
                        size="lg"
                        backdrop="static"
                        onOpened={this.ProductImageModalOpen}
                    >
                        <ModalHeader>
                            <IntlMessages id="image.edit"/>
                        </ModalHeader>
                        <ModalBody>
                            <div  className={product && product.productImages &&  product.productImages[productImagesIndex] ? "" :"dimmer active"}>
                                {!product && !product.productImages &&  !product.productImages[productImagesIndex]  && <div className="loader"/>}
                                <div className={product && product.productImages &&  product.productImages[productImagesIndex] ? ""  : "dimmer-content"}>
                                    
                                    <div className="row">
                                        <div className="col-md-3 col-sm-3">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="image.image"/></label>
                                                <img className="rounded" src={product && product.productImages[productImagesIndex].tempImg ? product.productImages[productImagesIndex].tempImg : product.productImages[productImagesIndex].image  ? product.productImages[productImagesIndex].image.image  :"../assets/images/ry.png"} alt="Moduled"  />
                                            </div>
                                        </div>
                                        <div className="col-md-9 col-sm-9">
                                            <div className="row clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="image.model"/></label>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                value={"Seçiniz"}
                                                                onChange={(e)=>this.onChangeImageRow("image",productImagesIndex,e)}
                                                                options={this.props.imageList.data && this.props.imageList.data.map(x=>({label:x.description,value:x._id,image:x.smallimage ? x.smallimage:x.image}))}
                                                                components={{ DropdownIndicator: () => null, Option: Option }}
                                                            />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="image.description"/></label>
                                                        <input type="text" 
                                                        className="form-control" 
                                                        value={product.productImages[productImagesIndex].description && product.productImages[productImagesIndex].description} 
                                                        onChange={(evt)=>this.onChangeImageRow("description",productImagesIndex,evt.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {<button className="btn btn-danger" onClick={()=>this.deleteproductImageRow(productImagesIndex)}><IntlMessages id="button.delete"/></button>}
                            <button className="btn btn-warning" onClick={()=>this.closeProductImageModal()}><IntlMessages id="button.close"/></button>
                        </ModalFooter>
                    </Modal>}
                
                    <Modal
                            isOpen={this.state.productstockRowModal}
                            toggle={this.toggleProductStockRowModal}
                            size="lg"
                            backdrop="static"
                        >
                        <ModalHeader>
                            <IntlMessages id="stock.operation"/>
                        </ModalHeader>
                            <ModalBody>
                                {productStockDetail && productStockDetail && 
                                    <div className="row clearfix">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.date"/></label>
                                                                <DatePicker
                                                                    name="operation_date"
                                                                    selected={productStockDetail.operation_date && new Date(productStockDetail.operation_date)}
                                                                    dateFormat="dd.MM.yyyy"
                                                                    onChange={this.handleChangeDateLabelOver}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.operationcenter"/></label>
                                                            <select 
                                                                className={Stockerrors.operation_center === "" ? "form-control" :"form-control is-invalid"}
                                                                onChange={(evt)=>this.onChangeStockOperationData("operation_center",evt.target.value)}
                                                            >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                                {operationCenterList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={productStockDetail.operation_center === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{Stockerrors.operation_center}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.operation"/></label>
                                                            <select 
                                                                className={Stockerrors.operation_type === "" ? "form-control" :"form-control is-invalid"}
                                                                onChange={(evt)=>this.onChangeStockOperationData("operation_type",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {operationTypeSelect.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={productStockDetail.operation_type === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{Stockerrors.operation_type}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.product"/></label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                autoComplete="off"
                                                                className={Stockerrors.stock_name === "" ? "form-control" :"form-control is-invalid"}
                                                                value={productStockDetail &&  productStockDetail.stock_name ? productStockDetail.stock_name :"" }
                                                            />
                                                            <div className="invalid-feedback">{Stockerrors.stock_name}</div>
                                                        </div>
                                                    </div>

                                                    <hr/>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.qty"/></label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={productStockDetail &&  productStockDetail.qty ? productStockDetail.qty :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("qty",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.unit"/></label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(evt)=>this.onChangeStockOperationData("unit",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {UnitList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={productStockDetail.unit === x.value}>{messages[x.label]} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.price"/></label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className={Stockerrors.price === "" ? "form-control" :"form-control is-invalid"}
                                                                value={productStockDetail &&  productStockDetail.price ? productStockDetail.price :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("price",evt.target.value)}
                                                            />
                                                            <div className="invalid-feedback">{Stockerrors.price}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.doviz"/></label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(evt)=>this.onChangeStockOperationData("doviz",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {CurrencyList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={productStockDetail.doviz === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.description"/></label>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={productStockDetail &&  productStockDetail.description ? productStockDetail.description :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("description",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </ModalBody>
                            <ModalFooter>
                                {productStockDetail && productStockDetail._id &&
                                <button type="button" className="btn btn-round btn-danger" onClick={()=>this.stockDelete(productStockDetail._id)}>
                                    <IntlMessages id="button.delete" />
                                </button>}{"  "}
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-round btn-primary" onClick={()=>this.stockSave()}>
                                    <IntlMessages id="button.save" />
                                </button>
                                <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleProductStockRowModal(null)}>
                                    <IntlMessages id="button.close" />
                                </button>
                            </ModalFooter>
                        </Modal>                 
                
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    productDetail:state.productDetail,
    modelList:state.modelList,
    materials:state.materials,
    locale:state.settings.locale,
    tcmb:state.tcmb.tcmb,
    copy:state.settings.copy,
    copyForm:state.settings.copyForm,
    productList:state.productList,
    productStocks:state.productStocks,
    imageList: state.imageList,
})

const mapDispatchToProps  =({getProductDetails,getModelList,updateProduct,createProduct,createProductStock,deleteProduct,getMaterialsList,copyProductSpecs,getProductList,getProductStockList,deleteProductStock,getImageList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductDetail)));
