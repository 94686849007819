import React from 'react';
import { View, StyleSheet,Image,Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const BackGround = ''
const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 67,
        borderBottomWidth: 0.5,
        width:550,
    },
    ProductImages: {
        width: 72,
        height: 60,
        marginLeft: '0',
        backgroundColor: BackGround,
    },
    ProductContainer: {
        flexDirection: 'column',
        width: 400,
        height: 60,
        marginLeft: '2',
        backgroundColor: BackGround,
    },
    PriceContainer: {
        flexDirection: 'column',
        width: 60,
        height: 60,
        marginLeft: '2',
        backgroundColor: BackGround,
    }
  });
  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  height :60px;
  width:60px;
  `;
  const ProductName = styled.Text`
  paddingLeft:5px;
  margin-top: 2px;
  font-size: 10px;
  text-align: left;
  `;
  const ProductCode = styled.Text`
  paddingLeft:5px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
  const ProductDetails = styled.Text`
  paddingLeft:5px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `; 

  const Price = styled.Text`
  margin-left: 2px;
  paddingTop:20px;
  paddingRight:5px;
  font-size: 10px;
  text-align: right;
  height :60px;
  width:70px;
  `;

const OfferTableRow = ({data,oran,Lang} ) => {
    const item = data;
    
    let img = "../assets/images/ry.png" ;
    if(item.productImages ){
        img = item.productImages;
    }else{
        img = "../assets/images/ry.png" ;
    }
  
    const ModelKodu = item.urun_model.model_kodu ? item.urun_model.model_kodu  : ""
    const PCBKodu = item.urun_kodu ? ("-"+item.urun_kodu):""
    const Optik = item.urun_optik ? ("-"+item.urun_optik):""
    const CCT  = item.urun_cct ? ("-"+item.urun_cct):""
    const Solar  = item.urun_panel ? ("-"+item.urun_panel):""
    const Battery  = item.urun_aku ? ("-"+item.urun_aku):""    
    //console.log(item,oran)
    return(

        <View style={styles.rowContainer} wrap={false} >
            
            {img ? 
            
            <Link src={"https://moduledv2.akaysis.com/web/"+item._id+"/"+Lang}><Image style={styles.ProductImages} src={ img}/></Link>
            : <Link src={"https://moduledv2.akaysis.com/web/"+item._id+"/"+Lang}><NoImage> X </NoImage></Link>}

            <View style={styles.ProductContainer}>
       
                    <ProductName style={styles.ProductName}>
                            {item.urun_adi[Lang]+" "}
                    </ProductName>
                    <ProductCode style={styles.ProductCode}>
                            {item.urun_lumen + (Lang === 0 ? " lm  Ürün Kodu ("  : " lm Product Code (" )
                            +ModelKodu+PCBKodu+Optik+CCT+Solar+Battery+")"}
                    </ProductCode>
        

                    <ProductDetails style={styles.ProductName}><Link src={"https://moduledv2.akaysis.com/web/"+item._id+"/"+Lang}>{Lang === 0 ? "Detaylar için Tıklayınız" : "Click for Detail"}</Link> </ProductDetails>
                    <ProductDetails style={styles.ProductName}><Link src={"https://moduledv2.akaysis.com/pdf/datasheet/"+item._id+"/"+Lang}>{Lang === 0 ? "Türkçe Teknik Dosya için Tıklayınız" : "Click for Datasheet"}</Link> </ProductDetails>
                    
    
                    
            </View >
            <View style={styles.PriceContainer}>
                <Price style={styles.ProductName}>{(parseFloat(item.urun_fiyat+ ((item.urun_fiyat/(100-oran))*oran))).toFixed(2) +" "+item.urun_doviz}</Price>
            </View >
            
        </View>

  
)};
  
export default OfferTableRow