import React, { Component } from 'react'
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {device_list_request} from '../../actions';

class GatewayList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            deviceList:[]
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
 
            this.props.device_list_request();
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.deviceList.data !== nextProps.deviceList.data) {
            this.setState({
                deviceList: nextProps.deviceList.data
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    onClickDetail=(id)=>{
        this.props.history.push({pathname : `/devicedetails/`+id})
    }
//******************************************************************************************************************************************************************/
    render() {
        //const { messages } = this.props.intl;
        const {deviceList} = this.state


        return (
            <>
            <div  className={this.props.deviceList.loading ? "dimmer active" : ""}>
                {this.props.deviceList.loading && <div className="loader"/>}
                <div className={this.props.deviceList.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th><IntlMessages id="akaysis.macaddress"/></th>
                                    <th><IntlMessages id="akaysis.devicename"/></th>
                                    <th><IntlMessages id="akaysis.devicecity"/></th>
                                    <th><IntlMessages id="akaysis.status"/></th>
                                    <th className="w40"></th>
                                </tr>
                            </thead>
                                <tbody>
                                    {deviceList &&  deviceList.length  ? deviceList.map((row,i)=>{
                                        return (
                                            <tr key={i}>
                                                <td><a href="#1" onClick={()=>this.onClickDetail(row._id)}>{row.mac_addr}</a></td>
                                                <td><span>{row.device_name}</span></td>
                                                <td><span>{row.city}</span></td>
                                                <td className="text-center"><span className={row.device_status === "Inactive" ? "tag tag-red": "tag tag-success"}>{row.device_status}</span></td>
                                                <td>
                                                    <div className="item-action dropdown">
                                                        <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)'}}>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                    )}):null}
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    deviceList: state.deviceList

})

const mapDispatchToProps = ({device_list_request})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GatewayList)));
