import {
	AK_USER_LOGIN_ACTION,
	AK_USER_LOGIN_SUCCESS,
    AK_USER_LOGIN_FAILURE, 
	AK_USER_LOGOUT_ACTION, 
	AK_USER_REAUTH_ACTION,
	AK_USER_REAUTH_SUCCESS,
	AK_USER_REAUTH_FAILURE
} from '../actions';
import axios from 'axios';

export const user_login_set_pending = (email) => ({
	type: AK_USER_LOGIN_ACTION,
	email: email,
})

export const user_reauth_set_pending = (token) => ({
	type: AK_USER_REAUTH_ACTION,
	token: token
})

export const user_login_set_successful = (email, res) => ({
	type: AK_USER_LOGIN_SUCCESS,
	email: email,
	response: res
})

export const user_login_set_failed = (email, res = {}) => ({
	type: AK_USER_LOGIN_FAILURE,
	email: email,
	response: res
})

export const user_logout_request = () => ({
	type: AK_USER_LOGOUT_ACTION
})

export const user_reauth_set_successful = (token, res) => ({
	type: AK_USER_REAUTH_SUCCESS,
	token: token,
	response: res
})

export const user_reauth_set_failure = () => ({
	type: AK_USER_REAUTH_FAILURE
})

export const user_login_request = ({email,password},history) => dispatch => {
    
	dispatch(user_login_set_pending(email))
	return new Promise((resolve, reject) => {
		axios.post("https://api.akaysis.com/auth/login", {
			email: email,
			password: password
		})

		.then(res => {
		console.log(res);
			if (res.status === 200) {
		
				if(res.data.status && res.data.status === "successful"){
					dispatch(user_login_set_successful( email, res));
					history.push('/gatewaylist');
				} else if(res.data.status && res.data.status === "failed"){
					dispatch(user_login_set_failed(email, res));
				}

			} 
		})
		.catch((err) => {
			// dispatch(error({title: "Error Occured", message:err.message}))
			dispatch(user_login_set_failed(email, {data: {explanation: err.message}}));
			console.log(JSON.stringify(err));
		});
	});
}
export const akaysis_user_reauth_request = (token) => dispatch => {
	dispatch(user_reauth_set_pending(token));
	axios.get(
		'https://api.akaysis.com/auth/verifytoken/', 
		{
			headers: {Authorization: 'Bearer ' + token
		}
	})
		.then(res => {
			if (res.status === 200) {
				dispatch(user_reauth_set_successful(token, res.data));
			}
			else if (res.status === 401) {
				dispatch(user_reauth_set_failure(res));
			}
		})
		.catch((err) => {
			dispatch(user_reauth_set_failure());
			//dispatch(error({title: "Re-Auth Failed", message: "The authorization token your browser has is not valid anymore. This problem usually happens when user logs in from another device. Just try to log-in again.", autoDismiss: 10}));
		});
}
export const logout_request = () => dispatch => {
	dispatch(user_logout_request());

}