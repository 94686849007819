import React, { Component } from 'react'
import { connect } from 'react-redux';
import {getProductDetails,getModelDetails,getCompany,changeLocale} from '../../actions';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import GlideComponentThumbs from "../common/GlideComponentThumbs";
import IntlMessages from "../../helpers/IntlMessages";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";

class WebProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedPageSize: 8,
          pageSizes: [6, 12, 24, 96],
          sortColumn:"model_category",
          currentPage: 1,
          totalItemCount: 0,
          totalPage: 1,
          search: "",
          filter:"",
          filterField:"",
          orderOptions: [
            { column: "model_category", label:"product.orderbyCategory"},
            { column: "urun_guc", label:"product.orderbyPower"},
            { column: "urun_fiyat", label:"product.orderbyPrice"}
          ],
          categoryList : [
            {value : 0 , label : "model.kategori"+0},
            {value : 1 , label : "model.kategori"+1},
            {value : 2 , label : "model.kategori"+2},
            {value : 3 , label : "model.kategori"+3},
            {value : 4 , label : "model.kategori"+4},
            {value : 5 , label : "model.kategori"+5}
          ]
        };
      }
//******************************************************************************************************************************************************************/
    gotoProducts=()=>{
        this.props.history.push({pathname : `/web`})
    }
//******************************************************************************************************************************************************************/
    gotoDataSheet=()=>{
        
        window.open('/pdf/datasheet/'+this.props.match.params.id+"/"+this.props.match.params.lang,"_blank");
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        const locale = this.props.match.params.lang=== '0' ?"tr":"en";
        this.props.changeLocale(locale);

        this.props.getProductDetails({id:this.props.match.params.id})
        !this.props.company.data.certificates && this.props.getCompany({id:1},this.props.match.params);
      
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.productDetail.data !== nextProps.productDetail.data) {
            this.setState({
                product: nextProps.productDetail.data,
            }); 
        };    
    }
//******************************************************************************************************************************************************************/

    render() {
        const { locale } = this.props;
        const { messages } = this.props.intl;
        const {product} = this.state
        const modelResim =[];
        const productImages  = product && product.productImages 
        const ThecnicalDrawing =  productImages &&  productImages.length && productImages.filter(x=>x.description ==="Teknik Resim").length ? (productImages.filter(x=>x.description ==="Teknik Resim"))[0].image.image :"/assets/images/TRH.png"
        if(product  && product.productImages){
            for (let x of product.productImages) {
                x.description!=="Teknik Resim" &&  modelResim.push(x.image);
            }
        }   
        const productCertificates =  product && product.urun_model &&  product.urun_model.certificates;
        const {certificates}  = this.props.company.data
        const Lang = locale==="tr" ? 0:1 
        const shareUrl = product &&  product._id &&  "https://moduledv2.akaysis.com/web/"+product._id+"/"+Lang
        return (
            <>
                <div  className={this.props.productDetail.loading ? "dimmer active" : ""}>
                {this.props.productDetail.loading && <div className="loader"/>}
                <div className={this.props.productDetail.loading ? "dimmer-content"  : "section-body"}>
                    <div className="container-fluid mt-1">
                        {product && 
                        <div className="row clearfix row-deck">
                            <div className="col-12 col-sm-12 col-md-3">
                                <div className="card">
                                <div className="card-header">
                                    <IntlMessages id="product.images" />
                                </div>
                                <hr/>
                                {modelResim && modelResim.length>0   &&                   
                                    <GlideComponentThumbs settingsImages={
                                        {
                                            bound: true,
                                            rewind: false,
                                            focusAt: 0,
                                            startAt: 0,
                                            gap: 5,
                                            perView: 1,
                                            data: modelResim,
                                        }
                                        } settingsThumbs={
                                        {
                                            bound: true,
                                            rewind: false,
                                            focusAt: 0,
                                            startAt: 0,
                                            gap: 2,
                                            perView: 3,
                                            data: modelResim ,
                                            breakpoints: {
                                            500: {
                                                perView: 4
                                            },
                                            400: {
                                                perView: 3
                                            }
                                            }
                                        }
                                        } />}
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <IntlMessages id="product.detail" />
                                    </div>
                                    <hr/>
                                    <div className="card-body row">
                                        <div className="col-5 py-1"><strong><IntlMessages id="product.name" /></strong></div>
                                        <div className="col-7 py-1">{product.urun_adi[Lang]}</div>
                                        <div className="col-5 py-1"><strong><IntlMessages id="product.category" /></strong></div>
                                        <div className="col-7 py-1">{messages["model.kategori"+product.model_category]}</div>
                                        <div className="col-5 py-1"><strong><IntlMessages id="product.code" /></strong></div>
                                        {product.model_category!==7 ? <div className="col-7 py-1"><strong>{product.urun_model.model_kodu + "-"+product.urun_kodu+ "-" + product.urun_optik+"-"+product.urun_cct+ (product.urun_aku && product.urun_panel ? "-"+product.urun_panel+"-"+product.urun_aku : "")}</strong></div>
                                        :
                                        <div className="col-7 py-1"><strong>{product.urun_model.model_kodu + "-"+product.urun_kodu }</strong></div>
                                        }
                                        {product.model_category!==7  && <div className="col-5 py-1"><strong><IntlMessages id="product.powerLumen" /></strong></div>}
                                        {product.model_category!==7  && <div className="col-7 py-1"><strong>{product.urun_guc+" W  / "+ product.urun_lumen+ " lm"}</strong></div>}
                                        {product.model_category===3  && <div className="col-5 py-1"><strong><IntlMessages id="product.battery" /></strong></div>}
                                        {product.model_category===3  && <div className="col-7 py-1"><strong>{product.urun_aku+ " Ah"}</strong></div>}
                                        {product.model_category===3  && <div className="col-5 py-1"><strong><IntlMessages id="product.solarpower" /></strong></div>}
                                        {product.model_category===3  && <div className="col-7 py-1"><strong>{product.urun_panel+ " Wp"}</strong></div>}
                                        <div className="col-5 py-1"><strong><IntlMessages id="product.dimensions" /></strong></div>
                                        <div className="col-7 py-1"><strong>{product.product_dimensions}</strong></div>
                                        <div className="col-5 py-1"><strong><IntlMessages id="product.weight" /></strong></div>
                                        <div className="col-7 py-1"><strong>{product.product_weight + " Kg"}</strong></div>                                        
                                        {product.urun_fiyat && <div className="col-5 py-1"><strong><IntlMessages id="product.USDprice" /></strong></div>}
                                        {product.urun_doviz && <div className="col-7 py-1"><strong>{product.urun_fiyat}  {product.urun_doviz}</strong></div>}
                                        {productCertificates  &&  productCertificates.map((cer,keyIndex)=>{
                                            return(
                                                <div className="media" key={keyIndex}>

                                                    
                                                    {cer.label ? 
                                                    <img
                                                        className="rounded w40 mr-1"
                                                        src={certificates ? certificates.filter(x=>x.certificate===cer.label)[0].file : "../assets/images/ry.png"}
                                                        alt="moduled"
                                                    />
                                                    :
                                                    <img
                                                        className="rounded w40 mr-1"
                                                        src={certificates ? certificates.filter(x=>x.certificate===cer)[0].file : "../assets/images/ry.png"}
                                                        alt="moduled"
                                                    />
                                                    }
                                                </div>
                                            )
                                        })}

                                    </div>
                               
                                    <div  className="card-footer text-right"> 
                                        <a href="#1" className="btn btn-outline-success" ><IntlMessages id="button.requestoffer"/></a>{" "}
                                        <a href="#1" className="btn btn-outline-info" onClick={()=>this.gotoDataSheet()}><IntlMessages id="button.datasheet"/></a>{" "}
                                        <a href="#1" className="btn btn-outline-warning" onClick={()=>this.gotoProducts()}>
                                            <IntlMessages id="button.productlist" />
                                        </a>
                                    </div>
                                 

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-3">

                                <div className="card">
                                    <div className="card-header">
                                        <IntlMessages id="product.tecnicaldrawing" />
                                    </div>
                                    <hr/>
                                    <div className="card-body">
                                        <div className="col-12 col-sm-12 col-md-12">
                                            <img src={ThecnicalDrawing} alt="moduled" className="rounded" />

                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <div className="row">
                                            <div className="ml-2">
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={product.urun_adi[Lang]}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                                <FacebookShareCount url={shareUrl}>
                                                    {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}
                                                </FacebookShareCount>
                                            </div>
                                            <div className="ml-2">
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={product.urun_adi[Lang]}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>
                                            </div>
                                            <div className="ml-2">
                                                <WhatsappShareButton
                                                    url={shareUrl}
                                                    title={product.urun_adi[Lang]}
                                                    separator=":: "
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <WhatsappIcon size={32} round />
                                                </WhatsappShareButton>
                                            </div>
                                            {/* <div className="ml-2">
                                                <TelegramShareButton
                                                    url={shareUrl}
                                                    title={product.urun_adi[Lang]}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TelegramIcon size={32} round />
                                                </TelegramShareButton>
                                            </div> */}
                                            <div className="ml-2">
                                                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                                                    <LinkedinIcon size={32} round />
                                                </LinkedinShareButton>
                                            </div>
                                            <div className="ml-2">
                                                <EmailShareButton
                                                    url={shareUrl}
                                                    subject={product.urun_adi[Lang]}
                                                    body={product.urun_adi[Lang]+" Link :"}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <EmailIcon size={32} round />
                                                </EmailShareButton>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            
                            </div>
                        </div>}
                    </div>
                </div>

                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#general-specs"><IntlMessages id="product.generalspecs"/></a></li>
                                <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-specs"><IntlMessages id="product.electricalspecs"/></a></li>
                                <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-images"><IntlMessages id="product.images"/></a></li>
                                <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#product-downloads"><IntlMessages id="product.downloads"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="section-body mt-1">
                        {product && product.urun_adi && 
                        <div className="container-fluid">
                            <div className="tab-content taskboard">
                                <div className="tab-pane fade show active" id="general-specs" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w40">#</th>
                                                            <th><IntlMessages id="model.label"/></th>
                                                            <th><IntlMessages id="model.feature"/></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product && product.urun_model && product.urun_model.ModelFeatures && product.urun_model.ModelFeatures.length ?
                                                            product.urun_model.ModelFeatures.map((featuresRow,specKeyIndex)=>{
                                                                return(
                                                                    <tr key={specKeyIndex}>
                                                                        <td>
                                                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{specKeyIndex+1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{featuresRow.label[0]}</h6>
                                                                            <span className="text-blue">{featuresRow.label[1]}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="mb-0">{featuresRow.feature[0]}</h6>
                                                                            <span className="text-blue">{featuresRow.feature[1]}</span>
                                                                        </td>
                                                                    </tr>
                                                                )})
                                                                :null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-specs" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w40">#</th>
                                                            <th><IntlMessages id="product.label"/></th>
                                                            <th><IntlMessages id="product.feature"/></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {product && product.urun_ozellikler && product.urun_ozellikler.length ?
                                                            product.urun_ozellikler.map((featuresRow,KeyIndex)=>{
                                                                return(
                                                                    <tr key={KeyIndex}>
                                                                        <td>
                                                                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{KeyIndex+1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <h6><span className="tag tag-primary">{featuresRow.urun_ozellik[0]}</span></h6>
                                                                            <h6><span className="tag tag-info">{featuresRow.urun_ozellik[1]}</span></h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{featuresRow.urun_aciklama && featuresRow.urun_aciklama.map((x,s)=>{return(<span key = {s} className="tag tag-primary ml-1">{x} </span>)})}</h6>
                                                                            <h6>{featuresRow.product_description && featuresRow.product_description.map((x,y)=>{return(<span key = {y} className="tag tag-info ml-1">{x}</span>)})}</h6>
                                                                        </td>
                                                                    </tr>
                                                                )})
                                                                :null
                                                            }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="product-images" role="tabpanel">

                                </div> 
                                <div className="tab-pane fade show" id="product-downloads" role="tabpanel">
                                    <div className="row row-deck">
                                        {product && product.productDownloads && product.productDownloads.length &&
                                            product.productDownloads.map((downloadsRow,downloadKey)=>{
                                                return(                                  
                                                    <div className="col-sm-6 col-lg-4" key={downloadKey}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "image" &&  <img src={downloadsRow.base64} alt="moduled" className="rounded" />}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "application" && <iframe title={downloadsRow.file}src={downloadsRow.base64} width="100%" height="100%"/>}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "application" &&  downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "image" && <img src="../assets/images/ry.png" alt="moduled" className="rounded" />}
                                                            </div>
                                                            <div className="card-footer">
                                                                <div>{downloadsRow.name}</div>
                                                                <small className="d-block text-muted mb-1">{downloadsRow.size}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {product && product.urun_model && product.urun_model.modelDownloads &&  product.urun_model.modelDownloads.length &&
                                            product.urun_model.modelDownloads.map((downloadsRow,downloadKey)=>{
                                                return(                                  
                                                    <div className="col-sm-6 col-lg-4" key={downloadKey}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "image" &&  <img src={downloadsRow.base64} alt="moduled" className="rounded" />}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  === "application" && <iframe title={downloadsRow.file} src={downloadsRow.base64} width="100%" height="100%"/>}
                                                                    {downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "application" &&  downloadsRow.type.substring(0,downloadsRow.type.indexOf('/'))  !== "image" && <img src="../assets/images/ry.png" alt="moduled" className="rounded" />}
                                                            </div>
                                                            <div className="card-footer">
                                                                <div>{downloadsRow.name}</div>
                                                                <small className="d-block text-muted mb-1">{downloadsRow.size}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> 
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    productDetail:state.productDetail,
    modelDetail:state.modelDetail,
    locale:state.settings.locale,
    tcmb:state.tcmb.tcmb,
    company:state.myCompany,
})

const mapDispatchToProps = ({getProductDetails,getModelDetails,getCompany,changeLocale})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(WebProduct)));
