import React, { Component } from 'react'
import { connect } from 'react-redux';
// import Pagination from "../common/Pagination";
import moment from "moment";
import { store } from 'react-notifications-component';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {getOrderItemList,updateOrderItem,setOrderItemFilter,setOrderItemCount,getProductList,getModelList,createOrderItem,deleteOfferItem,createDelivery} from '../../actions';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NumberFormat from 'react-number-format';
import FileBase64 from '../../helpers/FileBase64';



class Operations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [6, 12, 18, 30, 50, 100],
            selectedOrderOption: { column: "siparis_tarihi", label: <IntlMessages id="order.orderbyDate" /> },
            sortColumn:"siparis_tarihi",
            orderItemFilter:this.props.orderItemFilter ||  0,
            deliveryModal:false,
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            search: "",
            offeritem:{
                urun_resim:"",
                ready:false,
                urun_adi: "",
                urun_kodu: "",
                urun_kdv:18,
                urun_miktari: 1.00,
                urun_birimi:"Adet",
                urun_fiyati: 0.00,
                urun_dovizi : "$",
                toplamTL:0.00,
                toplamDoviz:0.00,
                toplamKDVTL:0.00,
                toplamKDVDoviz:0.00
            },
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            orderOptions: [
                { column: "urun_adi", label:"order.orderbyName"},
                { column: "siparis_tarihi", label:"order.orderbyDate"},
                //{ column: "siparis_durumu", label:"order.orderbyStatus" }
              ],
            OrderStatus: [
                {value : 1 , label:"order.orderStatus1" },
                {value : 2 , label:"order.orderStatus2" },
                {value : 3 , label:"order.orderStatus3" },
                {value : 4 , label:"order.orderStatus4" },
                {value : 6 , label:"order.orderStatus6" },

            ],
            CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
            ],
            KDVList : [
                { value: '18', label: '18' },
                { value: '8', label: '8' },
                { value: '0', label: '0' }
            ] ,
            DeliveryErrors:{
                company_id: "" ,
                qty: "",
                order_id:""
            },
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        if(this.state.orderItemFilter==="Tümü"){
            this.props.getOrderItemList("list",this.state.sortColumn,1,4,);
        }else{
            this.props.getOrderItemList("list",this.state.sortColumn,this.state.orderItemFilter,this.state.orderItemFilter,);
        }   
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.orderItemList.data !== nextProps.orderItemList.data) {
            let data = nextProps.orderItemList.data;
            const {sortColumn} = this.state
            sortColumn==="urun_adi" ? data  && data.sort((a, b) => a.urun_adi.localeCompare(b.urun_adi)) : 
            sortColumn==="siparis_tarihi" ?  data  && data.sort((a,b)=> new Date(a.siparis_tarihi)-new Date(b.siparis_tarihi)) :
            data  && (data.sort((a, b) => (a[sortColumn] > b[sortColumn]) ? -1 : 1 ));
            let boxClose = [];
            let boxOpen = [];
            data && data.forEach(element => {
                boxClose.push(false);
                boxOpen.push(true);
            });
            data && data.length  && this.props.setOrderItemCount(data.length)

            this.setState({
                orderItemList: data,
                boxClose:boxClose,
                boxOpen:boxOpen,
                totalItemCount:data.length 
            }); 
        };  
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList:nextProps.productList.data,
            }); 
        };   
        if (this.props.modelList.data !== nextProps.modelList.data) {
            this.setState({
                modelList:nextProps.modelList.data,
            }); 
        };   
        if (this.props.tcmb !== nextProps.tcmb) {
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  
                offer.teklif_usdkur =  nextProps.tcmb.Data[0].ForexSelling;
                offer.teklif_eurkur =  nextProps.tcmb.Data[1].ForexSelling;                       
                return { offer };    
            });
        }; 
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
            sortColumn:column
            },()=>this.props.getOrderItemList("list",this.state.sortColumn,(this.state.orderItemFilter==="Tümü" ? 1 : this.state.orderItemFilter) ,(this.state.orderItemFilter==="Tümü" ? 4 : this.state.orderItemFilter) ));
     };
//******************************************************************************************************************************************************************/
    changePageSize = size => {
        this.setState({
            selectedPageSize: size,
            currentPage: 1,
            totalPage: Math.ceil(this.state.totalItemCount/size)
        });
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        if (f!=="Tümü"){
            this.props.getOrderItemList("list",this.state.sortColumn,f,f);
        } else {
            this.props.getOrderItemList("list",this.state.sortColumn,1,4);
        }
        this.props.setOrderItemFilter(f);
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.orderItemList.data.filter(
            item => {return item.urun_adi.toLowerCase().includes(search.target.value.toLowerCase()) || (item.teklifno && item.teklifno.firma_adi  && item.teklifno.firma_adi.toLowerCase().includes(search.target.value.toLowerCase())) })
        if (search && search.target.value===""){
          this.setState({
            orderItemList:this.props.orderItemList.data,
          })
        }else{
          this.setState({
            orderItemList:filteredList,
          })
        }
    };
//******************************************************************************************************************************************************************/
    onSearchProductKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.productList.data.filter(item => {return item.urun_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.urun_model.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
            productList:this.props.productList.data,
            totalItemCount:this.props.productList.data &&  this.props.productList.data.length ? this.props.productList.data.length:0,
            totalPage: Math.ceil((this.props.productList.data &&  this.props.productList.data.length  ? this.props.productList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
            productList:filteredList,
            totalItemCount:this.state.productList &&  this.state.productList.length ? this.state.productList.length:0,
            totalPage: Math.ceil((this.state.productList &&  this.state.productList.length  ? this.state.productList.length : 0) /this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    onBoxClose=(rowIndex)=>{
        this.setState(prevState => {
            let boxClose = Object.assign({}, prevState.boxClose);  // creating copy of state variable jasper
            boxClose[rowIndex] = !this.state.boxClose[rowIndex]
            return { boxClose };    
        });
    }
//******************************************************************************************************************************************************************/
    onBoxOpen=(rowIndex)=>{
        this.setState(prevState => {
            let boxOpen = Object.assign({}, prevState.boxOpen);  // creating copy of state variable jasper
            boxOpen[rowIndex] = !this.state.boxOpen[rowIndex]
            return { boxOpen };    
        });
    }
//******************************************************************************************************************************************************************/
    calculateRate=(item)=>{
        let bitisTarihi = item.bitis_tarihi;
        let baslamaTarihi = item.baslama_tarihi;
        let siparisDurumu = item.siparis_durumu;
        let Bolen = item.Operations.length;
        let SonucOran = (item.Operations.reduce((acc, curr) => acc + parseInt(curr.OperationPercent, 10), 0))/Bolen;
        if(SonucOran===100) {
            bitisTarihi = new Date();
            siparisDurumu = 4;
        }else{
            bitisTarihi = "";

            if(SonucOran>80){
                siparisDurumu = 3
            }else{
                siparisDurumu = 2
            }
        }
        if(!baslamaTarihi) {
            baslamaTarihi = new Date();
        }
        this.setState((prevState) => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.bitis_tarihi= bitisTarihi
            orderItem.tamamlanmaOrani= SonucOran
            orderItem.baslama_tarihi= baslamaTarihi
            orderItem.siparis_durumu=siparisDurumu
            return {orderItem};   
        },()=>this.SaveOrderItem()); //,
    };
//***********************************************************************************************************************************************************/
    SaveOrderItem = (itemIndex) => {
        const { messages } = this.props.intl;
        this.props.updateOrderItem({...this.state.orderItem})
        .then(res => {
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.satirguncellendi"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                //onScreen: true
                }
            });        
        })
        .catch(err=>{
            if(err){
                store.addNotification({
                    title: this.props.orderItemDetail.status.statusText,
                    message: this.props.orderItemDetail.status.data,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                    duration: 3000,
                    //onScreen: true
                    }
                });
            }
        })
    }
//******************************************************************************************************************************************************************/
    gotoDetail=(id)=>{
        this.props.history.push({pathname : `/orderproductiondetail/`+id})
    }
//***************************************************************NEW PROODUCTION ROW ******************************************************************************/
    openNewStockModal = () => {
        this.props.getProductList("list","model_category");
        this.props.getModelList("detail","model_kategori");
        this.setState({
            offerRowModal: true
        });
    };
//******************************************************************************************************************************************************************/
    handleChangeProductCode = (val,sno) => evt => {
        let PrdouctCodeList = [];
        PrdouctCodeList.push(sno===0 ?evt.target.value :val[0]);
        PrdouctCodeList.push(sno===1 ?evt.target.value :val[1]);
        PrdouctCodeList.push(sno===2 ?evt.target.value :val[2]);
        PrdouctCodeList.push(sno===3 ?evt.target.value :val[3]);
        PrdouctCodeList.push(sno===4 ?evt.target.value :val[4]);
        PrdouctCodeList.push(sno===5 ?evt.target.value :val[5]);
        PrdouctCodeList.push(sno===6 ?evt.target.value :val[6]); 
        PrdouctCodeList.push(sno===7 ?evt.target.value :val[7]);
        PrdouctCodeList.push(sno===8 ?evt.target.value :val[8]);

        this.setState(prevState => {
            let offeritem = Object.assign({}, prevState.offeritem);  // creating copy of state variable jasper
            offeritem.urun_kodu = PrdouctCodeList;
            return { offeritem };    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeItemRow = (field) => evt => {
        let value = "";
        if (field==="urun_miktari" || field==="urun_fiyati"){
            value = evt.floatValue
        }else{
            value = evt.target.value
        }

        this.setState((prevState) => {
            let offeritem = Object.assign({}, prevState.offeritem);  // creating copy of state variable 
            offeritem[field]= value;
            return { offeritem};  
        },()=>this.handleChangeOfferCurrency());
    }
//******************************************************************************************************************************************************************/
    handleChangeOfferCurrency=()=>{

        let GtoplamTL = 0;
        let GtoplamDoviz = 0;
        let toplamKdvTL = 0;
        let toplamKdvDoviz = 0;
        const {offeritem} = this.state;
        const USDSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[0].ForexSelling  : 0 ;
        const EURSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[1].ForexSelling  : 0 ;

        let TL = 0 ; 
        if (offeritem.urun_dovizi === "$"){
            TL =offeritem.urun_fiyati * offeritem.urun_miktari * USDSelling;
        }else if (offeritem.urun_dovizi === "€"){
            TL = offeritem.urun_fiyati * offeritem.urun_miktari * EURSelling;
        }else{
            TL = (offeritem.urun_fiyati * offeritem.urun_miktari);
        } 
        let DOVIZ = 0 ; 
        if (offeritem.urun_dovizi === "$"){
            DOVIZ = offeritem.urun_fiyati * offeritem.urun_miktari;
        }else if (offeritem.urun_dovizi === "€"){
            DOVIZ = offeritem.urun_fiyati * offeritem.urun_miktari ;
        }else{
            DOVIZ = (offeritem.urun_fiyati * offeritem.urun_miktari) 
        }

        GtoplamTL = parseFloat(TL);
        GtoplamDoviz = parseFloat(DOVIZ) ;
        toplamKdvTL =  parseFloat((GtoplamTL/100)*offeritem.urun_kdv);
        toplamKdvDoviz = parseFloat((GtoplamDoviz/100)*offeritem.urun_kdv);

        this.setState((prevState) => {
            let offeritem = Object.assign({}, prevState.offeritem);  // creating copy of state variable 
            offeritem.toplamTL= GtoplamTL;
            offeritem.toplamDoviz= GtoplamDoviz;
            offeritem.toplamKdvTL= toplamKdvTL;
            offeritem.toplamKdvDoviz= toplamKdvDoviz;
            return { offeritem};  
        });

    }
//******************************************************************************************************************************************************************/
    toggleStockModal=()=>{
        this.setState({
            offerRowModal: !this.state.offerRowModal
        });
    }
//******************************************************************************************************************************************************************/
    toggleProductListModal=()=>{
        this.setState({
            offerRowModal: !this.state.offerRowModal,
            productListModal: !this.state.productListModal
        });
    }
//******************************************************************************************************************************************************************/
    selectedProduct =(item)=>{
        const {locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const KDV  =  item.urun_model.model_kdv;
        const Birimi = item.urun_model.model_birimi;
        const Urun_Adi =  item.urun_adi[Lang];
        const PID = item._id;
        const Doviz = item.urun_doviz;
        const Price = item.urun_fiyat;

        let PrdouctCodeList = [];
        PrdouctCodeList.push(item.urun_model.model_kodu);
        PrdouctCodeList.push(item.urun_kodu);
        PrdouctCodeList.push(item.urun_optik);
        PrdouctCodeList.push(item.urun_cct);
        PrdouctCodeList.push(item.urun_panel);
        PrdouctCodeList.push(item.urun_aku);

        this.setState((prevState) => {
            let offeritem = Object.assign({}, prevState.offeritem);  // creating copy of state variable 
            offeritem.urun_resim=null;
            offeritem.urun_id=PID;
            offeritem.urun_adi= Urun_Adi;
            offeritem.urun_kodu= PrdouctCodeList;
            offeritem.urun_kdv= Number(KDV);
            offeritem.urun_birimi= Birimi;
            offeritem.urun_fiyati= Price;
            offeritem.urun_dovizi = Doviz;
            return { offeritem ,offerRowModal: !this.state.offerRowModal,productListModal:false};  
        },()=>this.handleChangeOfferCurrency());
    }
//******************************************************************************************************************************************************************/
    onSaveOrderItem=()=>{
        const { messages } = this.props.intl;
        this.props.createOrderItem(this.state.offeritem)
        .then(res=>{ 
            console.log("Success")
            this.setState({
                offerRowModal: !this.state.offerRowModal
            })
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.urunolusturuldu"],//{messages[x.label]} 
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });    
            if(this.state.orderItemFilter==="Tümü"){
                this.props.getOrderItemList("list",this.state.sortColumn,1,4,);
            }else{
                this.props.getOrderItemList("list",this.state.sortColumn,this.state.orderItemFilter,this.state.orderItemFilter,);
            }
        })
    }
//******************************************************************************************************************************************************************/
    onTransfertoStock=(index)=>{
        const { messages } = this.props.intl;
        const {orderItemList} = this.state;
        const order = orderItemList[index];
        order.teklifno = null
        this.props.updateOrderItem(order)
        .then(res=>{ 
            console.log("Success")
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.urunolusturuldu"],//{messages[x.label]} 
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });    
            if(this.state.orderItemFilter==="Tümü"){
                this.props.getOrderItemList("list",this.state.sortColumn,1,4,);
            }else{
                this.props.getOrderItemList("list",this.state.sortColumn,this.state.orderItemFilter,this.state.orderItemFilter,);
            }
        })
    }
//******************************************************************************************************************************************************************/
    onDeleteOfferItem=(id)=>{
        if (window.confirm("Üretimi Silmek istiyor musunuz ?")) {
            this.props.deleteOfferItem(id)
            .then(res=>{
                if(this.state.orderItemFilter==="Tümü"){
                    this.props.getOrderItemList("list",this.state.sortColumn,1,4,);
                }else{
                    this.props.getOrderItemList("list",this.state.sortColumn,this.state.orderItemFilter,this.state.orderItemFilter,);
                }
            })
        }
    }
//******************************************************************************************************************************************************************/
    onClickDetailItem=(id)=>{
        this.props.history.push({pathname : `/orderitemdetail/`+id})
    }
//******************************************************************************************************************************************************************/
    onChangeDeliveryData=(field,data)=>{
        this.setState(prevState => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery[field] = data;   
            delivery.sumPrice = field==="qty" ? Number(data) * this.state.delivery.price :this.state.delivery.sumPrice ;              
            return {delivery};    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeDateLabelOver = () => date => {
        this.setState((prevState) => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery.date = date;               
            return { delivery };  
        });
    };
//******************************************************************************************************************************************************************/
    onOpenDeliveryModal=(index)=>{
        const {orderItemList} = this.state;
        const order = orderItemList[index];
        const teslimedilmis = order.deliveried_id.reduce((acc, curr) => acc + parseInt(curr.qty, 10), 0)
        
        if(order.teklifno  && order.teklifno.firma_id){
            this.setState((prevState) => {
                let delivery = Object.assign({}, prevState.delivery);  
                delivery.date = new Date();  
                delivery.company_id = order.teklifno.firma_id ;   
                delivery.company = order.teklifno.firma_adi;   
                delivery.product_id = order.urun_id && order.urun_id._id ? order.urun_id._id : null;    
                delivery.productname = order.urun_adi;
                delivery.order_id = order.teklifno._id; 
                delivery.orderitem_id = order._id ;
                delivery.qty = order.urun_miktari - teslimedilmis;
                delivery.unit = order.urun_birimi ;
                delivery.description = "" ;
                delivery.price = order.urun_fiyati ;
                delivery.doviz = order.urun_dovizi ;
                delivery.sumPrice = order.urun_fiyati *  (order.urun_miktari - teslimedilmis);
                delivery.status = 0;
                delivery.deliverer = this.props.auth.user;
                delivery.receiver = "";
                delivery.deliveried_id = order.deliveried_id;
                delivery.max=order.urun_miktari - teslimedilmis;
                delivery.orderQty=order.urun_miktari ;
                delivery.totaldeliveried=teslimedilmis ;
                return { delivery, deliveryModal:!this.state.deliveryModal };  
    
            });
        }else{
            window.confirm("Firma Kayıtlı Değil Devam edemezsiniz..") 
        }
    }
//******************************************************************************************************************************************************************/
    gotoDetail=(id)=>{
        this.props.history.push({pathname : `/orderproductiondetail/`+id})
    }

//******************************************************************************************************************************************************************/
    toggleDeliveryModal=()=>{
        this.setState({
            deliveryModal: !this.state.deliveryModal
        });     
    }
//******************************************************************************************************************************************************************/
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
//******************************************************************************************************************************************************************/
    onSaveDelivery=()=>{

        this.setState((prevState) => {
            let DeliveryErrors = Object.assign({}, prevState.DeliveryErrors);  // creating copy of state variable jasper         
            DeliveryErrors.qty= this.state.delivery.qty === 0 ? "Miktarı Yazınız" :""         
            DeliveryErrors.company_id=this.state.delivery.company_id === "" ? "Firma Tanımsız"  : ""  
            DeliveryErrors.order_id=  this.state.delivery.order_id === "" ? "Sipariş Tanımlanamadı" :""
        return { DeliveryErrors};  
        },()=>this.onSave());
    }
//******************************************************************************************************************************************************************/
    onSave = () =>{
        const isOk = this.validateForm(this.state.DeliveryErrors);
        const { messages } = this.props.intl;
        if (isOk){
            this.props.createDelivery(this.state.delivery)
            .then(res=>{
                this.setState({
                    deliveryModal: !this.state.deliveryModal
                }); 
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.stokhareketiolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });   
            })
            .catch(err=>{
                console.log(err.response)
            })
        }

    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {role} =this.props.auth;
        const {locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const {orderItemList,orderOptions,boxClose,boxOpen,offeritem,UnitList,CurrencyList,KDVList,productList,modelList,delivery,DeliveryErrors,totalItemCount} = this.state
        // const startIndex = (currentPage - 1) * selectedPageSize;
        // const endIndex = currentPage * selectedPageSize;
        const USDSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[0].ForexSelling  : 0 ;
        const EURSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[1].ForexSelling  : 0 ;
        const orderItemFilter=this.props.orderItemFilter;
        return (
            <>
            
            <div  className={this.props.orderItemList.loading ? "dimmer active" : ""}>
                {this.props.orderItemList.loading && <div className="loader"/>}
                <div className={this.props.orderItemList.loading ? "dimmer-content"  : ""}>

                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-md-flex justify-content-between  align-items-center ">
                                            <div className="form-group">
                                                <div className="selectgroup w-100">
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === "Tümü"} onChange={()=>this.onChangeFilter("siparis_durumu","Tümü")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="genel.all"/> <span className="badge badge-info badge-pill"> {this.props.orderItemList.totalItemCount}</span></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === 1} onChange={()=>this.onChangeFilter("siparis_durumu",1)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="order.orderStatus1"/> <span className="badge badge-info badge-pill">{this.props.orderItemList.status1}</span></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === 2} onChange={()=>this.onChangeFilter("siparis_durumu",2)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="order.orderStatus2"/> <span className="badge badge-info badge-pill">{this.props.orderItemList.status2}</span></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === 3} onChange={()=>this.onChangeFilter("siparis_durumu",3)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="order.orderStatus3"/> <span className="badge badge-info badge-pill">{this.props.orderItemList.status3}</span></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === 4} onChange={()=>this.onChangeFilter("siparis_durumu",4)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="order.orderStatus4"/> <span className="badge badge-info badge-pill">{this.props.orderItemList.status4}</span></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" defaultChecked= {orderItemFilter === 6} onChange={()=>this.onChangeFilter("siparis_durumu",6)}/>
                                                        <span className="selectgroup-button"><IntlMessages id="order.orderStatus6"/></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center sort_stat">
                                                <div className="d-flex">
                                                    <div className="ml-2">
                                                        <p className="mb-0 font-11">{messages["offer.offerTotal"].toUpperCase()}</p>
                                                        <h5 className="font-16 mb-0">XXXX</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex ml-3">
                                                    <div className="ml-2">
                                                        <p className="mb-0 font-11">{orderItemFilter !== "Tümü" && messages["order.orderStatus"+orderItemFilter].toUpperCase()}</p>
                                                        <h5 className="font-16 mb-0">{totalItemCount}</h5>
                                                    </div>
                                                </div>
                                                <select 
                                                        className="custom-select" 
                                                        onChange={(evt)=>this.changeOrderBy(evt.target.value)}
                                                    >
                                                        {orderOptions.map((x,y)=>{
                                                            return(
                                                                <option key={y}  value={x.column}>{messages[x.label]} </option>
                                                            )
                                                        })}
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" className="form-control search" placeholder="Search..."onKeyPress={e => this.onSearchKey(e)} />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary"><IntlMessages id="button.action"/></button>
                                                <button data-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle" />
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a className="dropdown-item" href={"/pdf/workorders"} target="_blank" rel="noopener noreferrer"><i className="fa fa-print mr-1" /><IntlMessages id="button.print"/></a>
                                                    <a className="dropdown-item" href="#1"  onClick={()=>this.openNewStockModal()}><i className="fa fa-plus mr-1" /><IntlMessages id="order.newStockProduction"/></a>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">

                        {orderItemList &&  orderItemList.length  && orderItemList.map((row,itemIndex)=>{
                            const ModelKodu = row.urun_kodu[0] ? row.urun_kodu[0] :""
                            const PCBKodu = row.urun_kodu[1] ? ("-"+row.urun_kodu[1]):""
                            const Optik = row.urun_kodu[2] ? ("-"+row.urun_kodu[2]):""
                            const CCT  = row.urun_kodu[3] ? ("-"+row.urun_kodu[3]):""
                            const Solar  = row.urun_kodu[4] ? ("-"+row.urun_kodu[4]):""
                            const Battery  = row.urun_kodu[5] ? ("-"+row.urun_kodu[5]):""    
                            let image = "../assets/images/ry.png" ;
                            if(row  && row.urun_id &&  row.urun_id.productImages && row.urun_id.productImages.length && row.urun_id.productImages[0].image  && row.urun_id.productImages[0].image.smallimage ){
                                image = row.urun_id.productImages[0].image.smallimage;
                            }else{
                                image = "../assets/images/ry.png" ;
                            }
                            return (
                            <div key={itemIndex}>
                                {!boxClose[itemIndex] ?
                                    <div className={`card  ${!boxOpen[itemIndex] ? 'card-collapsed' : ""}`}>
                                        <div className={row.ready ? "card-status card-status-left bg-success":"card-status card-status-left bg-danger"}/>
                                        <div className="card-header">
                                            <h6 className="card-title"><a href="#1" onClick={()=>this.gotoDetail(row._id)}>{(row.urun_adi ? row.urun_adi : null)+" "}<strong>({ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}) </strong>{row.urun_miktari+" "+(messages[ "genel."+row.urun_birimi])+" "}</a></h6>
                                            <div className="card-options">
                                                {role<3 && !row.teklifno && row.tamamlanmaOrani===0  &&<span className="ml-2 text-red" onClick={()=>this.onDeleteOfferItem(row._id)}><i className="dropdown-icon fa fa-trash" /></span>}
                                                <span className="card-options-collapse ml-2" data-toggle="card-collapse" onClick={()=>this.onBoxOpen(itemIndex)}><i className="fe fe-chevron-up" /></span>
                                                <span className="card-options-remove  ml-2" data-toggle="card-remove" onClick={()=>this.onBoxClose(itemIndex)}><i className="fe fe-x" /></span>
                                                <span className="ml-2" onClick={()=>this.onClickDetailItem(row._id)}><i className="fe fe-edit" /></span>
                                                <div className="item-action dropdown ml-2">
                                                    <a href="#0" data-toggle="dropdown"><i className="fe fe-more-vertical" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href="#1" onClick={()=>this.onOpenDeliveryModal(itemIndex)}><i className="dropdown-icon fa fa-truck" /><IntlMessages id="order.delivery"/></a>
                                                        {row.tamamlanmaOrani===100 && <a className="dropdown-item" href="#2" onClick={()=>this.onTransfertoStock(itemIndex)}><i className="dropdown-icon fa fa-truck" /><IntlMessages id="order.transfertoStock"/></a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            {row.teklifno  && row.teklifno.firma_adi  ?  
                                                <a href="#0" onClick={()=>this.gotoDetail(row.teklifno.firma_id)}><h6>{row.teklifno  &&  row.teklifno.firma_adi}</h6></a>:
                                                <h6>{"Stok Üretimi veya Alt Montaj Üretimi"}</h6>
                                            }
                                            <div className="row  mb-2">
                                                <div className="col-sm-2  col-md-2 col-3">
                                                    <div className="circle">
                                                        <a href="#0" className="mb-3">
                                                            <img className="rounded-circle" src={image  ? image : "../assets/images/ry.png"} alt="Moduled"  />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3  col-md-3 col-9">
                                                    <strong><IntlMessages id="order.optik"/> {row.urun_kodu[2] ? row.urun_kodu[2]:"Belirtilmemiş"}</strong> <br />
                                                    <strong><IntlMessages id="order.cct"/> {row.urun_kodu[3] ? row.urun_kodu[3]:"Belirtilmemiş"}</strong> <br />
                                                    <strong><IntlMessages id="order.bodycolor"/> {row.urun_kodu[6] ? row.urun_kodu[6]:"Belirtilmemiş"}</strong> <br />
                                                    <strong><IntlMessages id="order.ledmark"/> {row.urun_kodu[7]  ? row.urun_kodu[7]:"Belirtilmemiş"}</strong> <br />
                                                    <strong><IntlMessages id="order.driverMark"/>  {row.urun_kodu[8] ? row.urun_kodu[8]:"Belirtilmemiş"}</strong> <br />
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-12">
                                                    <strong><IntlMessages id="order.date"/></strong> <small className="float-right">{moment(row.siparis_tarihi).format("DD.MM.YYYY")}</small><br />
                                                    <div className="clearfix">
                                                        <div className="float-left"><strong>{parseFloat(row.tamamlanmaOrani).toFixed(1)+"%"}</strong></div>
                                                        <div className="float-right"><small className="text-muted">{messages["order.orderStatus"+row.siparis_durumu]}</small></div>
                                                    </div>
                                                    <div className="progress progress-xs">
                                                        <div className="progress-bar bg-azure" role="progressbar" style={{ width: row.tamamlanmaOrani+'%' }} aria-valuenow={row.tamamlanmaOrani} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                    <div>
                                                        <div className="text-muted-dark">{row.teklifno  ? row.teklifno._id : "Stok Üretimi / Alt Montaj "}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-6 text-center">
                                                    <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {row.deliveried_id.reduce((acc, curr) => acc + parseInt(curr.qty, 10), 0)}</h2>
                                                    <div className="text-muted-dark">{messages["genel."+row.urun_birimi]}</div>
                                                    <div className="text-muted-dark">{messages["order.orderStatus5"]}</div>
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-6 text-center">
                                                    <h2 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {row.urun_miktari}</h2>
                                                    <div className="text-muted-dark">{messages["genel."+row.urun_birimi]}</div>
                                                    <div className="text-muted-dark">{messages["offer.OfferStatus1"]}</div>
                                                </div>
                                            </div>
                                            {row.order_type && <span className={"tag tag-success "}>{row.order_type}</span>}
                                            {row.order_type==="production" && !row.Operations.length && <span className="tag tag-danger ">Üretim Planlanmamış</span>}

                                            <span className={row.siparis_durumu  === 4  ? "tag tag-success ml-2":row.siparis_durumu  === 2 ? "tag tag-warning ml-2": "tag tag-danger ml-2"}>{messages["order.orderStatus"+row.siparis_durumu]}</span>
                                            {row.ready === false  && <span className="tag tag-warning ml-2">{messages["order.orderNotReady"]}</span>}
                                            {row.tamamlanmaOrani < 100  && <span className="tag tag-info ml-2">{" Siparişten itibaren "+parseFloat((moment(new Date()) - moment(row.siparis_tarihi))/ (1000 * 3600 * 24)).toFixed()+" Gün GEÇTİ"}</span>}
                                            <div className="table-responsive">
                                                <table className="table table_custom spacing5 border-style mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th  ><IntlMessages id="order.operation"/></th>
                                                            <th className=" text-center"><IntlMessages id="order.startdate"/></th>
                                                            <th className=" text-center"><IntlMessages id="order.finishdate"/></th>
                                                            <th ><IntlMessages id="order.operationstaff"/></th>
                                                            <th className="w150"><IntlMessages id="order.percomlated"/></th>
                                                            <th className="w20"/>
                                                        </tr>
                                                    </thead>
                                                        {row.Operations  ?  row.Operations.map((operation, operationIndex)=>{
                                                            return(
                                                            <tbody key={operationIndex}>
                                                                <tr >
                                                                    <td>
                                                                        <h6 className="mb-0">{operation.Operation ? ((operation.Operation+" "+operation.Qty+" "+row.urun_birimi)) : null}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="mb-0">{operation.OperationStartTime ? moment(operation.OperationStartTime).format("DD.MM.YYYY"):"--.--.---"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="mb-0">{operation.OperationFinishTime ? moment(operation.OperationFinishTime).format("DD.MM.YYYY"):"--.--.---"}</h6>
                                                                    </td>                                                    
                                                                    <td >
                                                                        <h6 className="mb-0">{operation.OperationStaff ? operation.OperationStaff : null}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clearfix">
                                                                            <div className="float-left"><strong>{operation.OperationPercent.toFixed(1)+"%"}</strong></div>
                                                                            <div className="float-right"><small className="text-muted">{messages["order.operationStatus"+operation.OperationStatus]}</small></div>
                                                                        </div>
                                                                        <div className="progress progress-xs">
                                                                            <div className="progress-bar bg-azure" role="progressbar" style={{ width: operation.OperationPercent+'%' }} aria-valuenow={operation.OperationPercent} aria-valuemin={0} aria-valuemax={100} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {role<3 &&  <div className="item-action dropdown">
                                                                            <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)'}}>
                                                                               
        
                                                                             
                                                                            </div>
                                                                        </div>}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }):""}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                </div>
                        )})}
                    {/* <Pagination
                        currentPage={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        onChangePage={i => this.onChangePage(i)}
                    />   */}
                    </div>

                </div>
                <Modal 
                    isOpen={this.state.offerRowModal}
                    toggle={this.toggleOfferRowModal}
                    size="lg"
                    backdrop="static"
                >
                    <ModalHeader>
                        <IntlMessages id="offer.rowedit"/>
                    </ModalHeader>
                    <ModalBody>
                        {offeritem &&
                        <div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.product"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Ürün Adı"
                                            value={offeritem && offeritem.urun_adi ? offeritem.urun_adi :"" }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.modelCode"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Model Kodu"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[0] ? offeritem.urun_kodu[0] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,0)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.pcbCode"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="PCB Kodu"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[1] ? offeritem.urun_kodu[1] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,1)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.optik"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Güç"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[2] ? offeritem.urun_kodu[2] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,2)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.cct"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Optik Kodu"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[3] ? offeritem.urun_kodu[3] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,3)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.solar"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Solar Panel"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[4] ? offeritem.urun_kodu[4] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,4)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.battery"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="Solar"
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[5] ? offeritem.urun_kodu[5] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,5)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.bodyColor"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder={messages["offer.bodyColor"]}
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[6] ? offeritem.urun_kodu[6] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,6)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.LED"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder={messages["offer.LED"]}
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[7] ? offeritem.urun_kodu[7] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,7)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="offer.LEDSurucu"/></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder={messages["offer.LEDSurucu"]}
                                            value={offeritem&& offeritem.urun_kodu && offeritem.urun_kodu[8] ? offeritem.urun_kodu[8] :"" }
                                            onChange={this.handleChangeProductCode(offeritem.urun_kodu,8)}
                                        />
                                    </div>
                                </div>
                            </div> 
                            <hr/>
                            <div className="row">
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label text-center"><IntlMessages id="offer.qty"/></label>
                                        <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offeritem && offeritem.urun_miktari ? offeritem.urun_miktari :1 }  
                                            className="form-control text-center" 
                                            onValueChange={this.handleChangeItemRow("urun_miktari")} 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label text-center"><IntlMessages id="offer.unit"/></label>
                                        <select 
                                            className="form-control text-center"
                                            onChange={this.handleChangeItemRow("urun_birimi")}
                                            value={offeritem.urun_birimi  && offeritem.urun_birimi}
                                        >
                                            {UnitList && UnitList.map((x,y)=>{
                                                return(
                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                )
                                            })}
                                        </select>

                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label text-right"><IntlMessages id="offer.price"/></label>
                                        <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offeritem && offeritem.urun_fiyati ? offeritem.urun_fiyati :1 }  
                                            className="form-control text-right" 
                                            onValueChange={this.handleChangeItemRow("urun_fiyati")}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label text-center"><IntlMessages id="offer.currency"/></label>
                                        <select 
                                            className="form-control text-center"
                                            onChange={this.handleChangeItemRow("urun_dovizi")}
                                            value={offeritem.urun_dovizi}
                                        >
                                            {CurrencyList && CurrencyList.map((x,y)=>{
                                                return(
                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>  
                                <div className="col-sm-12 col-md-1">
                                    <div className="form-group">
                                        <label className="form-label text-center"><IntlMessages id="genel.kdv"/></label>
                                        <select 
                                            className="form-control"
                                            onChange={this.handleChangeItemRow("urun_kdv")}
                                            value={offeritem.urun_kdv}
                                        >
                                            {KDVList.map((x,y)=>{
                                                return(
                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3">
                                    <div className="form-group">
                                        <label className="form-label text-right"><IntlMessages id="offer.product₺price"/></label>
                                        <NumberFormat
                                            displayType="text"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offeritem.urun_fiyati * (offeritem.urun_dovizi ==="₺" ? 1 : offeritem.urun_dovizi ==="$"  ? USDSelling : EURSelling) }  
                                            className="form-control text-right" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row row-cards">
                                <div className="col-6">
                                    <IntlMessages id="offer.productPhoto"/>
                                </div>
                                <div className="col-6">
                                    <div className="page-options d-flex">
                                        <FileBase64 multiple={ false }  onDone={(file)=>this.getImages(file)} />
                                    </div>

                                </div>
                            </div>
                            <hr/>
                            <table className="table table_custom spacing5 border-style mb-0">
                                <tbody>
                                    <tr>
                                        <td className="font600 text-right">
                                            <IntlMessages id="offer.offerrowTotal"/>
                                        </td>
                                        <td className="font600 text-right text-red">
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" ₺"} 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem && offeritem.toplamTL ? offeritem.toplamTL :0 }  
                                            />
                                        </td>
                                        <td className="font600 text-right">
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" "+offeritem.urun_dovizi } 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem && offeritem.toplamDoviz ? offeritem.toplamDoviz :0 }  
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font600 text-right"> 
                                            <span><IntlMessages id="offer.offerrowKDV"/></span>
                                        </td>
                                        <td className="font600 text-right text-red">
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" ₺"} 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem && offeritem.toplamKdvTL ? offeritem.toplamKdvTL :0 }  
                                            />
                                        </td>
                                        <td className="font600 text-right">
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" "+offeritem.urun_dovizi } 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem && offeritem.toplamKdvDoviz ? offeritem.toplamKdvDoviz :0 }  
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font700 text-right">
                                            <span><IntlMessages id="offer.offerrowGrandTotal"/></span>
                                        </td>
                                        <td className="font700 text-right text-red">
                                            <strong>
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" ₺"} 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem.toplamKDVTL + offeritem.toplamTL}  
                                            />
                                            </strong>
                                        </td>
                                        <td className="font700 text-right text-red">
                                            <strong>
                                            <NumberFormat
                                                displayType="text"
                                                suffix={" ₺"} 
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}  
                                                value={offeritem.toplamKDVDoviz + offeritem.toplamDoviz}  
                                            />
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>

              
                        <button type="button" className="btn btn-round btn-info" onClick={()=>this.toggleProductListModal()}>
                            <IntlMessages id="offer.selectProduct"/>
                        </button>
                        <button type="button" className="btn btn-round btn-success" onClick={()=>this.onSaveOrderItem()}>
                            <IntlMessages id="button.save" />
                        </button>
                        <button type="button" className="btn btn-round btn-warning" onClick={()=>this.toggleStockModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal>           
                {productList &&
                <Modal
                    isOpen={this.state.productListModal}
                    toggle={this.toggleProductListModal}
                    size="lg"
                    backdrop="static"
                    scrollable={true}
                >
                    <ModalHeader>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onKeyPress={e => this.onSearchProductKey(e)}
                        />
                    </ModalHeader>
                    <ModalBody>
                        <div className="table-responsive">
                            <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 border-style mb-0">
                                <tbody>
                                {productList.map((productRow,index)=>{
                                    const urun_model = modelList && modelList.length &&  modelList.filter(x => x._id===productRow.urun_model._id)[0];
                                    return(
                                        <tr key={index}>
                                            <td className="w80">
                                                <img
                                                    className="rounded-circle img-thumbnail w80"
                                                    src={urun_model && urun_model.model_resim && urun_model.model_resim[0] && urun_model.model_resim[0].base64 ? urun_model.model_resim[0].base64:"../assets/images/ry.png"}
                                                    alt="moduled"
                                                />
                                            </td>
                                            <td>
                                                <div className="font-15">{productRow.urun_adi[Lang]}</div>
                                                <span className="text-muted">{productRow.urun_model.model_kodu+"-"+productRow.urun_kodu+"-"+productRow.urun_optik+"-"+productRow.urun_cct}</span>
                                            </td>
                                            <td className="font400 text-right">
                                                <NumberFormat
                                                    displayType={'text'}
                                                    fixedDecimalScale={true} 
                                                    decimalScale={2}
                                                    suffix={' '+productRow.urun_doviz} 
                                                    thousandSeparator={'.'} 
                                                    decimalSeparator={','}  
                                                    value={(productRow.urun_fiyat)}  
                                                />  
                                            </td>
                                            <td className="text-right">
                                                <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedProduct(productRow)}>Seç</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleProductListModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal>}        
                <Modal
                    isOpen={this.state.deliveryModal}
                    size="lg"
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="order.deliveryedit"/>
                </ModalHeader>
                    <ModalBody>
                        <div className="row clearfix">
                            <div className="card">
                                <div className="card-body">
                                    {DeliveryErrors && DeliveryErrors.qty  &&  <div className="alert alert-danger" role="alert">{DeliveryErrors.qty}</div>}
                                    {DeliveryErrors && DeliveryErrors.company_id  &&  <div className="alert alert-danger" role="alert">{DeliveryErrors.company_id}</div>}
                                    {DeliveryErrors && DeliveryErrors.order_id  &&  <div className="alert alert-danger" role="alert">{DeliveryErrors.order_id}</div>}
                                    {delivery && !delivery.price &&  <div className="alert alert-danger" role="alert">{"Ürün Fiyatı Hatalıdır"}</div>}
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id= "order.deliverydate"/></label>
                                                <DatePicker
                                                    name="date"
                                                    selected={delivery && delivery.date  &&delivery.date}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={this.handleChangeDateLabelOver()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-8 col-md-8">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id= "order.deliverydate"/></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.company  }
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.product" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.productname  }
                                                    onChange={(e)=>this.onChangeDeliveryData("productname",e.target.value)}
            
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.deliverer" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.deliverer  }
                                                    onChange={(e)=>this.onChangeDeliveryData("deliverer",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.receiver" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.receiver  }
                                                    onChange={(e)=>this.onChangeDeliveryData("receiver",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.description" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.description  }
                                                    onChange={(e)=>this.onChangeDeliveryData("description",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.deliveryqty" /></label>
                                                <input
                                                    type="number"
                                                    max={delivery && delivery.max  }
                                                    min={1}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.qty  }
                                                    onChange={(e)=>this.onChangeDeliveryData("qty",Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="genel.unit" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.unit  }
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-round btn-success" onClick={()=>this.onSaveDelivery()}>
                            <IntlMessages id="button.save" />
                        </button>{" "}
                        <button type="button" className="btn btn-round btn-info" onClick={()=>this.toggleDeliveryModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal>   
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    orderItemList:state.orderItemList,
    locale:state.settings.locale,
    auth:state.auth,
    orderItemDetail:state.orderItemDetail,
    orderItemFilter:state.settings.orderItemFilter,
    productList:state.productList,
    modelList:state.modelList,
    tcmb:state.tcmb.tcmb,
})

const mapDispatchToProps = ({getOrderItemList,updateOrderItem,setOrderItemFilter,setOrderItemCount,getProductList,getModelList,createOrderItem,deleteOfferItem,createDelivery})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Operations)));
