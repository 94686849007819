import {
    TODO_GET_LIST,
    TODO_GET_LIST_SUCCESS,
	TODO_GET_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
//TODO List GET ****************************************************************************************************************************************************

export const Todo_list_set_pending = () => ({
	type: TODO_GET_LIST
});

export const Todo_list_set_successful = (res) => ({
	type: TODO_GET_LIST_SUCCESS,
	response: res
});

export const Todo_list_set_failed = () => ({
	type: TODO_GET_LIST_ERROR
});

export const getTodoList = (date) => dispatch =>{
	const apiUrl_list = `/todo/list/`;
	dispatch(Todo_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(Todo_list_set_successful(res.data));
	
				resolve(true);
			} else { 
				dispatch(Todo_list_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(Todo_list_set_failed());
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
};
