import {
    TODO_GET_LIST,
    TODO_GET_LIST_SUCCESS,
	TODO_GET_LIST_ERROR,

} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case TODO_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case TODO_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.Todolist
            };
		case TODO_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: {
			return state
		}
	}
}
