import React, { Component } from 'react';
// import Piechart from '../../common/piechart';
// import Columnchart from '../../common/columnchart';
// import Stackedchart from '../../common/stackedchart';
// import Sparklineschart from '../../common/sparklineschart';
import { Link } from 'react-router-dom';
// import Donutchart from '../../common/donutchart';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../../helpers/IntlMessages";
import { connect } from 'react-redux';
import {getCompanyList,getTcmbData,getProductList,getStockOutList,getlogList} from '../../../actions'
import moment from "moment";
import trLocale from "moment/locale/tr";
moment.locale('tr',[trLocale]);

class Dashboard extends Component {
	constructor(props) {
        super(props);
        this.state = {

		}
	}
//******************************************************************************************************************************************************************/
	componentDidMount() {
		const today  = new Date();
		console.log(today)
		//const  yesterday = today.setDate(today.getDate() - 1);
		this.props.getStockOutList(today);
		this.props.getlogList(today);	
	}
//******************************************************************************************************************************************************************/
	gotoStockDetail=(form,orderid,itemid,meterialid)=>{
		if(form ==="orderitem"){
			window.open('../orderproductiondetail/'+itemid,"_blank");
		}else if(form==="order"){
			window.open('../order/'+orderid,"_blank");
		}else if(form==="material"){
			window.open('../material/'+meterialid,"_blank");
		}
	}
//******************************************************************************************************************************************************************/
	gotoLogDetail=(form,id,title)=>{
		if(form ==="orderitem"){
			window.open('../orderproductiondetail/'+id,"_blank");
		}else if(form==="order"){
			if(title===" Teklif "){
				window.open('../offer/'+id,"_blank");
			}else if(title===" Sipariş "){
				window.open('../order/'+id,"_blank");
			}else if(title===" Sipariş Bilgilendirmesi"){
				window.open('../order/'+id,"_blank");
			}else if(" E-Posta Gönderildi güncellendi."){
				window.open('../offer/'+id,"_blank");
			}
		}
	}
//******************************************************************************************************************************************************************/
	render() {
		
		const auth = this.props.auth;
		const { productList, stocks,logList} = this.props;
		const { messages } = this.props.intl;
		
		const  {offerCount,orderCount,orderItemCount} = this.props.settings;
		return (
			<>
				<div>
					<div className="section-body  mt-3">
						<div className="container-fluid">
							<div className="row clearfix">
								<div className="col-lg-12">
									<div className="section-body mb-4">
										<h4><IntlMessages id="menu.welcome" /> {" "+auth.user} </h4>
										<small>
										<IntlMessages id="dashboard.message" />{' '}
											<a href="#1">{moment(new Date()).format("DD MMMM YYYY  dddd")}</a>
										</small>
									</div>
								</div>
							</div>
							<div className="row clearfix">
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											{productList.data && productList.data.length ? <div className="ribbon-box green">{productList.data.length}</div>:""}
											<Link to="/products" className="my_sort_cut text-muted">
												<i className="fa fa-cubes" />
												<span><IntlMessages id="menu.products"/></span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
										{offerCount ? <div className="ribbon-box orange">{offerCount}</div>:""}
											<Link to="/offers" className="my_sort_cut text-muted">
												<i className="fa fa-envelope" />
												<span><IntlMessages id="menu.offers"/></span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											{orderCount ? <div className="ribbon-box blue">{orderCount}</div>:""}
											<Link to="/orders" className="my_sort_cut text-muted">
												<i className="fa fa-check-square-o" />
												<span> <IntlMessages id="menu.orders"/></span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											{orderItemCount ? <div className="ribbon-box red">{orderItemCount}</div>:""}
											<Link to="/orderitemlist" className="my_sort_cut text-muted">
												<i className="fa fa-industry" />
												<span><IntlMessages id="menu.productionlist"/></span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/companies" className="my_sort_cut text-muted">
												<i className="fa fa-list-alt" />
												<span><IntlMessages id="menu.companies" /></span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											<div className="ribbon-box pink">5</div>
											<Link to="/users" className="my_sort_cut text-muted">
												<i className="icon-users" />
												<span> <IntlMessages id="menu.users" /></span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section-body">
						<div className="container-fluid">
							<div className="row clearfix">
								<div className= "col-12 col-sm-12">
									<div  className={this.props.stocks.loading   ? "dimmer active" : ""}>
										{this.props.stocks.loading &&  <div className="loader"/>}
										<div className={this.props.stocks.loading  ? "dimmer-content"  : ""}>
											<div className="card">
												<div className="card-header">
													<h3 className="card-title"><IntlMessages id="stock.todaystockoutlist" /></h3>
												</div>
												<div className="card-body">
													<div style={{maxHeight:'400px', overflow: 'scroll'}}>
														<table className="table table-vcenter table_custom spacing2 border-style mb-0">
															<thead>
																<tr>
																	<th className="w20" style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}>#</th>
																	<th style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="stock.date"/></th>
																	<th className="w250" style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="order.product"/></th>
																	<th style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="stock.materials"/></th>
																	<th  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="stock.oty"/></th>
																	<th className="w20"  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}></th>
																</tr>
															</thead>
															{stocks && stocks.data ? stocks.stockoutlist.map((stockrow,sindex)=>{
								
																return(
																	<tbody key={sindex}>
																		<tr>
																			<td>{sindex+1}</td>
																			<td>{moment(stockrow.operation_date).format("DD.MM.YYYY HH:mm")}</td>
																			<td>{stockrow.productname}</td>
																			<td>{stockrow.stock_category+" - "+stockrow.stock_group+" - "+stockrow.stock_name}</td>
																			<td>{parseFloat(stockrow.qty).toFixed(2)+" "+messages["genel."+stockrow.unit]}</td>
																			<td>
																				<div className="item-action dropdown">
																					<a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
																					<div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
																						<a href="#1" className="dropdown-item" onClick={()=>this.gotoStockDetail("material",stockrow.order_id,stockrow.orderitem_id,stockrow.stock_id)}><i className="dropdown-icon fa fa-info-circle" /><IntlMessages id="stock.detail"/></a>
																						<a  href="#2" className="dropdown-item" onClick={()=>this.gotoStockDetail("order",stockrow.order_id,stockrow.orderitem_id,stockrow.stock_id)}><i className="dropdown-icon fa fa-info-circle" /><IntlMessages id="order.detail"/></a>
																						<a  href="#3" className="dropdown-item" onClick={()=>this.gotoStockDetail("orderitem",stockrow.order_id,stockrow.orderitem_id,stockrow.stock_id)}><i className="dropdown-icon fa fa-info-circle" /><IntlMessages id="order.itemdetail"/></a>
																					</div>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																)
															}) :null}
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row clearfix">
								<div className= "col-12 col-sm-12">
									<div  className={this.props.logList.loading   ? "dimmer active" : ""}>
										{this.props.logList.loading &&  <div className="loader"/>}
										<div className={this.props.logList.loading  ? "dimmer-content"  : ""}>
											<div className="card">
												<div className="card-header">
													<h3 className="card-title"><IntlMessages id="logs.todayloglist" /></h3>
												</div>
												<div className="card-body">
													<div style={{maxHeight:'400px', overflow: 'scroll'}}>
														<table className="table table-vcenter table_custom spacing2 border-style mb-0">
															<thead>
																<tr>
																	<th className="w20" style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}>#</th>
																	<th  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="logs.date"/></th>
																	<th className="w250"  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="logs.person"/></th>
																	<th  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="logs.title"/></th>
																	<th  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}><IntlMessages id="logs.description"/></th>
																	<th className="w20"  style={{position:'sticky', top: 0, backgroundColor:'#FFFFFF'}}></th>
																</tr>
															</thead>
															{logList && logList.data ? logList.data.map((logrow,lindex)=>{

																return(
																	<tbody key={lindex}>
																		<tr>
																			<td>{lindex+1}</td>
																			<td>{moment(logrow.date).format("DD.MM.YYYY HH:mm")}</td>
																			<td>{logrow.person}</td>
																			<td>{logrow.title}</td>
																			<td>{logrow.description}</td>
																			<td>
																				<a href="#1"  onClick={()=>this.gotoLogDetail(logrow.form,logrow.operation_id,logrow.title)}><i className="fe fe-info" /></a>
																			</td>
																		</tr>
																	</tbody>
																)
															}) :null}
												
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	settings: state.settings,
    productList:state.productList,
	modelList:state.modelList,
	orderItemList:state.orderItemList,
	offerList:state.offerList,
	auth :state.auth,
	stocks:state.stocks,
	logList:state.logList
})
const mapDispatchToProps  =({getCompanyList,getTcmbData,getProductList,getStockOutList,getlogList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
