import {
	GWPROGRAMS_LIST_ACTION,
	GWPROGRAMS_LIST_SUCCESS,
	GWPROGRAMS_LIST_FAILURE,
	GWPROGRAMS_LIST_ADD_ACTION,
	GWPROGRAMS_LIST_ADD_SUCCESS,
	GWPROGRAMS_LIST_ADD_FAILURE
} from '../actions';
import axios from 'axios';

import {store} from '../store';




export const gwprograms_list_set_pending = (deviceId) => ({
	type: GWPROGRAMS_LIST_ACTION,
	deviceId: deviceId
});

export const gwprograms_list_set_successful = (res) => ({
	type: GWPROGRAMS_LIST_SUCCESS,
	response: res
});

export const gwprograms_list_set_failed = () => ({
	type: GWPROGRAMS_LIST_FAILURE
});


export const gwprograms_list_request = (deviceId) => dispatch => {
	dispatch(gwprograms_list_set_pending(deviceId));
	return new Promise((resolve, reject) => {
		axios.get('https://api.akaysis.com/gateway_programs/list/' + deviceId,{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwprograms_list_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwprograms_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwprograms_list_set_failed());
			reject();
		});
	});
}

export const gwprograms_list_refresh_request = (deviceId) => dispatch => {
	return new Promise((resolve, reject) => {
		axios.get('https://api.akaysis.com/gateway_programs/list/' + deviceId,{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwprograms_list_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwprograms_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwprograms_list_set_failed());
			reject();
		});
	});
}


export const gwprograms_list_add_pending = (deviceId) => ({
	type: GWPROGRAMS_LIST_ADD_ACTION,
	deviceId: deviceId
});

export const gwprograms_list_add_successful = (res) => ({
	type: GWPROGRAMS_LIST_ADD_SUCCESS,
	response: res
});

export const gwprograms_list_add_failed = () => ({
	type: GWPROGRAMS_LIST_ADD_FAILURE
});

export const gwprograms_list_add_request = (data) => dispatch => {
	dispatch(gwprograms_list_add_pending(data.device_id));

	return new Promise((resolve, reject) => {
		axios.post('https://api.akaysis.com/gateway_programs/create', data, 
		{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwprograms_list_add_successful(res.data));
				dispatch(gwprograms_list_refresh_request(data.device_id))
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					resolve(true);
				})
				
			} else {
				dispatch(gwprograms_list_add_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(gwprograms_list_add_failed());
			reject(err);
		});
	});
}

export const gwprograms_list_update_request = (_id, data) => dispatch => {
	console.log(data);
	dispatch(gwprograms_list_add_pending(data.device_id));

	return new Promise((resolve, reject) => {
		axios.post('https://api.akaysis.com/gateway_programs/update/' + _id, 
		data, 
		{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwprograms_list_add_successful(res.data));
				dispatch(gwprograms_list_refresh_request(data.device_id))
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					resolve(true);
				})
				
			} else {
				dispatch(gwprograms_list_add_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(gwprograms_list_add_failed());
			reject(err);
		});
	});
}


export const gwprograms_delete_request = (_id, device_id) => dispatch => {
	dispatch(gwprograms_list_add_pending(device_id));

	return new Promise((resolve, reject) => {
		axios.get('https://api.akaysis.com/gateway_programs/delete/' + _id, 
		{
			headers: {Authorization: 'Bearer ' + store.getState().akaysisAuth.aktoken}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwprograms_list_add_successful(null));
				dispatch(gwprograms_list_refresh_request(device_id))
				.then(() => {
					resolve(true);
				})
				.catch(() => {
					resolve(true);
				})
				
			} else {
				dispatch(gwprograms_list_add_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(gwprograms_list_add_failed());
			reject(err);
		});
	});
}
