import React, { Component } from 'react';
import PDFMenu from './PdfMenu';
export default class PdfLayout extends Component {

	render() {
		return (
			<PDFMenu {...this.props} />
		);
	}
}
