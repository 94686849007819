import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
//************************************************ */
import Header from '../common/reportHeaders/Header1';
import Rows from './Rows';
import Foother from '../common/reportFoothers/pricelistFoothers';

import OpenSansFont from "../../fonts/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../fonts/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../fonts/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../fonts/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontRegular',
        paddingTop: 20,
        paddingBottom:60,
        paddingLeft:20,
        paddingRight:20,
    }, 
    Title: {
        marginBottom: 2,
        flexDirection: "row",
        borderBottomWidth:1,
        width:550,

    },
    Header: {
        flexDirection: "row",
    },     
    RowContainer: {
        width:550,
        bottom:5,
        top:5
    }, 
    Row: {
        width:550,
        height:64,
    },
    Foother: {
        position: 'absolute',
        border:0.3,
        width:550,
        marginLeft:20,
        alignItems:'center',
        bottom: 17,
        height:40
    },
    TitleContainer:{
        marginTop: 2,
        flexDirection: "row",
        borderBottomWidth: 0.2,
        width:550,
        fontFamily: 'OpenSansFontBold',
    },
  });
const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
`;
const PriceListText = styled.Text`
    marginTop: 10px;
    marginBottom: 10px;
    text-indent:30px;
    font-size: 10px;
    text-align: justify;
`;

const priceList = ({data,company,oran,kategori,pricelisttext,Lang,priceListTextOpen,user}) =>{
    const  datas = data//(data.sort((a, b) => (a.urun_model.model_sira > b.urun_model.model_sira) ? 1 : (a.urun_model.model_sira === b.urun_model.model_sira) ? ((a.urun_sira > b.urun_sira) ? 1 : -1) : -1 ))
    const  filter= kategori!=="Tümü" ? Number(kategori): "Tümü";
    const  filterField = kategori ?  "model_kategori" : "";

    return(
    <Document fileName={"moduledas.pdf"} loading={<div className="loader" creator={company.sirket_adi}  author="Moduled" producer="Moduled"/>} >
        <Page size="A4" style={styles.page}>
            <View style={styles.Header} fixed >
                <Header company={company} Lang={Lang}/>
            </View> 
            <View style={styles.Title} fixed >
                <Titlestyle>{Lang  === 0 ? "# FİYAT LİSTESİ #" : "# PRICE LIST #"}</Titlestyle>
            </View>            
            {pricelisttext  && priceListTextOpen && <View style={styles.TitleContainer} >
                <PriceListText>{pricelisttext}</PriceListText>
            </View>}  
            <View style={styles.RowContainer}>
                 {datas ? 
                 filter ==="Tümü" ?
                 datas.map((a) => {return( <Rows style={styles.Row} data={a} oran={oran} Lang={Lang} key={a._id}/>);})
                 :
                 datas.filter(x=>x.urun_model[filterField]===filter).map((a) => {return( <Rows data={a} Lang={Lang} oran={oran}  key={a._id}/>);})
                :"loading"
                 } 
            </View>
            <View style={styles.Foother} fixed>
                <Foother  user={user} />
            </View>
        </Page>
    </Document>
)};
  
export default priceList