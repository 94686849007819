import React, { Component } from 'react'
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
//import NumberFormat from 'react-number-format';
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getMaterialsList,getProductList,getModelList,updateOffer,updateOrderItem,getOrderItemDetails,getProductDetails} from '../../actions';


class OrderDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            operationDetail:false,
            newOperationModal:false,
            itemIndex:null,
            operationIndex:null,
            detailIndex:null,
            deliveryIndex:null,
            operationCenterList:[
                {value: "fatura", label: "Fatura"},
                {value: "ithalat", label: "İthalat"},
                {value: "uretim", label: "Üretim"},
                {value: "fire", label: "Fire"},
              ],
              operationTypeSelect:[
                { value: "giris", label: "Giriş" },
                { value: "cikis", label: "Çıkış" }],
        
              UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
              ],
            productList:this.props.productList.data,
            modelList:this.props.modelList.data,
            materials:this.props.materials.data
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getOrderItemDetails({id:this.props.match.params.id})
        .then(res=>{
            this.state.orderItem.urun_id && this.state.orderItem.urun_id._id && this.props.getProductDetails({id:this.state.orderItem.urun_id._id})
        })
        this.props.getMaterialsList("list");
        this.props.getProductList("list","model_category");
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.orderItemDetail.data !== nextProps.orderItemDetail.data) {
            this.setState({
                orderItem: nextProps.orderItemDetail.data,
            }); 
        }
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials: nextProps.materials.data,
            }); 
        };     
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList:nextProps.productList.data,
            }); 
        };   
 
    }
//******************************************************************************************************************************************************************/
    closeOrder=()=>{
        this.props.history.push({pathname : `/orderitemlist`})
    }
//******************************************************************************************************************************************************************/
    handleChangeItemRow = (index,field) => evt => {
        let value = "";
        if (field==="urun_miktari" || field==="urun_fiyati"){
            value = evt.floatValue
        }else{
            value = evt.target.value
        }
        let newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,[field]: value};
        });
        this.calculate(newItemArray);
    }
//******************************************************************************************************************************************************************/
    toggleOperationDetail=(operationIndex,detailIndex)=>{
        this.setState({
            operationIndex:operationIndex,
            detailIndex:detailIndex,
            operationDetail: !this.state.operationDetail
        });     
    }
//******************************************************************************************************************************************************************/
    addNewOperation=()=>{
        const newArray = {
            Operation:"",
            OperationStatus:0,
            OperationPercent: 0,
            OperationStaff: "",
            OperationStartTime:"",
            OperationFinishTime:"",
            OperationDetails:[
                {
                    material_category:"",
                    material_group:"",
                    material:"Bilinmeyen",
                    materialQty:this.state.orderItem.urun_miktari,
                    StockUpdate:true,
                    status:0
                }
            ]
        }
        var newItemArray = this.state.orderItem.Operations
        newItemArray.push(newArray);
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  
            orderItem.Operations= newItemArray;
            return { orderItem,operationIndex:(newItemArray.length)-1,newOperationModal:!this.state.newOperationModal};    
        }); 
    }
//******************************************************************************************************************************************************************/
    toggleNewOperation=(x)=>{
        this.setState({
            operationIndex:null,
            detailIndex:null,
            newOperationModal: !this.state.newOperationModal
        }); 
    }
//******************************************************************************************************************************************************************/
    onChangeNewOperation = (operationIndex,field,evt)=>{
        const newItemArray = this.state.orderItem.Operations.map((item, sindex) => {
        if (operationIndex !== sindex) return item;
            return { ...item, 
                [field]: evt,
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);
            orderItem.Operations = newItemArray;
            return { orderItem };    
        });
    }
//******************************************************************************************************************************************************************/
    operationDetailonOpen=()=>{

        this.props.getMaterialsList("list");
        this.props.getProductList("list","model_category");
        this.onLoadSelectOptions();
    }
//******************************************************************************************************************************************************************/
    onLoadSelectOptions = () => {
        const  {operationIndex,detailIndex,orderItem,materials,productList} = this.state;
        let MaterialCategory = [];
        let MaterialGroup = [];
        let  MaterialName = [];
        let operationList = [];
        orderItem && orderItem.Operations && orderItem.Operations.length  && orderItem.Operations.forEach(row => {
            operationList.push({
                label: row.Operation,
                value: row.Operation,
                component:row.OperationComponent
            });
        });
        const currentOperation =  orderItem.Operations[operationIndex].Operation;
        const currentCategory =  orderItem.Operations[operationIndex].OperationDetails[detailIndex].material_category;
        const currentGroup =  orderItem.Operations[operationIndex].OperationDetails[detailIndex].material_group;
        if(currentOperation ==="Yarı Mamül"){
            // component Kategori Seçeneklerini yazar
            console.log("Yarı Mamül")
            MaterialCategory =  operationList &&  operationList.length && operationList.filter(option => option.value === currentOperation)[0].component;
            if(!MaterialCategory){
                MaterialCategory=[];
                // Manuel operasyon eklendi ise tüm malzemeleri selectbox a  eklemek iiçim

            }
            productList.filter(x=>Number(x.model_category) === Number(currentCategory)).forEach(row => {
                console.log(row.model_category)
                MaterialGroup.push({
                    label: row.urun_adi[0],
                    value: row._id,
                });
            })
        }else{
            MaterialCategory =  operationList &&  operationList.length && operationList.filter(option => option.value === currentOperation)[0].component;
            if(!MaterialCategory){
                MaterialCategory=[];
                // Manuel operasyon eklendi ise tüm malzemeleri selectbox a  eklemek iiçim
                materials && materials.length && [...new Map(materials.map(x=> [x.material_category,x])).values()].forEach(row => {
                    MaterialCategory.push({
                        label: row.material_category,
                        value: row.material_category,
                    });
                })
            }
            materials && materials.length && materials.forEach(row => {
                if  ( currentCategory === row.material_category ){
                    if(MaterialGroup.filter(x=>x.value === row.material_group).length === 0){
                        MaterialGroup.push({
                            label: row.material_group,
                            value: row.material_group,
                        });
                    }
                }
                if  ( currentCategory === row.material_category && currentGroup === row.material_group ){
                    MaterialName.push({
                        label: row.material_name,
                        value: row._id,
                        price: row.material_price,
                        doviz: row.material_doviz,
                        stock:row.stockSum,
                        img:row.material_img
                    });
                }
            });
        }
        this.setState({
            operationList:operationList,
            MaterialCategory:MaterialCategory,
            MaterialGroup:MaterialGroup,
            MaterialName: MaterialName
        });    

    }
//******************************************************************************************************************************************************************/
    onChangeOperationDetail = (operationIndex,detailIndex,field,evt)=>{
        const  {materials,orderItem} = this.state;
        const newItemArray = orderItem.Operations[operationIndex].OperationDetails.map((item, sindex) => {
        
        let materialData = {};
        if (materials &&  materials.length && field==="material"){
            materialData = materials.filter(x =>  x._id === evt)[0];
        }
        if (detailIndex !== sindex) return item;
            return { ...item, 
                [field]: evt,
                totalQty: Number(field==="materialQty" ? evt :item.materialQty )* Number(orderItem.urun_miktari),
                //status:  field==="material"  && evt.price,
                status:  field==="material"  && materialData && materialData.stockSum ? materialData.stockSum  : 0
            };
        });
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);
            orderItem.Operations[operationIndex].OperationDetails = newItemArray;
            return { orderItem };    
        },()=>this.onLoadSelectOptions());
    }
//******************************************************************************************************************************************************************/
    onClickProductionDetails=()=>{
        this.props.getMaterialsList("list");
        this.props.getProductList("list","model_category");
    }
//*************************************************************** PRODUCTIONDETAIL DETAILS **********************************************************************************/
    removeOperationRow=(operationIndex,detailIndex)=>{
        const  {orderItem} = this.state;
        if(detailIndex===null){
            var newItemArray = orderItem.Operations
            newItemArray.splice(operationIndex, 1);
            //delete newItemArray[index];
            this.setState(prevState => {
                let offeritem = Object.assign({}, prevState.offeritem);  // creating copy of state variable 
                offeritem.Operations = newItemArray;              // update the offer property, assign a newitemval               
                return { offeritem };  
            });
        }else{
            // eslint-disable-next-line
            var newItemArray = orderItem.Operations[operationIndex].OperationDetails
            newItemArray.splice(detailIndex, 1);
            //delete newItemArray[index];
            this.setState(prevState => {
                let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable 
                orderItem.Operations[operationIndex].OperationDetails = newItemArray;              // update the offer property, assign a newitemval               
                return { orderItem };  
            });
        }
    }
//*************************************************************** PRODUCTIONDETAIL DETAILS **********************************************************************************/
    addOperationRow=(operationIndex)=>{
        const newArray = {
          material_category:"",
          material_group:"",
          material: "",
          materialQty: 1.00,
          totalQty:1.00,
          StockUpdate:false,
        }
        var newItemArray = this.state.orderItem.Operations[operationIndex].OperationDetails
        newItemArray.push(newArray);
        this.setState(prevState => {
          let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
          orderItem.Operations[operationIndex].OperationDetails = newItemArray;
          return { orderItem };    
        });
    }
//******************************************************************************************************************************************************************/
    refreshComponentList=(itemIndex)=>{
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
            orderItem.refreshcomponentlist  = true
            return { orderItem };    
        },()=>this.SaveOrderItem());
    }
//***********************************************************************************************************************************************************/
    SaveOrderItem = () => {
        const { messages } = this.props.intl;
        this.props.updateOrderItem({...this.state.orderItem})
        .then(res => {
            store.addNotification({
                title: messages["uyari.basarili"],
                message: messages["bilgi.satirguncellendi"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });        
        })
        .catch(err=>{
            if(err){
                store.addNotification({
                    title: this.props.orderItemDetail.status.statusText,
                    message: this.props.orderItemDetail.status.data,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
            }
        })
    }
//***********************************************************************************************************************************************************/
    updateOrderItem=(sID)=>{
        if(sID>=1){
            if (window.confirm(sID +" Adet Satır Malzemesi Belirsiz devam etmek istiyor musunuz ?")) {

                this.setState((prevState) => {
                    let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
                    orderItem.ready = sID >= 1 ? false : true
                    return {orderItem};   
                },()=>this.SaveOrderItem()); 
            }
        }else{
            this.setState((prevState) => {
                let orderItem = Object.assign({}, prevState.orderItem);  // creating copy of state variable jasper
                orderItem.ready = true
                return {orderItem};   
            },()=>this.SaveOrderItem()); 
        }
    }
//******************************************************************************************************************************************************************/
    onChangeOrderItemData=(field,data)=>{
        this.setState(prevState => {
            let orderItem = Object.assign({}, prevState.orderItem);  
            orderItem[field] = data;    
            orderItem.ready =  field==="order_type" &&  data==="material" ? true : field==="order_type" &&  data==="takeoff_stock" ? true :  field==="order_type" &&  data==="suply" ? true : this.state.orderItem.ready;     
            return { orderItem };    
        });
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        //const {locale} =  this.props;
        //const Lang = locale === "tr" ? 0  : 1 ;
        const { orderItem,operationIndex,detailIndex,operationList,MaterialCategory,MaterialGroup,MaterialName,materials,productList} = this.state;

        const item = orderItem ;
        
        const ModelKodu = item && item.urun_kodu[0] ? item.urun_kodu[0] :""
        const PCBKodu = item && item.urun_kodu[1] ? ("-"+item.urun_kodu[1]):""
        const Optik = item && item.urun_kodu[2] ? ("-"+item.urun_kodu[2]):""
        const CCT  = item && item.urun_kodu[3] ? ("-"+item.urun_kodu[3]):""
        const Solar  = item && item.urun_kodu[4] ? ("-"+item.urun_kodu[4]):""
        const Battery  = item && item.urun_kodu[5] ? ("-"+item.urun_kodu[5]):""
        let image = "../assets/images/ry.png" ;
        if(orderItem && orderItem.urun_id &&  orderItem.urun_id.productImages && orderItem.urun_id.productImages.length && orderItem.urun_id.productImages[0].image  && orderItem.urun_id.productImages[0].image.smallimage ){
            image = orderItem.urun_id.productImages[0].image.smallimage;
        }else{
            image = "../assets/images/ry.png" ;
        }
        let  sID =  0;
        
        return (
            <>
                
                <div  className={this.props.orderItemDetail.loading ? "dimmer active" : ""}>
                    {this.props.orderItemDetail.loading && <div className="loader"/>}
                    <div className={this.props.orderItemDetail.loading ? "dimmer-content"  : ""}>
                        <div className="container-fluid">                       
                            <div className="btn-list text-right  m-2">
                                {orderItem && orderItem.order_type && orderItem.order_type==="production"&&<button type="button" className="btn btn-warning ml-2" onClick={()=>this.refreshComponentList()}><i className="fa fa fa-refresh mr-2" /><IntlMessages id="order.refreshCompList"/></button>}
                                <button type="button" className="btn btn-success ml-2"  onClick={()=>this.updateOrderItem()}><i className="fa fa-save mr-2" /><IntlMessages id="button.save"/></button>
                                <button type="button" className="btn btn-info ml-2" onClick={()=>this.closeOrder()}><i className="fa fa-times mr-2" /><IntlMessages id="button.close"/></button>
                            </div>
                        </div>
                   
                        {item &&
                        <div className="container-fluid">
                            {materials && item &&
                            <div>
                                <div className="card" >
                                    <div className={item.ready ? "card-status card-status-left bg-success":"card-status card-status-left bg-danger"}/>
                                    <div className="card-header">
                                        <h3 className="card-title">{item.urun_adi ? item.urun_adi : null}</h3>
                                        <div className="card-options">
                                            <div className="item-action dropdown ml-2">
                                                <a href="#0" data-toggle="dropdown">
                                                    <i className="fe fe-more-vertical" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a  href="#2" className="dropdown-item"  onClick={()=>this.printOrderWorkorder()}>
                                                        <i className="dropdown-icon fa fa-file-pdf-o" /> <IntlMessages id="order.pdf"/>{' '}
                                                    </a>
                                                    <a  href="#2" className="dropdown-item"  onClick={()=>this.addNewOperation()}>
                                                        <i className="dropdown-icon fa fa-file-pdf-o" /> <IntlMessages id="order.addnewOperation"/>{' '}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-header"><strong>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</strong></div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="selectgroup w-100">
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" checked={item.order_type==="default"} onChange={()=>this.onChangeOrderItemData("order_type","default")} />
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.default" /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" checked={item.order_type==="material"} onChange={()=>this.onChangeOrderItemData("order_type","material")} />
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.material" /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" checked={item.order_type==="production"}  onChange={()=>this.onChangeOrderItemData("order_type","production")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.production" /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" checked={item.order_type==="suply"}  onChange={()=>this.onChangeOrderItemData("order_type","suply")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.suply" /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" checked={item.order_type==="takeoff_stock"}  onChange={()=>this.onChangeOrderItemData("order_type","takeoff_stock")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.takeoff_stock" /></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12  col-md-2">
                                                    <div className="card p-3">
                                                        <a href="#0" className="mb-3">
                                                            <img src={image} className="rounded" alt="Moduled"  />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12  col-md-3">
                                                    <strong><IntlMessages id="order.bodycolor"/> {item.urun_kodu[6]}</strong> <br />
                                                    <strong><IntlMessages id="order.ledmark"/> {item.urun_kodu[7]}</strong> <br />
                                                    <strong><IntlMessages id="order.driverMark"/>  {item.urun_kodu[8]}</strong> <br />
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <strong><IntlMessages id="order.date"/></strong> <small className="float-right">{moment(item.siparis_tarihi).format("DD.MM.YYYY")}</small><br />
                                                    <div className="clearfix">
                                                        <div className="float-left"><strong>{orderItem.tamamlanmaOrani.toFixed(1)+"%"}</strong></div>
                                                        <div className="float-right"><small className="text-muted">{orderItem.tamamlanmaOrani <1 ? "Waiting":"Pogress"}</small></div>
                                                    </div>
                                                    <div className="progress progress-xs">
                                                        <div className="progress-bar bg-azure" role="progressbar" style={{ width: orderItem.tamamlanmaOrani+'%' }} aria-valuenow={orderItem.tamamlanmaOrani} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2 text-center">
                                                    <h4 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {item.urun_miktari}</h4>
                                                    <div className="text-muted-dark">{messages["genel."+item.urun_birimi]}</div>
                                                    <div className="text-muted-dark">{messages["offer.OfferStatus1"]}</div>
                                                </div>
                                                <div className="col-sm-12 col-md-2 text-center">
                                                    <h4 className="mb-1"><i className="mdi mdi-trending-down text-danger" /> {item.teslim_edilmis}</h4>
                                                    <div className="text-muted-dark">{messages["genel."+item.urun_birimi]}</div>
                                                    <div className="text-muted-dark">{messages["order.orderStatus5"]}</div>
                                                </div>
                                            </div>
                                            <hr/>
                                            
                                            <div className="table-responsive">
                                                <div  className={(this.props.materials.loading)  ? "dimmer active" : ""}>
                                                    {(this.props.materials.loading ) && <div className="loader"/>}
                                                    <div className={this.props.materials.loading  ? "dimmer-content"  : ""}>
                                                        <table className="table table_custom spacing5 border-style mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th ><IntlMessages id="order.operation"/></th>
                                                                    <th ><IntlMessages id="order.category"/></th>
                                                                    <th ><IntlMessages id="order.group"/></th>
                                                                    <th ><IntlMessages id="order.material"/></th>
                                                                    <th className="text-center"><IntlMessages id="order.qty"/></th>
                                                                    <th className="text-center"><IntlMessages id="order.totalqty"/></th>
                                                                    <th className="text-center"><IntlMessages id="order.stock"/></th>
                                                                    <th className="w20"/>
                                                                </tr>
                                                            </thead>
                                                            {item && item.Operations ?  item.Operations.map((operation,opIndex)=>{
                                                                return(
                                                                    operation.OperationDetails  && operation.OperationDetails.map((detail,detailIndex)=>{
                                                                        let  materialData = [];
                                                                        let materialname = "";
                                                            
                                                                        if(operation.Operation!=="Yarı Mamül"){
                                                                            let rowMaterialName = [];
                                                                            materialData = detail.material && materials.filter(x =>  x._id === detail.material)[0];
                                                                            materialname = materialData && materialData.material_name; 
                                                                            materials && materials.length && materials.forEach(row => {
                                                                                
                                                                                if  ( detail.material_category === row.material_category && detail.material_group === row.material_group ){
                                                                                  
                                                                                    rowMaterialName.push({
                                                                                        label: row.material_name,
                                                                                        value: row._id,
                                                                                        price: row.material_price,
                                                                                        doviz: row.material_doviz,
                                                                                        stock:row.stockSum,
                                                                                        img:row.material_img
                                                                                    });
                                                                                }
                                                                            });

                                                                            if (rowMaterialName.length===1 && detail.material  ==="" ){
                                                                                detail.material = rowMaterialName.filter(option => option.value === rowMaterialName[0].value)[0].value
                                                                                //console.log(rowMaterialName.filter(option => option.value === rowMaterialName[0].value)[0].value)
                                                                                detail.status = rowMaterialName.filter(option => option.value === rowMaterialName[0].value)[0].stock
                                                                            }else if ( detail.material){
                                                                                detail.status = materialData && materialData.stockSum ? materialData.stockSum  : 0
                                                                            } 
                                                                        }else{
                                                                            materialData = detail.material_group && productList.filter(x =>  x._id === detail.material_group)[0];
                                                                            detail.material  = detail.material_group;
                                                                            detail.status = materialData && materialData.stockSum ? materialData.stockSum  : 0
                                                                            materialname = materialData  && materialData.urun_adi && materialData.urun_adi[0] && materialData.urun_adi[0]; 
                                                                        }

                                                                        if(detail.material_category!=="" && detail.material===""){
                                                                            sID=sID+1
                                                                        }

                                                                        return(
                                                                        <tbody key={operation._id  + detailIndex}>
                                                                            <tr>
                                                                                <td>
                                                                                    <span>{operation.Operation}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span>{detail.material_category && operation.Operation==="Yarı Mamül" ? messages["model.kategori"+detail.material_category] : detail.material_category}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span>{detail.material_group && detail.material_group}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span>{materialname ? materialname  : "Seçim Yapılmalı"}</span>
                                                                                </td>
                                                                                <td  className="text-center">
                                                                                    <span>{detail.materialQty && detail.materialQty}</span>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span>{detail.totalQty && detail.totalQty}</span>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <span>{detail.status && detail.status}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="item-action dropdown">
                                                                                        <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
                                                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)'}}>
                                                                                            <a href="#1"className="dropdown-item" onClick={()=>this.toggleOperationDetail(opIndex,detailIndex)}><i className="dropdown-icon fa fa-eye" /><IntlMessages id="order.editoperation"/></a>
                                                                                            <a href="#2" className="dropdown-item"  onClick={() => this.removeOperationRow(opIndex,detailIndex)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="order.deleteOperation"/></a>
                                                                                            <a href="#3" className="dropdown-item" onClick={() => this.addOperationRow(opIndex,detailIndex)}><i className="dropdown-icon fa fa-plus" /><IntlMessages id="order.addNewOperation"/></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        )
                                                                    })
                                                                )
                                                            }):null
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                      
                                </div>
                            </div>
                            }
                        </div>}  
                    </div>
                </div>
                {orderItem && orderItem.Operations  && orderItem.Operations[operationIndex] && orderItem.Operations[operationIndex].OperationDetails && orderItem.Operations[operationIndex].OperationDetails[detailIndex] && 
                <Modal 
                    isOpen={this.state.operationDetail}
                    size="lg"
                    backdrop="static"
                    onOpened={()=>this.operationDetailonOpen()}
                >
                <ModalHeader>
                    <IntlMessages id="product.operationedit"/>
                </ModalHeader>
                <ModalBody>
                        <div className="row clearfix">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.operation"/></label>
                                                <select 
                                                    className="form-control" 
                                                    disabled
                                                    value={orderItem.Operations[operationIndex].Operation}
                                                >
                                                    {operationList && operationList.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value}  >{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.materialcategory"/></label>
                                                <select 
                                                    className="form-control" 
                                                    onChange={(evt)=>this.onChangeOperationDetail(operationIndex,detailIndex,"material_category",evt.target.value)}
                                                    value={orderItem.Operations[operationIndex].OperationDetails[detailIndex].material_category}
                                                >
                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                    {MaterialCategory && MaterialCategory.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value} >{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.materialgroup"/></label>
                                                <select 
                                                    className="form-control" 
                                                    onChange={(evt)=>this.onChangeOperationDetail(operationIndex,detailIndex,"material_group",evt.target.value)}
                                                    value={orderItem.Operations[operationIndex].OperationDetails[detailIndex].material_group}
                                                >
                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                    {MaterialGroup && MaterialGroup.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value} >{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.material"/></label>
                                                <select 
                                                    className="form-control" 
                                                    onChange={(evt)=>this.onChangeOperationDetail(operationIndex,detailIndex,"material",evt.target.value)}
                                                    value={orderItem.Operations[operationIndex].OperationDetails[detailIndex].material}
                                                >
                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                    {MaterialName && MaterialName.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value}>{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-3">
                                            <div className="form-group">
                                                <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.materialqty"/></label>
                                                    <input
                                                        type="number"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Adet"
                                                        onChange={(evt)=>this.onChangeOperationDetail(operationIndex,detailIndex,"materialQty",evt.target.value)}
                                                        value={parseFloat(orderItem.Operations[operationIndex].OperationDetails[detailIndex].materialQty).toFixed(1)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-sm-12 col-md-3">
                                            <div className="form-group">
                                                <div className="form-group">
                                                <label className="form-label text-right"><IntlMessages id="order.totalqty"/></label>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control text-right"
                                                        value={parseFloat(orderItem.Operations[operationIndex].OperationDetails[detailIndex].totalQty).toFixed(1)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label text-right"><IntlMessages id="order.stock"/></label>
                                                <input
                                                    type="number"
                                                    disabled
                                                    className="form-control text-right"
                                                    value={parseFloat(orderItem.Operations[operationIndex].OperationDetails[detailIndex].status).toFixed(1)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group">
                                                <IntlMessages id="order.stokupdate" />
                                                <div className="float-right">
                                                    <label className="custom-switch">
                                                        <input type="checkbox" 
                                                            name="option" 
                                                            checked={orderItem.Operations[operationIndex].OperationDetails[detailIndex].StockUpdate}
                                                            className="custom-switch-input"  
                                                            onChange={(evt)=>this.onChangeOperationDetail(operationIndex,detailIndex,"StockUpdate",!(orderItem.Operations[operationIndex].OperationDetails[detailIndex].StockUpdate))}
                                                        />
                                                        <span className="custom-switch-indicator" />
                                                        
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleOperationDetail(null,null,null)}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>}
                {orderItem && orderItem.Operations[operationIndex] &&
                    <Modal 
                        isOpen={this.state.newOperationModal}
                        size="sm"
                        backdrop="static"
                    >
                        <ModalHeader>
                            <IntlMessages id="product.operationedit"/>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row clearfix">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="order.operation"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Operasyon"
                                                            onChange={(evt)=>this.onChangeNewOperation(operationIndex,"Operation",evt.target.value)}
                                                            value={orderItem.Operations[operationIndex].Operation  && orderItem.Operations[operationIndex].Operation}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleNewOperation(null)}>
                                <IntlMessages id="button.close" />
                            </button>
                        </ModalFooter>
                    </Modal>}
            
         
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    tcmb:state.tcmb.tcmb,
    locale:state.settings.locale,
    offerDetail:state.offerDetail,
    productList:state.productList,
    modelList:state.modelList,
    materials:state.materials,
    orderItemDetail:state.orderItemDetail,
    productDetail:state.productDetail,
})

const mapDispatchToProps  =({getMaterialsList,getProductList,getModelList,updateOffer,updateOrderItem,getOrderItemDetails,getProductDetails})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderDetails)));
