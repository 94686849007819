import {
    PRODUCT_STOCK_GET_LIST,
    PRODUCT_STOCK_GET_LIST_SUCCESS,
	PRODUCT_STOCK_GET_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Product Stock List GET **************************************************************************************************************************************************

export const productStock_list_set_pending = () => ({
	type: PRODUCT_STOCK_GET_LIST
});

export const productStock_list_set_successful = (res) => ({
	type: PRODUCT_STOCK_GET_LIST_SUCCESS,
	response: res
});

export const productStock_list_set_failed = () => ({
	type: PRODUCT_STOCK_GET_LIST_ERROR
});

export const getProductStockList = (id) => dispatch =>{
	const apiUrl_list = `/productStocks/list/${id}`;
	dispatch(productStock_list_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(productStock_list_set_successful(res.data));
			} else {
				dispatch(productStock_list_set_failed());
			}
		})
		.catch((err) => {
			dispatch(productStock_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};

