import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getMaterialsList} from '../../actions';
import NumberFormat from 'react-number-format';

class Models extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            sortColumn:"model_kategori",
            filter:"",
            filterField:"",  
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            search: "",
            isLoading:false,
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            KDVList : [
              { value: '18', label: '18' },
              { value: '8', label: '8' },
              { value: '0', label: '0' }
            ],
            categoryList : [
                {value : 0 , label : "model.kategori"+0},
                {value : 1 , label : "model.kategori"+1},
                {value : 2 , label : "model.kategori"+2},
                {value : 3 , label : "model.kategori"+3},
                {value : 4 , label : "model.kategori"+4},
                {value : 5 , label : "model.kategori"+5},
                {value : 6 , label : "model.kategori"+6},
                {value : 7 , label : "model.kategori"+7},
                {value : 8 , label : "model.kategori"+8},
            ],
            operationList : [
             {value : "Dizgi" ,label : "Dizgi"},
             {value : "Mekanik Montaj" ,label :"Mekanik Montaj"},
             {value : "Paketleme" ,label :"Paketleme"},
             {value : "E-Test" ,label : "E-Test"},
             {value : "Harici İşlem" ,label : "Harici İşlem"},
             {value : "Onay" ,label : "Onay"}
            ],
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getMaterialsList("detail")
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials: nextProps.materials.data,
                totalItemCount:nextProps.materials.totalItemCount,
                totalPage: Math.ceil(nextProps.materials.totalItemCount/this.state.selectedPageSize)
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
                sortColumn:column
            },()=>this.props.getMaterialsList("detail",this.state.sortColumn));
    };
//******************************************************************************************************************************************************************/
    changePageSize = size => {
        this.setState({
            selectedPageSize: size,
            currentPage: 1,
            totalPage: Math.ceil(this.state.totalItemCount/size)
        });
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        const  materials = this.props.materials.data;
        let Count   = "";
        let _filter = "" ;
        f === "Tümü" ? _filter  = "" : _filter = f ;
        if (f!=="Tümü"){
            Count = materials.filter(x=>x.material_category ===_filter).length;// değişiklik olacak
          this.setState(
            {
                materials: materials.filter(x=>x.material_category ===_filter),
                currentPage:1,
                totalItemCount:Count,
                totalPage: Math.ceil(Count/this.state.selectedPageSize) || 1
            }
          );
        } else {
            Count = materials.length;
          this.setState(
            {
                materials:materials,
                currentPage:1,
                totalItemCount:Count,
                totalPage: Math.ceil(materials.length/this.state.selectedPageSize)
            }
          );
        }
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.materials.data.filter(item => 
            {return   item.material_category.toLowerCase().includes(search.target.value.toLowerCase()) 
                || item.material_group.toLowerCase().includes(search.target.value.toLowerCase())
                || item.material_name.toLowerCase().includes(search.target.value.toLowerCase())
            })
        if (search && search.target.value===""){
            this.setState({
                materials:this.props.materials.data,
                totalItemCount:this.props.materials.data &&  this.props.materials.data.length ? this.props.materials.data.length:0,
                totalPage: Math.ceil((this.props.materials.data &&  this.props.materials.data.length  ? this.props.materials.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
                materials:filteredList,
                totalItemCount:this.state.materials &&  this.state.materials.length ? this.state.materials.length:0,
                totalPage: Math.ceil((this.state.materials &&  this.state.materials.length  ? this.state.materials.length : 0) /this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    newMaterials=()=>{
        this.props.history.push({pathname : `/material/new`})
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {materials,selectedPageSize,currentPage,totalItemCount} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
        const MaterialGroup=[];
        materials && materials.length   && 
        [...new Map(this.props.materials.data.map(x=> [x.material_category,x])).values()].forEach(row => {
            MaterialGroup.push({
                label: row.material_category,
                value: row.material_category,
            });
        }) 
        return (
            <>
            <div  className={this.props.materials.loading ? "dimmer active" : ""}>
                {this.props.materials.loading && <div className="loader"/>}
                <div className={this.props.materials.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</div>
                                        <div className="page-options d-flex">
                                            <select 
                                                className="custom-select w-auto" 
                                                onChange={(evt)=>this.onChangeFilter("material_category",evt.target.value)}
                                            >
                                                        <option value={"Tümü"} >{messages["genel.choose"]}</option>
                                                {MaterialGroup.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.value}>{x.label} </option>
                                                    )
                                                })}
                                            </select>
                                            <div className="input-icon ml-2">
                                                <span className="input-icon-addon">
                                                    <i className="fe fe-search" />
                                                </span>
                                                <input type="text" className="form-control" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)}/>
                                            </div>
                                            <button type="button" className="btn btn-primary ml-2" onClick={()=>this.newMaterials()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.add"/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                        <thead>
                            <tr>
                                <th className="w80">#</th>
                                <th ><IntlMessages id="material.name"/></th>
                                <th className="text-center  w150"><IntlMessages id="genel.unit"/></th>
                                <th className="text-right"><IntlMessages id="material.price"/></th>
                                <th className="text-right"><IntlMessages id="material.stock"/></th>
                                <th className="w40"></th>
                            </tr>
                        </thead>
                        {materials &&  materials.length  ?
                            <tbody>
                            {
                                materials.slice(startIndex, endIndex).map((row,i)=>{
                                return (
                                    <tr key={i}>
                                        <td>
                                            <a href={`/material/${row._id}`}>
                                                <img
                                                    src={row.material_img ? row.material_img:"../assets/images/ry.png"}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="Moduled"
                                                    className="rounded-circle img-thumbnail w80"
                                                    data-original-title="Moduled"
                                                />
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`/material/${row._id}`}><h6 className="mb-0">{row.material_name}</h6></a>
                                            <a href={`/material/${row._id}`}><span>{row.material_category+" / "+row.material_group}</span></a>
                                        </td>
                                        <td className="text-center">
                                            <span className="tag tag-success">{row.material_unit}</span>
                                        </td>
                                        <td className="text-right">{row.material_price+" "+row.material_doviz}</td>
                                        <td className="text-right"><NumberFormat value={row.stockSum} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  /></td>
                                        <td>
                                            <div className="item-action dropdown">
                                                <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                    <a href={`/material/${row._id}`}  className="dropdown-item"><i className="dropdown-icon fa fa-eye" /><IntlMessages id="material.viewdetails"/></a>
                                                    <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="material.updateStock"/></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )})}
                            </tbody>:
                        null}
                    </table>
                <Pagination
                currentPage={this.state.currentPage}
                totalPage={this.state.totalPage}
                onChangePage={i => this.onChangePage(i)}
                /> 
                </div> 
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    materials:state.materials,
    locale:state.settings.locale
})

const mapDispatchToProps = ({getMaterialsList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Models)));
