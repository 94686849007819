import {
	STOCK_DETAILS_ACTION,
	STOCK_DETAILS_SUCCESS,
    STOCK_DETAILS_ERROR,
	CREATE_STOCK_ACTION,
	CREATE_STOCK_SUCCESS,
	CREATE_STOCK_ERROR,
	UPDATE_STOCK_ACTION,
	UPDATE_STOCK_SUCCESS,
	UPDATE_STOCK_FAILURE,
	DELETE_STOCK_ACTION,
	DELETE_STOCK_SUCCESS,
	DELETE_STOCK_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case STOCK_DETAILS_ACTION: //GET STOCK DETAILS
			return { ...state, loading: true };
		case STOCK_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case STOCK_DETAILS_ERROR:
            return { ...state, loading: false };	
            	
		case CREATE_STOCK_ACTION: //CREATE STOCK
			return { ...state, loading: true };
		case CREATE_STOCK_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_STOCK_ERROR:
			return { ...state, loading: false, data: action.response  };	
		case UPDATE_STOCK_ACTION: //UPDATE STOCK
			return { ...state, loading: true };
		case UPDATE_STOCK_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_STOCK_FAILURE:
			return { ...state, loading: false, data: action.response  };
		case DELETE_STOCK_ACTION: //DELETE STOCK
			return { ...state, loading: true };
		case DELETE_STOCK_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_STOCK_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
