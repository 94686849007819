import {
	ORDER_GET_LIST,
    ORDER_GET_LIST_SUCCESS,
	ORDER_GET_LIST_ERROR,

} from '../actions';
import axios from 'axios';
import {store} from '../store';


//ORDERS List GET ****************************************************************************************************************************************************

export const order_list_set_pending = () => ({
	type: ORDER_GET_LIST
});

export const order_list_set_successful = (res) => ({
	type: ORDER_GET_LIST_SUCCESS,
	response: res
});

export const order_list_set_failed = () => ({
	type: ORDER_GET_LIST_ERROR
});

export const getOrderItemList = (type,selectedOrder,filter0,filter1) => dispatch =>{
	
	const apiUrl_list = "/orders/list";
	dispatch(order_list_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?type=${type}&orderBy=${selectedOrder}&filter1=${filter0}&filter2=${filter1}`,
	{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(order_list_set_successful(res.data));
		} else {
			dispatch(order_list_set_failed());
		}
	})
	.catch((err) => {
        //console.log(err)
		dispatch(order_list_set_failed());
		//dispatch(returnErrors(err.response.data, err.response.status)); // deneme
		if (err.response && err.response.status && err.response.status === 401) {
			
		}
	});

};