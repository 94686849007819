import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';


const BackGround = ''
const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        height: 100,
        width:550,
        backgroundColor: BackGround,
    },
    DetailsContainer: {
        flexDirection: 'row',
        height: 100,
        width:550,
        backgroundColor: BackGround,
    },    
    DetailsRowContainer: {
        flexDirection: 'column',
        height: 95,
        border: 0.5,
        width:360,
        backgroundColor: BackGround,
    },    
    ProductImage: {
        flexDirection: 'column',
        width: 95,
        height: 95,
        marginLeft: '0',
        backgroundColor: BackGround,
        border : 0.5
    },
    QRImages: {
        flexDirection: 'column',
        width: 95,
        height: 95,
        marginLeft: '0',
        backgroundColor: BackGround,
        border : 0.5
    },

  });


  const Product = styled.Text`
  paddingLeft:5px;
  margin-top: 2px;
  font-size: 10px;
  text-align: left;
  font-family:'OpenSansFontBold';
  color: #0C71E0;
  `;
const Company = styled.Text`
paddingLeft:5px;
margin-top: 2px;
margin-buttom: 2px;
font-size: 10px;
text-align: left;
font-family:'OpenSansFontBold';
color: #A92205;
`;
const ProductCode = styled.Text`
paddingLeft:5px;
margin-top: 1px;
margin-buttom: 1px;
font-size: 8px;
text-align: left;
font-family:'OpenSansFontBold';
`;
const Oty = styled.Text`
paddingLeft:5px;
font-size: 14px;
text-align: center;
font-family:'OpenSansFontBold';

`; 
const Unit = styled.Text`
paddingLeft:5px;
margin-top: 2px;
font-size: 10px;
text-align: center;
font-family:'OpenSansFontBold';
`; 
const OfferTableRow = ({data}) => {
    const item = data;
        const ModelKodu = item && item.productCode[0] ? item.productCode[0] :""
        const PCBKodu =  item && item.productCode[1] ? ("-"+item.productCode[1]):""
        const Optik =  item && item.productCode[2] ? ("-"+item.productCode[2]):""
        const CCT  =  item && item.productCode[3] ? ("-"+item.productCode[3]):""
        const Solar  =  item && item.productCode[4] ? ("-"+item.productCode[4]):""
        const Battery  =  item && item.productCode[5] ? ("-"+item.productCode[5]):""

        const BodyColor  =  item && item.productCode[6] ? ("Gövde Rengi :"+item.productCode[6]):"Gövde Rengi :Standart"
        const LEDColor  =  item && item.productCode[3] ? ("LED Rengi :"+item.productCode[3]+" K"):"LED Rengi :Sorunuz"
        const LEDDriver  =  item && item.productCode[8] ? ("LED Sürücü :"+item.productCode[8]):"LED Sürücü :Sistem"
    return(
    <View style={styles.rowContainer} wrap={false} >

    
        <View style={styles.DetailsContainer}>
            <View style={styles.ProductImage}>
                {item.productImage && <Image src={item.productImage}/>}
            </View>
            <View style={styles.DetailsRowContainer}>
                <Company>{(item.company).substring(0,60)}</Company>
                <Product>{item.product}</Product>
                <ProductCode>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</ProductCode>
                <Company>{BodyColor+" / "+LEDColor+" / "+LEDDriver}</Company>
                <Oty>{item.Qty}</Oty>
                <Unit>{item.Unit+" Üretilecek "}</Unit>
                {/* {data.Operations.map((operation,index)=>{
                    return(
                        operation.OperationDetails.map((detail,index2)=>{
                        return(
                            <ProductCode key={index+index2}>{operation.Operation +"=>"+detail.material_category+" "+detail.material_group +"=> "+detail.materialQty +" Adet  Toplam Miktar  "+detail.totalQty + " Adet"}</ProductCode>
                        )})
                    )})
                }  */}
            </View>

            <View style={styles.QRImages}>
                <Image src={item.QRimage}/>

       
            </View>

        </View>
    </View>  
)
};
  
export default OfferTableRow