import React from 'react';
import { Page, Document, StyleSheet, Font, View ,Image} from '@react-pdf/renderer';
import QRCode from 'qrcode';
//************************************************ */
import styled from '@react-pdf/styled-components';
import moment from "moment";
import ArialBold from "../../fonts/arial-bold.ttf"
import Arial from "../../fonts/arial.ttf"
import GillSans from "../../fonts/gillsans.ttf"
import OpenSansFont from "../../fonts/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../fonts/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../fonts/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../fonts/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'ArialBold', src: ArialBold  });
Font.register({ family: 'Arial', src: Arial  });
Font.register({ family: 'GillSans', src: GillSans  });


const styles = StyleSheet.create(
    {
    page: {
        padding: 10,
    }, 
    Header: {
        width:270,
        height:40,
        flexDirection: "row",
        alignItems:"center",
        alignSelf:"center",
        borderBottomWidth: 2
    },
    logo: {
        width: 270,
        height: 30
    },
    ProductInfoContainer: {
        fontFamily: 'ArialBold',
        marginTop:4,
        flexDirection: "column",
        width: 260,
        height: 45,
        alignItems:"center",
        alignSelf:"center",
    },
    productImage: {
        width: 70,
        height: 70,
        alignItems:"center",
    },
    Container1: {//QR ve Güç Vooltaj vss
        
        width:260,
        height:87,
        marginTop:3,
        marginLeft:5,
        borderBottomWidth:1.5,
        flexDirection: "row"
    },
    Column1: {
        marginLeft:5,
        width:70,
        height:82,
        flexDirection: 'column',
        alignItems:"center",
        alignSelf:"center",
    },
    Column2: {
        width:180,
        height:82,
        flexDirection: 'column'
    },
    Container2: {
        width:260,
        height:30,
        marginTop:3,
        marginLeft:5,
        flexDirection: "column"
    },
    CertificatesContainer: {
        flexDirection: "row",
        width: 260,
        height: 45,
        marginLeft:5,
        alignItems:'center',
        borderBottomWidth:1.5,
    }
});
const Label10px = styled.Text`
margin_left:2px;
height:15;
font-size: 10px;
text-align: left;
`;
const Label10pxRight = styled.Text`
margin_left:2px;
height:12;
font-size: 10px;
text-align: right;
`;
const Label10pxCenter = styled.Text`
margin_left:2px;
height:12;
font-size: 10px;
text-align: center;
`;
const Label8px = styled.Text`
margin_Top:2px;
margin_left:2px;
height:10;
font-size: 8px;
text-align: left;
`;
const Power = styled.Text`
margin_left:2px;
height:36px;
font-size: 28px;
text-align: right;
`;
const Certificates = styled.Image`
margin_left: 5px;
height: 36;
width: 36;
color: #0C71E0;
`;
const Label10x10 = ({company,data,messages}) =>{
    let value = data.ProductID ? window.location.origin+"/web/"+data.ProductID : "www.moduled.com.tr";
    let QrImage = "";
    QRCode.toDataURL(value, function(err, url) {
        QrImage=  url;
    });
    return (
    <Document>
        {company.bwlogo && data  && 
        <Page size={[ 283.4 ,  283.4 ]} orientation={"landscape"} style={styles.page}>
            <View style={styles.Header}>
                <View style={styles.Header}>
                    {data.logoOpen &&  <Image style={styles.logo} src={company.bwlogo}/> }      
                </View>
            </View>
            <View style={styles.ProductInfoContainer}>
                <Label10px>{(data.model).toUpperCase()}</Label10px>
                <Label10px>{data.productName.toUpperCase()}</Label10px>
                <Label8px>{data.productCode.toUpperCase()}</Label8px>
            </View>   


            <View style={styles.Container1}>
                <View style={styles.productImage}>
                    {data.logoOpen && QrImage && <Image  src={QrImage}/>}
                    <Label10px> {data.BoxQty+" "+messages["genel."+data.Unit]}</Label10px>    
                </View>
                <View style={styles.Column2}>
                    <Power>{data.Power}</Power>
                    <Label10pxRight>{data.Voltage}</Label10pxRight>
                    <Label10pxRight>{data.Dimensions}</Label10pxRight>    
                    <Label10pxRight>{data.Weight}</Label10pxRight>     
                </View>

            </View>
            <View style={styles.CertificatesContainer}>
                {data.Certificates && data.Certificates.slice(0,6).map((cer,index)=>{
                    return(
                         <Certificates key={index} style={styles.Certificates} src={cer.bwfile} /> 
                    )
                })}
            </View>
            <View style={styles.Container2}>
                {data.logoOpen && <Label10pxCenter>www.moduled.com.tr</Label10pxCenter>}
                 <Label10pxCenter>Made In TURKIYE</Label10pxCenter>
                <Label10pxCenter>P.D / Ü.T : {moment(new Date()).format("DD.MM.YYYY")}</Label10pxCenter>
            </View>
        </Page>
        }
    </Document>
)};
  
export default Label10x10