import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';

//************************************************ */
import Header from './Header'
import Title from './Title'
import Rows from './Rows'
import OpenSansFont from "../../fonts/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../fonts/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../fonts/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../fonts/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFont',
        paddingTop: 25,
        paddingLeft:20,
        paddingRight:20,
        paddingBottom: 24,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 1,
        flexDirection: "row",
    },     
    RowContainer: {
        marginTop: 2,
        marginButtom: 3,
        flexDirection: "column",
        borderBottomWidth: 1,
        borderColor:"blue",
        width:550
    }, 
    SignContainer: {
        marginLeft: 252,
        flexDirection: "row"
    }, 
    image: {
        width: 170,
        height: 120,
        marginLeft: '0',
    },  
  });

const productionList = ({data,company}) =>{
    const  datas = data;
    return(
    <Document file={"moduledas.pdf"} className="pdfPreview" loading={<div className="loading" creator={company.sirket_adi}/>} >
        <Page size="A4" style={styles.page} wrap>
            <Header company={company} fixed/>
            <Title data={data}/>
            <View style={styles.RowContainer}>
                {
                 datas ? datas.map((a,y) => {return( <Rows key={y} data={a} />);}): "" 
                } 
            </View>

        </Page>
    </Document>
)};
  
export default productionList