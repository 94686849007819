import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import FileBase64 from '../../helpers/FileBase64';
import { store } from 'react-notifications-component';
import CountUp from 'react-countup';
import CreatableSelect from 'react-select/creatable';
import NumberFormat from 'react-number-format';
import moment from "moment";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";

import {createMaterial,updateMaterial,deleteMaterial,getMaterialDetails,getMaterialsList,getStockList,getstockDetails,updateStock,createStock,deleteStock} from '../../actions';

const createOption = (label) => ({
    label,
    value: label
});
  
type State = {
materialCategory: [{ [string]: string }],
value: string | void,
};

class MaterialDetails extends Component < * ,State>{
    constructor(props) {
        super(props);
        this.inputReference = React.createRef();
        this.state = {
            selectedPageSize: 10,
            pageSizes: [6, 12, 18, 30, 50, 100],
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            isLoading:false,
            stockRowModal:false,
            stocks:[],
            errors:{
                material_name:"",
                material_category:"",
                material_group:""
            },
            Stockerrors:{
                operation_center: "" ,
                operation_type: "",
                price:"",
                stock_name:""
            },
            operationCenterList:[
                {value: "fatura", label: "Fatura"},
                {value: "ithalat", label: "İthalat"},
                {value: "uretim", label: "Üretim"},
                {value: "fire", label: "Fire"},
                {value: "teslimat", label: "Teslimat"},
              ],
              operationTypeSelect:[
                { value: "giris", label: "Giriş" },
                { value: "cikis", label: "Çıkış" }],
        
              UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
              ],
              CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
              ],
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
    
        if(this.props.match.params.id==="new"){
            this.setState({
                material:{
                    material_no:1,
                    material_name:"",
                    material_group:"",
                    material_category:"",
                    material_unit:"Adet",
                    material_price:0,
                    material_doviz:"$",
                    material_img:"",
                    stockSum:0,
                }
            }); 
            if(this.props.materials  && !this.props.materials.data.length) {
                this.props.getMaterialsList("list")
                .then(response=>{
                    this.loadSelectOptions();
                })
            }else{
                this.loadSelectOptions();
            }
        }else{
            this.props.getMaterialDetails({id:this.props.match.params.id})
            .then(res=>{
                this.props.getStockList(this.props.match.params.id)
                if(this.props.materials  && !this.props.materials.data.length) {
                    this.props.getMaterialsList("list")
                    .then(response=>{
                        this.loadSelectOptions();
                    })
                }else{
                    this.loadSelectOptions();
                }
            });            
        }
    }
//******************************************************************************************************************************************************************/
    loadSelectOptions(){
        let materialCategories=[];
        let materialGroup=[];
            [...new Map(this.props.materials.data && this.props.materials.data.map(x=> [x.material_category,x])).values()].forEach(row => {
                materialCategories.push({
                        value: row.material_category,
                        label: row.material_category
                });
            });
            this.state.material && this.state.material.material_category &&
            [...new Map(this.props.materials.data && this.props.materials.data.map(x=> [x.material_group,x])).values()].forEach(row => {
                if(row.material_category===this.state.material.material_category){
                    materialGroup.push({
                    value: row.material_group,
                    label: row.material_group
                    });
                }
            });
            this.setState({
                materialGroup: materialGroup,
                materialCategories:materialCategories
            }); 
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.materialDetail.data !== nextProps.materialDetail.data || this.props.stocks.data !== nextProps.stocks.data) {
            let matreialsList = [];
            let StokGiris = 0 ;
            let StokCikis = 0;
            let StokDurumu = 0;

            let runingTotal = 0
            nextProps.stocks &&  nextProps.stocks.data && nextProps.stocks.data.sort((a,b)=> new Date(a.operation_date)-new Date(b.operation_date)).forEach(mtr=>{
                runingTotal = runingTotal + mtr.sumQty
                matreialsList.push({
                    runTotal: runingTotal,
                    stock_id : mtr._id,
                    order_id : mtr.order_id,
                    orderitem_id:mtr.orderitem_id,
                    operation_center:mtr.operation_center,
                    operation_date : mtr.operation_date,
                    operation_type  : mtr.operation_type,
                    stock_name : mtr.stock_name,
                    stock_category:mtr.stock_category,
                    stock_group:mtr.stock_group,
                    qty:mtr.qty,
                    unit:mtr.unit,
                    price : mtr.price,
                    doviz : mtr.doviz,
                    sumQty:mtr.sumQty,
                    _id:mtr._id
                });  
            })
            StokGiris = matreialsList.filter(x=>x.operation_type==="giris").reduce((prev, current) => (prev + current.sumQty), 0)
            StokCikis = matreialsList.filter(x=>x.operation_type==="cikis").reduce((prev, current) => (prev + current.sumQty), 0)
            StokDurumu =  StokGiris - Math.abs(StokCikis)
            this.setState({
                StokCikis:Math.abs(StokCikis),
                StokGiris:Math.abs(StokGiris),
                StokDurumu:StokDurumu,
                material: nextProps.materialDetail.data,
                stocks: matreialsList.sort((a,b)=> new Date(b.operation_date)-new Date(a.operation_date)),
                totalPage: Math.ceil(matreialsList.length/this.state.selectedPageSize)
            }); 
        };   
    }
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//***********************************************************************************************************************************************************/
    handleCreateCategory = (inputValue) => {
        this.setState({ isLoading: true });
        const { materialCategories } = this.state;
        const newOption = createOption(inputValue);
        this.setState(prevState => {
          let material = Object.assign({}, prevState.material);  
          material.material_category = newOption && newOption.value ? newOption.value:"";                          
          return { material,isLoading: false, materialCategories: [...materialCategories, newOption]};    
        });
    };
//***********************************************************************************************************************************************************/
    handleCreateGroup = (inputValue) => {
        this.setState({ isLoading: true });
        const { materialGroup } = this.state;
        const newOption = createOption(inputValue);
        this.setState(prevState => {
            let material = Object.assign({}, prevState.material);  
            material.material_group = newOption && newOption.value ? newOption.value:"";                          
            return { material,isLoading: false, materialGroup: [...materialGroup, newOption]};    
        });
    };
//***********************************************************************************************************************************************************/
    onChangeCategoryData = (newValue, actionMeta) => {
        const _errors = this.state.errors;
        _errors.material_category = (newValue &&  newValue.value.length < 2) || (newValue ===null)
            ? 'Select pls to Category'
            : '';
        let materialGroup=[];
        [...new Map(this.props.materials.data && this.props.materials.data.map(x=> [x.material_group,x])).values()].forEach(row => {
          if(newValue  &&  newValue.value && row.material_category===newValue.value){
            materialGroup.push({
              value: row.material_group,
              label: row.material_group
            });
          }
        });
        this.setState(prevState => {
            let material = Object.assign({}, prevState.material);  
            let errors = Object.assign({}, prevState.errors);
            material.material_category = newValue && newValue.value ? newValue.value:"";    
            errors= _errors;                      
            return { material,materialGroup: materialGroup,errors};    
        });
    };
//***********************************************************************************************************************************************************/
    onChangeGroupData = (newValue, actionMeta) => {
        const _errors = this.state.errors;
        _errors.material_group = (newValue &&  newValue.value.length < 2) || (newValue ===null)
        ? 'Select pls to Group'
        : '';
        this.setState(prevState => {
            let material = Object.assign({}, prevState.material); 
            let errors = Object.assign({}, prevState.errors); 
            material.material_group = newValue && newValue.value ? newValue.value:"";  
            errors=_errors;                
            return { material ,errors};    
        });
    };
//******************************************************************************************************************************************************************/
    onChangeMaterialData=(field,data)=>{
        this.setState(prevState => {
            let material = Object.assign({}, prevState.material);  
            material[field] = data;                          
            return { material };    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeNumber = (field )=> (evt)=>{
        this.setState((prevState, props) => {
            let material = Object.assign({}, prevState.material);  // creating copy of state variable jasper
            material[field] = evt.floatValue;                    // update the name property, assign a new value                 
            return { material};  
        });
    }
//***********************************************************************************************************************************************************/
    getFiles=(files)=>{
        this.setState(prevState => {
            let material = Object.assign({}, prevState.material);  // creating copy of state variable jasper
            material.material_img = files.base64;                     // update the name property, assign a new value                 
            return { material };    
        });
    }
//******************************************************************************************************************************************************************/
    materialSave=()=>{
        const { messages } = this.props.intl;
        if(this.state.material._id){
            this.props.updateMaterial(this.state.material)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.malzemeguncellendi"],
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
            })
            .catch(err=>{
                if(err){
                    store.addNotification({
                        title: this.props.materialDetail.status.statusText,
                        message: this.props.materialDetail.status.data,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                }
            })
        }else{
            this.props.createMaterial({...this.state.material}, this.props.history)
            .then(res=>{
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.malzemeolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });    
            })
        }
    }
//******************************************************************************************************************************************************************/
    materialsDelete=()=>{
        let id = this.state.material._id;
		if (window.confirm("Malzemeyi Silmek istiyor musunuz ?")) {
			this.props.deleteMaterial(id)
			.then(res=>{
                this.props.history.push({pathname : `/materials`})
			}) 
		}else{
		
		}
    }
//******************************************************************************************************************************************************************/
    gotoMaterials=()=>{
        this.props.history.push({pathname : `/materials`})
    }
//******************************************************************************************************************************************************************/
    onChangeStockOperationData=(field,data)=>{
        this.setState(prevState => {
            let stock = Object.assign({}, prevState.stock);  
            stock[field] = data;                          
            return { stock };    
        });
    }
//******************************************************************************************************************************************************************/
    newStockRow=()=>{
        this.setState({
            stock:{
                operation_date: new Date(),
                operation_center:"",
                operation_type: "",
                stock_name:this.state.material.material_name,
                stock_category:this.state.material.material_category,
                stock_group:this.state.material.material_group,
                qty:1,  
                unit:"Adet",
                description:"",
                price:0,
                doviz:"$",
                stock_id:this.state.material._id
            },
            stockRowModal: !this.state.stockRowModal,
        });
    }
//******************************************************************************************************************************************************************/
    getStockModalData=(id)=>{
        this.props.getstockDetails({id:id})
        .then(res=>{
            this.setState({
                stock:this.props.stockDetail.data,
                stockRowModal: !this.state.stockRowModal
            })
        })
    }
//******************************************************************************************************************************************************************/
    toggleStockRowModal=(id)=>{
        this.setState({
            stockRowModal: !this.state.stockRowModal,
            stockRow:id
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeDateLabelOver = date => {
        this.setState(prevState => {
            let stock = Object.assign({}, prevState.stock);  // creating copy of state variable jasper
            stock.operation_date = date;                     // update the name property, assign a new value                 
            return { stock };    
        })
    };
//******************************************************************************************************************************************************************/
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
//******************************************************************************************************************************************************************/
    stockSave=()=>{
        this.setState((prevState) => {
            let stock = Object.assign({}, prevState.stock);  // creating copy of state variable jasper     
            let Stockerrors = Object.assign({}, prevState.Stockerrors);  // creating copy of state variable jasper         
            stock.sumQty= this.state.stock.operation_type === "giris" ? 0 + this.state.stock.qty  : 0 - this.state.stock.qty          
            stock.sumPrice= this.state.stock.operation_type === "giris" ? 0 + (this.state.stock.price*this.state.stock.qty)  : 0 - (this.state.stock.price*this.state.stock.qty)  
            Stockerrors.operation_center=this.state.stock.operation_center.length > 2 ? "" :"Operasyon Birimi Seçiniz"
            Stockerrors.operation_type=this.state.stock.operation_type.length > 2 ? "" :"Operasyon Tipini Seçiniz"
            Stockerrors.price=this.state.stock.price !==0 ?"":"Birim Fiyat Yazınız"
            Stockerrors.stock_name=this.state.stock.stock_name.length > 2  ?"":"Write Company Name please"
        return { stock,Stockerrors};  
        },()=>this.onSave());
    }
//******************************************************************************************************************************************************************/
    onSave = () =>{
        const isOk = this.validateForm(this.state.Stockerrors);
        const { messages } = this.props.intl;
        if (isOk){
            if(this.state.stock._id){
                this.props.updateStock(this.state.stock)
                .then(res=>{
                    this.setState({
                        stockRowModal: !this.state.stockRowModal
                    }); 
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.stokhareketiguncellendi"],
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.stockDetail.status.statusText,
                            message: this.props.stockDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }else{
                this.props.createStock(this.state.stock)
                .then(res=>{
                    this.setState({
                        stockRowModal: !this.state.stockRowModal
                    }); 
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.stokhareketiolusturuldu"],//{messages[x.label]} 
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });   
                    this.props.getStockList(this.props.match.params.id)
                })
            }
        }

    }
//******************************************************************************************************************************************************************/
    stockDelete=()=>{
        if(this.state.stock._id){
            if (window.confirm("Stok Hareketini Silmek istiyor musunuz ?")) {
                this.props.deleteStock(this.state.stock)
                .then(res=>{
                    this.setState({
                        stockRowModal: !this.state.stockRowModal
                    },()=>this.props.getStockList(this.props.match.params.id)); 
                })
            }
        }
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const { material,errors ,isLoading,materialCategories,materialGroup,CurrencyList,UnitList,stocks,selectedPageSize,currentPage,operationCenterList,operationTypeSelect,stock,Stockerrors,StokGiris,StokCikis,StokDurumu} = this.state;
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
        return (
            <>
            <div  className={this.props.match.params.id!=="new" &&  this.props.materialDetail.loading && this.props.stocks.loading ? "dimmer active" : ""}>
                {this.props.match.params.id!=="new" &&  this.props.materialDetail.loading && this.props.stocks.loading && <div className="loader"/>}
                <div className={this.props.match.params.id!=="new" && this.props.materialDetail.loading && this.props.stocks.loading ? "dimmer-content"  : ""}>
                    <div className="container-fluid">                       
                        {material && materialCategories && materialCategories.length &&
                        <div className="clearfix">
                            <div className="card" >
                                <div className="ml-3 d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#material-detail"><IntlMessages id="material.detail"/></a></li>
                                        <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#material-qc"><IntlMessages id="material.qc"/></a></li>
                                        {/* <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#material-images"><IntlMessages id="material.images"/></a></li> */}
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="material-detail" role="tabpanel">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-3">
                                                    <div className="card p-3">
                                                        <a href="fake_url" className="mb-3">
                                                            <img src={material &&  material.material_img ? material.material_img :"../assets/images/ry.png"}  alt={material.material_name} className="rounded" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-9">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="form-group">
                                                                <div className="form-group">
                                                                <label className="form-label"><IntlMessages id="material.category" /></label>
                                                                    <CreatableSelect
                                                                        className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        isClearable
                                                                        isDisabled={isLoading}
                                                                        isLoading={isLoading}
                                                                        onCreateOption={this.handleCreateCategory}
                                                                        value={material && material.material_category && materialCategories.filter(x => x.value === material.material_category)}
                                                                        onChange={this.onChangeCategoryData}
                                                                        options={materialCategories}
                                                                    />
                                                                    <div className="invalid-feedback">{errors.material_category}</div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="form-group">
                                                                <div className="form-group">
                                                                <label className="form-label"><IntlMessages id="material.group" /></label>
                                                                    <CreatableSelect
                                                                        className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        value={material && material.material_group && materialGroup.filter(x => x.value === material.material_group)}
                                                                        onChange={this.onChangeGroupData}
                                                                        isClearable
                                                                        isDisabled={isLoading}
                                                                        isLoading={isLoading}
                                                                        onCreateOption={this.handleCreateGroup}
                                                                        options={materialGroup}
                                                                    />
                                                                    <div className="invalid-feedback">{errors.material_group}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-12">
                                                            <div className="form-group">
                                                                <div className="form-group">
                                                                <label className="form-label"><IntlMessages id="material.name"/></label>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        placeholder={<IntlMessages id="material.name"/>}
                                                                        value={material && material.material_name  ? material.material_name :"" }
                                                                        onChange={(evt)=>this.onChangeMaterialData("material_name",evt.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label"><IntlMessages id="genel.unit"/></label>
                                                                <select 
                                                                    className="form-control"
                                                                    onChange={(evt)=>this.onChangeMaterialData("material_unit",evt.target.value)}
                                                                    defaultValue={material.material_unit && material.material_unit}
                                                                >
                                                                    {UnitList.map((x,y)=>{
                                                                        return(
                                                                            <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label text-right"><IntlMessages id="material.price"/></label>
                                                                <NumberFormat
                                                                    decimalScale={4}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={material.material_price}  
                                                                    className="form-control text-right" 
                                                                    onValueChange={this.handleChangeNumber("material_price")} 
                                                                />

                                                            </div>
                                                        </div>      
                                                        <div className="col-sm-12 col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label"><IntlMessages id="material.currency"/></label>
                                                                <select 
                                                                    className="form-control"
                                                                    onChange={(evt)=>this.onChangeMaterialData("material_doviz",evt.target.value)}
                                                                    defaultValue={material.material_doviz && material.material_doviz}
                                                                >
                                                                    {CurrencyList && CurrencyList.map((x,y)=>{
                                                                        return(
                                                                            <option key={y}  value={x.value}>{x.label} </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div> 
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="material.image"/></label>
                                                                <div className="page-options d-flex">
                                                                    <FileBase64 multiple={ false }  onDone={this.getFiles} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade show active" id="material-qc" role="tabpanel">

                                    </div>
                                </div>
                            <div className="card-footer text-right">
                                {material  && material._id &&
                                <button type="button" className="btn btn-danger" onClick={()=>this.materialsDelete()}>
                                    <IntlMessages id="button.delete" />
                                </button>
                                }{"  "}
                                {material  && material._id &&
                                <button type="button" className="btn btn-info" onClick={()=>this.newStockRow()}>
                                    <IntlMessages id="stock.newStockOperation" />
                                </button>
                                }{"  "}
                                <button type="button" className="btn btn-primary" onClick={()=>this.materialSave()}><IntlMessages id="button.save"/></button>{"  "}
                                <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.gotoMaterials()}>
                                    <IntlMessages id="button.close" />
                                </button>
                            </div>
                            </div> 
                        </div>
                        }  
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body w_sparkline">
                                        <div className="details">
                                            <span><IntlMessages id="stock.stockin"/></span>
                                            <h3 className="mb-0">
                                                {StokGiris && <CountUp end={StokGiris} />}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body w_sparkline">
                                        <div className="details">
                                            <span><IntlMessages id="stock.stockout"/></span>
                                            <h3 className="mb-0 counter">{StokCikis && <CountUp end={StokCikis} />}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body w_sparkline">
                                        <div className="details">
                                            <span><IntlMessages id="stock.stock"/></span>
                                            <h3 className="mb-0 counter">{StokDurumu  && <CountUp end={StokDurumu} />}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="table-responsive">
                            <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                <thead>
                                    <tr>
                                        <th className="w120"><IntlMessages id="stock.date"/></th>
                                        <th><IntlMessages id="stock.materials"/></th>
                                        <th><IntlMessages id="stock.operation"/></th>
                                        <th ><IntlMessages id="stock.operationcenter"/></th>
                                        <th className="text-center"><IntlMessages id="stock.oty"/></th>
                                        <th className="text-right"><IntlMessages id="stock.price"/></th>
                                        <th className="text-right"><IntlMessages id="stock.stock"/></th>
                                        <th className="w20"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stocks && stocks.length >  0 ? stocks.slice(startIndex, endIndex).map((StockRow,StockIndex)=>{ 
                                        return(
                                            <tr key={StockIndex}>
                                                <td>{StockRow.operation_date && moment(StockRow.operation_date).format("DD.MM.YYYY")}</td>
                                                <td>{StockRow.stock_name &&StockRow.stock_category   +" " +StockRow.stock_group+" "+StockRow.stock_name}</td>
                                                <td>{StockRow.operation_type &&<span className={StockRow.operation_type==="giris"?"tag tag-blue":"tag tag-red"}>{(StockRow.operation_type).toUpperCase()}</span>}</td>
                                                <td>{StockRow.operation_center &&<span className={StockRow.operation_center==="fatura" ? "tag tag-yellow": StockRow.operation_center==="fire" ? "tag tag-red":"tag tag-primary"}>{(StockRow.operation_center).toUpperCase()}</span>}</td>
                                                <td className="text-center">{StockRow.qty &&  <NumberFormat value={StockRow.qty} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  />}</td>
                                                <td className="text-right">{StockRow.operation_type &&(parseFloat(StockRow.price).toFixed(2)).replace(".",",")+" "+StockRow.doviz }</td>
                                                <td className="text-right"><NumberFormat value={StockRow.runTotal} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  /></td>
                                                <td>
                                                    <div className="item-action dropdown">
                                                        <a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                            <a href="#1" className="dropdown-item" onClick={()=>this.getStockModalData(StockRow._id)}><i className="dropdown-icon fa fa-edit" /><IntlMessages id="stock.edit"/></a>
                                                            <a  href={`/orderproductiondetail/${StockRow.orderitem_id}`} className="dropdown-item"><i className="dropdown-icon fa fa-info-circle" /><IntlMessages id="order.detail"/></a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }):null}
                                </tbody>
                            </table>
                        </div>  
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            onChangePage={i => this.onChangePage(i)}
                        />
                        <Modal
                            isOpen={this.state.stockRowModal}
                            toggle={this.togglestockRowModal}
                            size="lg"
                            backdrop="static"
                        >
                        <ModalHeader>
                            <IntlMessages id="stock.operation"/>
                        </ModalHeader>
                            <ModalBody>
                                {stock && stock && 
                                    <div className="row clearfix">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.date"/></label>
                                                                <DatePicker
                                                                    name="operation_date"
                                                                    selected={stock.operation_date && new Date(stock.operation_date)}
                                                                    dateFormat="dd.MM.yyyy"
                                                                    onChange={this.handleChangeDateLabelOver}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.operationcenter"/></label>
                                                            <select 
                                                                className={Stockerrors.operation_center === "" ? "form-control" :"form-control is-invalid"}
                                                                onChange={(evt)=>this.onChangeStockOperationData("operation_center",evt.target.value)}
                                                            >
                                                                <option value={""} >{messages["genel.choose"]}</option>
                                                                {operationCenterList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={stock.operation_center === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{Stockerrors.operation_center}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.operation"/></label>
                                                            <select 
                                                                className={Stockerrors.operation_type === "" ? "form-control" :"form-control is-invalid"}
                                                                onChange={(evt)=>this.onChangeStockOperationData("operation_type",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {operationTypeSelect.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={stock.operation_type === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{Stockerrors.operation_type}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.material"/></label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                autoComplete="off"
                                                                className={Stockerrors.stock_name === "" ? "form-control" :"form-control is-invalid"}
                                                                value={stock &&  stock.stock_name ? stock.stock_name :"" }
                                                            />
                                                            <div className="invalid-feedback">{Stockerrors.stock_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.category"/></label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={stock &&  stock.stock_category ? stock.stock_category :"" }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.group"/></label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={stock &&  stock.stock_group ? stock.stock_group :"" }
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.qty"/></label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={stock &&  stock.qty ? stock.qty :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("qty",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.unit"/></label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(evt)=>this.onChangeStockOperationData("unit",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {UnitList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={stock.unit === x.value}>{messages[x.label]} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.price"/></label>
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className={Stockerrors.price === "" ? "form-control" :"form-control is-invalid"}
                                                                value={stock &&  stock.price ? stock.price :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("price",evt.target.value)}
                                                            />
                                                            <div className="invalid-feedback">{Stockerrors.price}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.doviz"/></label>
                                                            <select 
                                                                className="form-control"
                                                                onChange={(evt)=>this.onChangeStockOperationData("doviz",evt.target.value)}
                                                            >
                                                                        <option value={""} >{messages["genel.choose"]}</option>
                                                                {CurrencyList.map((x,y)=>{
                                                                    return(
                                                                        <option key={y}  value={x.value} selected={stock.doviz === x.value}>{x.label} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label"><IntlMessages id="stock.description"/></label>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={stock &&  stock.description ? stock.description :"" }
                                                                onChange={(evt)=>this.onChangeStockOperationData("description",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </ModalBody>
                            <ModalFooter>
                                {stock && stock._id &&
                                <button type="button" className="btn btn-round btn-danger" onClick={()=>this.stockDelete(stock._id)}>
                                    <IntlMessages id="button.delete" />
                                </button>}{"  "}
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-round btn-primary" onClick={()=>this.stockSave()}>
                                    <IntlMessages id="button.save" />
                                </button>
                                <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleStockRowModal()}>
                                    <IntlMessages id="button.close" />
                                </button>
                            </ModalFooter>
                        </Modal>                           
                    </div>
                </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    tcmb:state.tcmb.tcmb,
    locale:state.settings.locale,
    materialDetail:state.materialDetail,
    materials:state.materials,
    stocks:state.stocks,
    stockDetail:state.stockDetail
})

const mapDispatchToProps  =({createMaterial,updateMaterial,deleteMaterial,getMaterialDetails,getMaterialsList,getStockList,getstockDetails,updateStock,createStock,deleteStock})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MaterialDetails)));
