import React, { Component } from 'react'
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker,{registerLocale} from "react-datepicker"
import tr from "date-fns/locale/tr"; 
import "react-datepicker/dist/react-datepicker.css";
import FileBase64 from '../../helpers/FileBase64';
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getOfferDetails,getCompanyList,updateCompany,getCompanyDetails,createCompany,offerDovizOpen,offerNotesOpen,getProductList,getModelList,createOffer,updateOffer,deleteOffer,setLoading,setInvoiceType,getMaterialsList} from '../../actions';
import Countries from "../../data/countries"


registerLocale("tr", tr);

class OfferDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            offerCurrency:true,
            offerRowModal:false,
            productListModal:false,
            companyModal:false,
            operationCenterList:[
                {value: "fatura", label: "Fatura"},
                {value: "ithalat", label: "İthalat"},
                {value: "uretim", label: "Üretim"},
                {value: "fire", label: "Fire"},
              ],
              operationTypeSelect:[
                { value: "giris", label: "Giriş" },
                { value: "cikis", label: "Çıkış" }],
        
              UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
              ],
              CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
              ],
              InvoiveType: [
                { value: 0, label: 'T0' },
                { value: 1, label: 'T1' },
                { value: 2, label: 'T2' }
              ],
              KDVList : [
                { value: '18', label: '18' },
                { value: '8', label: '8' },
                { value: '0', label: '0' }
            ],
            productList:this.props.productList.data,
            modelList:this.props.modelList.data,
            companyList:this.props.companyList.data,
            orderError:[],
            errors:{
                firma_adi:"",
                firma_ulke:"",
                tutarhatasi:""
            },
            company_errors:{
                company_name:"",
                company_email:"",
                company_city:"",
                company_country:"",
                company_category:""
            }
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.companyList.data  &&  !this.props.companyList.data.length && this.props.getCompanyList();
        if(this.props.match.params.id==="new"){
            this.setState({
                offer:{    
                    firma_adi : "",
                    teklif_tarihi : moment(new Date()),
                    offer_creator_user: this.props.auth.user,
                    teklif_tarih:true,
                    teklif_konu : "",
                    teklif_notu :"",
                    firma_id:"",
                    firma_adres : "",
                    firma_il : "",
                    buyer_phone:"",
                    firma_eposta:"",
                    firma_ilce : "",
                    firma_ulke : "",
                    teklif_durumu : 0,
                    teklif_dovizi : "$",
                    teklif_usdkur : this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[0].ForexSelling ,
                    teklif_eurkur : this.props.tcmb && this.props.tcmb.Data && this.props.tcmb.Data[1].ForexSelling ,
                    teklif_sevkadresi:"",
                    teklif_referansno:" ",
                    related_person:"",
                    teklif_no:"",
                    offeritem : [{
                      urun_id:"",
                      urun_adi: "",
                      urun_kodu: "",
                      urun_kdv:18,
                      urun_miktari: 1.00,
                      urun_birimi:"Adet",
                      urun_fiyati: 0.00,
                      urun_dovizi : "$",
                      toplamTL:0.00,
                      toplamDoviz:0.00,
                      toplamKDVTL:0.00,
                      toplamKDVDoviz:0.00,
                      siparis_tarihi:"",
                      baslama_tarihi:"",
                      bitis_tarihi:"",
                      order_type:"default"
                    }]
                  },
            })
            this.props.setLoading(true)
            if(this.props.tcmb &&  this.props.tcmb.Data){
                this.setState(prevState => {
                    let offer = Object.assign({}, prevState.offer);  
                    offer.teklif_usdkur =  this.props.tcmb.Data[0].ForexSelling;
                    offer.teklif_eurkur =  this.props.tcmb.Data[1].ForexSelling;                       
                    return { offer };    
                });
            }

        }else{
            this.props.getOfferDetails(this.props.match.params)         
        }
        if(this.props.companyList.data){
            let companyList=[];
            this.props.companyList.data.forEach(comp=>{
                companyList.push({
                    id: comp._id,
                    firma_adi : comp.company_name,
                    address:comp.company_adress,
                    district :comp.company_district,
                    city:comp.company_city,
                    country:comp.company_country,
                    email:comp.company_email
                });  
            })
            this.setState({
                companyList:companyList
            }); 
        }
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.offerDetail.data !== nextProps.offerDetail.data) {
            let  ecode =[];
            nextProps.offerDetail.data.offeritem.forEach(offeritem=>{
                let  orderError= {};
                if(!offeritem.takeoff_from_stock  && offeritem.tamamlanmaOrani && offeritem.tamamlanmaOrani > 0) {
                    orderError= {
                        product : offeritem.urun_adi,
                        orderError: "Üretimi Başlamış iptal edilemez. İptal etmek için üretim operasyonlarını sıfırlayınız.."
                    }
                    ecode.push(orderError)

                } 

            });
            this.setState({
                orderError:ecode,
                offer:nextProps.offerDetail.data
            }); 
        };  
        if (this.props.companyList.data !== nextProps.companyList.data) {
            let companyList=[];
            nextProps.companyList.data.forEach(comp=>{
                companyList.push({
                    id: comp._id,
                    firma_adi : comp.company_name,
                    address:comp.company_adress,
                    district :comp.company_district,
                    city:comp.company_city,
                    country:comp.company_country,
                    email:comp.company_email
                });  
            })
            this.setState({
                companyList:companyList
            });  
        } 
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList:nextProps.productList.data,
            }); 
        };   
 
        if (this.props.tcmb !== nextProps.tcmb) {
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  
                offer.teklif_usdkur =  nextProps.tcmb.Data[0].ForexSelling;
                offer.teklif_eurkur =  nextProps.tcmb.Data[1].ForexSelling;                       
                return { offer };    
            });
        };   
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials:nextProps.materials.data,
            }); 
        }; 
        if (this.props.companyDetail.data !== nextProps.companyDetail.data) {
            this.setState({
                company: nextProps.companyDetail.data,
            }); 
        }; 
    }
//******************************************************************************************************************************************************************/
    onChangeOfferData=(field,data)=>{
        let productionvalid = true;
        switch (field) {
            case "teklif_durumu" :
                const _offer = this.state.offer;
                for (let val of _offer.offeritem) { 
                    if(data===4  ||  data=== 0){
                        val.tamamlanmaOrani > 0 && (productionvalid = false)
                    }else if(data===2){
                      
                    }
                };
            break;
			default:
			break;
        }
        if(productionvalid){
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);   
                offer[field] = data;                          
                return { offer};    
            },()=>this.handleChangeOfferCurrency());
        }else{
            if(productionvalid===false){
                if (window.confirm("Üretim Yapılmayacak Stoklu Ürünler var Doğru mu ?")){}
            }
        }


    }
//******************************************************************************************************************************************************************/
    handleChangeOfferCurrency= (field)  => {
        let newItemArray = this.state.offer.offeritem.map((item) => {
        return { ...item};
        });
        this.calculate(newItemArray);
    }
//******************************************************************************************************************************************************************/
    onChangeOfferCompany=(selected)=>{
      
        const {errors} =  this.state;
        if(selected[0]  &&  selected[0].customOption) {
            errors.firma_adi =(selected[0].customOption).length < 3 
            ? 'Write to Company NameCode'
            : '';
		
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  
                offer.firma_adi = selected[0].firma_adi; 
                offer.firma_id = null ;
                return { offer };    
            });
        }else if(selected[0]  &&  selected[0].firma_adi){
            errors.firma_adi =(selected[0].firma_adi).length < 3 
            ? 'Write to Company NameCode'
            : '';
		
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  
                let errors = Object.assign({}, prevState.errors);
                offer.firma_id =  selected[0].id ? selected[0].id :null ;
                offer.firma_adi = selected[0].firma_adi; 
                offer.firma_adres = selected[0].address; 
                offer.firma_il = selected[0].city;
                offer.firma_ilce = selected[0].district;
                offer.firma_ulke = selected[0].country;
                offer.firma_eposta = selected[0].email
                return { offer,errors };    
            });
        }else{
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  
                offer.firma_adi = "";  
                return { offer };    
            });
        }
        
    }
//******************************************************************************************************************************************************************/
    handleOfferCurrency(e) {
        this.props.offerDovizOpen(e)
    }
//******************************************************************************************************************************************************************/
    handleOfferNotes(e) {
        this.props.offerNotesOpen(e)
    }
//******************************************************************************************************************************************************************/
    onChangeOfferType(e) {
        this.props.setInvoiceType(e)
    }
//******************************************************************************************************************************************************************/
    handleChangeDateLabelOver = date => {
        this.setState(prevState => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable jasper
            offer.teklif_tarihi = date;                     // update the name property, assign a new value                 
            return { offer };    
        })
    };
//******************************************************************************************************************************************************************/
    toggleRowEdit=(id)=>{
        this.setState({
            offerRowModal: !this.state.offerRowModal,
            rowIndex:id
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeProductCode = (index,val,sno) => evt => {
        let PrdouctCodeList = [];
        PrdouctCodeList.push(sno===0 ?evt.target.value :val[0]);
        PrdouctCodeList.push(sno===1 ?evt.target.value :val[1]);
        PrdouctCodeList.push(sno===2 ?evt.target.value :val[2]);
        PrdouctCodeList.push(sno===3 ?evt.target.value :val[3]);
        PrdouctCodeList.push(sno===4 ?evt.target.value :val[4]);
        PrdouctCodeList.push(sno===5 ?evt.target.value :val[5]);
        PrdouctCodeList.push(sno===6 ?evt.target.value :val[6]); 
        PrdouctCodeList.push(sno===7 ?evt.target.value :val[7]);
        PrdouctCodeList.push(sno===8 ?evt.target.value :val[8]);
        let newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
        return { ...item,urun_kodu: PrdouctCodeList};
        });
        this.setState(prevState => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable jasper
            offer.offeritem = newItemArray;
            return { offer };    
        });
    }
//*************************************************************** PRODUCT DETAILS **********************************************************************************/
    onChangeProductName = (data,index) => {
        const newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
        return { ...item, urun_adi: data};
        });
        this.setState(prevState => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable jasper
            offer.offeritem = newItemArray;
            return { offer };    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeItemRow = (index,field) => evt => {
        let value = "";
        if (field==="urun_miktari" || field==="urun_fiyati"){
            value = evt.floatValue
        }else{
            value = evt.target.value
        }
        let newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,[field]: value};
        });
        this.calculate(newItemArray);
    }
//******************************************************************************************************************************************************************/
    removeItemRow = index => {
        if (this.state.offer.offeritem.length>0){ 
            var newItemArray = this.state.offer.offeritem;
            newItemArray.splice(index, 1);
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  // creating copy of state variable 
                offer.offeritem = newItemArray;                     // update the offer property, assign a newitemval               
                return { offer ,offerRowModal: false};  
            });
        }
        this.handleChangeOfferCurrency();
    };
//***************************************************************ADD ROW & REMOVE ROW ******************************************************************************/
    addItemRow = () => {
        const newArray = {
            urun_resim:"",
            //urun_id:"IDYOK0000",
            urun_adi: "",
            urun_kodu: "",
            urun_kdv:18,
            urun_miktari: 1.00,
            urun_birimi:"Adet",
            urun_fiyati: 0.00,
            urun_dovizi : "$",
            toplamTL:0.00,
            toplamDoviz:0.00,
            toplamKDVTL:0.00,
            toplamKDVDoviz:0.00,
            order_type:"default"
        }
        var newItemArray = this.state.offer.offeritem;
        newItemArray.push(newArray);
        this.setState((prevState, props) => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable 
            offer.offeritem = newItemArray;                     // update the offer property, assign a newitemval               
            return { offer,offerRowModal: true , rowIndex:(newItemArray.length-1)};  
        });
    };
//******************************************************************************************************************************************************************/
    calculate = (newItemArray) => {
        let GtoplamTL = 0;
        let GtoplamDoviz = 0;
        let toplamKDVTL = 0;
        let toplamKDVDoviz = 0;
        for(var i=0; i < newItemArray.length ;i++ ){ 
            let TL = 0 ; 
            if (newItemArray[i].urun_dovizi === "$"){
                TL = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * this.state.offer.teklif_usdkur;
            }else if (newItemArray[i].urun_dovizi === "€"){
                TL = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * this.state.offer.teklif_eurkur;
            }else{
                TL = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari);} 
            let DOVIZ = 0 ; 
            if(this.state.offer.teklif_dovizi==="€"){
            if (newItemArray[i].urun_dovizi === "$"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (this.state.offer.teklif_usdkur/this.state.offer.teklif_eurkur);
            }else if (newItemArray[i].urun_dovizi === "€"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * 1;
            }else{
                DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari) / this.state.offer.teklif_eurkur;} 
            }else if (this.state.offer.teklif_dovizi==="$"){
            if (newItemArray[i].urun_dovizi === "$"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * 1;
            }else if (newItemArray[i].urun_dovizi === "€"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (this.state.offer.teklif_eurkur/this.state.offer.teklif_usdkur);
            }else{
                DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari) / this.state.offer.teklif_usdkur;} 
            }else{
            if (newItemArray[i].urun_dovizi === "$"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * this.state.offer.teklif_usdkur;
            }else if (newItemArray[i].urun_dovizi === "€"){
                DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (this.state.offer.teklif_eurkur);
            }else{
                DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari);} 
            }
            newItemArray[i].toplamTL=parseFloat(TL);
            newItemArray[i].toplamDoviz=parseFloat(DOVIZ); 
            newItemArray[i].toplamKdvTL=parseFloat((TL/100)*newItemArray[i].urun_kdv); 
            newItemArray[i].toplamKdvDoviz=parseFloat((DOVIZ/100)*newItemArray[i].urun_kdv); 
            GtoplamTL = GtoplamTL+ TL;
            GtoplamDoviz = GtoplamDoviz + DOVIZ ;
            toplamKDVTL = toplamKDVTL + ((TL/100)*newItemArray[i].urun_kdv)
            toplamKDVDoviz = toplamKDVDoviz + ((DOVIZ/100)*newItemArray[i].urun_kdv)
        };

        this.setState(prevState  => {
            let offer = Object.assign({}, prevState.offer);  
            let errors = Object.assign({}, prevState.errors);
            offer.teklif_tutartl = parseFloat(GtoplamTL);                  
            offer.teklif_kdvtl = parseFloat(toplamKDVTL); 
            offer.teklif_kdvdoviz = parseFloat(toplamKDVDoviz);              
            offer.teklif_tutardoviz = parseFloat(GtoplamDoviz); 
            offer.teklif_gtoplamtl = parseFloat(GtoplamTL+toplamKDVTL);
            offer.teklif_gtoplamdoviz = parseFloat(GtoplamDoviz+toplamKDVDoviz);
            offer.offer_modified_user= this.props.auth.user
            offer.offeritem = newItemArray;         
            errors.firma_adi= this.state.offer.firma_adi === "" ? "Firma Adını Yazınız... New Selection özelliğini kullanınız..." :"" ;        
            errors.firma_ulke=this.state.offer.firma_ulke === "" ? "Firma Ülkesini Yazınız..."  : ""  ;
            errors.firma_id= this.state.offer.teklif_durumu && (this.state.offer.firma_id === "" || this.state.offer.firma_id ===null) ? "Firma Kayıt Edilmeden Sipariş Oluşmaz..."  : "" 
            errors.tutarhatasi= (GtoplamTL+GtoplamDoviz+toplamKDVTL+toplamKDVDoviz)=== 0  ? "Toplamlar Sıfır olamaz..." :""         
            return { offer,errors };  
        });
        
    }
//******************************************************************************************************************************************************************/
    toggleProductListModal=(rowIndex,type)=>{
        if(rowIndex===null){
            this.setState({
                offerRowModal: !this.state.offerRowModal,
                productListModal: !this.state.productListModal,
                type:type
            });
        }else{
            this.setState({
                productListModal: !this.state.productListModal,
                rowIndex:rowIndex,
                type:type
            });
        }

    }
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        if(this.state.type==="product"){
            filteredList =  this.props.productList.data.filter(item => {return item.urun_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.urun_model.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase())})
            if (search && search.target.value===""){
                this.setState({
                    productList:this.props.productList.data,
                    totalItemCount:this.props.productList.data &&  this.props.productList.data.length ? this.props.productList.data.length:0,
                })
            }else{
                this.setState({
                    productList:filteredList,
                    totalItemCount:this.state.productList &&  this.state.productList.length ? this.state.productList.length:0,
                })
            }
        }else{
            filteredList =  this.props.materials.data.filter(item => {return item.material_category.toLowerCase().includes(search.target.value.toLowerCase()) || item.material_group.toLowerCase().includes(search.target.value.toLowerCase())|| item.material_name.toLowerCase().includes(search.target.value.toLowerCase())})
            if (search && search.target.value===""){
                this.setState({
                    materials:this.props.productList.data,
                    totalItemCount:this.props.materials.data &&  this.props.materials.data.length ? this.props.materials.data.length:0,
                })
            }else{
                this.setState({
                    materials:filteredList,
                    totalItemCount:this.state.materials &&  this.state.materials.length ? this.state.materials.length:0,
                })
            }  
        }

    };
//******************************************************************************************************************************************************************/
    selectedProduct =(item)=>{
        const component =  this.state.type;
        const {locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const Birimi = component === "product" ? item.urun_model.model_birimi : item.material_unit;
        const Urun_Adi = component === "product" ?  item.urun_adi[Lang]:item.material_category +" * "+item.material_group+" * "+item.material_name;
        const PID = item._id;
        const KDV  = component === "product" ? item.urun_model.model_kdv  :18;
        const Doviz = component === "product" ? item.urun_doviz : item.material_doviz;
        const Price = component === "product" ? item.urun_fiyat : item.material_price;
        const Stok = item.stockSum;
        const type = component === "product" ? "production" : 'material';

        let PrdouctCodeList = [];
        if(component==="product"){
            PrdouctCodeList.push(item.urun_model.model_kodu);
            PrdouctCodeList.push(item.urun_kodu);
            PrdouctCodeList.push(item.urun_optik);
            PrdouctCodeList.push(item.urun_cct);
            PrdouctCodeList.push(item.urun_panel);
            PrdouctCodeList.push(item.urun_aku);
        }

        const index = this.state.rowIndex;
        const newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item, 
                urun_resim:"",
                urun_id:PID,
                urun_adi: Urun_Adi,
                urun_kodu: PrdouctCodeList,
                urun_kdv: Number(KDV),
                urun_birimi: Birimi,
                urun_fiyati: Price,
                urun_dovizi : Doviz,    
                stockSum :Stok,   
                order_type:type      
            };
        });
        this.setState((prevState) => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable 
            offer.offeritem = newItemArray;                     // update the offer property, assign a newitemval               
            return { offer ,offerRowModal: !this.state.offerRowModal,productListModal:false};  
        },()=>this.handleChangeOfferCurrency());
    }
//******************************************************************************************************************************************************************/
    getImages=(files,index)=>{
        let newItemArray = this.state.offer.offeritem.map((item, sindex) => {
        if (index !== sindex) return item;
            return { ...item,urun_resim: files.base64};
        });
        this.setState(prevState => {
            let offer = Object.assign({}, prevState.offer);  // creating copy of state variable jasper
            offer.offeritem = newItemArray;
            return { offer };    
        });
    }
//******************************************************************************************************************************************************************/
    printOffer=()=>{
    window.open('../pdf/offer/t1/'+this.props.match.params.id,"_blank");
    }
//******************************************************************************************************************************************************************/
    closeOffer=()=>{
    this.props.history.push({pathname : `/offers`})
    }
//******************************************************************************************************************************************************************/
    deleteOffer = () =>{
        let id = this.props.match.params.id;
        if (window.confirm("Teklifi Silmek istiyor musunuz ?")) {
            this.props.deleteOffer(id, this.props.history)
        }else{
        
        } 
    }
//******************************************************************************************************************************************************************/
    validateForm = () => {
        this.setState((prevState) => {
            let errors = Object.assign({}, prevState.errors);  // creating copy of state variable jasper         
            errors.firma_adi= this.state.offer.firma_adi === "" ? "Firma Adını Yazınız..." :""         
            errors.firma_ulke=this.state.offer.firma_ulke === "" ? "Firma Ülkesini Yazınız..."  : ""  
            errors.firma_id= this.state.offer.teklif_durumu!==0 && (this.state.offer.firma_id === "" || this.state.offer.firma_id ===null) ? "Firma Kayıt Edilmeden Sipariş Oluşmaz..."  : ""  
            return { errors};  
        });
    
        const {errors} = this.state;
        let valid = true;
        Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
//******************************************************************************************************************************************************************/
    emailOffer = (i) =>{
        if  (i===1 ){
            this.setState(prevState => {
                let offer = Object.assign({}, prevState.offer);  // creating copy of state variable jasper
                offer.orderTrackingEmail = "true";
                return { offer };    
            },()=>this.saveOffer());
        }
    }

//******************************************************************************************************************************************************************/
    saveOffer = () => {
        
        const { messages } = this.props.intl;
        const {teklif_gtoplamdoviz , teklif_gtoplamtl ,teklif_kdvdoviz ,teklif_kdvtl,offeritem} = this.state.offer
        const  isOK = this.validateForm()

        if((teklif_gtoplamdoviz + teklif_gtoplamtl +teklif_kdvdoviz +teklif_kdvtl)>0  && offeritem.length>0 ){
            if (this.props.match.params.id!=="new"){
                this.props.updateOffer({...this.state.offer}, this.props.history)
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.teklifguncellendi"],
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
            
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.offerDetail.status.statusText,
                            message: this.props.offerDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }else{
                if(isOK){

                    this.props.createOffer({...this.state.offer}, this.props.history)
                    .then(res=>{
                        store.addNotification({
                            title: messages["uyari.basarili"],
                            message: messages["bilgi.teklifolusturuldu"],//{messages[x.label]} 
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });  
                    })
                    .catch(err=>{
                        if(err){
                            store.addNotification({
                                title: messages["uyari.hata"],
                                message: messages["bilgi.teklifolusturulamadı"],//{messages[x.label]} 
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                duration: 3000,
                                onScreen: true
                                }
                            }); 
                        }
                    }); 
                }

            }
        }else{
            this.setState((prevState) => {
                let errors = Object.assign({}, prevState.errors);  // creating copy of state variable jasper         
                errors.tutarhatasi= (teklif_gtoplamdoviz+teklif_gtoplamtl+teklif_kdvdoviz+teklif_kdvtl)=== 0  ? "Toplamlar Sıfır olamaz..." :""         
                return { errors};  
            });
        }

       
    }
//******************************************************************************************************************************************************************/
    gotoDetail=()=>{
        let id = this.props.match.params.id;
        this.props.history.push({pathname : `/order/`+id})
    }
//******************************************************************************************************************************************************************/
    productListOpened = () => {
        if(this.state.type==="product"){
            this.props.productList.data  &&  !this.props.productList.data.length &&  this.props.getProductList("list","model_category");
        }else{
            this.props.materials.data  &&  !this.props.materials.data.length && this.props.getMaterialsList("list")
        }
        
    }
//******************************************************************************************************************************************************************/
    toggleCompanyModal=(id)=>{
        if(id===null){
            this.setState({
                companyModal: !this.state.companyModal,
                company:{    
                    company_name:this.state.offer.firma_adi,
                    company_adress:this.state.offer.firma_adres,
                    company_district:this.state.offer.firma_ilce,
                    company_email:this.state.offer.firma_eposta,
                    company_city:this.state.offer.firma_il,
                    company_country:this.state.offer.firma_ulke,
                    company_category:"Customer",
                    company_phone:this.state.offer.buyer_phone,
                    company_mobilephone:"",
                    company_fax:"",
                    company_taxoffice:"",
                    company_taxno:"",
                    company_img:"",
                    company_notes:"",
                    company_status:true,
                },
                company_errors:{
                    company_name:"",
                    company_email:"",
                    company_city:"",
                    company_country:"",
                    company_category:""
                }

            });
        }else  if (id==="close"){

            if (window.confirm("Düzenlenen firma bilgilerini teklife aktarmak istiyor musunuz ?")) {
                if(this.state.company._id && this.state.company.company_name ){
                    this.setState(prevState => {
                        let offer = Object.assign({}, prevState.offer);   
                        offer.firma_adi = this.state.company.company_name;
                        offer.firma_id = this.state.company._id;
                        offer.firma_adres = this.state.company.company_adress;
                        offer.firma_il = this.state.company.company_city;
                        offer.buyer_phone  = this.state.company.company_phone;
                        offer.firma_eposta = this.state.company.company_email;
                        offer.firma_ilce = this.state.company.company_district;
                        offer.firma_ulke = this.state.company.company_country;
                        return { offer,companyModal: !this.state.companyModal};    
                    });
                }
            }else{
                this.setState({
                    companyModal: !this.state.companyModal,
                });
            } 
        }else{
            this.props.getCompanyDetails({companyId:id})
            this.setState({
                companyModal: !this.state.companyModal,
            });
        }
    }
//******************************************************************************************************************************************************************/
    onChangeCompanyData = (field,data) => {
        const {company_errors} = this.state ;
        // eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        switch (field) {
            case 'company_name': 
            company_errors.company_name = data.length <= 2
                ? 'Company Name must be 3 characters long!'
                : '';
            break;
            case 'company_email': 
            company_errors.company_email = 
                validEmailRegex.test(data)
                ? ''
                : 'Email is not valid!';
                break;
            case 'company_city': 
            company_errors.company_city = data.length < 3
                ? 'Company City must be 3 characters long!'
                : '';
                break;     
            case 'company_country': 
            company_errors.company_country = data.length < 2
                ? 'Select Company Country'
                : '';
                break;    
            case 'company_category': 
            company_errors.company_category = data.length < 2
                ? 'Select Company Category'
                : '';
                break;                    
            default:
                break;
        }
        this.setState(prevState => {
            let company = Object.assign({}, prevState.company); 
            let company_errors = Object.assign({}, prevState.company_errors);
            company[field] = data;   
            return { company,company_errors };    
        });
    }
//******************************************************************************************************************************************************************/
    saveCompany = () => {   
        let valid = true;
        Object.values(this.state.company_errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
        );
        const { messages } = this.props.intl;
        if(valid){
            if (this.state.company._id ){
                this.props.updateCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaguncellendi"],
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    //});
                    },()=>this.toggleCompanyModal());
                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }else{
                this.props.createCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaolusturuldu"],//{messages[x.label]} 
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }
        }else{
            this.setState({
                err_visible: true
            });
        }

    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages } = this.props.intl;
        const {dovizRowVisible,offerNotesVisible,locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const { offer,companyList,CurrencyList,rowIndex,KDVList,productList,errors,UnitList,InvoiveType,orderError,materials,company,company_errors} = this.state;
        const USDSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[0].ForexSelling  : 0 ;
        const EURSelling = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[1].ForexSelling  : 0 ;
        const  USDBuying = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[0].ForexBuying  : 0 ;
        const  EURBuying = this.props.tcmb && this.props.tcmb.Data ? this.props.tcmb.Data[1].ForexBuying  : 0 ;
        const CompanyGroup= [
            { value: "Suppliers", label: "company.Suppliers"},
            { value: "Customer", label: "company.Customer" },
        ];
        return (
            <>
                {
                <div  className={this.props.match.params.id!=="new" && this.props.offerDetail.loading  ? "dimmer active" : ""}>
                    {this.props.match.params.id!=="new" && this.props.offerDetail.loading  && <div className="loader"/>}
                    <div className={this.props.match.params.id!=="new" && this.props.offerDetail.loading  ? "dimmer-content"  : ""}>
                        <div className="container-fluid">
                            {errors.firma_adi &&  <div className="alert alert-danger" role="alert">{errors.firma_adi}</div>}
                            {errors.firma_ulke &&  <div className="alert alert-danger" role="alert" >{errors.firma_ulke}</div>}
                            {errors.firma_id &&  <div className="alert alert-warning" role="alert" >{errors.firma_id}</div>}
                            {errors.tutarhatasi &&  <div className="alert alert-warning" role="alert" >{errors.tutarhatasi}</div>}
                        </div>


                        <div className="container-fluid">
                            {orderError&&  orderError.length ?  orderError.map((err,indexerr)=>{
                                return(
                                    <div className="alert alert-info" role="alert" key={indexerr}>
                                        {err.product +" "+err.err}
                                    </div>
                                )
                            }):null}
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item"><a className="nav-link active" id="offer-tab" data-toggle="tab" href="#offer-detail"><IntlMessages id="offer.detail"/></a></li>
                                    <li className="nav-item"><a className="nav-link" id="offer-tab" data-toggle="tab" href="#offer-setting"><IntlMessages id="offer.setting"/></a></li>
                                </ul>
                                <div className="header-action d-md-flex">
                                    {offer  && offer.teklif_durumu===3 && <button type="button" className="btn btn-secondary ml-2" onClick={()=>this.gotoDetail()}><i className="fa fa-info mr-2" /><IntlMessages id="order.detail"/></button>}
                                    {!orderError.length && offer  && offer.teklif_durumu<=1 && offer._id && <button type="button" className="btn btn-danger ml-2" onClick={()=>this.deleteOffer()}><i className="fa fa-trash mr-2" /><IntlMessages id="button.delete"/></button>}
                                    {errors.tutarhatasi===""  && <button type="button" className="btn btn-success ml-2" onClick={()=>this.saveOffer()}><i className="fa fa-floppy-o mr-2" /><IntlMessages id="button.save"/></button>}
                                    {errors.tutarhatasi===""  &&  offer  && offer.teklif_durumu===1 && <button type="button" className="btn btn-success ml-2" onClick={()=>this.emailOffer(offer.teklif_durumu)}><i className="fa fa-envelope mr-2" /><IntlMessages id="button.email"/></button>}
                                    {offer &&  offer._id && <button type="button" className="btn btn-info ml-2"  onClick={()=>this.printOffer()}><i className="fa fa-print mr-2" /><IntlMessages id="button.print"/></button>}
                                    <button type="button" className="btn btn-warning ml-2" onClick={()=>this.closeOffer()}><i className="fa fa-times-o mr-2" /><IntlMessages id="button.close"/></button>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="card" >
                                <div className="card-status card-status-left bg-info" />
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-7">
                                            <div className="form-group">
                                            <label className="form-label"><IntlMessages id="offer.subject"/></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder={messages["offer.subject"]}
                                                    value={offer && offer.teklif_konu  ? offer.teklif_konu :"" }
                                                    onChange={(evt)=>this.onChangeOfferData("teklif_konu",evt.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-3">
                                            <div className="form-group">
                                            <label className="form-label"><IntlMessages id="offer.person"/></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder={messages["offer.person"]}
                                                    value={offer &&  offer.related_person  ? offer.related_person :"" }
                                                    onChange={(evt)=>this.onChangeOfferData("related_person",evt.target.value)}
                                                />
                                            </div>
                                        </div>                      
                                        <div className="col-sm-12 col-md-2">
                                            <div className="form-group text-right">
                                                <label className="form-label"><IntlMessages id="offer.date"/></label>
                                                <DatePicker
                                                    name="teklif_tarihi"
                                                    className="form-control text-right"
                                                    selected={offer && offer.teklif_tarihi && new Date(offer.teklif_tarihi)}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={this.handleChangeDateLabelOver}
                                                    locale={locale}
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                      
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="offer-detail" role="tabpanel">                   
                                    <div className="card" >
                                        <div className={offer && offer.firma_id  ?"card-status card-status-left bg-success":"card-status card-status-left bg-danger"} />
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-9">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.company"/></label>
                                                        <div className="input-group">
                                                                <Typeahead
                                                                    id="oppp"
                                                                    labelKey={"firma_adi"}
                                                                    allowNew
                                                                    options={companyList}
                                                                    selected={offer && offer.firma_adi? [offer.firma_adi]: []}
                                                                    onChange={(e)=>this.onChangeOfferCompany(e)}
                                                                    ref={(ref) => this._typeahead = ref}
                                                                    renderMenuItemChildren={(option) => (
                                                                        <div>
                                                                        {option.firma_adi}
                                                                        <div>
                                                                            <small>{option.address+" "+option.district+" "+option.city+" "+option.country}</small>
                                                                        </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            <div className="input-group-append">
                                                                <button data-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle" />
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a className="dropdown-item" href="#1" onClick={()=>this.toggleCompanyModal(null)}><IntlMessages id="offer.newcompany"/></a>
                                                                    {offer && offer.firma_id &&  <a className="dropdown-item" href="#2" onClick={()=>this.toggleCompanyModal(offer.firma_id)}><IntlMessages id="offer.companyedit"/></a>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.firma_adi}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.referance"/></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={offer && offer.teklif_referansno  ? offer.teklif_referansno :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("teklif_referansno",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder={messages["offer.address"]}
                                                            value={offer && offer.firma_adres  ? offer.firma_adres :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("firma_adres",evt.target.value)}
                                                        />
                                                        <div className="invalid-feedback">{errors.firma_adres}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder={messages["offer.district"]}
                                                            value={offer &&  offer.firma_ilce  ? offer.firma_ilce :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("firma_ilce",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder={messages["offer.city"]}
                                                            value={offer && offer.firma_il  ? offer.firma_il :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("firma_il",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder={messages["offer.country"]}
                                                            value={offer && offer.firma_ulke  ? offer.firma_ulke :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("firma_ulke",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder={messages["offer.email"]}
                                                            value={offer && offer.firma_eposta  ? offer.firma_eposta :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("firma_eposta",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={offer && offer.teklif_no  ? offer.teklif_no :"" }
                                                            onChange={(evt)=>this.onChangeOfferData("teklif_no",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    {!orderError.length && 
                                                    <div className="form-group">
                                                        <div className="selectgroup w-100">
                                                            {offer && (offer.teklif_durumu === 0  || offer.teklif_durumu === 4) &&
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="teklif_durumu" onChange={()=>this.onChangeOfferData("teklif_durumu",0)} className="selectgroup-input"  defaultChecked={offer.teklif_durumu === 0} />
                                                                <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus0"/></span>
                                                            </label>}
                                                            {offer && (offer.teklif_durumu === 0  || offer.teklif_durumu === 1) &&
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="teklif_durumu" onChange={()=>this.onChangeOfferData("teklif_durumu",1)} className="selectgroup-input" checked={offer.teklif_durumu === 1}/>
                                                                <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus1"/></span>
                                                            </label>}

                                                            {offer && offer.teklif_durumu === 1   &&
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="teklif_durumu" onChange={()=>this.onChangeOfferData("teklif_durumu",4)} className="selectgroup-input" checked={offer.teklif_durumu === 3}/>
                                                                <span className="selectgroup-button"><IntlMessages id="offer.OfferStatus4"/></span>
                                                            </label>}
 
                                                        </div>
                                                    </div>}
                                                </div>

                                            </div>
                                        </div>                              
                                    </div>
                                    <div className={`card  ${!dovizRowVisible ? 'card-collapsed' : ""}`}>
                                        <div className="card-status card-status-left bg-warning"/>
                                        <div className="card-header">
                                            <h3 className="card-title"><IntlMessages id="offer.currencyDetail"/>{" =>  "}<IntlMessages id="offer.currency"/>{" "+(offer && offer.teklif_dovizi)+" "}</h3>
                                            <div className="card-options">
                                                <span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleOfferCurrency(!dovizRowVisible)}><i className="fe fe-chevron-up" /></span>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.USDDetail"/></label>
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                    
                                                                value={offer && offer.teklif_usdkur  ? offer.teklif_usdkur :"" }
                                                                onChange={(evt)=>this.onChangeOfferData("teklif_usdkur",evt.target.value)}
                                                            />
                                                            <div className="input-group-append">
                                                                <button  type="button" className="btn btn-primary" onClick={()=>this.onChangeOfferData("teklif_usdkur",USDSelling)} >{USDSelling}</button>
                                                                <button  type="button" className="btn btn-secondary" onClick={()=>this.onChangeOfferData("teklif_usdkur",USDBuying)} >{USDBuying}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.EURDetail"/></label>
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder={messages["offer.address"]}
                                                                value={offer && offer.teklif_eurkur  ? offer.teklif_eurkur :"" }
                                                                onChange={(evt)=>this.onChangeOfferData("teklif_eurkur",evt.target.value)}
                                                            />
                                                            <div className="input-group-append">
                                                                <button  type="button" className="btn btn-primary" onClick={()=>this.onChangeOfferData("teklif_eurkur",EURSelling)}>{EURSelling}</button>
                                                                <button  type="button" className="btn btn-secondary" onClick={()=>this.onChangeOfferData("teklif_eurkur",EURBuying)}>{EURBuying}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.currency"/></label>
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeOfferData("teklif_dovizi",evt.target.value)}
                                                            value={offer && offer.teklif_dovizi}
                                                        >
                                                            {CurrencyList && CurrencyList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="offer-setting" role="tabpanel">
                                    <div className="card">
                                        <div className="card-status card-status-left bg-warning"/>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.viewDate"/></label>
                                                        <label className="custom-switch">
                                                            <input type="checkbox" checked={offer && offer.teklif_tarih===true ? true :false}  className="custom-switch-input" onChange={()=>this.onChangeOfferData("teklif_tarih",!offer.teklif_tarih)}/>
                                                            <span className="custom-switch-indicator" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.stampsignOffer"/></label>
                                                        <label className="custom-switch">
                                                            <input type="checkbox" checked={offer && offer.teklif_kaseimza===true ? true:false} className="custom-switch-input" onChange={()=>this.onChangeOfferData("teklif_kaseimza",!offer.teklif_kaseimza)}/>
                                                            <span className="custom-switch-indicator" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.stampOffer"/></label>
                                                        <label className="custom-switch">
                                                            <input type="checkbox" checked={offer && offer.teklif_kase===true ? true:false} className="custom-switch-input" onChange={()=>this.onChangeOfferData("teklif_kase",!offer.teklif_kase)}/>
                                                            <span className="custom-switch-indicator" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.commercialInvoice"/></label>
                                                        <label className="custom-switch">
                                                            <input type="checkbox" checked={offer && offer.commercial_invoice===true ? true:false} className="custom-switch-input" onChange={()=>this.onChangeOfferData("commercial_invoice",!offer.commercial_invoice)}/>
                                                            <span className="custom-switch-indicator" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="offer.commercialInvoice"/></label>
                                                        <select 
                                                            className="form-control text-center"
                                                            onChange={(e)=>this.onChangeOfferType(e.target.value)}
                                                            value={this.props.invoiceType}
                                                        >
                                                            {InvoiveType && InvoiveType.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-status card-status-left  bg-success"/>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-center w-1"><i className="icon-people" /></th>
                                                    <th ><IntlMessages id="offer.product"/></th>
                                                    <th className="text-center"><IntlMessages id="offer.qty"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.price"/></th>
                                                    <th className="text-center"><IntlMessages id="offer.tax"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.rowtotal"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.rowgrandtotal"/></th>
                                                    <th className="text-center w20"><i className="fa fa-plus" onClick={()=>this.addItemRow()}/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {offer  && offer.offeritem && offer.offeritem.map((item,rowIndex) => {
                                                let image = "../assets/images/ry.png" ;
                                                if(item.urun_id &&  item.urun_id.productImages &&  item.urun_id.productImages.length && item.urun_id.productImages[0].image  && item.urun_id.productImages[0].image.smallimage ){
                                                    image = item.urun_id.productImages[0].image.smallimage;
                                                }else{
                                                    image = "../assets/images/ry.png" ;
                                                }
                                                const ModelKodu = item.urun_kodu[0] ? item.urun_kodu[0] :""
                                                const PCBKodu = item.urun_kodu[1] ? ("-"+item.urun_kodu[1]):""
                                                const Optik = item.urun_kodu[2] ? ("-"+item.urun_kodu[2]):""
                                                const CCT  = item.urun_kodu[3] ? ("-"+item.urun_kodu[3]):""
                                                const Solar  = item.urun_kodu[4] ? ("-"+item.urun_kodu[4]):""
                                                const Battery  = item.urun_kodu[5] ? ("-"+item.urun_kodu[5]):""


                                                return(
                                                    <tr key={rowIndex}>
                                                        <td className="text-center">
                                                            <div className="avatar d-block">
                                                                <img className="rounded-circle img-thumbnail w80" src={image} alt="avatar" />
                                                                <span className="avatar-status bg-blue" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div><a href="#1" onClick={()=>this.toggleRowEdit(rowIndex)}>{item.urun_adi}</a></div>
                                                            <span className="text-muted"><a href="#1"  className="small text-muted" onClick={()=>this.toggleRowEdit(rowIndex)}>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</a></span>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="text-center"><strong>{item.urun_miktari+" "+item.urun_birimi}</strong></div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div>
                                                                <NumberFormat
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true} 
                                                                    decimalScale={2}
                                                                    suffix={' '+item.urun_dovizi} 
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={item.urun_fiyati}  
                                                                /> 
                                                            </div>                                                   
                                                            <div className="small text-muted">{" "}</div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div><strong>{item.urun_kdv+" %"}</strong></div>
                                                        </td>
                                                        <td className="text-right">
                                                            <div>
                                                                <NumberFormat
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true} 
                                                                    decimalScale={2}
                                                                    suffix={' '+offer.teklif_dovizi} 
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={item.toplamDoviz}  
                                                                /> 
                                                            </div>
                                                            <div className="small text-muted">
                                                                <NumberFormat
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true} 
                                                                    decimalScale={2}
                                                                    suffix={' ₺'} 
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={item.toplamTL}  
                                                                /> 
                                                            </div>
                                                        </td>
                                                        <td  className="text-right">
                                                            <div>
                                                                <NumberFormat
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true} 
                                                                    decimalScale={2}
                                                                    suffix={" "+offer.teklif_dovizi} 
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={item.toplamDoviz+item.toplamKdvDoviz}  
                                                                />                                                 
                                                            </div>
                                                            <div className="small text-muted">
                                                                <NumberFormat
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true} 
                                                                    decimalScale={2}
                                                                    suffix={' ₺'} 
                                                                    thousandSeparator={'.'} 
                                                                    decimalSeparator={','}  
                                                                    value={(item.toplamTL+item.toplamKdvTL)}  
                                                                /> 
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="item-action dropdown">
                                                                <a href="#0" data-toggle="dropdown" className="icon"><i className="fe fe-more-vertical" /></a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.toggleRowEdit(rowIndex)}><i className="dropdown-icon fa fa-eye" /><IntlMessages id="offer.rowedit"/></a>
                                                                    <div className="dropdown-divider" />
                                                                    {offer.teklif_durumu===0 && <a href="#6" className="dropdown-item"  onClick={()=>this.removeItemRow(rowIndex)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="offer.rowDelete"/></a>}
                                                                    
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )})}
                                                <tr>
                                                    <td colSpan={5} className="font600 text-right"><IntlMessages id="offer.offerTotal"/></td>
                                                    <td className="font600 text-right text-red">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' ₺'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_tutartl)} 
                                                        /> 
                                                    </td>
                                                    <td className="font600 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+(offer &&  offer.teklif_dovizi && offer.teklif_dovizi)} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_tutardoviz)} 
                                                        /> 
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="font600 text-right"><IntlMessages id="offer.offerKDV"/></td>
                                                    <td className="font600 text-right text-red">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' ₺'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_kdvtl)}  
                                                        /> 
                                                    </td>
                                                    <td className="font600 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+(offer &&  offer.teklif_dovizi && offer.teklif_dovizi)} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_kdvdoviz)}  
                                                        /> 
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className="font700 text-right"><IntlMessages id="offer.offerGrandTotal"/></td>
                                                    <td className="font700 text-right text-red">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' ₺'} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_gtoplamtl)}  
                                                        />                                                 
                                                    </td>
                                                    <td className="font700 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+(offer &&  offer.teklif_dovizi && offer.teklif_dovizi)} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(offer &&  offer.teklif_gtoplamdoviz)}  
                                                        />                                                 
                                                    </td>
                                                    <td></td>
                                                </tr>        
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className={`card  ${!offerNotesVisible ? 'card-collapsed' : ""}`}>
                                <div className="card-status card-status-left bg-warning"/>
                                <div className="card-header">
                                    <h3 className="card-title"><IntlMessages id="offer.offerNotes"/></h3>
                                    <div className="card-options">
                                        <span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleOfferNotes(!offerNotesVisible)}><i className="fe fe-chevron-up" /></span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="offer.offerSubNotes"/></label>
                                                <textarea
                                                    type="text"
                                                    rows={6}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={offer && offer.teklif_altbilgi  ? offer.teklif_altbilgi :"" }
                                                    onChange={(evt)=>this.onChangeOfferData("teklif_altbilgi",evt.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="offer.offerNotes"/></label>
                                                <textarea
                                                    type="text"
                                                    rows={6}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={offer && offer.teklif_notu  ? offer.teklif_notu :"" }
                                                    onChange={(evt)=>this.onChangeOfferData("teklif_notu",evt.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-status card-status-left  bg-success"/>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-2"><span colSpan={5} className="font400"><IntlMessages id="offer.creator"/></span></div>
                                        <div className="col-sm-12 col-md-4"><span className="font400 text-blue">{offer && moment(offer.created_date).format("DD.MM.yyyy")+" / "+(offer && offer.offer_creator_user)}</span></div>
                                        <div className="col-sm-12 col-md-2"><span className="font400"><IntlMessages id="offer.updater"/></span></div>
                                        <div className="col-sm-12 col-md-3"><span className="font400 text-green">{offer && offer.modified_date && (moment(offer.modified_date).format("DD.MM.yyyy")+" / "+(offer && offer.offer_modified_user))}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                }  
                <Modal 
                    isOpen={this.state.offerRowModal}
                    toggle={this.toggleOfferRowModal}
                    size="lg"
                    //wrapClassName="modal-right"
                    //onOpened={() => this.productionRowModalonOpen()} 
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="offer.rowedit"/>
                </ModalHeader>
                <ModalBody>
                    {offer && offer.offeritem && offer.offeritem[rowIndex] &&
                    <div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.product"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Ürün Adı"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_adi ? offer.offeritem[rowIndex].urun_adi :"" }
                                        onChange={(evt)=>this.onChangeProductName(evt.target.value,rowIndex)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.modelCode"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Model Kodu"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[0] ? offer.offeritem[rowIndex].urun_kodu[0] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,0)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.pcbCode"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="PCB Kodu"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[1] ? offer.offeritem[rowIndex].urun_kodu[1] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,1)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.optik"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Güç"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[2] ? offer.offeritem[rowIndex].urun_kodu[2] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,2)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.cct"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Optik Kodu"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[3] ? offer.offeritem[rowIndex].urun_kodu[3] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,3)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.solar"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Solar Panel"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[4] ? offer.offeritem[rowIndex].urun_kodu[4] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,4)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.battery"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder="Solar"
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[5] ? offer.offeritem[rowIndex].urun_kodu[5] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,5)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.bodyColor"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder={messages["offer.bodyColor"]}
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[6] ? offer.offeritem[rowIndex].urun_kodu[6] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,6)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.LED"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder={messages["offer.LED"]}
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[7] ? offer.offeritem[rowIndex].urun_kodu[7] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,7)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                                <div className="form-group">
                                <label className="form-label"><IntlMessages id="offer.LEDSurucu"/></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholder={messages["offer.LEDSurucu"]}
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_kodu && offer.offeritem[rowIndex].urun_kodu[8] ? offer.offeritem[rowIndex].urun_kodu[8] :"" }
                                        onChange={this.handleChangeProductCode(rowIndex,offer.offeritem[rowIndex].urun_kodu,8)}
                                    />
                                </div>
                            </div>
                        </div> 
                        <hr/>
                        <div className="row">
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                    <label className="form-label text-center"><IntlMessages id="offer.qty"/></label>
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_miktari ? offer.offeritem[rowIndex].urun_miktari :1 }  
                                        className="form-control text-center" 
                                        onValueChange={this.handleChangeItemRow(rowIndex,"urun_miktari")} 
                                    />
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                    <label className="form-label text-center"><IntlMessages id="offer.unit"/></label>
                                    <select 
                                        className="form-control text-center"
                                        onChange={this.handleChangeItemRow(rowIndex,"urun_birimi")}
                                        value={offer.offeritem[rowIndex].urun_birimi}
                                    >
                                        {UnitList && UnitList.map((x,y)=>{
                                            return(
                                                <option key={y}  value={x.value}>{messages[x.label]} </option>
                                            )
                                        })}
                                    </select>

                                </div>
                            </div>
                            <div className="col-sm-4 col-md-2">
                                <div className="form-group">
                                    <label className="form-label text-right"><IntlMessages id="offer.price"/></label>
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].urun_fiyati ? offer.offeritem[rowIndex].urun_fiyati :1 }  
                                        className="form-control text-right" 
                                        onValueChange={this.handleChangeItemRow(rowIndex,"urun_fiyati")}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label text-center"><IntlMessages id="offer.currency"/></label>
                                    <select 
                                        className="form-control text-center"
                                        onChange={this.handleChangeItemRow(rowIndex,"urun_dovizi")}
                                        value={offer.offeritem[rowIndex].urun_dovizi}
                                    >
                                        {CurrencyList && CurrencyList.map((x,y)=>{
                                            return(
                                                <option key={y}  value={x.value}>{x.label} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>  
                            <div className="col-sm-12 col-md-1">
                                <div className="form-group">
                                    <label className="form-label text-center"><IntlMessages id="genel.kdv"/></label>
                                    <select 
                                        className="form-control"
                                        onChange={this.handleChangeItemRow(rowIndex,"urun_kdv")}
                                        value={offer.offeritem[rowIndex].urun_kdv}
                                    >
                                        {KDVList.map((x,y)=>{
                                            return(
                                                <option key={y}  value={x.value}>{x.label} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-3">
                                <div className="form-group">
                                    <label className="form-label text-right"><IntlMessages id="offer.product₺price"/></label>
                                    <NumberFormat
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','}  
                                        value={offer.offeritem[rowIndex].urun_fiyati * (offer.offeritem[rowIndex].urun_dovizi ==="₺" ? 1 : offer.offeritem[rowIndex].urun_dovizi ==="$"  ? offer.teklif_usdkur : offer.teklif_eurkur) }  
                                        className="form-control text-right" 
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row row-cards">
                            <div className="col-sm-8 col-md-8">
                                <label className="form-label"><IntlMessages id="offer.productPhoto"/></label>
                                <div className="col-12">
                                    <div className="page-options d-flex">
                                        <FileBase64 multiple={ false }  onDone={(file)=>this.getImages(file,rowIndex)} />
                                    </div>

                                </div>
                            </div>

                            
                            <div className="col-sm-2 col-md-2" >
                                <div className="form-group">
                                    <label className="form-label"><IntlMessages id="product.stock"/></label>
                                    {offer && offer.offeritem[rowIndex]&&
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            value={offer  && offer.offeritem[rowIndex] &&  offer.offeritem[rowIndex].stockSum  && offer.offeritem[rowIndex].stockSum ? offer.offeritem[rowIndex].stockSum:0 }  
                                            readOnly
                                        />
}
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <table className="table table_custom spacing5 border-style mb-0">
                            <tbody>
                                <tr>
                                    <td className="font600 text-right">
                                        <IntlMessages id="offer.offerrowTotal"/>
                                    </td>
                                    <td className="font600 text-right text-red">
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" ₺"} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].toplamTL ? offer.offeritem[rowIndex].toplamTL :0 }  
                                        />
                                    </td>
                                    <td className="font600 text-right">
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" "+offer.teklif_dovizi} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].toplamDoviz ? offer.offeritem[rowIndex].toplamDoviz :0 }  
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font600 text-right"> 
                                        <span><IntlMessages id="offer.offerrowKDV"/></span>
                                    </td>
                                    <td className="font600 text-right text-red">
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" ₺"} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].toplamKdvTL ? offer.offeritem[rowIndex].toplamKdvTL :0 }  
                                        />
                                    </td>
                                    <td className="font600 text-right">
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" "+offer.teklif_dovizi} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex] && offer.offeritem[rowIndex].toplamKdvDoviz ? offer.offeritem[rowIndex].toplamKdvDoviz :0 }  
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font700 text-right">
                                        <span><IntlMessages id="offer.offerrowGrandTotal"/></span>
                                    </td>
                                    <td className="font700 text-right text-red">
                                        <strong>
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" ₺"} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex].toplamKdvTL + offer.offeritem[rowIndex].toplamTL}  
                                        />
                                        </strong>
                                    </td>
                                    <td className="font700 text-right">
                                        <strong>
                                        <NumberFormat
                                            displayType="text"
                                            suffix={" "+offer.teklif_dovizi} 
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={'.'} 
                                            decimalSeparator={','}  
                                            value={offer.offeritem[rowIndex].toplamKdvDoviz + offer.offeritem[rowIndex].toplamDoviz }  
                                        />
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    }
                </ModalBody>
                <ModalFooter>
                    {offer && offer.offeritem &&
                    <button type="button" className="btn btn-round btn-danger" onClick={()=>this.removeItemRow(rowIndex)}>
                        <IntlMessages id="button.delete" />
                    </button>}{"  "}
                    &nbsp;&nbsp;
                    {offer && offer.teklif_durumu===0 && <button type="button" className="btn btn-round btn-warning" onClick={()=>this.toggleProductListModal(null,"product")}>
                        <IntlMessages id="offer.selectProduct"/>
                    </button>}
                    {offer && offer.teklif_durumu===0 && <button type="button" className="btn btn-round btn-warning" onClick={()=>this.toggleProductListModal(null,"material")}>
                        <IntlMessages id="offer.selectMaterial"/>
                    </button>}
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleRowEdit(null)}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>           
                
                <Modal
                    isOpen={this.state.productListModal}
                    toggle={this.toggleProductListModal}
                    size="lg"
                    onOpened={()=>this.productListOpened()}
                    backdrop="static"
                    scrollable={true}
                >
                <ModalHeader>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyPress={e => this.onSearchKey(e)}
                    />
                </ModalHeader>
                <ModalBody>
                <div  className={((this.state.type==="product" &&  this.props.productList.loading) || (this.state.type==="material" &&  this.props.materials.loading)) ? "dimmer active" : ""}>
                    {((this.state.type==="product" && this.props.productList.loading) || (this.state.type==="material" &&  this.props.materials.loading)) && <div className="loader"/>}
                        <div className={((this.state.type==="product" && this.props.productList.loading) || (this.state.type==="material" &&  this.props.materials.loading)) ? "dimmer-content"  : ""}>
                            <div className="table-responsive">
                                <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 border-style mb-0">
                                    <tbody>
                                    {this.state.type==="product" ? 
                                        productList.map((productRow,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td className="w80">
                                                        <img
                                                            className="rounded-circle img-thumbnail w80"
                                                            src={productRow.productImages ?productRow.productImages :"../assets/images/ry.png"}
                                                            alt="moduled"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="font-15">{productRow.urun_adi[Lang]}</div>
                                                        <span className="text-muted">{productRow.urun_model.model_kodu+"-"+productRow.urun_kodu+"-"+productRow.urun_optik+"-"+productRow.urun_cct}</span>
                                                    </td>
                                                    <td className="font400 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+productRow.urun_doviz} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(productRow.urun_fiyat)}  
                                                        />  
                                                    </td>
                                                    <td className="text-right">
                                                        <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedProduct(productRow)}>Seç</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        materials && materials.map((materialRow,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td className="w80">
                                                        <img
                                                            className="rounded-circle img-thumbnail w80"
                                                            src={"../assets/images/ry.png"}
                                                            alt="moduled"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="font-15">{materialRow.material_category+"-"+materialRow.material_group}</div>
                                                        <span className="text-muted">{materialRow.material_name}</span>
                                                    </td>
                                                    <td className="font400 text-right">
                                                        <NumberFormat
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} 
                                                            decimalScale={2}
                                                            suffix={' '+materialRow.material_doviz} 
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','}  
                                                            value={(materialRow.material_price)}  
                                                        />  
                                                    </td>
                                                    <td className="text-right">
                                                        <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedProduct(materialRow)}>Seç</button>
                                                    </td>
                                                </tr>
                                            )
                                        })                                    
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleProductListModal(null,null)}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>      

                <Modal
                    isOpen={this.state.companyModal}
                    size="lg"
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="company.info" />
                </ModalHeader>
                <ModalBody>
                    <div  className={this.state.company  && this.state.company._id && !this.props.companyDetail.loading  ? "dimmer active" : ""}>
                        {this.state.company  && this.state.company._id && !this.props.companyDetail.loading  && <div className="loader"/>}
                        <div className={this.state.company && this.state.company._id && !this.props.companyDetail.loading  ? "dimmer-content"  : ""}>                                
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                    <label className="form-label"><IntlMessages id="company.name" /></label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className={company_errors.company_name ? "form-control is-invalid":"form-control"}
                                            placeholder="Company Name"
                                            value={company &&  company.company_name ? company.company_name :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_name",evt.target.value)}
                                        />
                                        <div className="invalid-feedback">{company_errors.company_name}</div>
                                    </div>
                                </div>
                            </div>  
                            <label className="form-label"><IntlMessages id="company.address" /></label>
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="State/Province"
                                            value={company &&  company.company_district ? company.company_district :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_district",evt.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            autoComplete="off"
                                            className={company_errors.company_city ? "form-control is-invalid":"form-control"}
                                            placeholder="City" 
                                            value={company &&  company.company_city ? company.company_city :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_city",evt.target.value)}
                                        />
                                        <div className="invalid-feedback">{company_errors.company_city}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <select 
                                            className={company_errors.company_country ? "form-control is-invalid":"form-control"}
                                            onChange={(evt)=>this.onChangeCompanyData("company_country",evt.target.value)}
                                            value={company && company.company_country && company.company_country}
                                        >
                                            {Countries.map((x,index)=>{
                                                return(
                                                    <option key={index} value={x.value}>{x.label}  </option>
                                                )
                                            })}
                                        </select>
                                        <div className="invalid-feedback">{company_errors.company_country}</div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            rows={2}
                                            type="text"
                                            className="form-control"
                                            placeholder="Address"
                                            value={company &&  company.company_adress ? company.company_adress :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_adress",evt.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>  
                            <label className="form-label"><IntlMessages id="company.taxinfo" /></label>
                            <div className="row clearfix">
                                <div className="col-lg-5 col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tax Office"
                                            value={company &&  company.company_taxoffice ? company.company_taxoffice :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_taxoffice",evt.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tax No"
                                            value={company &&  company.company_taxno ? company.company_taxno :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_taxno",evt.target.value)}
                                        />
                                    </div>
                                </div>{" "}&nbsp;&nbsp;
                            </div>  
                            <label className="form-label"><IntlMessages id="company.contact" /></label>
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-phone" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone"
                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                value={company &&  company.company_phone ? company.company_phone :"" }
                                                onChange={(evt)=>this.onChangeCompanyData("company_phone",evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>                                                    
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-fax" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Fax"
                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                value={company &&  company.company_fax ? company.company_fax :"" }
                                                onChange={(evt)=>this.onChangeCompanyData("company_fax",evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-fax" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mobile"
                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                value={company &&  company.company_mobilephone ? company.company_mobilephone :"" }
                                                onChange={(evt)=>this.onChangeCompanyData("company_mobilephone",evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="row clearfix">
                                <div className="col-lg-5 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-envelope" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={company_errors.company_email ? "form-control is-invalid":"form-control"}
                                                placeholder="e-Mail"
                                                value={company &&  company.company_email ? company.company_email :"" }
                                                onChange={(evt)=>this.onChangeCompanyData("company_email",evt.target.value)}
                                            />
                                            <div className="invalid-feedback">{company_errors.company_email}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="icon-globe" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                placeholder="http://"
                                                value={company &&  company.company_web ? company.company_web :"" }
                                                onChange={(evt)=>this.onChangeCompanyData("company_web",evt.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label"><IntlMessages id="company.aboutme" /></label>
                                        <textarea
                                            rows={2}
                                            type="text"
                                            className="form-control"
                                            placeholder="About Me"
                                            value={company &&  company.company_notes ? company.company_notes :"" }
                                            onChange={(evt)=>this.onChangeCompanyData("company_notes",evt.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="form-group">
                                        <select 
                                            className="form-control"
                                            onChange={(evt)=>this.onChangeCompanyData("company_category",evt.target.value)}
                                            value={company && company.company_category && company.company_category}
                                        >
                                            {CompanyGroup.map((x,y)=>{
                                                return(
                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                )
                                            })}
                                        </select>
                                        <div className="invalid-feedback">{errors.company_category}</div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <label className="custom-switch">
                            <input type="checkbox" 
                                name="option" 
                                checked={company && company.company_status  ? company.company_status :true }
                                className="custom-switch-input"  
                                onChange={(evt)=>this.onChangeCompanyData("company_status",!company.company_status)}
                            />
                            <span className="custom-switch-indicator" />
                            <span className="custom-switch-description"><IntlMessages id="company.status" /></span>
                        </label>
                    </div>
   


                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-round btn-success" onClick={()=>this.saveCompany()}>
                        <IntlMessages id="button.save" />
                    </button>{' '}
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleCompanyModal("close")}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>     
            
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    auth:state.auth,
    tcmb:state.tcmb.tcmb,
    locale:state.settings.locale,
    dovizRowVisible:state.settings.offerDovizRow,
    offerNotesVisible:state.settings.offerNotesRow,
    invoiceType:state.settings.invoiceType,
    offerDetail:state.offerDetail,
    companyList : state.companyList,
    productList:state.productList,
    materials:state.materials,
    modelList:state.modelList,
    companyDetail:state.companyDetail
})

const mapDispatchToProps  =({getOfferDetails,getCompanyList,updateCompany,createCompany,getCompanyDetails,offerDovizOpen,offerNotesOpen,getProductList,getModelList,createOffer,updateOffer,deleteOffer,setLoading,setInvoiceType,getMaterialsList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OfferDetails)));
