import React from 'react';
import { Page, Document, StyleSheet, Font, View ,Image} from '@react-pdf/renderer';
import QRCode from 'qrcode';
//************************************************ */
import styled from '@react-pdf/styled-components';
import moment from "moment";
import ArialBold from "../../fonts/arial-bold.ttf"
import Arial from "../../fonts/arial.ttf"
import GillSans from "../../fonts/gillsans.ttf"
import OpenSansFont from "../../fonts/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../fonts/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../fonts/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../fonts/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'ArialBold', src: ArialBold  });
Font.register({ family: 'Arial', src: Arial  });
Font.register({ family: 'GillSans', src: GillSans  });


const styles = StyleSheet.create(
    {
    page: {
        padding: 10,
        fontFamily: 'ArialBold',
    }, 
    Header: {
        width:200,
        height:25,
        flexDirection: "row",
        alignItems:"center",
        alignSelf:"center",
        borderBottomWidth: 2
    },
    logo: {
        width: 100,
        height: 10,
        alignItems:"left",
        alignSelf:"center"
    },
    Header2: {
        width:100,
        height:25,
        flexDirection: "columnn",
        alignItems:"left",
    },
    ProductInfoContainer: {
        fontFamily: 'ArialBold',
        marginTop:1,
        flexDirection: "column",
        width: 200,
        height: 25,
    },
    SpecContainer: {
        fontFamily: 'ArialBold',
        marginTop:5,
        flexDirection: "column",
        width: 200,
        borderTopWidth:1
    },
    SpecRow: {
        marginTop:1,
        flexDirection: "row",
        width: 200,
        height: 12,
    },

    productionInfo: {
        fontFamily: 'ArialBold',
        marginTop:5,
        flexDirection: "column",
        width: 200,
        borderTopWidth:1
    },
    warning: {
        fontFamily: 'ArialBold',
        marginTop:5,
        flexDirection: "row",
        width: 200,
        borderTopWidth:1
    },
    warningCol0: {
        fontFamily: 'ArialBold',
        flexDirection: "column",
        width: 160,
    },
    warningCol1: {
        fontFamily: 'ArialBold',
        flexDirection: "column",
        width: 40,
    },
    CertificatesContainer: {
        flexDirection: "row",
        width: 200,
        height: 20,
        marginTop:5,
        alignItems:'center',
        borderTopWidth:1,
        alignSelf:"center",
       
    }
});
const Label8px = styled.Text`
margin_left:2px;
margin_top:2px;
height:12;
font-size: 8px;
text-align: left;
`;
const Label6px = styled.Text`
margin_Top:2px;
margin_left:2px;
height:8px;
font-size: 6px;
text-align: center;
`;
const SpecRow0 = styled.Text`
margin_Top:1px;
margin_left:2px;
height:12px;
width:140;
font-size: 8px;
text-align: left;
`;
const SpecRow1 = styled.Text`
margin_Top:1px;
margin_left:2px;
height:12px;
width:60;
font-size: 8px;
text-align: left;
`;
const Certificates = styled.Image`
margin_left:5px;
height: 18;
width: 18;
color: #0C71E0;
`;
const Warning = styled.Text`
margin_left:2px;
height:35;
font-size: 5px;
text-align: justify`;
const Label10x10 = ({company,data,messages}) =>{
    let value = data.ProductID ? window.location.origin+"/web/"+data.ProductID : "www.moduled.com.tr";
    let QrImage = "";
    QRCode.toDataURL(value, function(err, url) {
        QrImage=  url;
    });
    return (
    <Document>
        {company.bwlogo && data  && 
        <Page size={[283.4,226.4 ]} orientation={"landscape"} style={styles.page}>
            <View style={styles.Header}>
                <View style={styles.Header}>
                    {data.logoOpen &&  <Image style={styles.logo} src={company.bwlogo}/> }      
                    <View style={styles.Header2}>
                        <Label8px>{data.Power}</Label8px>
                    </View>
                </View>
            </View>
            <View style={styles.ProductInfoContainer}>
                <Label8px>{data.productName.toUpperCase()+" ("+data.productCode.toUpperCase()+")"}</Label8px>
                <Label8px>{messages["product.dimensions"]+": "+data.Dimensions +"/"+data.Weight }</Label8px>
            </View>   
            <View style={styles.SpecContainer}>
                {data.Specs.map((spec,sindex)=>{
                    return(
                        <View style={styles.SpecRow} key={sindex}>
                            <SpecRow0>{spec.urun_ozellik}</SpecRow0>
                            <SpecRow1>{spec.urun_aciklama}</SpecRow1>
                        </View>
                    )
                })}
            </View>   
            <View style={styles.CertificatesContainer}>
                {data.Certificates && data.Certificates.map((cer,index)=>{
                    return(
                        <Certificates  style={styles.Certificates} src={cer.bwfile} key={index}/>
                    )
                })}
            </View>
            <View style={styles.warning}>
                <View style={styles.warningCol0}>
                    <Warning>{data &&  data.warning}</Warning>
                </View>
                <View style={styles.warningCol1}>
                    {QrImage && data.qrOpen && <Image  src={QrImage}/>}
                </View>
            </View>

            <View style={styles.productionInfo}>
                {data.logoOpen && <Label6px>www.moduled.com.tr</Label6px>}
                <Label6px>P.D / Ü.T : {moment(new Date()).format("DD.MM.YYYY")}</Label6px>
            </View>
        </Page>
        }
    </Document>
)};
  
export default Label10x10