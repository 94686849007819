import {
	USER_DETAILS_ACTION,
	USER_DETAILS_SUCCESS,
    USER_DETAILS_ERROR,
	CREATE_USER_ACTION,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	UPDATE_USER_ACTION,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	DELETE_USER_ACTION,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	CHANGE_PASSWORD_ACTION,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILURE,
	FORGOT_PASSWORD_ACTION,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	RESET_PASSWORD_ACTION,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAILURE

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from './auth';
// Get - Offer Details ****************************************************************************************************************************************************
export const user_details_pending = () => ({
	type: USER_DETAILS_ACTION
});

export const user_details_success = (res) => ({
	type: USER_DETAILS_SUCCESS,
	response: res
});

export const user_details_failure = () => ({
	type: USER_DETAILS_ERROR
});

export const getUserDetails = (id) => dispatch =>{
	const url = `/users/${id.id}` 
	dispatch(user_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(user_details_success(res.data))
                resolve(true);
            } else {
				dispatch(user_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(user_details_failure());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
            
        });
    });
};

// POST - USER Create ****************************************************************************************************************************************************
export const create_user_pending = () => ({
	type: CREATE_USER_ACTION
});

export const create_user_success = (res) => ({
	type: CREATE_USER_SUCCESS,
	response: res
});

export const create_user_failure = (response) => ({
	type: CREATE_USER_ERROR,
	response:response
});

export const createUser = (data,history) => dispatch => {
    const url = `/users/create/`
	dispatch(create_user_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_user_success(res.data));
				
				if(localStorage.getItem("role")){

				}else{
					history.push({
						pathname : "/"
					})					
				}

				resolve(true);
			} else {
				dispatch(create_user_failure());
				reject(res);
			};
		})
		.catch((err) => {

            dispatch(create_user_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}else{
				console.log(err)
			}
			reject(err);
		});
	});
}
// update USER ****************************************************************************************************************************************************
export const update_user_pending = (UserID) => ({
	type: UPDATE_USER_ACTION,
	UserID: UserID
});

export const update_user_successful = (res) => ({
	type: UPDATE_USER_SUCCESS,
	response: res
});

export const update_user_failed = (response) => ({
	type: UPDATE_USER_FAILURE,
	response:response
});

export const updateUser = (data) => dispatch => {
	const url = `/users/update/${data._id}`
	dispatch(update_user_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_user_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_user_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_user_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}
// chance password USER ****************************************************************************************************************************************************
export const chance_password_pending = (UserID) => ({
	type: CHANGE_PASSWORD_ACTION,
	UserID: UserID
});

export const chance_password_successful = (res) => ({
	type: CHANGE_PASSWORD_SUCCESS,
	response: res
});

export const chance_password_failed = () => ({
	type: CHANGE_PASSWORD_FAILURE
});

export const changePassword = (data) => dispatch => {
	const url = `/users/paswordchange/${data.id}`
	dispatch(chance_password_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(chance_password_successful(res.data));
				resolve(true);
			} else {
				dispatch(chance_password_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(chance_password_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {

			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}
// FORGOT PASSWORD ****************************************************************************************************************************************************
export const forgot_password_pending = () => ({
	type: FORGOT_PASSWORD_ACTION
});

export const forgot_password_successful = () => ({
	type: FORGOT_PASSWORD_SUCCESS
});

export const forgot_password_failed = () => ({
	type: FORGOT_PASSWORD_FAILURE
});

export const forgotPassword = (data,history) => dispatch => {
	const url = `/users/forgot-password`;
	dispatch(forgot_password_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url,data)
		.then(res => {
			if (res.status === 200) {
			
				dispatch(forgot_password_successful());
				history.push({
					pathname : "/"
				})             
				resolve(true);
			} else {
				dispatch(forgot_password_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(forgot_password_failed());
			reject(err);
		});
	});
}

// RESET PASSWORD ****************************************************************************************************************************************************
export const reset_password_pending = () => ({
	type: RESET_PASSWORD_ACTION
});

export const reset_password_successful = () => ({
	type: RESET_PASSWORD_SUCCESS
});

export const reset_password_failed = () => ({
	type: RESET_PASSWORD_FAILURE
});

export const resetPassword = (data,history) => dispatch => {
	const url = `/users/reset-password`;
	dispatch(reset_password_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url,data)
		.then(res => {
			if (res.status === 200) {
				dispatch(reset_password_successful());

				history.push({
					pathname : "/user/login"
				})             
				resolve(true);
			} else {
				dispatch(reset_password_failed());

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(reset_password_failed());
			reject(err);
		});
	});
}





// delete USER ****************************************************************************************************************************************************
export const delete_user_pending = () => ({
	type: DELETE_USER_ACTION,
});

export const delete_user_successful = (res) => ({
	type: DELETE_USER_SUCCESS,
	response:res
	
});

export const delete_user_failed = (res) => ({
	type: DELETE_USER_FAILURE,
	response:res
});

export const deleteUser = (id) => dispatch => {
	const url = `/users/delete/${id}` 
	dispatch(delete_user_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				//console.log(res)
				dispatch(delete_user_successful(res.data));        
				resolve(true);
			} else {
				dispatch(delete_user_failed());

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_user_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
