import {

	ORDER_GET_LIST,
    ORDER_GET_LIST_SUCCESS,
	ORDER_GET_LIST_ERROR,

} from '../actions';

const INIT_STATE = {
	data: [],
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case ORDER_GET_LIST: // GET LIST 
			return { ...state, loading: true };
		case ORDER_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.orders,  
                status2: action.response.status2,
                status1:action.response.status1,
				status3: action.response.status3,
                status4:action.response.status4,
                totalItemCount:action.response.totalItemCount
            };
		case ORDER_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: {
			return state
		}
	}
}
