import {
    MATERIALS_GET_LIST,
    MATERIALS_GET_LIST_SUCCESS,
	MATERIALS_GET_LIST_ERROR
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Offer List GET ****************************************************************************************************************************************************

export const materials_list_set_pending = () => ({
	type: MATERIALS_GET_LIST
});

export const materials_list_set_successful = (res) => ({
	type: MATERIALS_GET_LIST_SUCCESS,
	response: res
});

export const materials_list_set_failed = () => ({
	type: MATERIALS_GET_LIST_ERROR
});
 
export const getMaterialsList = (type) => dispatch =>{
	const apiUrl_list = `/materials/list`;
	dispatch(materials_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?type=${type}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
		
			if (res.status === 200) {
				dispatch(materials_list_set_successful(res.data));
				resolve(true);
			} else {
				dispatch(materials_list_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(materials_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject();
		});
	});
};
