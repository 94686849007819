import React, { Component } from 'react'

import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import NumberFormat from 'react-number-format';
import moment from "moment";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import { store } from 'react-notifications-component';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {getCompanyList,getCompanyDetails,updateCompany,createCompany,deleteCompany,getCompanyOfferList,getCompanyDeliveryList,createDelivery,updateDelivery,getProductList,deleteDelivery,getMaterialsList} from '../../../actions'
import Countries from "../../../data/countries"

class Companies extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            err_visible:false,
            errors:{
                company_name:"",
                company_email:"",
                company_city:"",
                company_country:"",
                company_category:""
            },
            deliveryModal:false,
            deliveryStatusModal:false,
            productListModal:false,
            delete_errors:"",
            delivery:{},
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            CurrencyList: [
                { value: '₺', label: '₺' },
                { value: '$', label: '$' },
                { value: '€', label: '€' }
            ],
            StatusList: [
                { value: 0, label: 'Açık' },
                { value: 1, label: 'Kısmi Kapandı' },
                { value: 2, label: 'Kapandı' },
            ],
            StatusDescriptionList: [
                { value: 0, label: '' },
                { value: 1, label: 'Fatura Edildi' },
                { value: 2, label: 'Tahsil Edildi' },
                { value: 3, label: 'Ücretsiz Numune' },
                { value: 4, label: 'Garanti Kapsamında' },
            ],

        };
      }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        if(this.props.match.params.id==="new"){
            this.setState({
                company:{    
                    company_name:"",
                    company_adress:"",
                    company_district:"",
                    company_email:"",
                    company_city:"",
                    company_country:"Türkiye",
                    company_category:"Customer",
                    company_phone:"",
                    company_mobilephone:"",
                    company_fax:"",
                    company_taxoffice:"",
                    company_taxno:"",
                    company_img:"",
                    company_notes:"",
                    company_status:true,
                },
                errors:{
                    company_name:"Company Name must be 3 characters long!",
                    //company_country:"Select Company Country",
                    company_category:"Select Company Category",
                    company_email:"Write to Email Please", 
                    company_city:"Company City must be 3 characters long!"
                }

            })
        }else{
            this.props.getCompanyDetails({companyId:this.props.match.params.id})
        }
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){

        if (this.props.companyDetail.data !== nextProps.companyDetail.data) {
            this.setState({
                company: nextProps.companyDetail.data,
            }); 
        }; 
        if (this.props.productList.data !== nextProps.productList.data) {
            this.setState({
                productList:nextProps.productList.data,
            }); 
        };  
        if (this.props.materials.data !== nextProps.materials.data) {
            this.setState({
                materials:nextProps.materials.data,
            }); 
        }; 
    }
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page
        });
    };
//******************************************************************************************************************************************************************/
    onChangeCompanyData = (field,data) => {
        const {errors} = this.state ;
        // eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        switch (field) {
            case 'company_name': 
              errors.company_name = data.length <= 2
                  ? 'Company Name must be 3 characters long!'
                  : '';
              break;
            case 'company_email': 
                errors.company_email = 
                validEmailRegex.test(data)
                  ? ''
                  : 'Email is not valid!';
                break;
            case 'company_city': 
                errors.company_city = data.length < 3
                  ? 'Company City must be 3 characters long!'
                  : '';
                break;     
            case 'company_country': 
                errors.company_country = data.length < 2
                  ? 'Select Company Country'
                  : '';
                break;    
            case 'company_category': 
                errors.company_category = data.length < 2
                ? 'Select Company Category'
                : '';
                break;                    
            default:
                break;
        }
        this.setState(prevState => {
            let company = Object.assign({}, prevState.company); 
            let errors = Object.assign({}, prevState.errors);
            company[field] = data;   

            return { company,errors };    
        });
    }
//******************************************************************************************************************************************************************/
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
//******************************************************************************************************************************************************************/
    handleSubmitMyForm = () => {   
        const isOK = this.validateForm(this.state.errors);
        const { messages } = this.props.intl;
        if(isOK){
            if (this.state.company._id ){
                this.props.updateCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaguncellendi"],
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 3000,
                          onScreen: true
                        }
                    //});
                    },()=>this.toggleCompanyModal());
                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }else{
                this.props.createCompany({...this.state.company})
                .then(res=>{
                    store.addNotification({
                        title: messages["uyari.basarili"],
                        message: messages["bilgi.firmaolusturuldu"],//{messages[x.label]} 
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 3000,
                          onScreen: true
                        }
                    });                    
                })
                .catch(err=>{
                    if(err){
                        store.addNotification({
                            title: this.props.companyDetail.status.statusText,
                            message: this.props.companyDetail.status.data,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                            duration: 3000,
                            onScreen: true
                            }
                        });
                    }
                })
            }
        }else{
            this.setState({
                err_visible: true
            });
        }

    }
//******************************************************************************************************************************************************************/
    deleteForm = () =>{
        this.props.getCompanyDeliveryList(this.props.match.params.id)
        .then(()=>this.delete_Company())
    }
//******************************************************************************************************************************************************************/
    delete_Company = () =>{
        let id = this.state.company._id;
        if(!this.props.deliveryList.data.length &&  id){
            
            if (window.confirm("Firmayı Silmek istiyor musunuz ?")) {
                this.props.deleteCompany(id)
                .then(res=>{
    
                }) 
            }
        }else{
            this.setState({
                err_visible: true,
                delete_errors:"Teslimatı  olan Firma Silinemez.."

            });
        }
    }
//******************************************************************************************************************************************************************/
    gotoCompanyList=()=>{
        this.props.history.push({pathname : `/companies`})
    }
//******************************************************************************************************************************************************************/
    onClickCompanyOrdered=()=>{
        this.props.getCompanyOfferList(this.props.match.params.id,1,5);
    }
//******************************************************************************************************************************************************************/
    onClickDelivery=()=>{
        this.props.getCompanyDeliveryList(this.props.match.params.id);
    }
//******************************************************************************************************************************************************************/
    deleteDelivery=(delivery)=>{
        if (window.confirm("Teslimatı Silmek istiyor musunuz ?")) {
            this.props.deleteDelivery(delivery)
            .then(res=>{
                this.props.getCompanyDeliveryList(this.props.match.params.id);
            }) 
          }else{
           
          }
    }
//******************************************************************************************************************************************************************/
    onChangeDeliveryData=(field,data)=>{
        this.setState(prevState => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery[field] = data;   
            delivery.sumPrice = field==="qty"  ? Number(data) * this.state.delivery.price : field==="price"  ? Number(data) * this.state.delivery.qty : this.state.delivery.sumPrice ;   
            delivery.modified_date = (field==="status" || field==="statusdescription") &&  new Date() ;
            delivery.modified_user = (field==="status" || field==="statusdescription") && this.props.auth.user;
            return {delivery};    
        });
    }
//******************************************************************************************************************************************************************/
    handleChangeDateLabelOver = () => date => {
        this.setState((prevState) => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery.date = date;               
            return { delivery };  
        });
    };
//******************************************************************************************************************************************************************/
    onOpenDeliveryModal=()=>{
        this.setState((prevState) => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery.date = new Date();  
            delivery.company_id = this.props.match.params.id   
            delivery.product_id = "" 
            delivery.productname = ""
            delivery.order_id = null
            delivery.orderitem_id =null 
            delivery.qty = 1
            delivery.unit = "Adet"
            delivery.description = "" 
            delivery.price = 1
            delivery.doviz = ""
            delivery.sumPrice = 1
            delivery.status = 0
            delivery.deliverer = this.props.auth.user
            delivery.receiver = ""
            delivery.component_type = "default"
            return { delivery, deliveryModal:!this.state.deliveryModal };  
        });
    }
//******************************************************************************************************************************************************************/
    toggleDeliveryModal=()=>{
        this.setState({
            deliveryModal: !this.state.deliveryModal
        });     
    }
//******************************************************************************************************************************************************************/
    onSaveDelivery=()=>{
        this.setState((prevState) => {
            let DeliveryErrors = Object.assign({}, prevState.DeliveryErrors);  // creating copy of state variable jasper         
            DeliveryErrors.qty= this.state.delivery.qty === 0 ? "Miktarıı Yazınız" :""         
            DeliveryErrors.company_id=this.state.delivery.company_id === "" ? "Firma Tanımsız"  : ""  
            DeliveryErrors.order_id=  this.state.delivery.order_id === "" ? "Sipariş Tanımlanamadı" :""
        return { DeliveryErrors};  
        },()=>this.onSave());
    }
//******************************************************************************************************************************************************************/
    onSave = () =>{
        const isOk = this.validateForm(this.state.DeliveryErrors);
        const { messages } = this.props.intl;
        if (isOk){
            this.props.createDelivery(this.state.delivery)
            .then(res=>{
                this.props.getCompanyDeliveryList(this.props.match.params.id);
                this.setState({
                    deliveryModal: !this.state.deliveryModal,
                    err_visible: false
                }); 
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.stokhareketiolusturuldu"],//{messages[x.label]} 
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });   
            })
        }

    }
//******************************************************************************************************************************************************************/
    productListOpened = () => {
        if(this.state.delivery.component_type==="material"){
            this.props.getMaterialsList("list")
        }else  if(this.state.delivery.component_type==="product"){
            this.props.getProductList("list","model_category");
        }
    }
//******************************************************************************************************************************************************************/
    toggleProductListModal=()=>{
        this.setState({
            deliveryModal: !this.state.deliveryModal,
            productListModal: !this.state.productListModal
        });
    }
//******************************************************************************************************************************************************************/
    selectedProduct =(item)=>{
        const component =  this.state.delivery.component_type;
        const {locale} =  this.props;
        const Lang = locale === "tr" ? 0  : 1 ;
        const Birimi = component === "product" ? item.urun_model.model_birimi : item.material_unit;
        const Urun_Adi = component === "product" ?  item.urun_adi[Lang]:item.material_category +" * "+item.material_group+" * "+item.material_name;
        const PID = item._id;
        const Doviz = component === "product" ? item.urun_doviz : item.material_doviz;
        const Price = component === "product" ? item.urun_fiyat : item.material_price;
        const Stok = item.stockSum;

        let PrdouctCode = component === "product" ? item.urun_model.model_kodu+"-"+item.urun_kodu+"-"+item.urun_optik+"-"+item.urun_cct+"-"+item.urun_panel+"-"+item.urun_aku  :"";

        this.setState((prevState) => {
            let delivery = Object.assign({}, prevState.delivery);  
            delivery.product_id = PID
            delivery.productname = Urun_Adi +" ("+PrdouctCode+")"
            delivery.qty = 1
            delivery.unit = Birimi
            delivery.price = Price
            delivery.doviz = Doviz
            delivery.max = Stok
            delivery.sumPrice =  Number(Price) * this.state.delivery.qty
            return { delivery,deliveryModal: !this.state.deliveryModal, productListModal: !this.state.productListModal };  
        });
    
    }
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        const component =  this.state.delivery.component_type;
        let filteredList =[]; 
        if(component==="product"){
            filteredList =  this.props.productList.data.filter(item => {return item.urun_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.urun_model.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase())})
            if (search && search.target.value===""){
                this.setState({
                    productList:this.props.productList.data,
                    totalItemCount:this.props.productList.data &&  this.props.productList.data.length ? this.props.productList.data.length:0,
                    totalPage: Math.ceil((this.props.productList.data &&  this.props.productList.data.length  ? this.props.productList.data.length : 0) /this.state.selectedPageSize)
                })
            }else{
                this.setState({
                    productList:filteredList,
                    totalItemCount:this.state.productList &&  this.state.productList.length ? this.state.productList.length:0,
                    totalPage: Math.ceil((this.state.productList &&  this.state.productList.length  ? this.state.productList.length : 0) /this.state.selectedPageSize)
                })
            }
        }else{
            filteredList =  this.props.materials.data.filter(item => {return item.material_category.toLowerCase().includes(search.target.value.toLowerCase()) || item.material_group.toLowerCase().includes(search.target.value.toLowerCase())|| item.material_name.toLowerCase().includes(search.target.value.toLowerCase())})
            if (search && search.target.value===""){
                this.setState({
                    materials:this.props.productList.data,
                    totalItemCount:this.props.materials.data &&  this.props.materials.data.length ? this.props.materials.data.length:0,
         
                })
            }else{
                this.setState({
                    materials:filteredList,
                    totalItemCount:this.state.materials &&  this.state.materials.length ? this.state.materials.length:0,
                })
            }  
        }
    };
//******************************************************************************************************************************************************************/
    CloseDeliveryRow=(i)=>{
        let delivery = this.props.deliveryList.data[i];
        this.setState({
            deliveryStatusModal: !this.state.deliveryStatusModal,
            delivery : i!==null  && delivery
        });
    }
//******************************************************************************************************************************************************************/
    onUpdateDelivery=()=>{
        const { messages } = this.props.intl;
        if (this.state.delivery._id ){
            this.props.updateDelivery({...this.state.delivery})
            .then(res=>{
                this.props.getCompanyDeliveryList(this.props.match.params.id)
                store.addNotification({
                    title: messages["uyari.basarili"],
                    message: messages["bilgi.teslimatguncellendi"],
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                });
            })
            this.setState({
                deliveryStatusModal: !this.state.deliveryStatusModal,
            });
        }
    }
//******************************************************************************************************************************************************************/
    render() {
        const { messages,locale } = this.props.intl;
        const Lang = locale === "tr" ? 0  : 1 ;
        const {company,errors,delivery,UnitList,CurrencyList,productList,err_visible,delete_errors,materials,StatusList,StatusDescriptionList} = this.state
        const CompanyGroup= [
            { value: "Suppliers", label: "company.Suppliers"},
            { value: "Customer", label: "company.Customer" },
        ];
        return (
            <>
				<div className="container-fluid">
                    {err_visible && errors.company_name &&  <div className="alert alert-danger" role="alert">{errors.company_name}</div>}
                    {err_visible && errors.company_category &&  <div className="alert alert-danger" role="alert" >{errors.company_category}</div>}
                    {err_visible && errors.company_city &&  <div className="alert alert-danger" role="alert" >{errors.company_city}</div>}
                    {err_visible && errors.company_country &&  <div className="alert alert-danger" role="alert">{errors.company_country}</div>}
                    {err_visible && errors.company_email &&  <div className="alert alert-danger" role="alert">{errors.company_email}</div>}
                    {err_visible && delete_errors!=="" &&  <div className="alert alert-danger" role="alert">{delete_errors}</div>}

                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" id="TaskBoard-tab" data-toggle="tab" href="#company-detail"><IntlMessages id="company.detail"/></a></li>
                                <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#company-delivery" onClick={()=>this.onClickDelivery()}><IntlMessages id="company.deliveryList"/></a></li>
                                <li className="nav-item"><a className="nav-link" id="TaskBoard-tab" data-toggle="tab" href="#company-history" onClick={()=>this.onClickCompanyOrdered()}><IntlMessages id="company.orderedlist"/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="company-detail" role="tabpanel">
                                    <div  className={this.props.match.params.id!=="new" && !this.props.companyDetail.loading  ? "dimmer active" : ""}>
                                    {this.props.match.params.id!=="new" && !this.props.companyDetail.loading  && <div className="loader"/>}
                                        <div className={this.props.match.params.id!=="new" && !this.props.companyDetail.loading  ? "dimmer-content"  : ""}>                                
                                            <div className="row clearfix">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                    <label className="form-label"><IntlMessages id="company.name" /></label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className={errors.company_name ? "form-control is-invalid":"form-control"}
                                                            placeholder="Company Name"
                                                            value={company &&  company.company_name ? company.company_name :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_name",evt.target.value)}
                                                        />
                                                        <div className="invalid-feedback">{errors.company_name}</div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <label className="form-label"><IntlMessages id="company.address" /></label>
                                            <div className="row clearfix">
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="State/Province"
                                                            value={company &&  company.company_district ? company.company_district :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_district",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            autoComplete="off"
                                                            className={errors.company_city ? "form-control is-invalid":"form-control"}
                                                            placeholder="City" 
                                                            value={company &&  company.company_city ? company.company_city :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_city",evt.target.value)}
                                                        />
                                                        <div className="invalid-feedback">{errors.company_city}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <select 
                                                            className={errors.company_country ? "form-control is-invalid":"form-control"}
                                                            onChange={(evt)=>this.onChangeCompanyData("company_country",evt.target.value)}
                                                            value={company && company.company_country && company.company_country}
                                                        >
                                                            {Countries.map((x,index)=>{
                                                                return(
                                                                    <option key={index} value={x.value}>{x.label}  </option>
                                                                )
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.company_country}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            rows={2}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            value={company &&  company.company_adress ? company.company_adress :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_adress",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>  
                                            <label className="form-label"><IntlMessages id="company.taxinfo" /></label>
                                            <div className="row clearfix">
                                                <div className="col-lg-5 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tax Office"
                                                            value={company &&  company.company_taxoffice ? company.company_taxoffice :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_taxoffice",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tax No"
                                                            value={company &&  company.company_taxno ? company.company_taxno :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_taxno",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>{" "}&nbsp;&nbsp;
                                            </div>  
                                            <label className="form-label"><IntlMessages id="company.contact" /></label>
                                            <div className="row clearfix">
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-phone" />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Phone"
                                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                                value={company &&  company.company_phone ? company.company_phone :"" }
                                                                onChange={(evt)=>this.onChangeCompanyData("company_phone",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>                                                    
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-fax" />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Fax"
                                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                                value={company &&  company.company_fax ? company.company_fax :"" }
                                                                onChange={(evt)=>this.onChangeCompanyData("company_fax",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-fax" />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Mobile"
                                                                data-mask="(00) 000-0000" data-mask-clearifnotmatch="true" 
                                                                value={company &&  company.company_mobilephone ? company.company_mobilephone :"" }
                                                                onChange={(evt)=>this.onChangeCompanyData("company_mobilephone",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="row clearfix">
                                                <div className="col-lg-5 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fa fa-envelope" />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className={errors.company_email ? "form-control is-invalid":"form-control"}
                                                                placeholder="e-Mail"
                                                                value={company &&  company.company_email ? company.company_email :"" }
                                                                onChange={(evt)=>this.onChangeCompanyData("company_email",evt.target.value)}
                                                            />
                                                            <div className="invalid-feedback">{errors.company_email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="icon-globe" />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                placeholder="http://"
                                                                value={company &&  company.company_web ? company.company_web :"" }
                                                                onChange={(evt)=>this.onChangeCompanyData("company_web",evt.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="row clearfix">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label"><IntlMessages id="company.aboutme" /></label>
                                                        <textarea
                                                            rows={2}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="About Me"
                                                            value={company &&  company.company_notes ? company.company_notes :"" }
                                                            onChange={(evt)=>this.onChangeCompanyData("company_notes",evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeCompanyData("company_category",evt.target.value)}
                                                            value={company && company.company_category && company.company_category}
                                                        >
                                                            {CompanyGroup.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                                )
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.company_category}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div> 
                                            <label className="custom-switch">
                                            <input type="checkbox" 
                                                name="option" 
                                                checked={company && company.company_status  ? company.company_status :true }
                                                className="custom-switch-input"  
                                                onChange={(evt)=>this.onChangeCompanyData("company_status",!company.company_status)}
                                            />
                                            <span className="custom-switch-indicator" />
                                            <span className="custom-switch-description"><IntlMessages id="company.status" /></span>
                                        </label>
                                        </div>
                                </div>
                                <div className="tab-pane fade show" id="company-history" role="tabpanel">
                                    <div className="table-responsive">
                                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                            <thead>
                                                <tr>
                                                    <th ><IntlMessages id="offer.date"/></th>
                                                    <th><IntlMessages id="order.date"/></th>
                                                    <th><IntlMessages id="order.product"/></th>
                                                    <th ><IntlMessages id="order.qty"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.price"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.offerTotal"/></th>
                                                    <th className="text-right"><IntlMessages id="offer.offerKDV"/></th>
                                                    <th className="text-right"><IntlMessages id="stock.stock"/></th>
                                                    <th className="w20"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.props.offerList.loading && this.props.offerList.datalist.map((order)=>{ 
                                                  
                                                    return(
                                                        order.offeritem &&  order.offeritem.map((item,index)=>{
                                                            return(
                                                            <tr key={index}>
                                                                <td>{order.teklif_tarihi && moment(order.teklif_tarihi).format("DD.MM.YYYY")}</td>
                                                                <td>{order.siparis_tarihi && moment(order.siparis_tarihi).format("DD.MM.YYYY")}</td>
                                                                <td>{item.urun_adi && item.urun_adi}</td>
                                                                <td className="text-center">{item.urun_miktari &&  <NumberFormat value={item.urun_miktari} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  />}</td>
                                                                <td className="text-right">{item.urun_fiyati &&(parseFloat(item.urun_fiyati).toFixed(2)).replace(".",",")+" "+item.urun_dovizi }</td>
                                                                <td className="text-right">{item.toplamDoviz &&(parseFloat(item.toplamDoviz).toFixed(2)).replace(".",",")+" "+order.teklif_dovizi }</td>
                                                                <td className="text-right">{item.toplamKdvDoviz &&(parseFloat(item.toplamKdvDoviz).toFixed(2)).replace(".",",")+" "+order.teklif_dovizi }</td>
                                                                <td className="text-right">{item.toplamDoviz &&item.toplamKdvDoviz &&(parseFloat(item.toplamDoviz+item.toplamKdvDoviz).toFixed(2)).replace(".",",")+" "+order.teklif_dovizi }</td>
                                                                <td>
                                                                    <div className="item-action dropdown">
                                                                        <a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                            <a href="#1" className="dropdown-item" onClick={()=>this.editOffer(order._id)}><i className="dropdown-icon fa fa-edit" /><IntlMessages id="order.offeredit"/></a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            )
                                                        })
                                                    )
                                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                                <div className="tab-pane fade show" id="company-delivery" role="tabpanel">
                                    <div className="btn-list text-right">
                                        <button type="button" className="btn btn-round btn-info" onClick={()=>this.onOpenDeliveryModal()} >
                                            <IntlMessages id="button.add" />
                                        </button>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                                            <thead>
                                                <tr>
                                                    
                                                    <th ><IntlMessages id="delivery.date"/></th>
                                                    <th><IntlMessages id="delivery.product"/></th>
                                                    <th ><IntlMessages id="delivery.receiver"/></th>
                                                    <th className="text-right"><IntlMessages id="delivery.qty"/></th>
                                                    <th className="text-right"><IntlMessages id="delivery.price"/></th>
                                                    <th className="text-right"><IntlMessages id="delivery.total"/></th>
                                                    <th className="text-right"><IntlMessages id="delivery.status"/></th>
                                                    <th className="w20"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.props.deliveryList.loading && this.props.deliveryList.data.map((delivery,index)=>{ 
                                                    return(
                                                    <tr key={index}>
                                                        <td>{delivery.date && moment(delivery.date).format("DD.MM.YYYY")}</td>
                                                        <td>{delivery.productname && delivery.productname}</td>
                                                        <td>{delivery.receiver && delivery.receiver}</td>
                                                        <td className="text-center">{delivery.qty &&  <NumberFormat value={delivery.qty} displayType={'text'} thousandsGroupStyle={'thousand'} thousandSeparator={'.'} decimalSeparator={false}  />}</td>
                                                        <td className="text-right">{delivery.price &&(parseFloat(delivery.price).toFixed(2)).replace(".",",")+" "+delivery.doviz }</td>
                                                        <td className="text-right">{delivery.sumPrice &&(parseFloat(delivery.sumPrice).toFixed(2)).replace(".",",")+" "+delivery.doviz }</td>
                                                        <td className="text-center"><span className={delivery.status ===  0 ? "tag tag-red":delivery.status ===  1 ? "tag tag-success":delivery.status ===  2 ?  "tag tag-success" :"tag tag-red"} onClick={()=>this.CloseDeliveryRow(index)}>{messages["delivery.status"+delivery.status]}</span></td>
                                                        <td>
                                                            <div className="item-action dropdown">
                                                                <a href="#1" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                                    <a href="#1" className="dropdown-item" onClick={()=>this.deleteDelivery(delivery)}><i className="dropdown-icon fa fa-trash" /><IntlMessages id="button.delete"/></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )                                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>                            
                            </div>
                        </div>
                        <div className="card-footer text-right">   	
                            <button type="button" className="btn btn-round btn-danger" onClick={()=>this.deleteForm()}>
                                <IntlMessages id="button.delete" />
                            </button>{"  "}
                            <button type="button" className="btn btn-round btn-success" onClick={()=>this.handleSubmitMyForm()}>
                                <IntlMessages id="button.save" />
                            </button>{' '}
                            <button type="button" className="btn btn-round btn-warning" onClick={()=>this.gotoCompanyList()}>
                                <IntlMessages id="button.close" />
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.deliveryModal}
                    size="lg"
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="order.deliveryedit"/>
                </ModalHeader>
                    <ModalBody>
                        <div className="row clearfix">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id= "order.deliverydate"/></label>
                                                <DatePicker
                                                    name="date"
                                                    selected={delivery && delivery.date  &&delivery.date}
                                                    dateFormat="dd.MM.yyyy"
                                                    onChange={this.handleChangeDateLabelOver()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-8">
                                            <div className="form-group">
                                                <label className="form-label">*</label>
                                                <div className="selectgroup w-100">
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" onChange={()=>this.onChangeDeliveryData("component_type","material")} />
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.material" /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <input type="radio" name="value" className="selectgroup-input" onChange={()=>this.onChangeDeliveryData("component_type","product")}/>
                                                        <span className="selectgroup-button"><IntlMessages id="delivery.product" /></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.product" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.productname  }
                                                    onChange={(e)=>this.onChangeDeliveryData("productname",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.description" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.description  }
                                                    onChange={(e)=>this.onChangeDeliveryData("description",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-2">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="order.deliveryqty" /></label>
                                                <input
                                                    type="number"
                                                    max={delivery && delivery.max  }
                                                    min={1}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.qty  }
                                                    onChange={(e)=>this.onChangeDeliveryData("qty",Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-2">
                                            <div className="form-group">
                                                <label className="form-label text-center"><IntlMessages id="genel.unit"/></label>
                                                <select 
                                                    className="form-control text-center"
                                                    onChange={(e)=>this.onChangeDeliveryData("unit",e.target.value)}
                                                    value={delivery.unit}
                                                >
                                                    {UnitList && UnitList.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                        )
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-2">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="offer.price" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control text-right"
                                                    value={delivery && delivery.price  }
                                                    onChange={(e)=>this.onChangeDeliveryData("price",Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="product.currency"/></label>
                                                <select 
                                                    className="form-control" 
                                                    onChange={(e)=>this.onChangeDeliveryData("doviz",e.target.value)}
                                                    value={delivery && delivery.doviz}
                                                >
                                                    {CurrencyList && CurrencyList.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value} >{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.total" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control text-right"
                                                    value={delivery && parseFloat(delivery.sumPrice).toFixed(2).replace(".",",")+ " "+ delivery.doviz }
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.deliverer" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.deliverer  }
                                                    onChange={(e)=>this.onChangeDeliveryData("deliverer",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.receiver" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.receiver  }
                                                    onChange={(e)=>this.onChangeDeliveryData("receiver",e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {delivery.component_type !== "default" &&<button type="button" className="btn btn-round btn-warning" onClick={()=>this.toggleProductListModal(null)}>
                            <IntlMessages id="offer.selectProduct"/>
                        </button>}
                        <button type="button" className="btn btn-round btn-success" onClick={()=>this.onSaveDelivery()}>
                            <IntlMessages id="button.save" />
                        </button>{" "}
                        <button type="button" className="btn btn-round btn-info" onClick={()=>this.toggleDeliveryModal()}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal>   
                <Modal
                    isOpen={this.state.deliveryStatusModal}
                    size="lg"
                    backdrop="static"
                >
                <ModalHeader>
                    <IntlMessages id="order.deliveryedit"/>
                </ModalHeader>
                    <ModalBody>
                        <div className="row clearfix">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label text-center"><IntlMessages id="delivery.status"/></label>
                                                <select 
                                                    className="form-control"
                                                    onChange={(e)=>this.onChangeDeliveryData("status",e.target.value)}
                                                    value={delivery.status}
                                                >
                                                    {StatusList && StatusList.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value}>{x.label} </option>
                                                        )
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            {delivery && delivery.status!==0 &&<div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.statusdescrription"/></label>
                                                <select 
                                                    className="form-control" 
                                                    onChange={(e)=>this.onChangeDeliveryData("statusdescription",e.target.value)}
                                                    value={delivery && delivery.statusdescription}
                                                >
                                                    {StatusDescriptionList && StatusDescriptionList.map((x,y)=>{
                                                        return(
                                                            <option key={y}  value={x.value} >{x.label} </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>}
                                        </div>

                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.modifieduser" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && delivery.modified_user  }
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label"><IntlMessages id="delivery.modifieddate" /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    value={delivery && moment(delivery.modified_date).format("DD.MM.YYYY")  }
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-round btn-success" onClick={()=>this.onUpdateDelivery()}>
                            <IntlMessages id="button.save" />
                        </button>{" "}
                        <button type="button" className="btn btn-round btn-info" onClick={()=>this.CloseDeliveryRow(null)}>
                            <IntlMessages id="button.close" />
                        </button>
                    </ModalFooter>
                </Modal> 

                <Modal
                    isOpen={this.state.productListModal}
                    toggle={this.toggleProductListModal}
                    size="lg"
                    onOpened={()=>this.productListOpened()}
                    backdrop="static"
                    scrollable={true}
                >
                <ModalHeader>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyPress={e => this.onSearchKey(e)}
                    />
                </ModalHeader>
                <ModalBody>
                    <div  className={((delivery.component_type==="product" &&  this.props.productList.loading) || (delivery.component_type==="material" &&  this.props.materials.loading)) ? "dimmer active" : ""}>
                    {((delivery.component_type==="product" && this.props.productList.loading) || (delivery.component_type==="material" &&  this.props.materials.loading)) && <div className="loader"/>}
                        <div className={((delivery.component_type==="product" && this.props.productList.loading) || (delivery.component_type==="material" &&  this.props.materials.loading)) ? "dimmer-content"  : ""}>
                            <div className="table-responsive">
                                <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 border-style mb-0">
                                    <tbody>
                                    {delivery.component_type==="product" ? 
                                        productList && productList.map((productRow,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td className="w80">
                                                    <img
                                                        className="rounded-circle img-thumbnail w80"
                                                        src={productRow.productImages ?productRow.productImages :"../assets/images/ry.png"}
                                                        alt="moduled"
                                                    />
                                                </td>
                                                <td>
                                                    <div className="font-15">{productRow.urun_adi[Lang]}</div>
                                                    <span className="text-muted">{productRow.urun_model.model_kodu+"-"+productRow.urun_kodu+"-"+productRow.urun_optik+"-"+productRow.urun_cct}</span>
                                                </td>
                                                <td className="font400 text-right">
                                                    <NumberFormat
                                                        displayType={'text'}
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2}
                                                        suffix={' '+productRow.urun_doviz} 
                                                        thousandSeparator={'.'} 
                                                        decimalSeparator={','}  
                                                        value={(productRow.urun_fiyat)}  
                                                    />  
                                                </td>
                                                <td className="text-right">
                                                    <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedProduct(productRow)}>Seç</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    materials && materials.map((materialRow,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td className="w80">
                                                    <img
                                                        className="rounded-circle img-thumbnail w80"
                                                        src={"../assets/images/ry.png"}
                                                        alt="moduled"
                                                    />
                                                </td>
                                                <td>
                                                    <div className="font-15">{materialRow.material_category+"-"+materialRow.material_group}</div>
                                                    <span className="text-muted">{materialRow.material_name}</span>
                                                </td>
                                                <td className="font400 text-right">
                                                    <NumberFormat
                                                        displayType={'text'}
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2}
                                                        suffix={' '+materialRow.material_doviz} 
                                                        thousandSeparator={'.'} 
                                                        decimalSeparator={','}  
                                                        value={(materialRow.material_price)}  
                                                    />  
                                                </td>
                                                <td className="text-right">
                                                    <button type="button" className="btn btn-round btn-secondary  btn-sm"  onClick={()=>this.selectedProduct(materialRow)}>Seç</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-round btn-secondary" onClick={()=>this.toggleProductListModal()}>
                        <IntlMessages id="button.close" />
                    </button>
                </ModalFooter>
                </Modal>  
            </>
        )
    }
}
const mapStateToProps = state => ({
    locale:state.settings.locale,
    auth:state.auth,
    companyDetail:state.companyDetail,
    offerList:state.offerList,
    deliveryDetails:state.deliveryDetails,
    deliveryList:state.deliveryList,
    productList:state.productList,
    materials:state.materials,
})

const mapDispatchToProps = ({getCompanyList,getCompanyDetails,updateCompany,createCompany,deleteCompany,getCompanyOfferList,getCompanyDeliveryList,createDelivery,updateDelivery,getProductList,deleteDelivery,getMaterialsList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Companies)));