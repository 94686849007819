// Import all components here
// import React, { Component } from 'react'
import Dashboard from './HRMS/Dashboard/Dashboard';



// MODULED MENU
import MyCompany from './HRMS/myCompany/myCompany';
import Users from './HRMS/Users/Users';
import Companies from './HRMS/Companies/Companies';
import ImageManagment from './HRMS/ImageManagment/ImageManagment';
import CompanyDetail from './HRMS/Companies/companyDetails';

import TCMB from './HRMS/Tcmb/Tcmb';

// PRRODUCTS
import Models from './Products/models';
import Products from './Products/products';
import ModelDetail from './Products/modelDetail';
import ProductDetail from './Products/productDetail';
import Materials from './Products/materials';
import MaterialDetail from './Products/materialDetail';

// OFFERS ****
import Offers from './Offers/offers';
import OfferDetail from './Offers/offerDetail';

// BUYINNGS
import BuyingList from './buyings/buyings';


// EMAİLS ****
import  OrderTracking from './emails/ordertracking';
// ORDERS ****

import Orders from './Orders/orders';



// PRODUCTIONS ****
import OrderItemList from './Productions/orderItemList';
import ComponentList from './Productions/componentList';
import ProductionDetail from './Productions/productionDetail';
import ProductionDashBoard  from "./Productions/Dashboard";
import OrdersDashboard from './Productions/OrdersDashboard';
import OrderItemDetail from './Productions/orderItemDetail';
//  LABELS 
import ProductsLabels from './Labels/productslabels';



import Login from './Authentication/login';
import Signup from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import InternalServer from './Authentication/500';

//AKAYSIS
import AkaysisLogin from './akaysis/akaysisLogin';
import GatewayList from './akaysis/gatewayList';
import DeviceDetails from './akaysis/devicedetails';
import Maps from './akaysis/dashboard';
//WEB
import WebMain from './web/ProductList'
import productDetail from './web/productDetail'
const Routes = [
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "menu.dashboard",
        component: Dashboard,
        role:2
    },
    {
        path: "/users",
        name: 'users',
        exact: true,
        pageTitle:"menu.users",
        component: Users,
        role:1
    },
    {
        path: "/mycompany",
        name: 'mycompany',
        exact: true,
        pageTitle:"menu.companysetting",
        component: MyCompany,
        role:1
    },
    {
        path: "/companies",
        name: 'companies',
        exact: true,
        pageTitle: "menu.companies",
        component: Companies,
        role:2
    },
    {
        path: "/imagemanagment",
        name: 'imagemanagment',
        exact: true,
        pageTitle: "menu.imagemanagment",
        component: ImageManagment,
        role:2
    },
    {
        path: "/companydetail/:id",
        name: 'companydetail',
        exact: true,
        pageTitle: "menu.companyDetail",
        component: CompanyDetail,
        role:2
    },
    {
        path: "/tcmblist",
        name: 'tcmblist',
        exact: true,
        pageTitle: "menu.tcmb",
        component: TCMB,
        role:2
    },
    // Web---------------------------------------
    {
        path: "/web",
        name: 'web',
        exact: true,
        pageTitle: "menu.products",
        component: WebMain,
        role:7
    },
    {
        path: "/web/:id/:lang",
        name: 'web',
        exact: true,
        pageTitle: "menu.products",
        component: productDetail,
        role:7
    },
    // PRODUCTS---------------------------------------
    {
        path: "/models",
        name: 'models',
        exact: true,
        pageTitle: "menu.models",
        component: Models,
        role:1
    },
    {
        path: "/products",
        name: 'products',
        exact: true,
        pageTitle: "menu.products",
        component: Products,
        role:2
    },
    {
        path: "/model/:id",
        name: 'model',
        exact: true,
        pageTitle: "menu.modeldetail",
        component: ModelDetail,
        role:2
    },
    {
        path: "/product/:id",
        name: 'product',
        exact: true,
        pageTitle: "menu.productDetail",
        component: ProductDetail,
        role:2
    },
    {
        path: "/materials",
        name: 'materials',
        exact: true,
        pageTitle: "menu.materials",
        component: Materials,
        role:2
    },
    {
        path: "/material/:id",
        name: 'material',
        exact: true,
        pageTitle: "menu.materialDetail",
        component: MaterialDetail,
        role:2
    },
    //Offers*********************
    {
        path: "/offers",
        name: 'offers',
        exact: true,
        pageTitle: "menu.offers",
        component: Offers,
        role:2
    },
    {
        path: "/offer/:id",
        name: 'offer',
        exact: true,
        pageTitle: "menu.offer",
        component: OfferDetail,
        role:2
    },    //BUYINGS*********************
    {
        path: "/buyings",
        name: 'buyings',
        exact: true,
        pageTitle: "menu.buying",
        component: BuyingList,
        role:2
    },
    //Orders*********************
    {
        path: "/ordersdashboard",
        name: 'ordersdashboard',
        exact: true,
        pageTitle: "menu.orderDashBoard",
        component: OrdersDashboard,
        role:3
    },
    {
        path: "/orders",
        name: 'orders',
        exact: true,
        pageTitle: "menu.orders",
        component: Orders,
        role:3
    },
    //Emails*********************
    {
        path: "/ordertracking/:offerID/:companyID/:token",
        name: 'ordertracing',
        exact: true,
        pageTitle: "menu.orderTracking",
        component: OrderTracking,
        role:3
    },

    //Production*********************
    {
        path: "/productionDashBoard",
        name: 'production',
        exact: true,
        pageTitle: "menu.productionDashBoard",
        component: ProductionDashBoard,
        role:3
    },
    {
        path: "/orderitemlist",
        name: 'orderitemlist',
        exact: true,
        pageTitle: "menu.orderItemList",
        component: OrderItemList,
        role:3
    },
    {
        path: "/componentlist",
        name: 'componentlist',
        exact: true,
        pageTitle: "menu.componentlist",
        component: ComponentList,
        role:3
    },
    {
        path: "/orderitemdetail/:id",
        name: 'orderitemdetail',
        exact: true,
        pageTitle: "menu.orderitemdetail",
        component: OrderItemDetail,
        role:3
    },
    {
        path: "/orderproductiondetail/:id",
        name: 'orderproductiondetail',
        exact: true,
        pageTitle: "menu.productiondetail",
        component: ProductionDetail,
        role:3
    },
    //Labels*********************
    {
        path: "/productslabels",
        name: 'productslabels',
        exact: true,
        pageTitle: "menu.productslabels",
        component: ProductsLabels,
        role:3
    },// *************AKAYSIIS 
    {
        path: "/akaysislogin",
        name: 'akaysislogin',
        exact: true,
        pageTitle: "menu.akaysisLogin",
        component: AkaysisLogin,
        role:3
    },
    {
        path: "/gatewaylist",
        name: 'gatewaylist',
        exact: true,
        pageTitle: "menu.gatewaylist",
        component: GatewayList,
        role:3
    },
    {
        path: "/devicedetails/:id",
        name: 'devicedetails',
        exact: true,
        pageTitle: "menu.devicedetails",
        component: DeviceDetails,
        role:3
    },
    {
        path: "/akaysismap",
        name: 'akaysismap',
        exact: true,
        pageTitle: "menu.akaysismap",
        component: Maps,
        role:3
    },


    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Login",
        component: Login,
        role:7
    },
    {
        path: "/signup",
        name: 'signup',
        exact: true,
        pageTitle: "Tables",
        component: Signup,
        role:7
    },
    {
        path: "/forgotpassword",
        name: 'forgotpassword',
        exact: true,
        pageTitle: "Tables",
        component: ForgotPassword,
        role:7
    },
    {
        path: "/notfound",
        name: 'notfound',
        exact: true,
        pageTitle: "Tables",
        component: NotFound,
        role:0
    },
    {
        path: "/internalserver",
        name: 'internalserver',
        exact: true,
        pageTitle: "Tables",
        component: InternalServer
    }

];

export default Routes;