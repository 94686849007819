import React, { Component } from 'react';
import Chart from "react-apexcharts";

class Barchart extends Component {
    constructor(props) {
        super(props);

        this.state = {

    }
}
    render() {
        let category = [];
        let series1=[];
        let series2=[];
        this.props.data.forEach(row=>{
            category.push(row.model)
            series1.push(row.siparis)
            series2.push(row.teslimat)
        })

        let options = {
            chart: {
                id: "basic-bar"
            },
            fill: {
                colors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
            },
            xaxis: {
                categories: category
            }
        }
        let series  = [
            {
                name: "Sipariş",
                data: series1
            },
            {
                name: "Teslimat",
                data: series2
            }
        ]



        return (
            <div>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="400px"
                />
            </div>
        );
    }
}

export default Barchart;
