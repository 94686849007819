import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pagination from "../common/Pagination";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router'
import IntlMessages from "../../helpers/IntlMessages";
import {getProductList,getModelList} from '../../actions';


class Models extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 50,
            pageSizes: [6, 12, 18, 30, 50, 100],
            sortColumn:"model_kategori",
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            search: "",
            selectedItems: [],
            isLoading:false,
            modelList:this.props.modelList.data,
            UnitList : [
                { value: 'Adet', label: "genel.Adet"},
                { value: 'Kg', label: "genel.Kg" },
                { value: 'Metre', label: "genel.Metre"},
                { value: 'Takım', label: "genel.Takım"},
                { value: 'Paket', label:"genel.Paket"}
            ],
            KDVList : [
              { value: '18', label: '18' },
              { value: '8', label: '8' },
              { value: '0', label: '0' }
            ],
            categoryList : [
                {value : 0 , label : "model.kategori"+0},
                {value : 1 , label : "model.kategori"+1},
                {value : 2 , label : "model.kategori"+2},
                {value : 3 , label : "model.kategori"+3},
                {value : 4 , label : "model.kategori"+4},
                {value : 5 , label : "model.kategori"+5},
                {value : 6 , label : "model.kategori"+6},
                {value : 7 , label : "model.kategori"+7},
                {value : 8 , label : "model.kategori"+8},
            ],
            operationList : [
             {value : "Dizgi" ,label : "Dizgi"},
             {value : "Mekanik Montaj" ,label :"Mekanik Montaj"},
             {value : "Paketleme" ,label :"Paketleme"},
             {value : "E-Test" ,label : "E-Test"},
             {value : "Harici İşlem" ,label : "Harici İşlem"},
             {value : "Onay" ,label : "Onay"}
            ],
        };
    }
//******************************************************************************************************************************************************************/
    componentDidMount() {
        this.props.getModelList("detail",this.state.sortColumn);
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.modelList.data !== nextProps.modelList.data) {
            this.setState({
                modelList: nextProps.modelList.data,
                totalItemCount:nextProps.modelList.data.length,
                totalPage: Math.ceil(nextProps.modelList.data.length/this.state.selectedPageSize)
            }); 
        };  
    }
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        const {orderOptions} = this.state
        this.setState({
            selectedOrderOption: orderOptions.find(
                x => x.column === column
            ),
                sortColumn:column
            },()=>this.props.getModelList("detail",this.state.sortColumn));
    };
//******************************************************************************************************************************************************************/
    changePageSize = size => {
        this.setState({
            selectedPageSize: size,
            currentPage: 1,
            totalPage: Math.ceil(this.state.totalItemCount/size)
        });
    };
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
    };
//******************************************************************************************************************************************************************/
    onChangeFilter= (e,f) => {
        const  modelList = this.props.modelList.data;
        let modelCount   = "";
        let _filter = "" ;
        f === "Tümü" ? _filter  = "" : _filter = f ;
        if (f!=="Tümü"){
            modelCount = modelList.filter(x=>x.model_kategori ===Number(_filter)).length;
          this.setState(
            {
                modelList:modelList.filter(x=>x.model_kategori ===Number(_filter)),
                currentPage:1,
                totalItemCount:modelCount,
                totalPage: Math.ceil(modelCount/this.state.selectedPageSize) || 1
            }
          );
        } else {
            modelCount = modelList.length;
          this.setState(
            {
                modelList:modelList,
                currentPage:1,
                totalItemCount:modelCount,
                totalPage: Math.ceil(modelList.length/this.state.selectedPageSize)
            }
          );
        }
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
        filteredList =  this.props.modelList.data.filter(item => {return item.model_adi[0].toLowerCase().includes(search.target.value.toLowerCase()) || item.model_adi[1].toLowerCase().includes(search.target.value.toLowerCase())})
        if (search && search.target.value===""){
            this.setState({
                modelList:this.props.modelList.data,
                totalItemCount:this.props.modelList.data &&  this.props.modelList.data.length ? this.props.modelList.data.length:0,
                totalPage: Math.ceil((this.props.modelList.data &&  this.props.modelList.data.length  ? this.props.modelList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
                modelList:filteredList,
                totalItemCount:this.state.modelList &&  this.state.modelList.length ? this.state.modelList.length:0,
                totalPage: Math.ceil((this.state.modelList &&  this.state.modelList.length  ? this.state.modelList.length : 0) /this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    editModel=(id)=>{
        this.props.history.push({pathname : `/model/${id}`})
    }
//******************************************************************************************************************************************************************/
    newModel=(id)=>{
        this.props.history.push({pathname : `/model/new`})
    }
//******************************************************************************************************************************************************************/
    render() {
      //  const { fixNavbar,locale } = this.props;
        const { messages } = this.props.intl;
        const {modelList,selectedPageSize,currentPage,totalItemCount,categoryList} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;
     //   const Lang = locale==="tr" ? 0:1 
        return (
            <>
            <div  className={this.props.modelList.loading? "dimmer active" : ""}>
                {this.props.modelList.loading && <div className="loader"/>}
                <div className={this.props.modelList.loading? "dimmer-content"  : ""}>
                    <div className="container-fluid">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount} `}</div>
                                        <div className="page-options d-flex">
                                            <select 
                                                className="custom-select w-auto" 
                                                onChange={(evt)=>this.onChangeFilter("model_kategori",evt.target.value)}
                                            >
                                                        <option value={"Tümü"} >{messages["genel.choose"]}</option>
                                                {categoryList.map((x,y)=>{
                                                    return(
                                                        <option key={y}  value={x.value}>{messages[x.label]} </option>
                                                    )
                                                })}
                                            </select>
                                            <div className="input-icon ml-2">
                                                <span className="input-icon-addon">
                                                    <i className="fe fe-search" />
                                                </span>
                                                <input type="text" className="form-control" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)}/>
                                            </div>
                                            <a href="#1" className="btn btn-outline-info ml-2"  onClick={()=>this.newModel()}><i className="fe fe-plus mr-2" /><IntlMessages id="button.add"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
                            <thead>
                                <tr>
                                    <th className="w80">#</th>
                                    <th ><IntlMessages id="model.name"/></th>
                                    <th className="text-center  w150"><IntlMessages id="model.category"/></th>
                                    <th className="w120"><IntlMessages id="genel.unit"/></th>
                                    <th className="w40"></th>
                                </tr>
                            </thead>
                            {modelList &&  modelList.length  ?
                                <tbody>
                                        {modelList.slice(startIndex, endIndex).map((row,i)=>{
                                        return (
                                            <tr key={i}>
                                                <td><span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top">{row.model_sira}</span></td>
                                                <td>
                                                    <a href={`#1`} onClick={()=>this.editModel(row._id)}><h6 className="mb-0">{row.model_adi[0]}</h6></a>
                                                    <a href={`#1`} onClick={()=>this.editModel(row._id)}><span>{row.model_adi[1]}</span></a>
                                                </td>
                                                <td className="text-center">
                                                    <span className={row.model_kategori===0 ?"tag tag-success": row.model_kategori===1 ?"tag tag-info":  row.model_kategori===2 ?"tag tag-warning": row.model_kategori===3 ?"tag tag-black": row.model_kategori===4 ?"tag tag-danger": "tag tag-danger"}>{messages["model.kategori"+row.model_kategori]}</span>
                                                </td>
                                                <td className="text-center">{<IntlMessages id={"genel."+ row.model_birimi}/>}</td>
                                                <td>
                                                    <div className="item-action dropdown">
                                                        <a href="#1"  data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-h" /></a>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(18px, 25px, 0px)' }}>
                                                            <a href="#1"className="dropdown-item" onClick={()=>this.editModel(row._id)}><i className="dropdown-icon fa fa-eye" /><IntlMessages id="user.viewdetails"/></a>
                                                            <a href="#2" className="dropdown-item"><i className="dropdown-icon fa fa-share-alt" /><IntlMessages id="user.email"/></a>
                                                            <a href="#3" className="dropdown-item"><i className="dropdown-icon fa fa-cloud-download" /><IntlMessages id="user.sms"/></a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )})}
                                </tbody>
                            :null}
                        </table>
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            onChangePage={i => this.onChangePage(i)}
                        />  
                    </div>
                </div>
            </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    productList:state.productList,
    modelList:state.modelList,
    locale:state.settings.locale
})

const mapDispatchToProps = ({getProductList,getModelList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Models)));
