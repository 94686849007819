import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Label10x10 from "./Label10x10/label10x10";
import { getCompany } from '../actions';

class PDFViewerModal extends Component {
    constructor(props) {
      super(props);
      this.state = {

        selectedOrderOption:"urun_adi"
      };
    }

    componentDidMount() {
      this.props.dispatch(getCompany({id:1}));
      this.props.Label && this.props.Label.type  &&
      this.setState({
        LabelData: this.props.Label
      }); 

    };


    render() {     
        const company = this.props.company.data;
        const LabelData = this.state.LabelData;
        const { messages } = this.props.intl;
        return  !this.props.company.loading && LabelData  ? (
            <Fragment>
              {  company && LabelData ?                
              <PDFViewer style={{height: '100vh',  width: '100vw',top: 0,left: 0}}>
                  {
                    LabelData.type ==="Kutu Etiketi"  && <Label10x10  company={company} data={LabelData} messages={messages}/>
                  }
              </PDFViewer>:<div className="loading" />}       
            </Fragment>
        ) : (
            <div className="loader" />
        );
    }
}
const mapStateToProps = state  => ({
    itemDetails : state.orderItemDetail,
    product: state.productDetail,
    company: state.myCompany,
    locale:state.settings.locale,
    Label:state.settings.LabelData
});
export default injectIntl(connect(mapStateToProps)(PDFViewerModal));
