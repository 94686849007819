import {
    PRODUCT_GET_LIST,
    PRODUCT_GET_LIST_SUCCESS,
	PRODUCT_GET_LIST_ERROR
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Offer List GET ****************************************************************************************************************************************************

export const product_list_set_pending = () => ({
	type: PRODUCT_GET_LIST
});

export const product_list_set_successful = (res) => ({
	type: PRODUCT_GET_LIST_SUCCESS,
	response: res
});

export const product_list_set_failed = () => ({
	type: PRODUCT_GET_LIST_ERROR
});

export const getProductList = (type,orderBy) => dispatch =>{
	const apiUrl_list = "/products/list";
	dispatch(product_list_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?type=${type}&orderBy=${orderBy}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(product_list_set_successful(res.data));
			} else {
				dispatch(product_list_set_failed());
			}
		})
		.catch((err) => {
			dispatch(product_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});

};
