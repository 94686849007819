import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import DeliveryReport from "./DeliveryReport/DeliveryReport";
import { getCompany,getOfferDetails } from '../actions';

class PDFViewerModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        offer:{},
      };
    }

    componentDidMount() {
      this.props.getOfferDetails({id:this.props.match.params.id})
      this.props.getCompany({id:1})
    };


    render() {     
        const {data} = this.props.offerDetails;
        const company = this.props.company.data;
        return !this.props.offerDetails.loading && !this.props.company.loading ?  (
            <Fragment>
              { data && company  ?                
              <PDFViewer   style={{height: '100vh',  width: '100vw',top: 0,left: 0}}>
                  <DeliveryReport data={data} company={company}  />
              </PDFViewer>:<div className="loader" />}       
            </Fragment>
        ) : (
            <div className="loader" />
        );
    }
}
const mapStateToProps = state  => ({
  offerDetails : state.offerDetail,
  company: state.myCompany,
  locale:state.settings.locale,
});

 const mapDispatchToProps  =({getOfferDetails,getCompany})
 export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PDFViewerModal));
