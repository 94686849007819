import { combineReducers } from 'redux';
import settings from './settings';
import auth from './auth';
import userList from './userList';
import userDetail from './userDetails';
import companyList from './companyList';
import companyDetail from './companyDetails';
import modelList from './modelList';
import modelDetail from './modelDetails';
import productList from './productList';
import productDetail from './productDetails';
import productStocks from './productStockList';
import materials from './materials';
import materialDetail from './materialDetails';
import stocks from './stockList';
import stockDetail from './stockDetails';
import myCompany from './myCompany';
import offerList from './offerList';
import offerDetail from './offerDetails';
import orderItemDetail from './orderItemDetails';
import orderItemList from './orderItemList';
import tcmb from './tcmb';
import tcmbList from './tcmbList';
import logList from './logList';
import todoList from './todoList';
import akaysisAuth from './akaysisauth';
import deviceList from './deviceList';
import deviceDetails from './deviceDetails';
import devicePrograms from './gw_programs';
import deviceCommands from './gw_commands';
import imageList from "./imageList";
import imageDetails from "./imageDetails";
import deliveryDetails from "./deliveriedDetail";
import deliveryList from "./deliveriedList";
import buyingList from "./buyings";
export default combineReducers({
    settings,
    auth,
    tcmb,
    tcmbList,
    myCompany,
    userList,
    userDetail,
    companyList,
    companyDetail,
    productList,
    productDetail,
    modelList,
    modelDetail,
    materials,
    materialDetail,
    stocks,
    stockDetail,
    offerList,
    offerDetail,
    orderItemDetail,
    orderItemList,
    logList,
    todoList,
    akaysisAuth,
    deviceList,
    deviceDetails,
    devicePrograms,
    deviceCommands,
    productStocks,
    imageList,
    imageDetails,
    deliveryDetails,
    deliveryList,
    buyingList


});