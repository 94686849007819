import React from 'react';
import { Page, Document, StyleSheet, Font, View,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
//************************************************ */

import OpenSansFont from "../../fonts/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../fonts/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../fonts/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../fonts/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontRegular',
        paddingTop: 17,
        paddingBottom:30,
      
    }, 
    Header: {
        width:842,
        height:120,
        backgroundColor:'#C0C0C0',
        flexDirection: "row"
    },
    HeaderContainerLeft:{
        marginTop:5,
        marginLeft:20,
        width:600,
        height:110,
 
    },
    HeaderContainerRight:{
        marginTop:5,
        marginRight:10,
        height:110,
        width:235,
        alignItems:'right'
    },
    logo: {
        width: 200,
        height: 50,
        marginRight: 10,
    },
    ProductImage: {
        width: 240,
        height: 240,
        marginLeft:602,
        marginTop:10,
        alignItems:'right',
        position: 'absolute',
        transform: 'scaleX(-1)'
    },
    CertificatesContainer: {
        flexDirection: "row",
        width: 300,
        height: 45,
        marginLeft:25,
        marginTop:90,
        borderRadius:5,
        backgroundColor:'#DCDCDC',
        alignItems:'center',
        position: 'absolute'
    },
    ProductCodeContainer: {
        flexDirection: "row",
        width: 550,
        height: 18,
        marginLeft:25,
        marginTop:20,
        borderTopRightRadius:12,
        borderBottomRightRadius:12,
        backgroundColor:'#DCDCDC'      
    },
    ProductLabelContainer: {
        flexDirection: "row",
        width: 530,
        height: 18,
        marginLeft:25,
        marginTop:1,
        borderBottomWidth:0.5,
        borderBottomColor:'#DCDCDC'    
    },
    ProductLabelCol150: {
        flexDirection: "row",
        width: 150,
        height: 18,
    },
    ProductLabelCol80: {
        flexDirection: "row",
        width: 80,
        height: 18,
    },
    ProductLabelCol100: {
        flexDirection: "row",
        width: 100,
        height: 18,
    },
    ProductTableLabelContainer: {
        flexDirection: "row",
        width: 780,
        height: 20,
        marginLeft:25,
        marginTop:20
    },
    ProductTableLabelCol320: {
        flexDirection: "row",
        width: 320,
        height: 20,
        borderTopRightRadius:12,
        borderBottomRightRadius:12,
        backgroundColor:'#DCDCDC'   
    },
    ProductTableLabelCol240: {
        marginLeft:20,
        flexDirection: "row",
        width: 240,
        height: 20,
        borderTopRightRadius:12,
        borderBottomRightRadius:12,
        backgroundColor:'#DCDCDC' ,
    },
    ProductTableLabelCol190: {
        flexDirection: "row",
        width: 190,
        height: 20,
        marginLeft:20,
        borderTopRightRadius:12,
        borderBottomRightRadius:12,
        backgroundColor:'#DCDCDC' 
    },
    ProductTableRowContainer: {
        flexDirection: "row",
        width: 780,
        marginLeft:25,
    },
    ProductTableRowCol320: {
        flexDirection: "column",
        width: 320,
        marginRight:20,
    },
    ProductTableRowCol240: {
        flexDirection: "column",
        width: 240,
        minHeight:30,
        alignItems:"center"
    },
    ProductFeatureRowCol320: {
        flexDirection: "row",
        width: 320,
        height:30,
        borderBottom:0.3,
        alignSelf:"center"
    },
    ProductFeatureRowCol240: {
        flexDirection: "row",
        width: 240,
        maxHeight:100,
        borderBottom:0.3,
        alignSelf:"center"
    },
    FeatureLabelCol130: {
        marginLeft:3,
        flexDirection: "column",
        width: 130,
        height:30,
        alignSelf:"center"
    },
    FeatureCol170: {
        flexDirection: "column",
        width: 170,
        height:30,
        alignSelf:"center"
    },
    FeatureLabelCol80: {
        flexDirection: "column",
        justifyContent:"center",
        minHeight:30
    },
    FeatureCol160: {
        flexDirection: "column",
        width: 160,
        maxHeight:100,
        justifyContent:"center"
    },
    ProductTableRowCol190: {
        flexDirection: "row",
        width: 190,
        marginLeft:20,
        borderBottom:0.3,
    },
    technicalDrawing: {
        width: "auto",
        maxHeight:300,
        objectFit:"contain"
    },
  });
const TextHeaderTR = styled.Text`
margin_top: 15px;
height:30;
font-size: 20px;
text-align: left;
color: #09080a;
`;
const TextHeaderEN = styled.Text`
margin: 0px;
height:30;
font-size: 14px;
text-align: left;
color: #0C71E0;
`;
const Certificates = styled.Image`
margin_left: 1px;
height: 36;
width: 36;
color: #0C71E0;
`;
const Label = styled.Text`
margin_top: 4px;
margin_left:4px;
height:18;
font-size: 9px;
align-self:center;
text-align: left;
font-family:'OpenSansFontBold';
`;
const LabelLight9px = styled.Text`
margin_top: 6px;
margin_left:2px;
height:18;
font-size: 9px;
text-align: left;
align-self:center;
color: #09080a;
font-family:'OpenSansFont';
`;
const LabelRegular9px = styled.Text`
margin_top: 6px;
margin_left:2px;
height:18;
font-size: 9px;
text-align: left;
align-self:center;
font-family:'OpenSansFontRegular';
`;

const OzellikEtiket = styled.Text`
color: #0C71E0;
margin_top: 11px;
width:130;
height:30px;
font-size: 8px;
text-align: left;
align-self:center;
font_family:'OpenSansFontRegular';
`;
const Ozellik = styled.Text`
margin_top: 11px;
width:170;
height:30;
font-size: 8px;
text-align: left;
align-self:center;
font_family:'OpenSansFontRegular';
`;

const UrunOzellik = styled.Text`
color: #0C71E0;
width:80px;
margin_top:14px;
height:30px;
font-size: 8px;
text-align: left;
align-self:center;
font_family:'OpenSansFontRegular';
`;
const UrunOzellikDetay = styled.Text`
margin_top: 2px;
width:160px;
height:12px;
font-size: 8px;
text-align: left;
font_family:'OpenSansFontRegular';
`;

const DataSheet = ({company,product,messages,Lang}) =>{
    const productImages  = product.productImages
    const ThecnicalDrawing =  productImages &&  productImages.length && productImages.filter(x=>x.description ==="Teknik Resim").length ? (productImages.filter(x=>x.description ==="Teknik Resim"))[0].image.image :"../assets/images/TRH.png"
    const MainImage =  productImages &&  productImages.length && productImages.filter(x=>x.description ==="Ana Görsel").length ? (productImages.filter(x=>x.description ==="Ana Görsel"))[0].image.image : "../assets/images/TRH.png"
    const productCertificates =  product && product.urun_model &&  product.urun_model.certificates;
    const {certificates}  = company;
    const ModelKodu = product.urun_model && product.urun_model.model_kodu ? product.urun_model.model_kodu  :""
    const PCBKodu = product.urun_kodu ? ("-"+product.urun_kodu):""
    const Optik = product.urun_optik ? ("-"+product.urun_optik):""
    const CCT  = product.urun_cct ? ("-"+product.urun_cct):""
    const Solar  = product.urun_panel ? ("-"+product.urun_panel):""
    const Battery  = product.urun_aku ? ("-"+product.urun_aku):""
    
    return(
    <Document file={"moduledas.pdf"} className="pdfPreview" loading={<div className="loading" creator={company.sirket_adi}/>} >
        {company.sirket_rapor_logo && 
        <Page size="A4" style={styles.page} orientation={"landscape"}>

            <View style={styles.Header}>
                <View style={styles.HeaderContainerLeft}>
                    <TextHeaderTR>{product.urun_model.model_adi[Lang]} </TextHeaderTR>
                    <TextHeaderEN>{product.urun_adi[Lang]}</TextHeaderEN>
                </View>
                <View style={styles.HeaderContainerRight}>
                    <Image style={styles.logo} src={company.sirket_rapor_logo}/>       
                </View>
                <Image style={styles.ProductImage} src={MainImage}/>  
                <View style={styles.CertificatesContainer} >
                    {productCertificates  && productCertificates.length  && productCertificates.map((cer,keyIndex)=>{
                        return(
                            <>
                                {cer.label ? 
                                    <Certificates key={keyIndex} style={styles.Certificates} src={certificates ? certificates.filter(x=>x.certificate===cer.label)[0].file : "../assets/images/ry.png"} />
                                :
                                    <Certificates key={keyIndex} style={styles.Certificates} src={certificates ? certificates.filter(x=>x.certificate===cer)[0].file : "../assets/images/ry.png"} />
                                }
                            </>
                        )
                    })}
                </View>
            </View>
            <View style={styles.ProductCodeContainer}>
                <Label>{messages["product.code"]}</Label>
            </View>  
            <View style={styles.ProductLabelContainer}>
                <View style={styles.ProductLabelCol150}>
                   <LabelLight9px>{messages["product.code"]}</LabelLight9px>
                </View>                 
                <View style={styles.ProductLabelCol80}>
                   <LabelLight9px>{messages["product.power"]}</LabelLight9px>
                </View>                      
                <View style={styles.ProductLabelCol80}>
                   <LabelLight9px>{messages["product.lumen"]}</LabelLight9px>
                </View>    
                <View style={styles.ProductLabelCol80}>
                   <LabelLight9px>{messages["product.weight"]}</LabelLight9px>
                </View>  
                <View style={styles.ProductLabelCol100}>
                   <LabelLight9px>{messages["product.dimensions"]}</LabelLight9px>
                </View>  
            </View> 
            <View style={styles.ProductLabelContainer}>
                <View style={styles.ProductLabelCol150}>
                    <LabelRegular9px>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</LabelRegular9px>
                </View>                 
                <View style={styles.ProductLabelCol80}>
                   <LabelRegular9px>{product.urun_guc+" W"}</LabelRegular9px>
                </View>                      
                <View style={styles.ProductLabelCol80}>
                    <LabelRegular9px>{product.urun_lumen+" lm"}</LabelRegular9px>
                </View>    
                <View style={styles.ProductLabelCol80}>
                   <LabelRegular9px>{product.product_weight+" kg"}</LabelRegular9px>
                </View>  
                <View style={styles.ProductLabelCol100}>
                   <LabelRegular9px>{product.product_dimensions}</LabelRegular9px>
                </View>  
            </View> 
            <View style={styles.ProductTableLabelContainer}>
                <View style={styles.ProductTableLabelCol320}>
                    <Label>{messages["product.generalspecs"]}</Label>
                </View>  
                <View style={styles.ProductTableLabelCol240}>
                   <Label>{messages["product.electricalspecs"]}</Label>
                </View>                      
                <View style={styles.ProductTableLabelCol190}>
                    <Label>{messages["product.tecnicaldrawing"]}</Label>
                </View>    
            </View> 

            <View style={styles.ProductTableRowContainer}>
                {product.urun_model && product.urun_model.ModelFeatures && product.urun_model.ModelFeatures.length && 
                <View style={styles.ProductTableRowCol320}>
                    {product.urun_model.ModelFeatures.slice(0,10).map((row,index)=>{
                        return(
                            <View style={styles.ProductFeatureRowCol320} key={index}>
                                <View style={styles.FeatureLabelCol130}>
                                    <OzellikEtiket>{Lang !== 0 && (row.label[Lang] === ""  || row.label[Lang] === "-") ?row.label[0] :row.label[Lang]}</OzellikEtiket>
                                </View>
                                <View style={styles.FeatureCol170} >
                                    <Ozellik>{Lang !== 0 && (row.feature[Lang] === ""  || row.feature[Lang] === "-") ?row.feature[0]: row.feature[Lang]}</Ozellik>                               
                                </View>
                            </View>  
                        )
                    })}
                </View>}  

                {product.urun_ozellikler &&  product.urun_ozellikler.length && <View style={styles.ProductTableRowCol240}>
                    {product.urun_ozellikler.slice(0,5).map((row,index1)=>{
                        return(
                            <View style={styles.ProductFeatureRowCol240} key={index1}>
                                <View style={styles.FeatureLabelCol80}>
                                    <UrunOzellik>{row.urun_ozellik[Lang]}</UrunOzellik>
                                </View>
                                <View style={styles.FeatureCol160} >
                                    {Lang === 0 ? 
                                    row.urun_aciklama.map((val,valIndex)=>{
                                        return(
                                            <UrunOzellikDetay key={valIndex}>{val}</UrunOzellikDetay> 
                                        )
                                    }):
                                    row.product_description.map((val,valIndex)=>{
                                        return(
                                            <UrunOzellikDetay key={valIndex}>{val}</UrunOzellikDetay> 
                                        )
                                    })
                                    }
                                                                  
                                </View>
                            </View>  
                        )
                    })}
                </View> } 

                <View style={styles.ProductTableRowCol190}>
                    <Image style={styles.technicalDrawing} src={ThecnicalDrawing}/>
                </View>    
            </View> 





        </Page>
        }
    </Document>
)};
  
export default DataSheet