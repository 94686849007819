import {
    PRODUCT_STOCK_GET_LIST,
    PRODUCT_STOCK_GET_LIST_SUCCESS,
	PRODUCT_STOCK_GET_LIST_ERROR,
	PRODUCT_STOCK_OUTGET_LIST,
	PRODUCT_STOCK_GET_OUTLIST_SUCCESS,
	PRODUCT_STOCK_GET_OUTLIST_ERROR
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_STOCK_GET_LIST: // GEL LIST 
			return { ...state,data:[], loading: true };
		case PRODUCT_STOCK_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.productstock,  
 
            };
		case PRODUCT_STOCK_GET_LIST_ERROR:
			return { ...state,data:[], loading: false };
		case PRODUCT_STOCK_OUTGET_LIST: // GEL LIST 
			return { ...state,data:[], loading: true };
		case PRODUCT_STOCK_GET_OUTLIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.productstock,  
            };
		case PRODUCT_STOCK_GET_OUTLIST_ERROR:
			return { ...state,data:[], loading: false };
		default: return  {...state};
	}
}
