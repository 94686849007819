import {
    GET_IMAGE_LIST,
    GET_IMAGE_LIST_SUCCESS,
	GET_IMAGE_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
//Log List GET ****************************************************************************************************************************************************

export const image_list_set_pending = () => ({
	type: GET_IMAGE_LIST
});

export const image_list_set_successful = (res) => ({
	type: GET_IMAGE_LIST_SUCCESS,
	response: res
});

export const image_list_set_failed = () => ({
	type: GET_IMAGE_LIST_ERROR
});

export const getImageList = (filter) => dispatch =>{
	const apiUrl_list = `/images/list/${filter}`;
	dispatch(image_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(image_list_set_successful(res.data));
				resolve(true);
			} else { 
				dispatch(image_list_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(image_list_set_failed());
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
};
