import {
    STOCK_GET_LIST,
    STOCK_GET_LIST_SUCCESS,
	STOCK_GET_LIST_ERROR,
	STOCK_OUTGET_LIST,
	STOCK_GET_OUTLIST_SUCCESS,
	STOCK_GET_OUTLIST_ERROR
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from './auth';
//Stock List GET ****************************************************************************************************************************************************

export const stock_list_set_pending = () => ({
	type: STOCK_GET_LIST
});

export const stock_list_set_successful = (res) => ({
	type: STOCK_GET_LIST_SUCCESS,
	response: res
});

export const stock_list_set_failed = () => ({
	type: STOCK_GET_LIST_ERROR
});

export const getStockList = (id) => dispatch =>{
	const apiUrl_list = `/stock/list/${id}`;
	dispatch(stock_list_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(stock_list_set_successful(res.data));
			} else {
				dispatch(stock_list_set_failed());
			}
		})
		.catch((err) => {
			dispatch(stock_list_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};

//Stock List GET ****************************************************************************************************************************************************

export const stock_outlist_set_pending = () => ({
	type: STOCK_OUTGET_LIST
});

export const stock_outlist_set_successful = (res) => ({
	type: STOCK_GET_OUTLIST_SUCCESS,
	response: res
});

export const stock_outlist_set_failed = () => ({
	type: STOCK_GET_OUTLIST_ERROR
});

export const getStockOutList = (date) => dispatch =>{
	const apiUrl_list = `/stock/outlist/${date}`;
	dispatch(stock_outlist_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(stock_outlist_set_successful(res.data));
			} else {
				dispatch(stock_outlist_set_failed());
			}
		})
		.catch((err) => {
			dispatch(stock_outlist_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};