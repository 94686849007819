import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from "../../common/Pagination";
import { withRouter } from 'react-router';
import { injectIntl } from "react-intl";
import IntlMessages from "../../../helpers/IntlMessages";
import FileBase64 from '../../../helpers/FileBase64';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { store } from 'react-notifications-component';
import {getImageList,getImageDetails,createImage,updateImages,deleteImage,getModelList} from '../../../actions';
import moment from "moment";
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
			selectedPageSize: 100,
            pageSizes: [6, 12, 18, 30, 50, 100],
			imageModalOpen:false,
            currentPage: 1,
            totalItemCount: 0,
            totalPage: 1,
            companyModalOpen:false,
			imageDescriptionList : [
                {value : "Ana Görsel" , label : "Ana Görsel"},
                {value : "Yan Görsel" , label : "Yan Görsel"},
                {value : "Teknik Resim" , label : "Teknik Resim"},
                {value : "Renkli Resim" , label : "Renkli Resim"},
            ],

        };
      }
//******************************************************************************************************************************************************************/
    componentDidMount() {
		this.props.getImageList("none");
    }
//******************************************************************************************************************************************************************/
    componentWillReceiveProps(nextProps){
        if (this.props.imageList.data !== nextProps.imageList.data) {
            this.setState({
                imageList: nextProps.imageList.data,
                totalItemCount:nextProps.imageList.data.length,
                totalPage: Math.ceil(nextProps.imageList.length/this.state.selectedPageSize) 
            }); 
		};  
		if (this.props.imageDetails.data !== nextProps.imageDetails.data) {
            this.setState({
                image: nextProps.imageDetails.data,
            }); 
		}; 
    }
//******************************************************************************************************************************************************************/
    onChangePage = page => {
        this.setState({
            currentPage: page
        });
    };
//******************************************************************************************************************************************************************/
    onSearchKey = search => {
        let filteredList =[]; 
		filteredList =  this.props.imageList.data.filter(item => {return item.description.toLowerCase().includes(search.target.value.toLowerCase())
		})
        if (search && search.target.value===""){
            this.setState({
                imageList:this.props.imageList.data,
                totalItemCount:this.props.imageList.data &&  this.props.imageList.data.length ? this.props.imageList.data.length:0,
                totalPage: Math.ceil((this.props.imageList.data &&  this.props.imageList.data.length  ? this.props.imageList.data.length : 0) /this.state.selectedPageSize)
            })
        }else{
            this.setState({
                userList:filteredList,
                totalItemCount:this.state.imageList &&  this.state.imageList.length ? this.state.imageList.length:0,
                totalPage: Math.ceil((this.state.imageList &&  this.state.imageList.length ? this.state.imageList.length : 0)/this.state.selectedPageSize)
            })
        }
    };
//******************************************************************************************************************************************************************/
    changeOrderBy = column => {
        this.setState({
            sortColumn:column
        });
	};
//******************************************************************************************************************************************************************/
	toggleImageModal=(id)=>{
		this.props.getModelList("list","");
		if(id){
			this.props.getImageDetails(id)
			.catch(err => {
				if(err){
					if(err.response && err.response.status && err.response.status === 403){
					}
				}
			})
			this.setState({

				imageModalOpen:!this.state.imageModalOpen
			});
		}else{
			this.setState({
				image: {
					_id:null,
					model:[],
					image: "",
					description: "",
				},
				imageModalOpen:!this.state.imageModalOpen
			});
		}
	}
//******************************************************************************************************************************************************************/
	closeModal=()=>{
		this.setState({
			imageModalOpen:!this.state.imageModalOpen
		},()=>this.props.getImageList("none"));
	}
//******************************************************************************************************************************************************************/
	onChangeImageData = (field,data) => {
		this.setState(prevState => {
			let image = Object.assign({}, prevState.image); 
			image[field] = data;   
			return { image };    
		});
	}
//******************************************************************************************************************************************************************/
	getImages=(field,files)=>{
		this.setState(prevState => {
			let image = Object.assign({}, prevState.image); 
			image[field] = files.base64;                         
			return { image };    
		});
	}
//******************************************************************************************************************************************************************/
	handleSubmitMyForm = () => {   
		const { messages } = this.props.intl;
			if (this.state.image._id ){
				this.props.updateImages({...this.state.image})
				.then(res=>{
					store.addNotification({
						title: messages["uyari.basarili"],
						message: messages["bilgi.resimguncellendi"],
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					//});
					});
					
				},()=>this.props.getImageList("none"))//filter none
				.catch(err=>{
					if(err){
						store.addNotification({
							title: this.props.imageDetails.status.statusText,
							message: this.props.imageDetails.status.data,
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
			}else{
				this.props.createImage({...this.state.image})
				.then(res=>{
					this.props.getImageList("none")
					store.addNotification({
						title: messages["uyari.basarili"],
						message: messages["bilgi.resimolusturuldu"],//{messages[x.label]} 
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					});                    
				})//filter none
				.catch(err=>{
					if(err){
						store.addNotification({
							title: this.props.userDetail.status.statusText,
							message: this.props.userDetail.status.data,
							type: "danger",
							insert: "top",
							container: "top-right",
							dismiss: {
							duration: 3000,
							onScreen: true
							}
						});
					}
				})
			}
			this.setState({
				imageModalOpen:!this.state.imageModalOpen
			})

	}
//******************************************************************************************************************************************************************/
	deleteImage = () =>{
		let id = this.state.image._id;
		if (window.confirm("Resimi Silmek istiyor musunuz ?")) {
			this.props.deleteImage(id)
			.then(res=>{
				this.closeModal();
			}) 
		}else{
			this.closeModal();
		}
	}
//******************************************************************************************************************************************************************/
	render() {
		// const { locale} = this.props;
		// const Lang = locale === "tr" ? 0  : 1 ;
		const { messages } = this.props.intl;
        const {imageList,image,selectedPageSize,currentPage,totalItemCount,imageDescriptionList} = this.state
        const startIndex = (currentPage - 1) * selectedPageSize;
		const endIndex = currentPage * selectedPageSize;
		return (
			<>
                <div  className={this.props.imageList.loading ? "dimmer active" : ""}>
                    {this.props.imageList.loading && <div className="loader"/>}
                    <div className={this.props.imageList.loading ? "dimmer-content"  : ""}>
						<div className="container-fluid">
							<div className="row row-cards">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
								
											<div className="page-subtitle ml-0">{`${startIndex}-${endIndex} of ${totalItemCount &&  totalItemCount} `}</div>
											<div className="page-options d-flex">
												<select className="custom-select w-auto"  onChange={(evt)=>this.changeOrderBy(evt.target.value)}>
													<option value={"firstname"}>{messages["user.sortfirstname"]} </option>
													<option value={"lastname"}>{messages["user.sortlastname"]}</option>
												</select>
												<div className="input-icon ml-2">
													<span className="input-icon-addon">
														<i className="fe fe-search" />
													</span>
													<input type="text" className="form-control" placeholder="Search..." onKeyPress={e => this.onSearchKey(e)}/>
												</div>
												<button type="button" className="btn btn-success ml-2"  onClick={()=>this.toggleImageModal(null)}><i className="fe fe-plus mr-2" /><IntlMessages id="button.add"/></button>
										
											</div>
										</div>
									</div>
								</div>
							</div>						
							<div className="table-responsive">
								<table className="table table-vcenter table_custom spacing5 border-style mb-0">
									<thead>
										<tr>
											<th className="w-auto">#</th>
											<th><IntlMessages id="image.description"/></th>
											<th><IntlMessages id="image.models"/></th>
											<th className="text-center"><IntlMessages id="user.createddate"/></th>

										</tr>
									</thead>
									{imageList ?
										<tbody>
											{imageList.slice(startIndex, endIndex).map((row,i)=>{
									
											return(
												<tr key={i}>
													<td>
														<a href="#1" onClick={()=>this.toggleImageModal(row._id)}>
															<img
																src={row.image}
																data-toggle="tooltip"
																data-placement="top"
																alt="Avatar"
																className="avatar avatar-lg"
																data-original-title="Avatar Name"
															/>
														</a>
													</td>
													<td><h6 className="mb-0"><a href="#1" onClick={()=>this.toggleImageModal(row._id)}>{row.description}</a></h6></td>
													<td><h6>{row.model && row.model.map((x,y)=>{return(<span key={y} className="tag tag-info ml-1">{x.label} </span>)})}</h6></td>
													<td className="text-center">{moment(row.date).format("DD.MM.YYYY")}</td>
												</tr>
												)
											})}
										</tbody>:
										null}
								</table>
								<Pagination
									currentPage={this.state.currentPage}
									totalPage={this.state.totalPage}
									onChangePage={i => this.onChangePage(i)}
								/>
							</div>
						</div>
					</div>
				</div>


                <Modal
					isOpen={this.state.imageModalOpen}
					size="lg"
					backdrop="static"
				>
					<ModalHeader>
						<IntlMessages id="image.edit"/>
					</ModalHeader>

					<ModalBody>
						<div  className={image && image._id &&  this.props.imageDetails.loading ? "dimmer active" : ""}>
							{image && image._id && this.props.imageDetails.loading && <div className="loader"/>}
							<div className={image && image._id && this.props.imageDetails.loading ? "dimmer-content"  : ""}>
								<div className="row">
									<div className="col-md-3 col-sm-3">
										<div className="form-group">
											<label className="form-label"><IntlMessages id="image.image"/></label>
											<img className="rounded" src={image && image.image ? image.image : "../assets/images/ry.png"} alt="Moduled"  />
										</div>
									</div>
									<div className="col-md-9 col-sm-9">
										<div className="row clearfix">
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<label className="form-label"><IntlMessages id="image.model"/></label>
														<Select
															components={{ Input: CustomSelectInput }}
															className="react-select"
															classNamePrefix="react-select"
															isMulti
															value={image && image.model}
															onChange={(e)=>this.onChangeImageData("model",e)}
															options={this.props.modelList.data && this.props.modelList.data.map(x=>({label:x.model_kodu,value:x._id}))}
														/>
												</div>
											</div>
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<label className="form-label"><IntlMessages id="image.description"/></label>
													<select 
                                                            className="form-control"
                                                            onChange={(evt)=>this.onChangeImageData("description",evt.target.value)}
                                                            value={image && image.description && image.description}
                                                        >
                                                                    <option value={""} >{messages["genel.choose"]}</option>
                                                            {imageDescriptionList && imageDescriptionList.map((x,y)=>{
                                                                return(
                                                                    <option key={y}  value={x.value}>{x.label} </option>
                                                                )
                                                            })}
                                                    </select>

													
												</div>
											</div>
										</div>
									</div>

									<hr/>
									<div className="col-lg-12 col-md-12 col-sm-12">
										<div className="form-group">
											<label className="form-label"><IntlMessages id="user.selectimages" /></label>
											<FileBase64
												multiple={ false }
												onDone={ (e)=>this.getImages("image",e)} 
											/>
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12">
										<div className="form-group">
											<label className="form-label"><IntlMessages id="user.selectimages" /></label>
											<FileBase64
												multiple={ false }
												onDone={ (e)=>this.getImages("smallimage",e)} 
											/>
											<img
												src={image && image.smallimage ? image.smallimage : "../assets/images/ry.png"}
												data-toggle="tooltip"
												data-placement="top"
												alt="Avatar"
												className="avatar avatar-lg"
												data-original-title="Avatar Name"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						{image && image._id && <button className="btn btn-danger" onClick={()=>this.deleteImage()}><IntlMessages id="button.delete"/></button>}
						{image && image.image && <button className="btn btn-success" onClick={()=>this.handleSubmitMyForm()}><IntlMessages id="button.save"/></button>}
						<button className="btn btn-warning" onClick={()=>this.closeModal()}><IntlMessages id="button.close"/></button>
					</ModalFooter>
				</Modal>
																			


			</>
		);
	}
}
const mapStateToProps = state => ({
	imageList: state.imageList,
	imageDetails: state.imageDetails,
	modelList:state.modelList,
	auth:state.auth
})

const mapDispatchToProps =  ({getImageList,getImageDetails,createImage,updateImages,deleteImage,getModelList})
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Users)));